<template>
  <Row id="UploadRecord">
    <Col :span="24" class="title">
      <TypographyTitle>{{$t('nav.name.UploadRecordManage')}}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24" >
          <Form :model="searchForm">
            <Row :gutter="30">
              <!-- <Col :xs="24" :sm="8">
                <FormItem :label="$t('member.name.search_user_name')" name="search">
                  <Input :placeholder="$t('member.name.search_user_name')" v-model:value="searchForm.search"></Input>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="8">
                <FormItem :label="$t('public.field.status')">
                  <Select v-model:value="searchForm.status" :placeholder="$t('public.placeholder.please_select')">
                    <SelectOption :value="0">{{ $t('public.placeholder.please_select') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('public.status.1') }}</SelectOption>
                    <SelectOption :value="2">{{ $t('public.status.2') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col> -->
              <Col :xs="24" :sm="24" class="text-right">
                <!-- <Button type="primary" @click="search_submit">{{$t('public.name.search')}}</Button>&nbsp;
                <Button @click="search_reset">{{$t('public.name.reset')}}</Button> -->
                <Button type="primary" @click="downloadMpfStatement">{{$t('downloadMpfStatement.name.title')}}</Button>&nbsp;
                <Button type="primary" @click="downloadMpfStatementYear">{{$t('downloadMpfStatementYear.name.title')}}</Button>&nbsp;
                <Button type="primary" @click="downloadGerenalInsurance">{{$t('downloadGerenalInsurance.name.title')}}</Button>&nbsp;
                <Button type="primary" @click="downloadEaeGiList">{{$t('downloadEaeGiList.name.title')}}</Button>&nbsp;
              </Col>
            </Row>
          </Form>
        </Col>
        <Col :span="24">
        <Table :columns="columns" :dataSource="list" :scroll="{ x: true }" rowKey="idUplRec" :loading="loading" :pagination="tablePagination" @change="chang_page" @expand="expandRow">
            <template #actionTitle>
              <span>
                {{$t("public.table.action")}}
                <Button v-if="checkPermission('UploadRecordManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined />
                </Button>
              </span>
            </template>
            <template #action="{text}">
              <div>
                <div class="pc-display">
                  <Button type="default" size="small" @click="detail(text)">{{$t("public.name.detail")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('UploadRecordManage.Edit')" type="primary" size="small" @click="edit(text)">{{$t("public.name.edit")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('UploadRecordManage.Delete')" type="primary" danger size="small" @click="del(text.idUplRec)">{{$t("public.name.del")}}</Button>
                </div>
                <Dropdown :trigger="['click']" class="wap-display">
                  <template #overlay>
                    <Menu>
                      <MenuItem @click="del(text.id)">{{$t('public.name.del')}}</MenuItem>
                    </Menu>
                  </template>
                  <Button size="small">
                    {{$t("public.name.more")}}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </template>
            <template #expandedRowRender="{record:pRecord, index:pIndex, indent, expanded}">
              <Table :columns="pRecord.type === 1 ? mpfColumns: giColumns" :data-source="pRecord.innerData" :pagination="false">
                
              </Table>
            </template>
          </Table>
        </Col>
      </Row>
    </Col>
    <UploadRecordDetail ref="refUploadRecordDetail"></UploadRecordDetail>
    <UploadRecordEdit ref="refUploadRecordEdit" @updateList="get_list"></UploadRecordEdit>
    <DownloadMpfStatement ref="refDownloadMpfStatement"></DownloadMpfStatement>
    <DownloadMpfStatementYear ref="refDownloadMpfStatementYear"></DownloadMpfStatementYear>
    <DownloadGerenalInsurance ref="refDownloadGerenalInsurance"></DownloadGerenalInsurance>
  </Row>
</template>

<script>
import { createVNode } from "vue";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, Form, Input, Select, message } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { UploadRecordList } from "@/api/UploadRecordList";
import { UploadRecordDelete } from "@/api/UploadRecordDelete"
import { MpfGetByIdUplRec } from "@/api/MpfGetByIdUplRec"
import { GiGetByIdUplRec } from "@/api/GiGetByIdUplRec"
import { GiDownAll } from "@/api/GiDownAll"
import UploadRecordDetail from "@/components/Home/UploadRecordDetail";
import UploadRecordEdit from "@/components/Home/UploadRecordEdit";
import DownloadMpfStatement from "@/components/Home/DownloadMpfStatement";
import DownloadMpfStatementYear from "@/components/Home/DownloadMpfStatementYear";
import DownloadGerenalInsurance from "@/components/Home/DownloadGerenalInsurance";
import moment from "moment";

export default {
  name: "UploadRecordManage",
  components: {
    Row,
    Col,
    Table,
    Form,
    FormItem: Form.Item,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Input,
    Select,
    SelectOption: Select.Option,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    Menu,
    MenuItem: Menu.Item,
    UploadRecordDetail,
    UploadRecordEdit,
    DownloadMpfStatement,
    DownloadMpfStatementYear,
    DownloadGerenalInsurance,
  },
  data() {
    return {
      searchForm: {
      },
      tablePagination: {
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      list: [],
      columns: [
          { title: this.$t("uploadRecord.table.id_upl_rec"), fixed: "left", dataIndex: "idUplRec", key: "idUplRec" },
          { title: this.$t("uploadRecord.table.type"), dataIndex: "type", key: "type", customRender: ({text}) =>{
              return this.selectList.typeList.find(item=>item.value===text).label
            }
          },
          { title: this.$t("uploadRecord.table.created_time"), dataIndex: "createdTime", key: "createdTime" },
        { key: "action", fixed: "right", slots: { title: "actionTitle", customRender: "action" } },
      ],
      selectList:{
        typeList: [{value: 1,label: 'Mpf'},{value:2,label: 'GI'}],
      },
      mpfColumns: [
          { title: this.$t("mpf.table.id_mpf"), fixed: "left", dataIndex: "idMpf", key: "idMpf" },
          { title: this.$t("mpf.table.mpf_no"), dataIndex: "mpfNo", key: "mpfNo" },
          { title: this.$t("mpf.table.mpf_date"), dataIndex: "mpfDate", key: "mpfDate" },
          { title: this.$t("mpf.table.provider"), dataIndex: "provider", key: "provider" },
          { title: this.$t("mpf.table.scheme_no"), dataIndex: "schemeNo", key: "schemeNo" },
          { title: this.$t("mpf.table.company_name"), dataIndex: "companyName", key: "companyName" },
          { title: this.$t("mpf.table.net_commission"), dataIndex: "netCommission", key: "netCommission" },
          { title: this.$t("mpf.table.commission"), dataIndex: "commission", key: "commission", customRender: ({text,record}) => {
            return record.netCommission / 2;
          } },
          { title: this.$t("mpf.table.contribution_period"), dataIndex: "contributionPeriod", key: "contributionPeriod", customRender: ({text,record}) => {
            return 'Asset Transfer';
          } },
        { key: "action", fixed: "right", slots: { title: "actionTitle", customRender: "action" } },
      ],
      giColumns: [
          { title: this.$t("gi.table.id_gi"), fixed: "left", dataIndex: "idGi", key: "idGi" },
          { title: this.$t("gi.table.date_time"), dataIndex: "dateTime", key: "dateTime", customRender: ({text}) =>{return moment(text).format('YYYY-MM-DD')}},
          { title: this.$t("gi.table.tr"), dataIndex: "tr", key: "tr" },
          { title: this.$t("gi.table.insurer"), dataIndex: "insurer", key: "insurer" },
          { title: this.$t("gi.table.policy_no"), dataIndex: "policyNo", key: "policyNo" },
          { title: this.$t("gi.table.risk"), dataIndex: "risk", key: "risk" },
          { title: this.$t("gi.table.insured"), dataIndex: "insured", key: "insured" },
          { title: this.$t("gi.table.period_from"), dataIndex: "periodFrom", key: "periodFrom", customRender: ({text}) =>{return moment(text).format('YYYY-MM-DD')}},
          { title: this.$t("gi.table.period_to"), dataIndex: "periodTo", key: "periodTo", customRender: ({text}) =>{return moment(text).format('YYYY-MM-DD')}},
          { title: this.$t("gi.table.gross_premium"), dataIndex: "grossPremium", key: "grossPremium" },
          { title: this.$t("gi.table.commission"), dataIndex: "commission", key: "commission" },
          { title: this.$t("gi.table.net_amount"), dataIndex: "netAmount", key: "netAmount" },
          { title: this.$t("gi.table.payment"), dataIndex: "payment", key: "payment" },
          { title: this.$t("gi.table.balance"), dataIndex: "balance", key: "balance" },
          { title: this.$t("gi.table.tr_rate"), dataIndex: "trRate", key: "trRate" },
          { title: this.$t("gi.table.eae_received"), dataIndex: "eaeReceived", key: "eaeReceived" },
          { title: this.$t("gi.table.eae_rate"), dataIndex: "eaeRate", key: "eaeRate" },
          { title: this.$t("gi.table.com"), dataIndex: "com", key: "com" },
          { title: this.$t("gi.table.levy"), dataIndex: "levy", key: "levy" },
          { title: this.$t("gi.table.bank"), dataIndex: "bank", key: "bank" },
          { title: this.$t("gi.table.cq_no"), dataIndex: "cqNo", key: "cqNo" },
          { title: this.$t("gi.table.amount"), dataIndex: "amount", key: "amount" },
          { title: this.$t("gi.table.amount_date"), dataIndex: "amountDate", key: "amountDate", customRender: ({text}) =>{return moment(text).format('YYYY-MM-DD')}},
          { title: this.$t("gi.table.com_rev_date"), dataIndex: "comRevDate", key: "comRevDate", customRender: ({text}) =>{return moment(text).format('YYYY-MM-DD')}},
        { key: "action", fixed: "right", slots: { title: "actionTitle", customRender: "action" } },
      ],
    };
  },
  methods: {
    chang_page(pagination) {
      this.tablePagination = pagination;
      this.get_list();
    },
    get_list() {
      this.loading = true;
      const api = new UploadRecordList();
      const getData = {
        page: this.tablePagination.current,
        limit: this.tablePagination.pageSize,
      }
      // if (this.searchForm.search != "") getData.search = this.searchForm.search
      // if (this.searchForm.status != 0) getData.status = this.searchForm.status
      // if (this.searchForm.order != "") getData.order = this.searchForm.order
      api
        .get(getData)
        .then((response) => {
          this.loading = false;
          if (0 === parseInt(response.data.code)) {
            const dataTmp = response.data.data;
            this.tablePagination.current = parseInt(dataTmp.current);
            this.tablePagination.pageSize = parseInt(dataTmp.pageSize);
            this.tablePagination.total = parseInt(dataTmp.total);
            this.list = dataTmp.data;
          } else {
            this.list = [];
          }
        });
    },
    add() {
      this.$refs.refUploadRecordEdit.addShow();
    },
    detail(obj) {
      this.$refs.refUploadRecordDetail.detailShow(obj);
    },
    edit(obj) {
      this.$refs.refUploadRecordEdit.editShow(obj);
    },
    del(idUplRec) {
      Modal.confirm({
        title: this.$t("public.name.are_you_sure_delete") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          const api = new UploadRecordDelete();
          api.post({ idUplRec: idUplRec }).then((response) => {
            if (0 === parseInt(response.data.code)) {
              this.get_list();
              message.success(response.data.msg);
            } else {
              message.error(response.data.msg);
            }
          });
        },
      });
    },
    search_submit() {
      this.get_list()
    },
    search_reset() {
      this.searchForm = {
        // search: "",
        // status: 0,
        // order: "",
      }
      this.get_list()
    },
    expandRow(expanded, record){
      if(record.type === 1){
        let api = new MpfGetByIdUplRec();
        api.get({
          idUplRec: record.idUplRec
        }).then((response) => {
          record.innerData = response.data.data
        })
      }else{
        let api = new GiGetByIdUplRec();
        api.get({
          idUplRec: record.idUplRec
        }).then((response) => {
          record.innerData = response.data.data
        })
      }
    },
    downloadMpfStatement(){
      this.$refs.refDownloadMpfStatement.show();
    },
    downloadMpfStatementYear(){
      this.$refs.refDownloadMpfStatementYear.show();
    },
    downloadGerenalInsurance(){
      this.$refs.refDownloadGerenalInsurance.show();
    },
    downloadEaeGiList(){
      const api = new GiDownAll();
      api.download(this.form).then((response) => {
        if (!response) {
          return
        }
        if(response.data.type == 'application/json'){
          message.error(this.$t("public.name.no_data"))
          return
        }
        let url = window.URL.createObjectURL(new Blob([response.data]))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', 'eaeGiList.pdf')
        document.body.appendChild(link)
        link.click()
      });
    }
  },
  mounted() {
    this.get_list();
  },
}
</script>

<style lang="less">
@import url("~@/assets/less/home/uploadRecord.less");
</style>