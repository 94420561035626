<template>
  <Row id="Policy">
    <Col :span="24" class="title">
      <TypographyTitle>{{$t('nav.name.PolicyManage')}}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24" >
          <Form :model="searchForm">
            <Row :gutter="30">
              <Col :xs="24" :sm="8">
                <FormItem :label="$t('public.name.search')" name="search">
                  <Input :placeholder="$t('policy.name.search')" v-model:value="searchForm.search"></Input>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="8">
                <FormItem :label="$t('public.field.status')">
                  <Select v-model:value="searchForm.status" :placeholder="$t('public.placeholder.please_select')">
                    <SelectOption :value="null">{{ $t('public.placeholder.please_select') }}</SelectOption>
                    <SelectOption :value="0">{{ $t('public.status.4') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('public.status.5') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="8">
                    <FormItem :label="$t('policy.field.id_pay_mod')" name="idPayMod">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="searchForm.idPayMod"
                              :placeholder="$t('policy.validate.required.id_pay_mod')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                        <SelectOption :value="null">{{ $t('public.placeholder.please_select') }}</SelectOption>
                        <SelectOption :key="sk" v-for="(item,sk) in selectList['payModeList']" :value="item['idPayMod']">{{ item['payMode'] }}</SelectOption>
                      </Select>
                  </FormItem>
                  </Col>
              <Col :xs="24" :sm="8">
                <FormItem :label="$t('policy.field.submit_date')" name="submitDate">
                  <DatePicker v-model:value="searchForm.submitDate" valueFormat="YYYY-MM" picker="month" />
                </FormItem>
              </Col>
              <Col :xs="24" :sm="8" class="text-right">
                <Button type="primary" @click="search_submit">{{$t('public.name.search')}}</Button>&nbsp;
                <Button @click="search_reset">{{$t('public.name.reset')}}</Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col :span="24">
        <Table :columns="columns" :dataSource="list" :scroll="{ x: true }" rowKey="idPol" :loading="loading" :pagination="tablePagination" @change="chang_page">
            <template #actionTitle>
              <span>
                {{$t("public.table.action")}}
                <Button v-if="checkPermission('PolicyManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined />
                </Button>
              </span>
            </template>
            <template #action="{text}">
              <div>
                <div class="pc-display">
                  <Button type="default" size="small" @click="detail(text)">{{$t("public.name.detail")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('PolicyManage.Edit')" type="primary" size="small" @click="edit(text)">{{$t("public.name.edit")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('PolicyManage.Delete')" type="primary" danger size="small" @click="del(text.idPol)">{{$t("public.name.del")}}</Button>
                </div>
                <Dropdown :trigger="['click']" class="wap-display">
                  <template #overlay>
                    <Menu>
                      <MenuItem @click="del(text.id)">{{$t('public.name.del')}}</MenuItem>
                    </Menu>
                  </template>
                  <Button size="small">
                    {{$t("public.name.more")}}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </template>
          </Table>
        </Col>
      </Row>
    </Col>
    <PolicyDetail ref="refPolicyDetail"></PolicyDetail>
    <PolicyEdit ref="refPolicyEdit" @updateList="get_list"></PolicyEdit>
  </Row>
</template>

<script>
import { createVNode } from "vue";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, Form, Input, Select, message,DatePicker } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { PolicyList } from "@/api/PolicyList";
import { PolicyDelete } from "@/api/PolicyDelete"
import { PayModeListAll } from "@/api/PayModeListAll"
import PolicyDetail from "@/components/Home/PolicyDetail";
import PolicyEdit from "@/components/Home/PolicyEdit";
import moment from "moment";

export default {
  name: "PolicyManage",
  components: {
    Row,
    Col,
    Table,
    Form,
    FormItem: Form.Item,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Input,
    Select,
    SelectOption: Select.Option,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    Menu,
    MenuItem: Menu.Item,
    PolicyDetail,
    PolicyEdit,
    DatePicker,
  },
  data() {
    return {
      searchForm: {
      },
      tablePagination: {
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      list: [],
      columns: [
          // { title: this.$t("policy.table.id_pol"), fixed: "left", dataIndex: "idPol", key: "idPol" },
          { title: this.$t("policy.table.submit_date"), dataIndex: "submitDate", key: "submitDate", customRender: ({text}) =>{return moment(text).format('YYYY-MM-DD')}},
          { title: this.$t("policy.table.id_adv"), dataIndex: "advisorName", key: "advisorName" },
          { title: this.$t("policy.table.id_ser_pro"), dataIndex: "serviceProviderCompanyName", key: "serviceProviderCompanyName" },
          { title: this.$t("policy.table.policy_no"), dataIndex: "policyNo", key: "policyNo" },
          { title: this.$t("policy.table.insured_name"), dataIndex: "insuredName", key: "insuredName" },
          { title: this.$t("policy.table.age"), dataIndex: "age", key: "age" },
          { title: this.$t("policy.table.owner_name"), dataIndex: "ownerName", key: "ownerName" },
          { title: this.$t("policy.table.id_pay_mod"), dataIndex: "payModePayMode", key: "payModePayMode" },
          { title: this.$t("policy.table.start_period"), dataIndex: "startPeriod", key: "startPeriod" },
          { title: this.$t("currency.field.currency_name"), dataIndex: "currencyCurrencyName", key: "currencyCurrencyName" },
          { title: this.$t("policy.table.real_currency"), dataIndex: "realCurrency", key: "realCurrency" },

          { title: this.$t("policy.table.payment_amount"), dataIndex: "paymentAmount", key: "paymentAmount" },
          { title: this.$t("policy.table.afyp_rate"), dataIndex: "afypRate", key: "afypRate", customRender: ({text}) =>{return text + '%'} },
          { title: this.$t("policy.table.afyp"), dataIndex: "afyp", key: "afyp" },
          { title: this.$t("policy.table.approval_date"), dataIndex: "approvalDate", key: "approvalDate", customRender: ({text}) =>{return moment(text).format('YYYY-MM-DD')}},
          { title: this.$t("policy.table.policy_date"), dataIndex: "policyDate", key: "policyDate", customRender: ({text}) =>{return moment(text).format('YYYY-MM-DD')}},
          { title: this.$t("policy.table.remark"), dataIndex: "remark", key: "remark" },
          { title: this.$t("policy.table.status"), dataIndex: "status", key: "status", customRender: ({text}) =>{
              return this.selectList.statusList.find(item=>item.value===text).label
            }
          },
          { title: this.$t("policy.table.create_date"), dataIndex: "createDate", key: "createDate" },


          
          

          



        { key: "action", fixed: "right", slots: { title: "actionTitle", customRender: "action" } },
      ],
      selectList:{
        payModeList: [],
        advisorList: [],
        serviceProviderList: [],
        statusList: [{value: 0,label: 'Off'},{value:1,label: 'On'}],
        currencyList: [],
      }
    };
  },
  methods: {
    chang_page(pagination) {
      this.tablePagination = pagination;
      this.get_list();
    },
    get_list() {
      this.loading = true;
      const api = new PolicyList();
      const getData = {
        page: this.tablePagination.current,
        limit: this.tablePagination.pageSize,
      }
      if (this.searchForm.search != "") getData.search = this.searchForm.search
      if (this.searchForm.status != undefined) getData.status = this.searchForm.status
      if (this.searchForm.idPayMod != undefined) getData.idPayMod = this.searchForm.idPayMod
      if (this.searchForm.submitDate != undefined) getData.submitDate = this.searchForm.submitDate
      // if (this.searchForm.order != "") getData.order = this.searchForm.order
      api
        .get(getData)
        .then((response) => {
          this.loading = false;
          if (0 === parseInt(response.data.code)) {
            const dataTmp = response.data.data;
            this.tablePagination.current = parseInt(dataTmp.current);
            this.tablePagination.pageSize = parseInt(dataTmp.pageSize);
            this.tablePagination.total = parseInt(dataTmp.total);
            this.list = dataTmp.data;
          } else {
            this.list = [];
          }
        });
    },
    add() {
      this.$refs.refPolicyEdit.addShow();
    },
    detail(obj) {
      this.$refs.refPolicyDetail.detailShow(obj);
    },
    edit(obj) {
      this.$refs.refPolicyEdit.editShow(obj);
    },
    del(idPol) {
      Modal.confirm({
        title: this.$t("public.name.are_you_sure_delete") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          const api = new PolicyDelete();
          api.post({ idPol: idPol }).then((response) => {
            if (0 === parseInt(response.data.code)) {
              this.get_list();
              message.success(response.data.msg);
            } else {
              message.error(response.data.msg);
            }
          });
        },
      });
    },
    search_submit() {
      this.get_list()
    },
    search_reset() {
      this.searchForm = {
        // search: "",
        // status: 0,
        // order: "",
      }
      this.get_list()
    },
    getPayModeList() {
      const api = new PayModeListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.payModeList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
  },
  mounted() {
    this.get_list();
    this.getPayModeList();
  },
}
</script>

<style lang="less">
@import url("~@/assets/less/home/policy.less");
</style>