<template>
  <Modal id="SalaryDetail" width="60%" :visible="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('salary.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('salary.field.id_sal')">{{data.idSal}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salary.field.id_adv')">{{data.advisorName}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salary.field.payment_date')">{{data.paymentDate}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salary.field.total_afyp')">{{data.totalAfyp}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salary.field.total_ifyp')">{{data.totalIfyp}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salary.field.total_renewal')">{{data.totalRenewal}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salary.field.payment_amount')">{{data.paymentAmount}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salary.field.total_fyc')">{{data.totalFyc}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salary.field.create_date')">{{data.createDate}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salary.field.others')">{{data.others}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salary.field.contribution')">{{data.contribution}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salary.field.bank_charge')">{{data.bankCharge}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salary.field.overide')">{{data.overide}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salary.field.extra_commission')">{{data.extraCommission}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salary.field.extra_bonus')">{{data.extraBonus}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salary.field.total_subject')">{{data.totalSubject}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salary.field.confirm_date')">{{data.confirmDate}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>

<script>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "SalaryDetail",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      selectList:{
      },
      data: {
      },
      visible: false,
    };
  },
  computed: {
  },
  methods: {
    async detailShow(obj) {
      this.data = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
  },
}
</script>

<style lang="less">
</style>