<template>
  <Menu id="Nav" :theme="$store.getters.getTheme" @click="clickItem" mode="inline">
    <div class="logo" />
    <MenuItem key="Dashboard">
      <DashboardOutlined />
      <span>{{$t('nav.name.Dashboard')}}</span>
    </MenuItem>
    <template v-for="(item) in navList" :key="item.name">
      <MenuItem v-if="!item.children" :key="item.name">
        <ControlOutlined v-if="item.name == 'Auth'" />
        <TeamOutlined v-else-if="item.name == 'PlanCategoryManage'" />
        <UserOutlined v-else-if="item.name == 'EmployeeManage'" />
        <UserOutlined v-else-if="item.name == 'UserManage'" />
        <UploadOutlined v-else-if="item.name == 'Uploads'" />
        <PhoneOutlined v-else-if="item.name == 'RoleManage'" />
        <BankOutlined v-else-if="item.name == 'CurrencyManage'" />
        <PicLeftOutlined v-else-if="item.name == 'PolicyManage'" />
        <MoneyCollectOutlined v-else-if="item.name == 'PaymentManage'" />
        <PayCircleOutlined v-else-if="item.name == 'SalaryManage'" />
        <ProfileOutlined v-else-if="item.name == 'PlanManage'" />
        <TagsOutlined v-else-if="item.name == 'UploadRecordManage'" />
        <MergeCellsOutlined v-else-if="item.name == 'ServiceProviderManage'" />
        <SafetyCertificateOutlined v-else-if="item.name == 'AdvisorManage'" />
        <NodeExpandOutlined v-else-if="item.name == 'PayModeManage'" />
        <ConsoleSqlOutlined v-else-if="item.name == 'LogManage'" />
        <AuditOutlined v-else-if="item.name == 'SubjectManage'" />
        <FilePptOutlined v-else-if="item.name == 'PersistencyManage'" />
        <GoldOutlined v-else-if="item.name == 'AutorityManage'" />
        <ControlOutlined v-else />
        <span>{{$t('nav.name.'+item.name)}}</span>
      </MenuItem>
      <MenuSub v-else :key="item.name">
        <template #title>
          <span>
            <ControlOutlined v-if="item.name == 'Auth'" />
            <TeamOutlined v-else-if="item.name == 'PlanCategoryManage'" />
            <UserOutlined v-else-if="item.name == 'EmployeeManage'" />
            <UserOutlined v-else-if="item.name == 'UserManage'" />
            <UploadOutlined v-else-if="item.name == 'Uploads'" />
            <PhoneOutlined v-else-if="item.name == 'RoleManage'" />
            <BankOutlined v-else-if="item.name == 'CurrencyManage'" />
            <PicLeftOutlined v-else-if="item.name == 'PolicyManage'" />
            <MoneyCollectOutlined v-else-if="item.name == 'PaymentManage'" />
            <PayCircleOutlined v-else-if="item.name == 'SalaryManage'" />
            <ProfileOutlined v-else-if="item.name == 'PlanManage'" />
            <TagsOutlined v-else-if="item.name == 'UploadRecordManage'" />
            <MergeCellsOutlined v-else-if="item.name == 'ServiceProviderManage'" />
            <SafetyCertificateOutlined v-else-if="item.name == 'AdvisorManage'" />
            <NodeExpandOutlined v-else-if="item.name == 'PayModeManage'" />
            <ConsoleSqlOutlined v-else-if="item.name == 'LogManage'" />
            <AuditOutlined v-else-if="item.name == 'SubjectManage'" />
            <FilePptOutlined v-else-if="item.name == 'PersistencyManage'" />
            <GoldOutlined v-else-if="item.name == 'AutorityManage'" />
            <ControlOutlined v-else />
            <span>{{$t('nav.name.'+item.name)}}</span>
          </span>
        </template>
        <MenuItem v-for="v in item.children" :key="v.name">{{$t('nav.name.'+v.name)}}</MenuItem>
      </MenuSub>
    </template>
  </Menu>
</template>

<script>
import { Menu, notification } from "ant-design-vue";
import { LogoutOutlined, UserOutlined,AuditOutlined, GoldOutlined,DashboardOutlined,ConsoleSqlOutlined, ControlOutlined,FilePptOutlined,NodeExpandOutlined, TeamOutlined, UploadOutlined, PhoneOutlined, BankOutlined,PicLeftOutlined,MoneyCollectOutlined,PayCircleOutlined,ProfileOutlined,TagsOutlined,MergeCellsOutlined,SafetyCertificateOutlined } from "@ant-design/icons-vue";
import { NavList } from "@/api/NavList"

export default {
  name: "Nav",
  components: {
    LogoutOutlined,
    UserOutlined,
    DashboardOutlined,
    ControlOutlined,
    TeamOutlined,
    UploadOutlined,
    PhoneOutlined,
    BankOutlined,
    PicLeftOutlined,
    MoneyCollectOutlined,
    PayCircleOutlined,
    ProfileOutlined,
    TagsOutlined,
    MergeCellsOutlined,
    SafetyCertificateOutlined,
    NodeExpandOutlined,
    ConsoleSqlOutlined,
    GoldOutlined,
    AuditOutlined,
    FilePptOutlined,
    Menu,
    MenuItem: Menu.Item,
    MenuSub: Menu.SubMenu,
  },
  data() {
    return {
      navList: null,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      location.reload();
    },
    clickItem(item) {
      if (!item) return false;
      if (this.$router.hasRoute(item.key)) {
        this.$router.push({
          name: item.key,
        });
      } else {
        notification.warning({
          message: this.$t('public.name.no_page'),
        });
      }
    },
    get_user_nav() {
      //此处配置角色路由，可从后台获取，可前端写死
      // this.navList = [
      //         { name:"AdvisorManage"},
      //   { name:"AdvisorOverrideManage"},
      //   { name:"AuthorityManage"},
      //   { name:"CommissionManage"},
      //   { name:"CommissionRateManage"},
      //   { name:"EmployeeManage"},
      //   { name:"PaymentManage"},
      //   { name:"PayModeManage"},
      //   { name:"PlanManage"},
      //   { name:"PolicyManage"},
      //   { name:"PolicyPlanManage"},
      //   { name:"RealCommissionRateManage"},
      //   { name:"RoleManage"},
      //   { name:"ServiceProviderManage"},
      // ]
      let api = new NavList();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.navList = response.data.data;
        } else {
          this.navList = [];
        }
      });
      //直接读取用户type来区分菜单
      // var type = this.$store.getters.getType
      // //后台人员
      // if(type === 0){
      //   this.navList = [{ name:"UserManage"},{ name:"CompanyManage"}]
      // }else{
      //   this.navList = [{ name:"CompanyManage"}]
      // }
    },
  },
  mounted() {
    this.get_user_nav();
  },
}
</script>


<style lang="less">
@import url("~@/assets/less/components/nav.less");
</style>