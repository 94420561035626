<template>
  <Modal
    id="SalaryEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
    :zIndex="9999999900"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="salaryForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                  <Col span="10">
                    <FormItem :label="$t('salary.field.id_adv')" name="idAdv">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="form.idAdv"
                              :placeholder="$t('salary.validate.required.id_adv')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                              :filter-option="filterOption"
                      show-search
                      >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['advisorList']" :value="item['idAdv']" :title="item['name']">{{ item['name'] }}</SelectOption>
                      </Select>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.payment_date')" name="paymentDate">
                      <DatePicker valueFormat="YYYY-MM-DD" v-model:value="form.paymentDate" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.total_afyp')" name="totalAfyp">
                      <InputNumber
                              v-model:value="form.totalAfyp"
                              :placeholder="$t('salary.validate.required.total_afyp')"
                              :step="0.01"
                      />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.total_ifyp')" name="totalIfyp">
                      <InputNumber
                              v-model:value="form.totalIfyp"
                              :placeholder="$t('salary.validate.required.total_ifyp')"
                              :step="0.01"
                      />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.total_renewal')" name="totalRenewal">
                      <InputNumber
                              v-model:value="form.totalRenewal"
                              :placeholder="$t('salary.validate.required.total_renewal')"
                              :step="0.01"
                      />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.payment_amount')" name="paymentAmount">
                      <InputNumber
                              v-model:value="form.paymentAmount"
                              :placeholder="$t('salary.validate.required.payment_amount')"
                              :step="0.01"
                      />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.total_fyc')" name="totalFyc">
                      <InputNumber
                              v-model:value="form.totalFyc"
                              :placeholder="$t('salary.validate.required.total_fyc')"
                              :step="0.01"
                      />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.others')" name="others">
                                            <InputNumber
                                                    v-model:value="form.others"
                                                    :placeholder="$t('salary.validate.required.others')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.contribution')" name="contribution">
                                            <InputNumber
                                                    v-model:value="form.contribution"
                                                    :placeholder="$t('salary.validate.required.contribution')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.bank_charge')" name="bankCharge">
                                            <InputNumber
                                                    v-model:value="form.bankCharge"
                                                    :placeholder="$t('salary.validate.required.bank_charge')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.overide')" name="overide">
                                            <InputNumber
                                                    v-model:value="form.overide"
                                                    :placeholder="$t('salary.validate.required.overide')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.extra_commission')" name="extraCommission">
                                            <InputNumber
                                                    v-model:value="form.extraCommission"
                                                    :placeholder="$t('salary.validate.required.extra_commission')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.extra_bonus')" name="extraBonus">
                                            <InputNumber
                                                    v-model:value="form.extraBonus"
                                                    :placeholder="$t('salary.validate.required.extra_bonus')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.total_subject')" name="totalSubject">
                                            <InputNumber
                                                    v-model:value="form.totalSubject"
                                                    :placeholder="$t('salary.validate.required.total_subject')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                <!--判断是否有子表，如果有则直接加入子表管理逻辑-->
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { SalaryAdd } from "@/api/SalaryAdd";
import { SalaryEdit } from "@/api/SalaryEdit";
import { AdvisorListAll } from "@/api/AdvisorListAll"

export default {
  name: "SalaryEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idSal : "",
        idAdv : "",
        paymentDate : "",
        totalAfyp : "",
        totalIfyp : "",
        totalRenewal : "",
        paymentAmount : "",
        totalFyc : "",
        createDate : "",
        others : "",
        contribution : "",
        bankCharge : "",
        overide : "",
        extraCommission : "",
        extraBonus : "",
        totalSubject : "",
        confirmDate : "",
      },
      fileList: {
      },
      selectList: {
        advisorList: [],
      },
      validateRules: {
        idSal: [
          {
            required: true,
            message: this.$t("salary.validate.required.id_sal"),
            trigger: "change",
            type: "number",
          },
        ],
        idAdv: [
          {
            required: true,
            message: this.$t("salary.validate.required.id_adv"),
            trigger: "change",
            type: "number",
          },
        ],
        paymentDate: [
          {
            required: true,
            message: this.$t("salary.validate.required.payment_date"),
            trigger: "change",
          },
        ],
        totalAfyp: [
          {
            required: true,
            message: this.$t("salary.validate.required.total_afyp"),
            trigger: "change",
            type: "number",
          },
        ],
        totalIfyp: [
          {
            required: true,
            message: this.$t("salary.validate.required.total_ifyp"),
            trigger: "change",
            type: "number",
          },
        ],
        totalRenewal: [
          {
            required: true,
            message: this.$t("salary.validate.required.total_renewal"),
            trigger: "change",
            type: "number",
          },
        ],
        paymentAmount: [
          {
            required: true,
            message: this.$t("salary.validate.required.payment_amount"),
            trigger: "change",
            type: "number",
          },
        ],
        totalFyc: [
          {
            required: true,
            message: this.$t("salary.validate.required.total_fyc"),
            trigger: "change",
            type: "number",
          },
        ],
        createDate: [
          {
            required: true,
            message: this.$t("salary.validate.required.create_date"),
            trigger: "change",
          },
        ],
        others: [
          {
            required: true,
            message: this.$t("salary.validate.required.others"),
            trigger: "change",
            type: "number",
          },
        ],
        contribution: [
          {
            required: true,
            message: this.$t("salary.validate.required.contribution"),
            trigger: "change",
            type: "number",
          },
        ],
        bankCharge: [
          {
            required: true,
            message: this.$t("salary.validate.required.bank_charge"),
            trigger: "change",
            type: "number",
          },
        ],
        overide: [
          {
            required: true,
            message: this.$t("salary.validate.required.overide"),
            trigger: "change",
            type: "number",
          },
        ],
        extraCommission: [
          {
            required: true,
            message: this.$t("salary.validate.required.extra_commission"),
            trigger: "change",
            type: "number",
          },
        ],
        extraBonus: [
          {
            required: true,
            message: this.$t("salary.validate.required.extra_bonus"),
            trigger: "change",
            type: "number",
          },
        ],
        totalSubject: [
          {
            required: true,
            message: this.$t("salary.validate.required.total_subject"),
            trigger: "change",
            type: "number",
          },
        ],
        confirmDate: [
          {
            required: true,
            message: this.$t("salary.validate.required.confirm_date"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.getAdvisorList();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.getAdvisorList();
      this.visible = true;
    },
    submit() {
      this.$refs["salaryForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new SalaryAdd();
              break;
            case "edit":
              console.log("edit");
              api = new SalaryEdit();
              break;
            default:
              return false;
          }
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    getAdvisorList() {
      const api = new AdvisorListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.advisorList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    filterOption(input, option) {
    // console.log("option.title.toLowerCase()：" + option.title.toLowerCase());
    // console.log("input.toLowerCase()：" + input.toLowerCase());
    // console.log("option.title.toLowerCase().indexOf(input.toLowerCase())：" + option.title.toLowerCase().indexOf(input.toLowerCase()));
    return option.title.toLowerCase().indexOf(input.toLowerCase()) != -1;
  }, 
    // reset data
    _resetData() {
      this.form = {
        idSal : "",
        idAdv : "",
        paymentDate : "",
        totalAfyp : "",
        totalIfyp : "",
        totalRenewal : "",
        paymentAmount : "",
        totalFyc : "",
        createDate : "",
        others : "",
        contribution : "",
        bankCharge : "",
        overide : "",
        extraCommission : "",
        extraBonus : "",
        totalSubject : "",
        confirmDate : "",
      };
    },
  },
};
</script>

<style lang="less">
</style>