import {Base,Axios} from "@/api/Base"

export class MpfUpload extends Base {
    path = "mpf/upload"
    upload(param){
        return Axios.post(this.path, param, { 
          headers: {
          "Content-Type":"multipart/form-data"
          }
        })
    }
}