<template>
  <Modal
    id="CommissionRateEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
    :zIndex="9999999900"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="commissionRateForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                  <Col span="10">
                    <FormItem :label="$t('commissionRate.field.start_month')" name="startMonth">
                                            <InputNumber
                                                    v-model:value="form.startMonth"
                                                    :placeholder="$t('commissionRate.validate.required.start_month')"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('commissionRate.field.end_month')" name="endMonth">
                                            <InputNumber
                                                    v-model:value="form.endMonth"
                                                    :placeholder="$t('commissionRate.validate.required.end_month')"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('commissionRate.field.commission_rate')" name="commissionRate">
                                            <InputNumber
                                                    v-model:value="form.commissionRate"
                                                    :placeholder="$t('commissionRate.validate.required.commission_rate')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('commissionRate.field.first_year')" name="firstYear">
                      <Select
                              v-model:value="form.firstYear"
                              :placeholder="$t('commissionRate.validate.required.first_year')"
                              :options="selectList.firstYearList"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                      </Select>
                  </FormItem>
                  </Col>
                <!--判断是否有子表，如果有则直接加入子表管理逻辑-->
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Space,
  Card,
  Table,
  Popconfirm,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { CommissionRateAdd } from "@/api/CommissionRateAdd";
import { CommissionRateEdit } from "@/api/CommissionRateEdit";
import { PlanListAll } from "@/api/PlanListAll"

export default {
  name: "CommissionRateEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    MinusCircleOutlined,
    ExclamationCircleOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    Space,
    Card,
    Table,
    Popconfirm,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idComRat : "",
        idPla : "",
        startMonth : "",
        endMonth : "",
        commissionRate : "",
        firstYear : "",
      },
      fileList: {
      },
      selectList: {
        planList: [],
        firstYearList: [{value: 0,label: 'First Year'},{value:1,label: 'Renewal'}],
      },
      validateRules: {
        idComRat: [
          {
            required: true,
            message: this.$t("commissionRate.validate.required.id_com_rat"),
            trigger: "change",
            type: "number",
          },
        ],
        idPla: [
          {
            required: true,
            message: this.$t("commissionRate.validate.required.id_pla"),
            trigger: "change",
            type: "number",
          },
        ],
        startMonth: [
          {
            required: true,
            message: this.$t("commissionRate.validate.required.start_month"),
            trigger: "change",
            type: "number",
          },
        ],
        endMonth: [
          {
            required: true,
            message: this.$t("commissionRate.validate.required.end_month"),
            trigger: "change",
            type: "number",
          },
        ],
        commissionRate: [
          {
            required: true,
            message: this.$t("commissionRate.validate.required.commission_rate"),
            trigger: "change",
            type: "number",
          },
        ],
        firstYear: [
          {
            required: true,
            message: this.$t("commissionRate.validate.required.first_year"),
            trigger: "change",
            type: "number",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  methods: {
    addShow(idPla) {
      this.actionType = "add";
      this._resetData();
      this.form.idPla = idPla
      this.getPlanList();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.getPlanList();
      this.visible = true;
    },
    submit() {
      this.$refs["commissionRateForm"]
        .validate()
        .then(() => {
          //没有传父id，则代表新增时的状况，直接回调对象，不请求，由新增的api自行按数组添加数据
          if(!this.form.idPla){
            this.$emit("updateList",this.form);
            this.visible = false;
            return;
          }
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new CommissionRateAdd();
              break;
            case "edit":
              console.log("edit");
              api = new CommissionRateEdit();
              break;
            default:
              return false;
          }
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList",response.data.data);
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    getPlanList() {
      const api = new PlanListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.planList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    // reset data
    _resetData() {
      this.form = {
        idComRat : "",
        idPla : "",
        startMonth : "",
        endMonth : "",
        commissionRate : "",
        firstYear : "",
      };
    },
  },
};
</script>

<style lang="less">
</style>