<template>
  <Modal
    id="EmployeeEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
    :zIndex="9999999900"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="employeeForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                  <Col span="10">
                    <FormItem :label="$t('employee.field.id_rol')" name="idRol">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="form.idRol"
                              :placeholder="$t('employee.validate.required.id_rol')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['roleList']" :value="item['idRol']">{{ item['name'] }}</SelectOption>
                      </Select>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('employee.field.account')" name="account">
                      <Input :placeholder="$t('employee.validate.required.account')"
                             v-model:value="form.account">
                      </Input>
                  </FormItem>
                  </Col>
                  <Col span="10" v-if="actionType == 'add'">
                    <FormItem :label="$t('employee.field.password')" name="password">
                      <InputPassword :placeholder="$t('employee.validate.required.password')"
                                     v-model:value="form.password">
                      </InputPassword>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('employee.field.email')" name="email">
                      <Input :placeholder="$t('employee.validate.required.email')"
                             v-model:value="form.email">
                      </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('employee.field.remark')" name="remark">
                      <Textarea
                              v-model:value="form.remark"
                              :placeholder="$t('employee.validate.required.remark')"
                              :auto-size="{ minRows: 5, maxRows: 10 }"
                              showCount
                      />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('employee.field.status')" name="status">
                      <Select
                              v-model:value="form.status"
                              :placeholder="$t('employee.validate.required.status')"
                              :options="selectList.statusList"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                      </Select>
                  </FormItem>
                  </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { EmployeeAdd } from "@/api/EmployeeAdd";
import { EmployeeEdit } from "@/api/EmployeeEdit";
import { RoleListAll } from "@/api/RoleListAll"


export default {
  name: "EmployeeEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idEmp : "",
        idRol : "",
        account : "",
        password : "",
        email : "",
        remark : "",
        status : "",
      },
      fileList: {
      },
      selectList: {
        roleList: [],
        statusList: [{value: 0,label: 'Off'},{value:1,label: 'On'}],
      },
      validateRules: {
        idEmp: [
          {
            required: true,
            message: this.$t("employee.validate.required.id_emp"),
            trigger: "change",
            type: "number",
          },
        ],
        idRol: [
          {
            required: true,
            message: this.$t("employee.validate.required.id_rol"),
            trigger: "change",
            type: "number",
          },
        ],
        account: [
          {
            required: true,
            message: this.$t("employee.validate.required.account"),
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("employee.validate.required.password"),
            trigger: "change",
          },
        ],
        salt: [
          {
            required: true,
            message: this.$t("employee.validate.required.salt"),
            trigger: "change",
          },
        ],
        lastLoginTime: [
          {
            required: true,
            message: this.$t("employee.validate.required.last_login_time"),
            trigger: "change",
          },
        ],
        registerTime: [
          {
            required: true,
            message: this.$t("employee.validate.required.register_time"),
            trigger: "change",
          },
        ],
        loginFailureCounter: [
          {
            required: true,
            message: this.$t("employee.validate.required.login_failure_counter"),
            trigger: "change",
            type: "number",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t("employee.validate.required.email"),
            trigger: "change",
            type: "email",
          },
        ],
        remark: [
          {
            required: true,
            message: this.$t("employee.validate.required.remark"),
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: this.$t("employee.validate.required.status"),
            trigger: "change",
            type: "number",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.getRoleList();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.getRoleList();
      this.visible = true;
    },
    submit() {
      this.$refs["employeeForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new EmployeeAdd();
              break;
            case "edit":
              console.log("edit");
              api = new EmployeeEdit();
              break;
            default:
              return false;
          }
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    getRoleList() {
      const api = new RoleListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.roleList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    // reset data
    _resetData() {
      this.form = {
        idEmp : "",
        idRol : "",
        account : "",
        password : "",
        email : "",
        remark : "",
        status : "",
      };
    },
  },
};
</script>

<style lang="less">
</style>