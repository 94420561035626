<template>
  <Row id="Payment">
    <Col :span="24" class="title">
      <TypographyTitle>{{$t('nav.name.PaymentManage')}}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24" >
          <Form :model="searchForm">
            <Row :gutter="30">
              <Col :xs="24" :sm="8">
                <FormItem :label="$t('public.name.search')" name="search">
                  <Input :placeholder="$t('public.name.search')" v-model:value="searchForm.search"></Input>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="8">
                <FormItem :label="$t('payment.name.dateRange')" name="dateRange">
                  <RangePicker :allowClear="false" v-model:value="rangePickerDate" @change="rangeChange"/>
                </FormItem>
              </Col>
              <!-- <Col :xs="24" :sm="8">
                <FormItem :label="$t('public.field.status')">
                  <Select v-model:value="searchForm.status" :placeholder="$t('public.placeholder.please_select')">
                    <SelectOption :value="0">{{ $t('public.placeholder.please_select') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('public.status.1') }}</SelectOption>
                    <SelectOption :value="2">{{ $t('public.status.2') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col> -->
              <Col :xs="24" :sm="8" class="text-right">
                <Button type="primary" @click="search_submit">{{$t('public.name.search')}}</Button>&nbsp;
                <Button @click="search_reset">{{$t('public.name.reset')}}</Button>
                <!-- <Button type="primary" @click="generalSalary">{{
                    $t('report.name.generalSalary')
                }}</Button>&nbsp; 
                <Button type="primary" @click="downloadCommissionPayment">{{$t('downloadCommissionPayment.name.title')}}</Button>&nbsp; -->
              </Col>
            </Row>
          </Form>
        </Col>
        <Col :span="24">
        <Table :columns="columns" :dataSource="list" :scroll="{ x: true }" rowKey="idPay" :loading="loading" :pagination="tablePagination" @change="chang_page">
            <template #actionTitle>
              <span>
                {{$t("public.table.action")}}
                <Button v-if="checkPermission('PaymentManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined />
                </Button>
              </span>
            </template>
            <template #action="{text}">
              <div>
                <div class="pc-display">
                  <Button type="default" size="small" @click="detail(text)">{{$t("public.name.detail")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('PaymentManage.Edit') && text.isLattest === 1" type="primary" size="small" @click="edit(text)">{{$t("public.name.edit")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('PaymentManage.Delete')" type="primary" danger size="small" @click="del(text.idPay)">{{$t("public.name.del")}}</Button>
                </div>
                
              </div>
            </template>
          </Table>
        </Col>
      </Row>
    </Col>
    <PaymentDetail ref="refPaymentDetail"></PaymentDetail>
    <PaymentEdit ref="refPaymentEdit" @updateList="get_list"></PaymentEdit>
    <DownloadCommissionPayment ref="refDownloadCommissionPayment"></DownloadCommissionPayment>
  </Row>
</template>

<script>
import { createVNode } from "vue";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, Form, Input, Select, message,RangePicker } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { PaymentList } from "@/api/PaymentList";
import { PaymentDelete } from "@/api/PaymentDelete"
import PaymentDetail from "@/components/Home/PaymentDetail";
import PaymentEdit from "@/components/Home/PaymentEdit";
import DownloadCommissionPayment from "@/components/Home/DownloadCommissionPayment";
import moment from "moment";

export default {
  name: "PaymentManage",
  components: {
    Row,
    Col,
    Table,
    Form,
    FormItem: Form.Item,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Input,
    Select,
    SelectOption: Select.Option,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    Menu,
    MenuItem: Menu.Item,
    PaymentDetail,
    PaymentEdit,
    DownloadCommissionPayment,
    RangePicker,
  },
  data() {
    return {
      rangePickerDate:[null,null],
      searchForm: {
      },
      tablePagination: {
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      list: [],
      columns: [
          // { title: this.$t("payment.table.id_pay"), fixed: "left", dataIndex: "idPay", key: "idPay" },
          { title: this.$t("payment.table.id_pol"), dataIndex: "policyPolicyNo", key: "policyPolicyNo" },
          { title: this.$t("payment.table.from_month"), dataIndex: "fromMonth", key: "fromMonth" },
          { title: this.$t("payment.table.to_month"), dataIndex: "toMonth", key: "toMonth" },
          { title: this.$t("advisor.table.name"), dataIndex: "trName", key: "trName" },
          { title: this.$t("payMode.table.pay_mode"), dataIndex: "payMode", key: "payMode" },
          { title: this.$t("payment.table.period"), dataIndex: "period", key: "period" },
          { title: this.$t("payment.table.bank_charge"), dataIndex: "bankCharge", key: "bankCharge", customRender: ({text}) =>{
              return this.money(text)
          } },
          { title: this.$t("payment.table.afyp"), dataIndex: "afyp", key: "afyp", customRender: ({text}) =>{
              return this.money(text)
          } },
          // { title: this.$t("payment.table.total_commission"), dataIndex: "totalCommission", key: "totalCommission" },
          { title: this.$t("payment.table.payment_date"), dataIndex: "paymentDate", key: "paymentDate", customRender: ({text}) =>{return moment(text).format('YYYY-MM-DD')}},
          { title: this.$t("payment.table.fyc"), dataIndex: "fyc", key: "fyc", customRender: ({text}) =>{
              return this.money(text)
          } },
          { title: this.$t("payment.table.ifyp"), dataIndex: "ifyp", key: "ifyp", customRender: ({text}) =>{
              return this.money(text)
          } },
          
          // { title: this.$t("payment.table.submitted_afyp"), dataIndex: "submittedAfyp", key: "submittedAfyp" },
          // { title: this.$t("payment.table.adjusted_afyp"), dataIndex: "adjustedAfyp", key: "adjustedAfyp" },
          { title: this.$t("currency.table.currency_name"), dataIndex: "currencyName", key: "currencyName" },
        //   { title: this.$t("payment.table.is_renewal"), dataIndex: "isRenewal", key: "isRenewal", customRender: ({text}) =>{
        //     return text?this.$t("public.name.yes"):this.$t("public.name.no")
        //   } 
        // },
        { title: this.$t("payment.table.renewal_commission"), dataIndex: "renewalCommission", key: "renewalCommission", customRender: ({text}) =>{
              return this.money(text)
          } },
          { title: this.$t("payment.table.create_date"), dataIndex: "createDate", key: "createDate" },
          // { title: this.$t("payment.table.others"), dataIndex: "others", key: "others" },
          { title: this.$t("payment.table.real_exchange_rate"), dataIndex: "realExchangeRate", key: "realExchangeRate", customRender: ({text}) =>{
              return text+'%'
          } },
          { title: this.$t("payment.table.afyp_rate"), dataIndex: "afypRate", key: "afypRate", customRender: ({text}) =>{
              return text+'%'
          }  },
          { title: this.$t("payment.table.remark"), dataIndex: "remark", key: "remark" },
        { key: "action", fixed: "right", slots: { title: "actionTitle", customRender: "action" } },
      ],
      selectList:{
        policyList: [],
      }
    };
  },
  methods: {
    chang_page(pagination) {
      this.tablePagination = pagination;
      this.get_list();
    },
    get_list() {
      this.loading = true;
      const api = new PaymentList();
      const getData = {
        page: this.tablePagination.current,
        limit: this.tablePagination.pageSize,
      }
      if (this.searchForm.search != "") getData.search = this.searchForm.search
      if (this.searchForm.startDate != "") getData.startDate = this.searchForm.startDate
      if (this.searchForm.endDate != "") getData.endDate = this.searchForm.endDate
      // if (this.searchForm.status != 0) getData.status = this.searchForm.status
      // if (this.searchForm.order != "") getData.order = this.searchForm.order
      api
        .get(getData)
        .then((response) => {
          this.loading = false;
          if (0 === parseInt(response.data.code)) {
            const dataTmp = response.data.data;
            this.tablePagination.current = parseInt(dataTmp.current);
            this.tablePagination.pageSize = parseInt(dataTmp.pageSize);
            this.tablePagination.total = parseInt(dataTmp.total);
            this.list = dataTmp.data;
          } else {
            this.list = [];
          }
        });
    },
    rangeChange(dates){
      this.searchForm.startDate = dates[0].format("YYYY-MM-DD")
      this.searchForm.endDate = dates[1].format("YYYY-MM-DD")
    },
    add() {
      this.$refs.refPaymentEdit.addShow();
    },
    detail(obj) {
      this.$refs.refPaymentDetail.detailShow(obj);
    },
    edit(obj) {
      this.$refs.refPaymentEdit.editShow(obj);
    },
    del(idPay) {
      Modal.confirm({
        title: this.$t("public.name.are_you_sure_delete") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          const api = new PaymentDelete();
          api.post({ idPay: idPay }).then((response) => {
            if (0 === parseInt(response.data.code)) {
              this.get_list();
              message.success(response.data.msg);
            } else {
              message.error(response.data.msg);
            }
          });
        },
      });
    },
    downloadCommissionPayment(){
      this.$refs.refDownloadCommissionPayment.show();
    },
    search_submit() {
      this.get_list()
    },
    search_reset() {
      this.searchForm = {
        // search: "",
        // status: 0,
        // order: "",
      }
      this.rangePickerDate = [null,null]
      this.get_list()
    }
  },
  
  mounted() {
    this.get_list();
  },
}
</script>

<style lang="less">
@import url("~@/assets/less/home/payment.less");
</style>