import { createRouter, createWebHashHistory } from 'vue-router'
import store from "@/store"
import Login from '@/views/Login'
import Home from '@/views/Home'
import AdvisorManage from '@/views/Home/AdvisorManage'
import AuthorityManage from '@/views/Home/AuthorityManage'
import CurrencyManage from '@/views/Home/CurrencyManage'
import EmployeeManage from '@/views/Home/EmployeeManage'
import GiManage from '@/views/Home/GiManage'
import InsuranceCompanyManage from '@/views/Home/InsuranceCompanyManage'
import MpfManage from '@/views/Home/MpfManage'
import OtherManage from '@/views/Home/OtherManage'
import PaymentManage from '@/views/Home/PaymentManage'
import PayModeManage from '@/views/Home/PayModeManage'
import PlanManage from '@/views/Home/PlanManage'
import PlanCategoryManage from '@/views/Home/PlanCategoryManage'
import PolicyManage from '@/views/Home/PolicyManage'
import RoleManage from '@/views/Home/RoleManage'
import SalaryManage from '@/views/Home/SalaryManage'
import ServiceProviderManage from '@/views/Home/ServiceProviderManage'
import SubjectManage from '@/views/Home/SubjectManage'
import UploadRecordManage from '@/views/Home/UploadRecordManage'
import LogManage from '@/views/Home/LogManage'
import PersistencyManage from '@/views/Home/PersistencyManage'
import ReportManage from '@/views/Home/ReportManage'


const routes = [
  {
    path: "/",
    name: "Login",
    component: Login
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      // token存在 进入该路由，不存在跳转到登陆页面
      if (store.getters.getToken) {
        //console.log(store.getters.getToken)
        next()
      } else {
        next('/Login')
      }
    },
    children: [
      {
        path: "/Dashboard",
        name: "Dashboard",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Home/Dashboard.vue')
      },
      {
        path: "/AdvisorManage",
        name: "AdvisorManage",
        component: AdvisorManage
      },
      {
        path: "/AuthorityManage",
        name: "AuthorityManage",
        component: AuthorityManage
      },
      {
        path: "/CurrencyManage",
        name: "CurrencyManage",
        component: CurrencyManage
      },
      {
        path: "/EmployeeManage",
        name: "EmployeeManage",
        component: EmployeeManage
      },
      {
        path: "/GiManage",
        name: "GiManage",
        component: GiManage
      },
      {
        path: "/InsuranceCompanyManage",
        name: "InsuranceCompanyManage",
        component: InsuranceCompanyManage
      },
      {
        path: "/MpfManage",
        name: "MpfManage",
        component: MpfManage
      },
      {
        path: "/OtherManage",
        name: "OtherManage",
        component: OtherManage
      },
      {
        path: "/PaymentManage",
        name: "PaymentManage",
        component: PaymentManage
      },
      {
        path: "/PayModeManage",
        name: "PayModeManage",
        component: PayModeManage
      },
      {
        path: "/PlanManage",
        name: "PlanManage",
        component: PlanManage
      },
      {
        path: "/PlanCategoryManage",
        name: "PlanCategoryManage",
        component: PlanCategoryManage
      },
      {
        path: "/PolicyManage",
        name: "PolicyManage",
        component: PolicyManage
      },
      {
        path: "/RoleManage",
        name: "RoleManage",
        component: RoleManage
      },
      {
        path: "/SalaryManage",
        name: "SalaryManage",
        component: SalaryManage
      },
      {
        path: "/ServiceProviderManage",
        name: "ServiceProviderManage",
        component: ServiceProviderManage
      },
      {
        path: "/SubjectManage",
        name: "SubjectManage",
        component: SubjectManage
      },
      {
        path: "/UploadRecordManage",
        name: "UploadRecordManage",
        component: UploadRecordManage
      },
      {
        path: "/LogManage",
        name: "LogManage",
        component: LogManage
      },
      {
        path: "/PersistencyManage",
        name: "PersistencyManage",
        component: PersistencyManage
      },
      {
        path: "/ReportManage",
        name: "ReportManage",
        component: ReportManage
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: routes
})

export default router