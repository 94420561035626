<template>
  <Modal
    id="OtherEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="otherForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                  <Col span="10">
                    <FormItem :label="$t('other.field.id_pay')" name="idPay">
                                            <Input :placeholder="$t('other.validate.required.id_pay')"
                                                   v-model:value="form.idPay">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('other.field.id_sub')" name="idSub">
                        <!--关联表的下拉选择-->
                        <Select
                                v-model:value="form.idSub"
                                :placeholder="$t('other.validate.required.id_sub')"
                                :dropdownStyle="{ zIndex: 9999999950 }"
                        >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['subjectList']" :value="item['idSub']">{{ item['subjectNameHk'] }}</SelectOption>
                        </Select>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('other.field.id_adv')" name="idAdv">
                        <!--关联表的下拉选择-->
                        <Select
                                v-model:value="form.idAdv"
                                :placeholder="$t('other.validate.required.id_adv')"
                                :dropdownStyle="{ zIndex: 9999999950 }"
                        >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['advisorList']" :value="item['idAdv']">{{ item['name'] }}</SelectOption>
                        </Select>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('other.field.other_amount')" name="otherAmount">
                                            <InputNumber
                                                    v-model:value="form.otherAmount"
                                                    :placeholder="$t('other.validate.required.other_amount')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                <!--判断是否有子表，如果有则直接加入子表管理逻辑-->
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Space,
  Card,
  Table,
  Popconfirm,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { OtherAdd } from "@/api/OtherAdd";
import { OtherEdit } from "@/api/OtherEdit";
import { SubjectListAll } from "@/api/SubjectListAll"
import { AdvisorListAll } from "@/api/AdvisorListAll"

export default {
  name: "OtherEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    MinusCircleOutlined,
    ExclamationCircleOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    Space,
    Card,
    Table,
    Popconfirm,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idOth : "",
        idPay : "",
        idSub : "",
        idAdv : "",
        otherAmount : "",
      },
      fileList: {
      },
      selectList: {
        subjectList: [],
        advisorList: [],
      },
      validateRules: {
        idOth: [
          {
            required: true,
            message: this.$t("other.validate.required.id_oth"),
            trigger: "change",
            type: "number",
          },
        ],
        idPay: [
          {
            required: true,
            message: this.$t("other.validate.required.id_pay"),
            trigger: "change",
            type: "number",
          },
        ],
        idSub: [
          {
            required: true,
            message: this.$t("other.validate.required.id_sub"),
            trigger: "change",
            type: "number",
          },
        ],
        idAdv: [
          {
            required: true,
            message: this.$t("other.validate.required.id_adv"),
            trigger: "change",
            type: "number",
          },
        ],
        otherAmount: [
          {
            required: true,
            message: this.$t("other.validate.required.other_amount"),
            trigger: "change",
            type: "number",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  watch: {
      "visible": {
          deep: true,
          handler: function (newValue, oldValue) {
              if (!newValue) {
                  this.$emit("updateList");
              }
          }
      }
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.getSubjectList();
      this.getAdvisorList();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.getSubjectList();
      this.getAdvisorList();
      this.visible = true;
    },
    submit() {
      this.$refs["otherForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new OtherAdd();
              break;
            case "edit":
              console.log("edit");
              api = new OtherEdit();
              break;
            default:
              return false;
          }
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList",response.data.data);
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    getSubjectList() {
      const api = new SubjectListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.subjectList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    getAdvisorList() {
      const api = new AdvisorListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.advisorList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    // reset data
    _resetData() {
      this.form = {
        idOth : "",
        idPay : "",
        idSub : "",
        idAdv : "",
        otherAmount : "",
      };
    },
  },
};
</script>

<style lang="less">
</style>