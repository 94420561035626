const enUS = {
    public: {
        name: {
            status: "State",
            hello: "Hello",
            login: "Login",
            logout: "Logout",
            username: "Username",
            email: "E-mail",
            password: "Password",
            confirm_password: "Confirm Password",
            reset_password: "Reset Password",
            captcha: "Captcha",
            more: "More",
            add: "Add",
            edit: "Edit",
            del: "Delete",
            detail: "Detail",
            yes: "Yes",
            no: "No",
            empty: "Empty",
            are_you_sure_delete: "Are you Sure Want To Delete This Item",
            are_you_sure_confirm: "Are you Sure Want To Confirm This Item",
            action_cannot_resumed: "This Action Cannot Be Resumed",
            search: "Search",
            reset: "Reset",
            spread: "Spread",
            retract: "Retract",
            show: "Show",
            no_page: "No Page",
            service: "Service",
            phone_country_code: "Phone Country Code",
            name: "Name",
            phone: "Phone",
            sex: "Sex",
            country: "Coutry",
            address: "Address",
            birthday: "Birthday",
            remark: "Remark",
            personal: "Personal",
            enclosure: "Enclosure",
            logo: "Logo",
            confirm: "Confirm",
            all:"All",
            download: "Download"
        },
        language: {
            zhTW: "Chinese (Traditional)",
            enUS: "English",
            zhCN: "Chinese (Simplified)",
        },
        status: {
            0: "Unknown",
            1: "Normal",
            2: "Disable",
            3: "Delete",
            4: "Off",
            5: "On",
        },
        sex: {
            1: "Man",
            2: "Woman",
            3: "Unknown"
        },
        placeholder: {
            please_select: "Please Select",
        },
        table: {
            name: "Name",
            status: "State",
            action: "Action",
            content: "Content",
            created_at: "Create Time",
            image: "Image",
            email: "E-mail",
            order_id: "Order",
            sex: "Sex",
            last_ip: "Last Ip",
            phone_country_code: "Please Select Phone Country Code",
            phone: "Phone",
        },
        field: {
            name: "Name",
            username: "Username",
            content: "Content",
            phone_country_code: "Phone Country Code",
            phone: "Phone",
            email: "E-mail",
            order_id: "Order",
            portrait: "Portrait",
            status: "State",
            image: "Image",
            sex: "Sex",
        },
        validate: {
            required: {
                id: "Lack ID",
                username: "Please Entry User Name",
                password: "Please Entry Password",
                repassword: "Please Repeat password",
                name: "Please Entry Name",
                phone: "Please Entry Phone",
                email: "Please Entry E-mail",
                portrait: "Please Upload Portrait",
            },
            tips: {
                inconsistent_password: "Inconsistent password",
                length_should: "Length Should Be {min} To {max}"
            }
        }
    },
    language: {
        zhTW: "Chinese(Traditional)",
        enUS: "English",
        zhCN: "Chinese (Simplified)"
    },
    nav: {
        name: {
            Dashboard: "Dashboard",
            AdvisorManage: "Advisor Manage",
            AuthorityManage: "Autority Manage",
            CurrencyManage: "Currency Manage",
            EmployeeManage: "Employee Manage",
            GiManage: "GI Manage",
            InsuranceCompanyManage: "Insurance Company Manage",
            MpfManage: "Mpf Manage",
            OtherManage: "Other Manage",
            PaymentManage: "Payment Manage",
            PayModeManage: "Pay Mode Manage",
            PlanManage: "Plan Manage",
            PlanCategoryManage: "Plan Category Manage",
            PolicyManage: "Policy Manage",
            RoleManage: "Role Manage",
            SalaryManage: "Salary Manage",
            SalaryOtherManage: "Salary Other Manage",
            ServiceProviderManage: "Service Provider Manage",
            SubjectManage: "Subject Manage",
            SubjectListSummaryManage: "Subject List Summary Manage",
            UploadRecordManage: "Upload Record Manage",
            LogManage: "Log Manage",
            PersistencyManage: "Persistency Manage",
            ReportManage: "Report Manage",
        },
        table: {
            order_id: "Order ID",
            url: "Url",
        },
        field: {
            pid: "PID",
            order_id: "Order ID",
            name: "Name",
            url: "Url",
            status: "State",
            icon: "Icon",
        },
    },
    login: {
        validate: {
            required: {
                username: "Please Entry Username",
                email: "Please Entry Email",
                password: "Please Entry Password",
                captcha: "Please Entry Captcha",
            }
        }
    },
    advisor:{
        name: {
            title: "Advisor",
            detailTitle: "Advisor Detail",
            editTitle: "Edit Advisor",
        },
        table: {
            id_adv: "ID",
            name: "TR Name",
            title: "Title",
            licence_no: "TR(Broker) Licence No",
            join_date: "Join Date",
            create_date: "Create Date",
            status: "Status",
            mpf_no: "MPF No",
            contribution: "Contribution(HKD)",
            tr_no: "TR No",
            tr_full_name: "TR Full Name",
        },
        field: {
            id_adv: "ID",
            name: "TR Name",
            title: "Title",
            licence_no: "TR(Broker) Licence No",
            join_date: "Join Date",
            create_date: "Create Date",
            status: "Status",
            mpf_no: "MPF No",
            contribution: "Contribution(HKD)",
            head_id_adv: "Head Advisor",
            tr_no: "TR No",
            tr_full_name: "TR Full Name",
        },
        validate: {
            required: {
                id_adv: "ID Required",
                name: "TR Name Required",
                title: "Title Required",
                licence_no: "TR(Broker) Licence No Required",
                join_date: "Join Date Required",
                create_date: "Create Date Required",
                status: "Status Required",
                mpf_no: "MPF No Required",
                contribution: "Contribution Required",
                head_id_adv: "Head Advisor Required",
                tr_no: "TR No Required",
                tr_full_name: "TR Full Name Required",
            }
        },
    },
    advisorCommission:{
        name: {
            title: "Overide",
            detailTitle: "Overide Commission Detail",
            editTitle: "Edit Overide Commission",
        },
        table: {
            id_adv_com: "ID",
            id_pay: "ID",
            id_adv: "ID",
            commission_rate: "Commission Rate(%)",
            commission: "Override(HKD)",
            total_commission: "Total Override(HKD)",
        },
        field: {
            id_adv_com: "ID",
            id_pay: "ID",
            id_adv: "ID",
            commission_rate: "Commission Rate(%)",
            commission: "Override(HKD)",
            total_commission: "Total Override(HKD)",
        },
        validate: {
            required: {
                id_adv_com: "ID Required",
                id_pay: "ID Required",
                id_adv: "ID Required",
                commission_rate: "Commission Rate(%) Required",
                commission: "Override(HKD) Required",
                total_commission: "Total Override(HKD) Required",
            }
        },
    },
    advisorOverride:{
        name: {
            title: "Advisor Override",
            detailTitle: "Advisor Override Detail",
            editTitle: "Edit Advisor Override",
        },
        table: {
            id_adv_ove: "ID",
            id_adv: "Advisor Name",
            head_id_adv: "Head Advisor Name",
            override_rate: "Override Rate(%)",
        },
        field: {
            id_adv_ove: "ID",
            id_adv: "Advisor Name",
            head_id_adv: "Head Advisor Name",
            override_rate: "Override Rate(%)",
        },
        validate: {
            required: {
                id_adv_ove: "ID Required",
                id_adv: "Advisor Name Required",
                head_id_adv: "Head Advisor Name Required",
                override_rate: "Override Rate Required",
            }
        },
    },
    authority:{
        name: {
            title: "Autority",
            detailTitle: "Autority Detail",
            editTitle: "Edit Autority",
        },
        table: {
            id_aut: "ID",
            a_id_aut: "Parent Authority",
            name: "Authority",
            resource_name: "Resource Name",
            type: "Type",
            sort: "Sort",
        },
        field: {
            id_aut: "ID",
            a_id_aut: "Parent Authority",
            name: "Authority",
            resource_name: "Resource Name",
            type: "Type",
            sort: "Sort",
        },
        validate: {
            required: {
                id_aut: "ID Required",
                a_id_aut: "Parent Authority Required",
                name: "Authority Required",
                resource_name: "Resource Name Required",
                type: "Type Required",
                sort: "Sort Required",
            }
        },
    },
    commission:{
        name: {
            title: "Commission",
            detailTitle: "Commission Detail",
            editTitle: "Edit Commission",
        },
        table: {
            id_com: "ID",
            id_pay: "ID",
            commission: "Commission",
            commission_rate: "Commission Rate(%)",
            amount: "Premium",
            is_basic: "Is Basic",
            id_pla: "Plan Name",
            id_pol_pla: "id_pol_pla",
            amount_hkd:"Premium(HKD)",
            commission_hkd:"Commission(HKD)"
        },
        field: {
            id_com: "ID",
            id_pay: "ID",
            commission: "Commission",
            commission_rate: "Commission Rate(%)",
            amount: "Premium",
            is_basic: "Is Basic",
            id_pla: "Plan Name",
            id_pol_pla: "id_pol_pla",
            amount_hkd:"Premium(HKD)",
            commission_hkd:"Commission(HKD)"
        },
        validate: {
            required: {
                id_com: "ID Required",
                id_pay: "ID Required",
                commission: "Commission Required",
                commission_rate: "Commission Rate(%) Required",
                amount: "Premium Required",
                is_basic: "Is Basic Required",
                id_pla: "Plan Name Required",
                id_pol_pla: "id_pol_pla Required",
                amount_hkd:"Premium(HKD) Required"
            }
        },
    },
    commissionRate:{
        name: {
            title: "Commission Rate",
            detailTitle: "Commission Rate Detail",
            editTitle: "Edit Commission Rate",
        },
        table: {
            id_com_rat: "ID",
            id_pla: "Plan Name",
            start_month: "Start Month",
            end_month: "End Month",
            commission_rate: "Commission Rate(%)",
            first_year: "Premium Paying Term",
        },
        field: {
            id_com_rat: "ID",
            id_pla: "Plan Name",
            start_month: "Start Month",
            end_month: "End Month",
            commission_rate: "Commission Rate(%)",
            first_year: "Premium Paying Term",
        },
        validate: {
            required: {
                id_com_rat: "ID Required",
                id_pla: "Plan Name Required",
                start_month: "Start Month Required",
                end_month: "End Month Required",
                commission_rate: "Commission Rate Required",
                first_year: "First Year? Required",
            }
        },
    },
    currency:{
        name: {
            title: "Currency",
            detailTitle: "Currency Detail",
            editTitle: "Edit Currency",
        },
        table: {
            id_cur: "ID",
            currency_name: "Currency",
            exchange_rate: "Exchange Rate",
        },
        field: {
            id_cur: "ID",
            currency_name: "Currency",
            exchange_rate: "Exchange Rate",
        },
        validate: {
            required: {
                id_cur: "ID Required",
                currency_name: "Currency Required",
                exchange_rate: "Exchange Rate Required",
            }
        },
    },
    employee:{
        name: {
            title: "Employee",
            detailTitle: "Employee Detail",
            editTitle: "Edit Employee",
        },
        table: {
            id_emp: "ID",
            id_rol: "Role",
            account: "Account",
            password: "Password",
            salt: "Salt",
            last_login_time: "Last Login Time",
            register_time: "Register Time",
            login_failure_counter: "Login Failure Counter",
            email: "Email",
            remark: "Remark",
            status: "Status",
        },
        field: {
            id_emp: "ID",
            id_rol: "Role",
            account: "Account",
            password: "Password",
            salt: "Salt",
            last_login_time: "Last Login Time",
            register_time: "Register Time",
            login_failure_counter: "Login Failure Counter",
            email: "Email",
            remark: "Remark",
            status: "Status",
        },
        validate: {
            required: {
                id_emp: "ID Required",
                id_rol: "Role Required",
                account: "Account Required",
                password: "Password Required",
                salt: "Salt Required",
                last_login_time: "Last Login Time Required",
                register_time: "Register Time Required",
                login_failure_counter: "Login Failure Counter Required",
                email: "Email Required",
                remark: "Remark Required",
                status: "Status Required",
            }
        },
    },
    extraOverride:{
        name: {
            title: "Extra Override",
            detailTitle: "Extra Override Detail",
            editTitle: "Edit Extra Override",
        },
        table: {
            id_ext_ove: "ID",
            id_pay: "ID",
            id_adv: "ID",
            commission_rate: "Commission Rate(%)",
            override: "Extra Overide(HKD)",
            total_override: "Total Overide(HKD)",
        },
        field: {
            id_ext_ove: "ID",
            id_pay: "ID",
            id_adv: "ID",
            commission_rate: "Commission Rate(%)",
            override: "Extra Overide(HKD)",
            total_override: "Total Overide(HKD)",
        },
        validate: {
            required: {
                id_ext_ove: "ID Required",
                id_pay: "ID Required",
                id_adv: "ID Required",
                commission_rate: "Commission Rate(%) Required",
                override: "Extra Overide Required",
                total_override: "Total Overide Required",
            }
        },
    },
    extraOverrideTemplate:{
        name: {
            title: "Extra override",
            detailTitle: "Extra override Detail",
            editTitle: "Edit Extra override",
        },
        table: {
            id_ext_ove_tem: "ID",
            id_pol: "ID",
            id_adv: "ID",
            commission_rate: "Commission Rate",
        },
        field: {
            id_ext_ove_tem: "ID",
            id_pol: "ID",
            id_adv: "ID",
            commission_rate: "Commission Rate",
        },
        validate: {
            required: {
                id_ext_ove_tem: "ID Required",
                id_pol: "ID Required",
                id_adv: "ID Required",
                commission_rate: "Commission Rate Required",
            }
        },
    },
    gi:{
        name: {
            title: "GI",
            detailTitle: "GI Detail",
            editTitle: "Edit GI",
            upload: "Upload GI"
        },
        table: {
            id_gi: "ID",
            id_upl_rec: "ID",
            date_time: "Date",
            tr: "TR",
            insurer: "Insurer",
            policy_no: "Policy No",
            risk: "Risk",
            insured: "Insured",
            period_from: "Period From",
            period_to: "Period To",
            gross_premium: "Gross Premium",
            commission: "Commission",
            net_amount: "Net Amount",
            payment: "Payment",
            balance: "Balance",
            tr_rate: "Tr Rate",
            eae_received: "EAE Received",
            eae_rate: "EAE Rate",
            com: "Com",
            levy: "LEVY",
            bank: "Bank",
            cq_no: "Cq No",
            amount: "Amount",
            amount_date: "Date",
            com_rev_date: "com_rev_date",
        },
        field: {
            id_gi: "ID",
            id_upl_rec: "ID",
            date_time: "Date",
            tr: "TR",
            insurer: "Insurer",
            policy_no: "Policy No",
            risk: "Risk",
            insured: "Insured",
            period_from: "Period From",
            period_to: "Period To",
            gross_premium: "Gross Premium",
            commission: "Commission",
            net_amount: "Net Amount",
            payment: "Payment",
            balance: "Balance",
            tr_rate: "Tr Rate",
            eae_received: "EAE Received",
            eae_rate: "EAE Rate",
            com: "Com",
            levy: "LEVY",
            bank: "Bank",
            cq_no: "Cq No",
            amount: "Amount",
            amount_date: "Date",
            com_rev_date: "com_rev_date",
        },
        validate: {
            required: {
                id_gi: "ID Required",
                id_upl_rec: "ID Required",
                date_time: "Date Required",
                tr: "TR Required",
                insurer: "Insurer Required",
                policy_no: "Policy No Required",
                risk: "Risk Required",
                insured: "Insured Required",
                period_from: "Period From Required",
                period_to: "Period To Required",
                gross_premium: "Gross Premium Required",
                commission: "Commission Required",
                net_amount: "Net Amount Required",
                payment: "Payment Required",
                balance: "Balance Required",
                tr_rate: "Tr Rate Required",
                eae_received: "EAE Received Required",
                eae_rate: "EAE Rate Required",
                com: "Com Required",
                levy: "LEVY Required",
                bank: "Bank Required",
                cq_no: "Cq No Required",
                amount: "Amount Required",
                amount_date: "Date Required",
                com_rev_date: "com_rev_date Required",
            }
        },
    },
    insuranceCompany:{
        name: {
            title: "Insurance Company",
            detailTitle: "Insurance Company Detail",
            editTitle: "Edit Insurance Company",
        },
        table: {
            id_ins_com: "ID",
            company_name: "Company Name",
        },
        field: {
            id_ins_com: "ID",
            company_name: "Company Name",
        },
        validate: {
            required: {
                id_ins_com: "ID Required",
                company_name: "Company Name Required",
            }
        },
    },
    log:{
      name: {
          title: "Log",
          detailTitle: "Log Detail",
          editTitle: "Edit Log",
      },
      table: {
          id_log: "ID",
          content: "Content",
          create_at: "Create At",
      },
      field: {
          id_log: "ID",
          content: "Content",
          create_at: "Create At",
      },
      validate: {
          required: {
              id_log: "ID Required",
              content: "Content Required",
              create_at: "Create At Required",
          }
      },
  },
    mpf:{
        name: {
            title: "Mpf",
            detailTitle: "Mpf Detail",
            editTitle: "Edit Mpf",
            upload: "Upload Mpf File"
        },
        table: {
            id_mpf: "ID",
            id_upl_rec: "ID",
            mpf_no: "MPF No",
            mpf_date: "MPF Date",
            provider: "Provider",
            scheme_no: "Scheme No",
            company_name: "Company Name",
            net_commission: "Net Commission",
            commission: "Commission",
            contribution_period: "Contribution Period",
        },
        field: {
            id_mpf: "ID",
            id_upl_rec: "ID",
            mpf_no: "MPF No",
            mpf_date: "MPF Date",
            provider: "Provider",
            scheme_no: "Scheme No",
            company_name: "Company Name",
            net_commission: "Net Commission",
            commission: "Commission",
            contribution_period: "Contribution Period",
        },
        validate: {
            required: {
                id_mpf: "ID Required",
                id_upl_rec: "ID Required",
                mpf_no: "MPF No Required",
                mpf_date: "MPF Date Required",
                provider: "Provider Required",
                scheme_no: "Scheme No Required",
                company_name: "Company Name Required",
                net_commission: "Net Commission Required",
                commission: "Commission Required",
                contribution_period: "Contribution Period Required",
            }
        },
    },
    other:{
        name: {
            title: "Other",
            detailTitle: "Other Detail",
            editTitle: "Edit Other",
        },
        table: {
            id_oth: "ID",
            id_pay: "ID",
            id_sub: "Subject Name",
            id_adv: "Advisor Name",
            other_amount: "Amount(HKD)",
        },
        field: {
            id_oth: "ID",
            id_pay: "ID",
            id_sub: "Subject Name",
            id_adv: "Advisor Name",
            other_amount: "Amount(HKD)",
        },
        validate: {
            required: {
                id_oth: "ID Required",
                id_pay: "ID Required",
                id_sub: "Subject Name Required",
                id_adv: "Advisor Name Required",
                other_amount: "Amount(HKD) Required",
            }
        },
    },
    payment:{
        name: {
            title: "Payment",
            detailTitle: "Payment Detail",
            editTitle: "Edit Payment",
            dateRange: "Date Range",
        },
        table: {
            id_pay: "ID",
            id_pol: "Policy Name",
            from_month: "From",
            to_month: "To",
            period: "Period",
            bank_charge: "Bank Charge",
            afyp: "AFYP(HKD)",
            total_commission: "Total Commission",
            payment_date: "Payment Period",
            fyc: "FYC(HKD)",
            ifyp: "Premium Amount",
            submitted_afyp: "Submitted AFYP",
            adjusted_afyp: "Adjusted AFYP",
            is_renewal: "Renewal",
            create_date: "Create Date",
            others: "Others",
            real_exchange_rate: "Exchange Rate",
            afyp_rate: "AFYP Rate(%)",
            extra_rate: "Extra Commission Rate(%)",
            extra_commission: "Extra Commission (HKD)",
            extra_overide: "Extra Overide",
            extra_bonus: "Extra Bonus",
            remark: "Remark",
            renewal_commission: "Renewal Commission(HKD)",
            service_provider:"Service Provider",
            insured_name:"Insured Name",
            pay_mode:"Pay Mode",
            plan_name:"Plan Name",
            total_fyc: "Total Commission",
        },
        field: {
            id_pay: "ID",
            id_pol: "Policy Name",
            from_month: "From",
            to_month: "To",
            period: "Period",
            bank_charge: "Bank Charge",
            afyp: "AFYP(HKD)",
            total_commission: "Total Commission",
            payment_date: "Payment Period",
            fyc: "FYC(HKD)",
            ifyp: "Premium Amount",
            submitted_afyp: "Submitted AFYP",
            adjusted_afyp: "Adjusted AFYP",
            is_renewal: "Renewal",
            create_date: "Create Date",
            others: "Others",
            current_period: "Last Period",
            real_exchange_rate: "Exchange Rate",
            afyp_rate: "AFYP Rate(%)",
            extra_rate: "Extra Commission Rate(%)",
            extra_commission: "Extra Commission (HKD)",
            extra_overide: "Extra Overide",
            extra_bonus: "Extra Bonus",
            remark: "Remark",
            renewal_commission: "Renewal Commission(HKD)",
            total_fyc: "Total Commission",
        },
        validate: {
            required: {
                id_pay: "ID Required",
                id_pol: "Policy Name Required",
                from_month: "From Required",
                to_month: "To Required",
                period: "Period Required",
                bank_charge: "Bank Charge Required",
                afyp: "AFYP(HKD) Required",
                total_commission: "Total Commission Required",
                payment_date: "Payment Period Required",
                fyc: "FYC(HKD) Required",
                ifyp: "Premium Amount Required",
                submitted_afyp: "Submitted AFYP Required",
                adjusted_afyp: "Adjusted AFYP Required",
                is_renewal: "Renewal Required",
                create_date: "Create Date Required",
                others: "Others Required",
                real_exchange_rate: "Exchange Rate Required",
                afyp_rate: "AFYP Rate(%) Required",
                extra_rate: "Extra Commission Rate(%) Required",
                extra_commission: "Extra Commission (HKD) Required",
                extra_overide: "Extra Overide Required",
                extra_bonus: "Extra Bonus Required",
                remark: "Remark Required",
                renewal_commission: "Renewal Commission(HKD) Required",
                total_fyc: "Total Commission Required",
            }
        },
    },
    payMode:{
        name: {
            title: "Pay Mode",
            detailTitle: "Pay Mode Detail",
            editTitle: "Edit Pay Mode",
        },
        table: {
            id_pay_mod: "ID",
            pay_mode: "Pay Mode",
            quantity: "Quantity",
            create_date: "Create Date",
            status: "Status",
        },
        field: {
            id_pay_mod: "ID",
            pay_mode: "Pay Mode",
            quantity: "Quantity",
            create_date: "Create Date",
            status: "Status",
        },
        validate: {
            required: {
                id_pay_mod: "ID Required",
                pay_mode: "Pay Mode Required",
                quantity: "Quantity Required",
                create_date: "Create Date Required",
                status: "Status Required",
            }
        },
    },
    plan:{
        name: {
            title: "Plan",
            detailTitle: "Plan Detail",
            editTitle: "Edit Plan",
            company: "Company",
        },
        table: {
            id_pla: "ID",
            id_ser_pro: "Company Name",
            id_pla_cat: "Plan Category",
            id_ins_com: "Insurance Company",
            plan_name_hk: "Plan Name(ZH-HK)",
            plan_name: "Plan Name(EN)",
            plan_code: "Plan Code",
            create_date: "Create Date",
            status: "Status",
            adjust_afyp_rate: "AFYP Rate(%)",
            expiry_date: "Expiry Date",
            plan_code_print: "Plan Name(Print)",
        },
        field: {
            id_pla: "ID",
            id_ser_pro: "Company Name",
            id_pla_cat: "Plan Category",
            id_ins_com: "Insurance Company",
            plan_name_hk: "Plan Name(ZH-HK)",
            plan_name: "Plan Name(EN)",
            plan_code: "Plan Code",
            create_date: "Create Date",
            status: "Status",
            adjust_afyp_rate: "AFYP Rate(%)",
            expiry_date: "Expiry Date",
            plan_code_print: "Plan Name(Print)",
        },
        validate: {
            required: {
                id_pla: "ID Required",
                id_ser_pro: "Company Name Required",
                id_pla_cat: "Plan Category Required",
                id_ins_com: "Insurance Company Required",
                plan_name_hk: "Plan Name(ZH-HK) Required",
                plan_name: "Plan Name(EN) Required",
                plan_code: "Plan Code Required",
                create_date: "Create Date Required",
                status: "Status Required",
                adjust_afyp_rate: "AFYP Rate Required",
                expiry_date: "Expiry Date Required",
                plan_code_print: "Plan Name(Print) Required",
            }
        },
    },
    planCategory:{
        name: {
            title: "Plan Category",
            detailTitle: "Plan Category Detail",
            editTitle: "Edit Plan Category",
        },
        table: {
            id_pla_cat: "ID",
            category_name: "Category Name",
        },
        field: {
            id_pla_cat: "ID",
            category_name: "Category Name",
        },
        validate: {
            required: {
                id_pla_cat: "ID Required",
                category_name: "Category Name Required",
            }
        },
    },
    policy:{
        name: {
            title: "Policy",
            subTitle: "Policy Plan",
            detailTitle: "Policy Detail",
            editTitle: "Edit Policy",
            search: "TR Name/Service Provider/Policy No/Insured Name/Owner Name",
        },
        table: {
            id_pol: "ID",
            id_pay_mod: "Pay Mode",
            id_adv: "TR Name",
            id_ser_pro: "Service Provider",
            submit_date: "Submit Date",
            insured_name: "Insured Name",
            owner_name: "Owner Name",
            payment_amount: "Premium Amount",
            create_date: "Create Date",
            currency: "Currency",
            start_period: "Start Period",
            policy_no: "Policy No",
            status: "Status",
            approval_date: "Approval Date",
            policy_date: "Policy Date",
            age: "Age",
            afyp: "AFYP(HKD)",
            id_cur: "Currency",
            real_currency: "Exchange Rate",
            afyp_rate: "AFYP Rate(%)",
            remark: "Remark",
            extra_commission_rate: "Extra Commission Rate",
            extra_commission: "Extra Commission(HKD)",
        },
        field: {
            id_pol: "ID",
            id_pay_mod: "Pay Mode",
            id_adv: "TR Name",
            id_ser_pro: "Service Provider",
            submit_date: "Submit Date",
            insured_name: "Insured Name",
            owner_name: "Owner Name",
            payment_amount: "Premium Amount",
            create_date: "Create Date",
            currency: "Currency",
            start_period: "Start Period",
            policy_no: "Policy No",
            status: "Status",
            approval_date: "Approval Date",
            policy_date: "Policy Date",
            age: "Age",
            afyp: "AFYP(HKD)",
            id_pla: "Plan",
            id_cur: "Currency",
            real_currency: "Exchange Rate",
            afyp_rate: "AFYP Rate(%)",
            remark: "Remark",
            extra_commission_rate: "Extra Commission Rate",
            extra_commission: "Extra Commission(HKD)",
        },
        validate: {
            required: {
                id_pol: "ID Required",
                id_pay_mod: "Pay Mode Required",
                id_adv: "TR Name Required",
                id_ser_pro: "Service Provider Required",
                submit_date: "Submit Date Required",
                insured_name: "Insured Name Required",
                owner_name: "Owner Name Required",
                payment_amount: "Premium Amount Required",
                create_date: "Create Date Required",
                currency: "Currency Required",
                start_period: "Start Period Required",
                policy_no: "Ploicy No Required",
                status: "Status Required",
                approval_date: "Approval Date Required",
                policy_date: "Policy Date Required",
                age: "Age Required",
                afyp: "AFYP(HKD) Required",
                id_pla: "Plan Required",
                id_cur: "Currency Required",
                real_currency: "Exchange Rate Required",
                afyp_rate: "AFYP Rate(%) Required",
                remark: "Remark Required",
                extra_commission_rate: "Extra Commission Rate Required",
                extra_commission: "Extra Commission(HKD) Required",
            }
        },
    },
    policyPlan:{
        name: {
            title: "Policy Plan",
            detailTitle: "Policy Plan Detail",
            editTitle: "Edit Policy Plan",
        },
        table: {
            id_pol_pla: "ID",
            id_pla: "Plan Name",
            id_pol: "Policy No",
            amount: "Premium",
            is_basic: "Is Basic",
        },
        field: {
            id_pol_pla: "ID",
            id_pla: "Plan Name",
            id_pol: "Policy No",
            amount: "Premium",
            is_basic: "Is Basic",
        },
        validate: {
            required: {
                id_pol_pla: "ID Required",
                id_pla: "Plan Name Required",
                id_pol: "Policy No Required",
                amount: "Premium Required",
                is_basic: "Is Basic Required",
            }
        },
    },
    realCommissionRate:{
        name: {
            title: "Real Commission Rate",
            detailTitle: "Real Commission Rate Detail",
            editTitle: "Edit Real Commission Rate",
        },
        table: {
            id_rea_com_rat: "ID",
            id_pol_pla: "Plan Name",
            start_month: "Start Month",
            end_month: "End Month",
            commission_rate: "Commission Rate(%)",
            first_year: "Premium Paying Term",
        },
        field: {
            id_rea_com_rat: "ID",
            id_pol_pla: "Plan Name",
            start_month: "Start Month",
            end_month: "End Month",
            commission_rate: "Commission Rate(%)",
            first_year: "Premium Paying Term",
        },
        validate: {
            required: {
                id_rea_com_rat: "ID Required",
                id_pol_pla: "Plan Name Required",
                start_month: "Start Month Required",
                end_month: "End Month Required",
                commission_rate: "Commission Rate(%) Required",
                first_year: "Premium Paying Term Required",
            }
        },
    },
    role:{
        name: {
            title: "Role",
            detailTitle: "Role Detail",
            editTitle: "Edit Role",
        },
        table: {
            id_rol: "ID",
            name: "Role",
            remark: "Remark",
        },
        field: {
            id_rol: "ID",
            name: "Role",
            remark: "Remark",
            authorityList: "Authority List"
        },
        validate: {
            required: {
                id_rol: "ID Required",
                name: "Role Required",
                remark: "Remark Required",
            }
        },
    },
    roleAuthority:{
        name: {
            title: "RoleAuthority",
            detailTitle: "RoleAuthority Detail",
            editTitle: "Edit RoleAuthority",
        },
        table: {
            id_rol_aut: "id_rol_aut",
            id_rol: "id_rol",
            id_aut: "id_aut",
        },
        field: {
            id_rol_aut: "id_rol_aut",
            id_rol: "id_rol",
            id_aut: "id_aut",
        },
        validate: {
            required: {
                id_rol_aut: "id_rol_aut Required",
                id_rol: "id_rol Required",
                id_aut: "id_aut Required",
            }
        },
    },
    salary:{
        name: {
            title: "Salary",
            detailTitle: "Salary Detail",
            editTitle: "Edit Salary",
            generalSalary: "General Salary",
            month: "Month",
        },
        table: {
            id_sal: "ID",
            id_adv: "Advisor Name",
            payment_date: "Payment Date",
            total_afyp: "Total AFYP",
            total_ifyp: "Total IFYP",
            total_renewal: "Total Renewal",
            payment_amount: "Total Amount",
            total_fyc: "Total FYC",
            create_date: "Create Date",
            others: "Others",
            contribution: "Contribution",
            bank_charge: "Bank Charge",
            overide: "Overide",
            extra_commission: "Extra Commission",
            extra_bonus: "Extra Bonus",
            total_subject: "Total Subject",
            confirm_date: "Confirm Date",
            month: "Month",
            override_remark: "Override Remark",
            total_fyc_remark: "Total FYC Remark",
            total_renewal_remark: "Total Renewal Remark",
            contribution_remark: "Contribution Remark",
            bank_charge_remark: "Bank Charge Remark",
        },
        field: {
            id_sal: "ID",
            id_adv: "Advisor Name",
            payment_date: "Payment Date",
            total_afyp: "Total AFYP",
            total_ifyp: "Total IFYP",
            total_renewal: "Total Renewal",
            payment_amount: "Total Amount",
            total_fyc: "Total FYC",
            create_date: "Create Date",
            others: "Others",
            contribution: "Contribution",
            bank_charge: "Bank Charge",
            overide: "Overide",
            extra_commission: "Extra Commission",
            extra_bonus: "Extra Bonus",
            total_subject: "Total Subject",
            confirm_date: "Confirm Date",
            month: "Month",
            override_remark: "Override Remark",
            total_fyc_remark: "Total FYC Remark",
            total_renewal_remark: "Total Renewal Remark",
            contribution_remark: "Contribution Remark",
            bank_charge_remark: "Bank Charge Remark",
        },
        validate: {
            required: {
                id_sal: "ID Required",
                id_adv: "Advisor Name Required",
                payment_date: "Payment Date Required",
                total_afyp: "Total AFYP Required",
                total_ifyp: "Total IFYP Required",
                total_renewal: "Total Renewal Required",
                payment_amount: "Total Amount Required",
                total_fyc: "Total FYC Required",
                create_date: "Create Date Required",
                others: "Others Required",
                contribution: "Contribution Required",
                bank_charge: "Bank Charge Required",
                overide: "Overide Required",
                extra_commission: "Extra Commission Required",
                extra_bonus: "Extra Bonus Required",
                total_subject: "Total Subject Required",
                confirm_date: "Confirm Date Required",
                month: "Month Required",
                override_remark: "Override Remark Required",
                total_fyc_remark: "Total FYC Remark Required",
                total_renewal_remark: "Total Renewal Remark Required",
                contribution_remark: "Contribution Remark Required",
                bank_charge_remark: "Bank Charge Remark Required",
            }
        },
    },
    salaryOther:{
        name: {
            title: "Salary Other",
            detailTitle: "Salary Other Detail",
            editTitle: "Edit Salary Other",
        },
        table: {
            id_sal_oth: "ID",
            id_sal: "Salary",
            id_sub: "Subject Name",
            amount: "Amount",
            remark: "Remark",
        },
        field: {
            id_sal_oth: "ID",
            id_sal: "Salary",
            id_sub: "Subject Name",
            amount: "Amount",
            remark: "Remark",
        },
        validate: {
            required: {
                id_sal_oth: "ID Required",
                id_sal: "Salary Required",
                id_sub: "Subject Name Required",
                amount: "Amount Required",
                remark: "Remark Required",
            }
        },
    },
    serviceProvider:{
        name: {
            title: "Service Provider",
            detailTitle: "Service Provider Detail",
            editTitle: "Edit Service Provider",
        },
        table: {
            id_ser_pro: "ID",
            company_name: "Company Name",
            company_code: "Company Code",
            create_date: "Create Date",
            status: "Status",
            short_name: "Insurance Name(Short Name)",
        },
        field: {
            id_ser_pro: "ID",
            company_name: "Company Name",
            company_code: "Company Code",
            create_date: "Create Date",
            status: "Status",
            short_name: "Insurance Name(Short Name)",
        },
        validate: {
            required: {
                id_ser_pro: "ID Required",
                company_name: "Company Name Required",
                company_code: "Company Code Required",
                create_date: "Create Date Required",
                status: "Status Required",
                short_name: "Insurance Name(Short Name) Required",
            }
        },
    },
    subject:{
        name: {
            title: "Subject",
            detailTitle: "Subject Detail",
            editTitle: "Edit Subject",
        },
        table: {
            id_sub: "ID",
            subject_name: "Subject Name",
            subject_name_hk: "Subject Name Hk",
        },
        field: {
            id_sub: "ID",
            subject_name: "Subject Name",
            subject_name_hk: "Subject Name Hk",
        },
        validate: {
            required: {
                id_sub: "ID Required",
                subject_name: "Subject Name Required",
                subject_name_hk: "Subject Name Hk Required",
            }
        },
    },
    subjectListSummary:{
        name: {
            title: "Subject List Summary",
            detailTitle: "Subject List Summary Detail",
            editTitle: "Edit Subject List Summary",
        },
        table: {
            id_sub_lis_sum: "ID",
            id_sub: "Subject Name",
            id_sal: "Salary",
            total_amount: "Total Amount",
            remark: "Remark",
        },
        field: {
            id_sub_lis_sum: "ID",
            id_sub: "Subject Name",
            id_sal: "Salary",
            total_amount: "Total Amount",
            remark: "Remark",
        },
        validate: {
            required: {
                id_sub_lis_sum: "ID Required",
                id_sub: "Subject Name Required",
                id_sal: "Salary Required",
                total_amount: "Total Amount Required",
                remark: "Remark Required",
            }
        },
    },
    uploadRecord:{
        name: {
            title: "Upload Record",
            detailTitle: "Upload Record Detail",
            editTitle: "Edit Upload Record",
        },
        table: {
            id_upl_rec: "ID",
            type: "Type",
            created_time: "Upload Date",
        },
        field: {
            id_upl_rec: "ID",
            type: "Type",
            created_time: "Upload Date",
        },
        validate: {
            required: {
                id_upl_rec: "ID Required",
                type: "Type Required",
                created_time: "Upload Date Required",
            }
        },
    },
    generalSalary:{
        name: {
            title: "General Salary",
            month: "Month",
            month_placeholder: "Select Month",
            generalSalary: "General",
            payment_list: "Payment List",
            override_list:"Override List",
            subject_list:"Subject List",
            extra_override_list: "Extra Override List",
        },
        table: {
            id_rep: "ID",
            id_emp: "Employee",
            month: "Month",
            base_salary: "Salary",
            allowance: "Allowance",
            car_subsidy: "Car Subsidy",
            house_subsidy: "House Subsidy",
            other_subsidy: "Other Subsidy",
            commission: "Commission",
            overtime_times: "Overtime Times",
            overtime_pay: "Overtime Pay",
            overtime: "Overtime(Minute)",
            bonus: "Bonus",
            compensatory_leave_time: "Compensatory Leave Time",
            total_use_annual_leave: "Total Used Annual Leave",
            total_annual_leave: "Total Annual Leave",
            use_annual_leave: "Used Annual Leave",
            remark: "Remark",
            leave_time: "Leave Time",
            absence_days: "Absence Days",
            create_time: "Create Time",
            mpf: "MPF",
            other_deductions: "Other deductions",
            total_salary: "Total Salary",
            attendance_days: "Attendance Days",
            rest_days: "Rest Days",
            work_hours: "Work Hours",
            late_time: "LateTime",
            late_times: "Late Times",
            serious_late_times: "Serious Late Times",
            serious_late_time: "Serious Late Time",
            absenteeism_times: "Absenteeism Times",
            early_leave_time: "Early Leave Time",
            early_leave_times: "Early Leave Times",
            no_check_in_times: "No Check In Times",
            no_check_out_times: "No Check Out Times",
        },
        field: {
            id_rep: "ID",
            id_emp: "Employee",
            month: "Month",
            base_salary: "Salary",
            allowance: "Allowance",
            car_subsidy: "Car Subsidy",
            house_subsidy: "House Subsidy",
            other_subsidy: "Other Subsidy",
            commission: "Commission",
            overtime_times: "Overtime Times",
            overtime_pay: "Overtime Pay",
            overtime: "Overtime(Minute)",
            bonus: "Bonus",
            compensatory_leave_time: "Compensatory Leave Time",
            total_use_annual_leave: "Total Used Annual Leave",
            total_annual_leave: "Total Annual Leave",
            use_annual_leave: "Used Annual Leave",
            remark: "Remark",
            leave_time: "Leave Time",
            absence_days: "Absence Days",
            create_time: "Create Time",
            mpf: "MPF",
            other_deductions: "Other deductions",
            total_salary: "Total Salary",
            attendance_days: "Attendance Days",
            rest_days: "Rest Days",
            work_hours: "Work Hours",
            late_time: "LateTime",
            late_times: "Late Times",
            serious_late_times: "Serious Late Times",
            serious_late_time: "Serious Late Time",
            absenteeism_times: "Absenteeism Times",
            early_leave_time: "Early Leave Time",
            early_leave_times: "Early Leave Times",
            no_check_in_times: "No Check In Times",
            no_check_out_times: "No Check Out Times",
        },
        validate: {
            required: {
                id_rep: "ID Required",
                id_emp: "Employee Required",
                month: "Month Required",
                base_salary: "Salary Required",
                allowance: "Allowance Required",
                car_subsidy: "Car Subsidy Required",
                house_subsidy: "House Subsidy Required",
                other_subsidy: "Other Subsidy Required",
                commission: "Commission Required",
                overtime_times: "Overtime Times Required",
                overtime_pay: "Overtime Pay Required",
                overtime: "Overtime Required",
                bonus: "Bonus Required",
                compensatory_leave_time: "Compensatory Leave Time Required",
                total_use_annual_leave: "Total Used Annual Leave Required",
                total_annual_leave: "Total Annual Leave Required",
                use_annual_leave: "Used Annual Leave Required",
                remark: "Remark Required",
                leave_time: "Leave Time Required",
                absence_days: "Absence Days Required",
                create_time: "Create Time Required",
                mpf: "MPF Required",
                other_deductions: "Other deductions Required",
                total_salary: "Total Salary Required",
                attendance_days: "Attendance Days Required",
                rest_days: "Rest Days Required",
                work_hours: "Work Hours Required",
                late_time: "LateTime Required",
                late_times: "Late Times Required",
                serious_late_times: "Serious Late Times Required",
                serious_late_time: "Serious Late Time Required",
                absenteeism_times: "Absenteeism Times Required",
                early_leave_time: "Early Leave Time Required",
                early_leave_times: "Early Leave Times Required",
                no_check_in_times: "No Check In Times Required",
                no_check_out_times: "No Check Out Times Required",
            }
        },
    },
    downloadMpfStatement:{
        name: {
            title: "Download Mpf Statement",
            month: "Month",
            print_date: "Print Date"
        },
    },
    downloadMpfStatementYear:{
        name: {
            title: "Download Mpf Statement Year",
            year: "Year",
        },
    },
    downloadEaeGiList:{
        name: {
            title: "Download Eae Gi List",
        },
    },
    downloadGerenalInsurance:{
        name: {
            title: "Download Advisor Statement For General Insurance",
            month: "Month",
        },
    },
    downloadCommissionPayment:{
        name: {
            title: "Download Commission Payment",
            month: "Month",
            downloadAdvisor:"Download Advisor",
            downloadPaymentCommissionExcel:"Download Commission Payment Excel",
            downloadAdvisorExcel:"Download Advisor Excel",
        },
    },
    persistency:{
      name: {
          title: "Persistency",
          detailTitle: "Persistency Detail",
          editTitle: "Edit Persistency",
          show: "Show",
          save: "Save",
          download: "Download",
          advisorInfo: "Advisor Info",
          year: "Year",
          downloadExcel: "Download Excel",
      },
      table: {
          id_per: "ID",
          id_adv: "Advisor Name",
          month: "Month",
          afyp: "AFYP",
          fyc: "FYC",
          case_num: "Case",
          lapsed_afyp: "Lapsed AFYP",
          reinstated_afyp: "Reinstated AFYP",
          exposure_afyp: "Exposure AFYP",
          total_lapsed_afyp: "Lapsed AFYP",
          persistency: "19M Personal Persistency",
      },
      field: {
          id_per: "ID",
          id_adv: "Advisor Name",
          month: "Month",
          afyp: "AFYP",
          fyc: "FYC",
          case_num: "Case",
          lapsed_afyp: "Lapsed AFYP",
          reinstated_afyp: "Reinstated AFYP",
          exposure_afyp: "Exposure AFYP",
          total_lapsed_afyp: "Lapsed AFYP",
          persistency: "19M Personal Persistency",
      },
      validate: {
          required: {
              id_per: "ID Required",
              id_adv: "Advisor Name Required",
              month: "Month Required",
              afyp: "AFYP Required",
              fyc: "FYC Required",
              case_num: "Case Required",
              lapsed_afyp: "Lapsed AFYP Required",
              reinstated_afyp: "Reinstated AFYP Required",
              exposure_afyp: "Exposure AFYP Required",
              total_lapsed_afyp: "Lapsed AFYP Required",
              persistency: "19M Personal Persistency Required",
          }
      },
  },
}
export default enUS