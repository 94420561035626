<template>
  <Modal id="MpfDetail" width="60%" :visible="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('mpf.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('mpf.field.id_mpf')">{{data.idMpf}}</DescriptionsItem>
      <DescriptionsItem :label="$t('mpf.field.id_upl_rec')">{{data.idUplRec}}</DescriptionsItem>
      <DescriptionsItem :label="$t('mpf.field.mpf_no')">{{data.mpfNo}}</DescriptionsItem>
      <DescriptionsItem :label="$t('mpf.field.mpf_date')">{{data.mpfDate}}</DescriptionsItem>
      <DescriptionsItem :label="$t('mpf.field.provider')">{{data.provider}}</DescriptionsItem>
      <DescriptionsItem :label="$t('mpf.field.scheme_no')">{{data.schemeNo}}</DescriptionsItem>
      <DescriptionsItem :label="$t('mpf.field.company_name')">{{data.companyName}}</DescriptionsItem>
      <DescriptionsItem :label="$t('mpf.field.net_commission')">{{data.netCommission}}</DescriptionsItem>
      <DescriptionsItem :label="$t('mpf.field.commission')">{{data.commission}}</DescriptionsItem>
      <DescriptionsItem :label="$t('mpf.field.contribution_period')">{{data.contributionPeriod}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>

<script>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "MpfDetail",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      selectList:{
      },
      data: {
      },
      visible: false,
    };
  },
  computed: {
  },
  methods: {
    async detailShow(obj) {
      this.data = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
  },
}
</script>

<style lang="less">
</style>