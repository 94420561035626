<template>
  <Row id="Report">
    <Col :span="24" class="title">
      <TypographyTitle>{{$t('nav.name.ReportManage')}}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24" >
          <Form :model="searchForm">
            <Row :gutter="30">
              <Col span="10">
              <FormItem :label="$t('salary.field.id_adv')">
                <!--关联表的下拉选择-->
                <Select v-model:value="searchForm.idAdvList" :placeholder="$t('salary.validate.required.id_adv')"  mode="multiple"
                  :dropdownStyle="{ zIndex: 9999999950 }" :filter-option="filterOption"
                      show-search>
                  <SelectOption :key="sk" v-for="(item, sk) in selectList['advisorList']" :title="item['name']" :value="item['idAdv']">{{
                      item['name']
                  }}</SelectOption>
                </Select>
              </FormItem>
              </Col>
              <Col :xs="24" :sm="4">
                <Checkbox
                  v-model:checked="checkAllTr"
                  @change="onCheckAllTrChange"
                >
                  All TR
                </Checkbox>
              </Col>
              <Col :xs="24" :sm="4">
                <FormItem :label="$t('salary.field.month')" name="month">
                  <DatePicker v-model:value="searchForm.month" valueFormat="YYYY-MM" picker="month" />
                </FormItem>
              </Col>
              <Col :xs="24" :sm="18">
                <Checkbox
                  v-model:checked="checkAll"
                  @change="onCheckAllChange"
                >
                  Check all
                </Checkbox>
                <CheckboxGroup v-model:value="searchForm.description" :options="plainOptions" />
              </Col>
              <Col :xs="24" :sm="16" class="text-right">
                <Button type="primary" @click="search_submit">{{$t('public.name.search')}}</Button>&nbsp;
                <Button @click="search_reset">{{$t('public.name.reset')}}</Button>&nbsp;
                <Button type="primary" @click="download">{{$t('persistency.name.download')}}</Button>&nbsp;
              </Col>
            </Row>
          </Form>
        </Col>
        <Col :span="24">
          <Table :columns="columns" :dataSource="list" :scroll="{ x: true }" :loading="loading" :pagination="false" >
            
          
          </Table>
        </Col>
      </Row>
    </Col>
  
  </Row>
  <DownloadPersistencyPrintDate ref="refDownloadPersistencyPrintDate" @selectedPrintDate="downloadCommissionPaymetDown"></DownloadPersistencyPrintDate>
</template>

<script>
import { createVNode } from "vue";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, Form, Input, Select, message,DatePicker,Descriptions,InputNumber,CheckboxGroup,Checkbox } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined,CheckOutlined,EditOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { AdvisorListAll } from "@/api/AdvisorListAll"
import { PaymentReportPersistency } from "@/api/PaymentReportPersistency"
import { PaymentReportPersistencyExport } from "@/api/PaymentReportPersistencyExport"
import { cloneDeep } from 'lodash-es';

export default {
  name: "ReportManage",
  components: {
    Row,
    Col,
    Table,
    Form,
    FormItem: Form.Item,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Input,
    Select,
    SelectOption: Select.Option,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    CheckOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    Menu,
    MenuItem: Menu.Item,
    DatePicker,
    Descriptions,
    DescriptionsItem: Descriptions.Item,
    InputNumber,
    CheckboxGroup,
    Checkbox,
  },
  data() {
    return {
      searchForm: {
        idAdvList: [],
        month: "",
        description: ['AFYP', 'FYC', 'CASE', 'Lapsed AFYP', 'Reinstated AFYP', 'Total Exposure AFYP', 'Total Lapsed AFYP', '19M Personal Persistency']
      },
      checkAll: true,
      checkAllTr: false,
      plainOptions: ['AFYP', 'FYC', 'CASE', 'Lapsed AFYP', 'Reinstated AFYP', 'Total Exposure AFYP', 'Total Lapsed AFYP', '19M Personal Persistency'],
      loading: false,
      list: [],
      selectList:{
        advisorList: [],
      }
    };
  },
  methods: {
    get_list() {
      this.loading = true;
      const api = new PaymentReportPersistency();
      api
        .get({
          idAdvListStr:this.searchForm.idAdvList.join(','),
          month:this.searchForm.month,
        })
        .then((response) => {
          this.loading = false;
          if (0 === parseInt(response.data.code)) {
            this.list = response.data.data;
          } else {
            this.list = [];
          }
        });
    },
    
    search_submit() {
      if(!(this.searchForm.idAdvList.length > 0) || !this.searchForm.month){
        message.error("請輸入所有條件")
        return;
      }
      this.get_list();
      
    },
    getAdvisorList() {
      const api = new AdvisorListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.selectList.advisorList = response.data.data;
        } else {
          message.error(response.data.msg);
        }
      });
    },
    
    search_reset() {
      this.searchForm = {
        idAdvList: [],
        month: "",
        description: ['AFYP', 'FYC', 'CASE', 'Lapsed AFYP', 'Reinstated AFYP', 'Total Exposure AFYP', 'Total Lapsed AFYP', '19M Personal Persistency']
      }
      this.list = [];
    },
    
    
    download() {
      if(!(this.searchForm.idAdvList.length > 0) || !this.searchForm.month){
        message.error("請輸入所有條件")
        return;
      }
      const api = new PaymentReportPersistencyExport();
      
      api
        .download({
          idAdvListStr:this.searchForm.idAdvList.join(','),
          month:this.searchForm.month,
        })
        .then((response) => {
          // console.log(decodeURI(res.headers['filename']));
          let blob = new Blob([response.data], { type: "application/xlsx" });
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a"); // 创建a标签
          link.href = url;
          link.download = decodeURI(response.headers['filename']); // 重命名文件
          link.click();
          URL.revokeObjectURL(url);
        });

    },
    onCheckAllChange(e) {
      if(e.target.checked){
        Object.assign(this.searchForm.description, this.plainOptions)
      }else{
        this.searchForm.description = []
      }
    },
    onCheckAllTrChange(e) {
      if(e.target.checked){
        this.searchForm.idAdvList = []
        this.selectList.advisorList.forEach((item) => {
          this.searchForm.idAdvList.push(item.idAdv)
        })
      }else{
        this.searchForm.idAdvList = []
      }
    },
    filterOption(input, option) {
    // console.log("option.title.toLowerCase()：" + option.title.toLowerCase());
    // console.log("input.toLowerCase()：" + input.toLowerCase());
    // console.log("option.title.toLowerCase().indexOf(input.toLowerCase())：" + option.title.toLowerCase().indexOf(input.toLowerCase()));
    return option.title.toLowerCase().indexOf(input.toLowerCase()) != -1;
  }, 
  },
  watch: {
      "searchForm.description": {
          deep: true,
          handler: function (newValue, oldValue) {
              this.checkAll = this.plainOptions.length === this.searchForm.description.length
          }
      },
      "searchForm.idAdvList": {
          deep: true,
          handler: function (newValue, oldValue) {
              this.checkAllTr = this.selectList.advisorList.length === this.searchForm.idAdvList.length
          }
      }
  },
  computed: {
    columns: function () {
      let result = []


      result.push({ title: this.$t("advisor.table.tr_no"), dataIndex: "advisorTrNo", key: "advisorTrNo" })
      result.push({ title: this.$t("advisor.table.tr_full_name"), dataIndex: "advisorTrFullName", key: "advisorTrFullName" })
      result.push({ title: this.$t("advisor.table.name"), dataIndex: "advisorName", key: "advisorName" })
      result.push({ title: this.$t("advisor.table.title"), dataIndex: "advisorTitle", key: "advisorTitle" })
      result.push({ title: this.$t("advisor.table.licence_no"), dataIndex: "advisorLicenceNo", key: "advisorLicenceNo" })



      result.push({ title: this.$t('persistency.table.month'), dataIndex: "month", key: "month" })
      let currentMonth = { title: 'Current Month', children:[]}
      if(this.searchForm.description.some(item => { return item === 'AFYP'})){
        currentMonth.children.push({ title: this.$t("persistency.table.afyp"), dataIndex: "afyp", key: "afyp", customRender: ({text}) =>{
              if(text != null){
                return text.toFixed(0)
              }
            }})
      }
      if(this.searchForm.description.some(item => { return item === 'FYC'})){
        currentMonth.children.push({ title: this.$t("persistency.table.fyc"), dataIndex: "fyc", key: "fyc", customRender: ({text}) =>{
              if(text != null){
                return text.toFixed(0)
              }
            }})
      }
      if(this.searchForm.description.some(item => { return item === 'CASE'})){
        currentMonth.children.push({ title: this.$t("persistency.table.case_num"), dataIndex: "caseNum", key: "caseNum" })
      }
      if(this.searchForm.description.some(item => { return item === 'Lapsed AFYP'})){
        currentMonth.children.push(
          { title: this.$t("persistency.table.lapsed_afyp"), dataIndex: "lapsedAfyp", key: "lapsedAfyp"
            }
        )
      }

      if(this.searchForm.description.some(item => { return item === 'Reinstated AFYP'})){
        currentMonth.children.push(
          { title: this.$t("persistency.table.reinstated_afyp"), dataIndex: "reinstatedAfyp", key: "reinstatedAfyp" }
        )
      }
      if(currentMonth.children.length > 0){
        result.push(currentMonth)
      }


      let total = { title: 'Total', children:[]}
      if(this.searchForm.description.some(item => { return item === 'Total Exposure AFYP'})){
        total.children.push({ title: this.$t("persistency.table.exposure_afyp"), dataIndex: "exposureAfyp", key: "exposureAfyp", customRender: ({text}) =>{
              if(text != null){
                return text.toFixed(0)
              }
            }  })
      }
      if(this.searchForm.description.some(item => { return item === 'Total Lapsed AFYP'})){
        total.children.push(
          { title: this.$t("persistency.table.total_lapsed_afyp"), dataIndex: "totalLapsedAfyp", key: "totalLapsedAfyp", customRender: ({text}) =>{
              if(text != null){
                return text.toFixed(0)
              }
            }  }
        )
      }
      if(this.searchForm.description.some(item => { return item === '19M Personal Persistency'})){
        total.children.push({ title: this.$t("persistency.table.persistency"), dataIndex: "persistency", key: "persistency", customRender: ({text}) =>{
              if(text != null){
                if(text == 100){
                  return text.toFixed(2) + '%'
                }else{
                  return text.toFixed(2) + '%'
                }
              }
            }
           })
      }
      if(total.children.length > 0){
        result.push(total)
      }



      return result
    },
  },
  mounted() {
    this.getAdvisorList();
  },
}
</script>

<style lang="less">
@import url("~@/assets/less/home/salary.less");
</style>