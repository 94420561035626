<template>
  <Modal
    id="PlanEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="planForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                  <Col span="10">
                    <FormItem :label="$t('plan.field.id_ser_pro')" name="idSerPro">
                        <!--关联表的下拉选择-->
                        <Select
                                v-model:value="form.idSerPro"
                                :placeholder="$t('plan.validate.required.id_ser_pro')"
                                :dropdownStyle="{ zIndex: 9999999950 }"
                                :filter-option="filterOption"
                      show-search
                        >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['serviceProviderList']" :title="item['companyName']" :value="item['idSerPro']">{{ item['companyName'] }}</SelectOption>
                        </Select>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('plan.field.id_pla_cat')" name="idPlaCat">
                        <Select
                                v-model:value="form.idPlaCat"
                                :placeholder="$t('plan.validate.required.id_pla_cat')"
                                :dropdownStyle="{ zIndex: 9999999950 }"
                                :filter-option="filterOption"
                      show-search
                        >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['planCategoryList']" :title="item['categoryName']" :value="item['idPlaCat']">{{ item['categoryName'] }}</SelectOption>
                        </Select>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('plan.field.id_ins_com')" >
                                            <Input 
                                            disabled
                                            :placeholder="$t('plan.validate.required.id_ins_com')"
                                                   v-model:value="serviceProviderShortName">
                                            </Input>
                  </FormItem>
                  </Col>
                  <!-- <Col span="10">
                    <FormItem :label="$t('plan.field.id_ins_com')" name="idInsCom">
                      
                        <Select
                                v-model:value="form.idInsCom"
                                :placeholder="$t('plan.validate.required.id_ins_com')"
                                :dropdownStyle="{ zIndex: 9999999950 }"
                        >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['insuranceCompanyList']" :value="item['idInsCom']">{{ item['companyName'] }}</SelectOption>
                        </Select>
                  </FormItem>
                  </Col> -->
                  <Col span="10">
                    <FormItem :label="$t('plan.field.plan_name_hk')" name="planNameHk">
                                            <Input :placeholder="$t('plan.validate.required.plan_name_hk')"
                                                   v-model:value="form.planNameHk">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('plan.field.plan_name')" name="planName">
                                            <Input :placeholder="$t('plan.validate.required.plan_name')"
                                                   v-model:value="form.planName">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('plan.field.plan_code')" name="planCode">
                                            <Input :placeholder="$t('plan.validate.required.plan_code')"
                                                   v-model:value="form.planCode">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('plan.field.status')" name="status">
                      <Select
                              v-model:value="form.status"
                              :placeholder="$t('plan.validate.required.status')"
                              :options="selectList.statusList"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                      </Select>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('plan.field.adjust_afyp_rate')" name="adjustAfypRate">
                                            <InputNumber
                                                    v-model:value="form.adjustAfypRate"
                                                    :placeholder="$t('plan.validate.required.adjust_afyp_rate')"
                                                    :step="0.01"
                                                    :min="0"
                                                    :max="100"
                                                    :formatter="value => `${value}%`"
                                                    :parser="value => value.replace('%', '')"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('plan.field.expiry_date')" name="expiryDate">
                      <DatePicker valueFormat="YYYY-MM-DD" v-model:value="form.expiryDate" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('plan.field.plan_code_print')" name="planCodePrint">
                                            <Input :placeholder="$t('plan.validate.required.plan_code_print')"
                                                   v-model:value="form.planCodePrint">
                                            </Input>
                  </FormItem>
                  </Col>
                <!--判断是否有子表，如果有则直接加入子表管理逻辑-->
                      <!--遍历Table，找出子表-->
                <Col span="24">
                  <Card size="small" :title="$t('commissionRate.name.title')">
                    <template #extra>
                      <Button size="small" type="primary" block @click="addCommissionRate">
                        <PlusOutlined />
                        {{ this.$t("public.name.add") }}
                      </Button>
                    </template>
                    <Table size="small" :columns="commissionRateColumns" :data-source="form.commissionRateList" bordered :pagination="false">
                      <template #operation="{ record, index }">
                        <div class="editable-row-operations">
                          <span>
                            <template v-if="actionType === 'edit'">
                              <a @click="editCommissionRate(record)">{{ this.$t("public.name.edit") }}</a>
                              &nbsp;
                            </template>
                            <Popconfirm :title="$t('public.name.are_you_sure_delete')" @confirm="removeCommissionRate(index)">
                              <a>{{ this.$t("public.name.del") }}</a>
                            </Popconfirm>
                          </span>
                        </div>
                      </template>
                    </Table>
                  </Card>
                </Col>

              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
  <CommissionRateEdit ref="refCommissionRateEdit" @updateList="updateListCommissionRate"></CommissionRateEdit>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Space,
  Card,
  Table,
  Popconfirm,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { PlanAdd } from "@/api/PlanAdd";
import { PlanEdit } from "@/api/PlanEdit";
import { ServiceProviderListAll } from "@/api/ServiceProviderListAll"
import { PlanCategoryListAll } from "@/api/PlanCategoryListAll"
import { InsuranceCompanyListAll } from "@/api/InsuranceCompanyListAll"
import CommissionRateEdit from "@/components/Home/CommissionRateEdit";
import { CommissionRateDelete } from "@/api/CommissionRateDelete"

export default {
  name: "PlanEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    MinusCircleOutlined,
    ExclamationCircleOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    Space,
    Card,
    Table,
    Popconfirm,
    CommissionRateEdit,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idPla : "",
        idSerPro : "",
        idPlaCat : "",
        idInsCom : "",
        planNameHk : "",
        planName : "",
        planCode : "",
        createDate : "",
        status : "",
        adjustAfypRate : "",
        expiryDate : "",
        planCodePrint : "",
        commissionRateList: []
      },
      fileList: {
      },
      selectList: {
        serviceProviderList: [],
        planCategoryList: [],
        insuranceCompanyList: [],
        statusList: [{value: 0,label: 'Off'},{value:1,label: 'On'}],
        firstYearList: [{value: 0,label: 'First Year'},{value:1,label: 'Renewal'}],
      },
      validateRules: {
        idPla: [
          {
            required: true,
            message: this.$t("plan.validate.required.id_pla"),
            trigger: "change",
            type: "number",
          },
        ],
        idSerPro: [
          {
            required: true,
            message: this.$t("plan.validate.required.id_ser_pro"),
            trigger: "change",
            type: "number",
          },
        ],
        idPlaCat: [
          {
            required: true,
            message: this.$t("plan.validate.required.id_pla_cat"),
            trigger: "change",
            type: "number",
          },
        ],
        idInsCom: [
          {
            required: true,
            message: this.$t("plan.validate.required.id_ins_com"),
            trigger: "change",
            type: "number",
          },
        ],
        planNameHk: [
          {
            required: true,
            message: this.$t("plan.validate.required.plan_name_hk"),
            trigger: "change",
          },
        ],
        planName: [
          {
            required: true,
            message: this.$t("plan.validate.required.plan_name"),
            trigger: "change",
          },
        ],
        planCode: [
          {
            required: true,
            message: this.$t("plan.validate.required.plan_code"),
            trigger: "change",
          },
        ],
        createDate: [
          {
            required: true,
            message: this.$t("plan.validate.required.create_date"),
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: this.$t("plan.validate.required.status"),
            trigger: "change",
            type: "number",
          },
        ],
        adjustAfypRate: [
          {
            required: true,
            message: this.$t("plan.validate.required.adjust_afyp_rate"),
            trigger: "change",
            type: "number",
          },
        ],
        expiryDate: [
          {
            required: true,
            message: this.$t("plan.validate.required.expiry_date"),
            trigger: "change",
          },
        ],
        planCodePrint: [
          {
            required: true,
            message: this.$t("plan.validate.required.plan_code_print"),
            trigger: "change",
          },
        ],
      },
      commissionRateColumns: [
        { title: this.$t("commissionRate.table.start_month"), dataIndex: "startMonth", key: "startMonth" },
        { title: this.$t("commissionRate.table.end_month"), dataIndex: "endMonth", key: "endMonth" },
        { title: this.$t("commissionRate.table.commission_rate"), dataIndex: "commissionRate", key: "commissionRate", customRender: ({text}) =>{
            if(typeof(text)!="undefined" && text != null)
                return text + "%"
          }
        },
        { title: this.$t("commissionRate.table.first_year"), dataIndex: "firstYear", key: "firstYear", customRender: ({text}) =>{
            if(typeof(text)!="undefined" && text != null)
                return this.selectList.firstYearList.find(item=>item.value===text).label
          }
        },
        {
          title: this.$t("public.table.action"),
          dataIndex: 'operation',
          slots: {
            customRender: 'operation',
          },
        },
      ],
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
    serviceProviderShortName: function(){
      if(this.form.idSerPro){
        let serviceProvider = this.selectList.serviceProviderList.find(item => {
          return item.idSerPro === this.form.idSerPro
        })
        if(serviceProvider){
          return serviceProvider.shortName
        }else{
          return ""
        }
        
      }else{
        return ""
      }
    }
  },
  watch: {
    "visible": {
      deep: true,
              handler: function (newValue, oldValue) {
        if (!newValue) {
          this.$emit("updateList");
        }
      }
    }
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.getServiceProviderList();
      this.getPlanCategoryList();
      this.getInsuranceCompanyList();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.getServiceProviderList();
      this.getPlanCategoryList();
      this.getInsuranceCompanyList();
      this.visible = true;
    },
    submit() {
      this.$refs["planForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new PlanAdd();
              this.form.commissionRateListStr = JSON.stringify(this.form.commissionRateList)
              break;
            case "edit":
              console.log("edit");
              api = new PlanEdit();
              break;
            default:
              return false;
          }
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList",response.data.data);
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    getServiceProviderList() {
      const api = new ServiceProviderListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.serviceProviderList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    getPlanCategoryList() {
      const api = new PlanCategoryListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.planCategoryList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    getInsuranceCompanyList() {
      const api = new InsuranceCompanyListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.insuranceCompanyList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    addCommissionRate() {
      this.$refs.refCommissionRateEdit.addShow(this.form.idPla);
    },
    editCommissionRate(obj) {
      //因为后台没有返回这个关联id到该对象，所以当编辑时需要把关联id手动设定
      if (this.actionType === 'edit') {
        obj.idPla = this.form.idPla
      }
      this.$refs.refCommissionRateEdit.editShow(obj);
    },
    removeCommissionRate(index) {
      if (this.actionType === 'add') {
        this.form.commissionRateList.splice(index, 1);
      } else {
        let commissionRate = this.form.commissionRateList[index]
        const api = new CommissionRateDelete();
        api.post({ idComRat: commissionRate.idComRat }).then((response) => {
          if (0 === parseInt(response.data.code)) {
            this.form.commissionRateList.splice(index, 1);
            message.success(response.data.msg);
          } else {
            message.error(response.data.msg);
          }
        });
      }
    },
    updateListCommissionRate(obj) {
      if (this.actionType === 'add') {
        this.form.commissionRateList.push(obj);
      } else {
        let index = this.form.commissionRateList.findIndex(item => {
          return item.idComRat === obj.idComRat
        })
        //没有找到index，代表编辑时新增
        if (index === -1) {
          this.form.commissionRateList.push(obj);
        } else {
          this.form.commissionRateList[index] = obj
        }
      }
    },
    filterOption(input, option) {
    // console.log("option.title.toLowerCase()：" + option.title.toLowerCase());
    // console.log("input.toLowerCase()：" + input.toLowerCase());
    // console.log("option.title.toLowerCase().indexOf(input.toLowerCase())：" + option.title.toLowerCase().indexOf(input.toLowerCase()));
    return option.title.toLowerCase().indexOf(input.toLowerCase()) != -1;
  }, 
    // reset data
    _resetData() {
      this.form = {
        idPla : "",
        idSerPro : "",
        idPlaCat : "",
        idInsCom : "",
        planNameHk : "",
        planName : "",
        planCode : "",
        createDate : "",
        status : "",
        adjustAfypRate : "",
        expiryDate : "",
        planCodePrint : "",
        commissionRateList: []
      };
    },
  },
};
</script>

<style lang="less">
</style>