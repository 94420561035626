<template>
  <Row id="Persistency">
    <Col :span="24" class="title">
      <TypographyTitle>{{$t('nav.name.PersistencyManage')}}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24" >
          <Form :model="searchForm">
            <Row :gutter="30">
              <Col span="10">
              <FormItem :label="$t('salary.field.id_adv')">
                <!--关联表的下拉选择-->
                <Select v-model:value="searchForm.idAdv" show-search :placeholder="$t('salary.validate.required.id_adv')"  :filter-option="filterOption"
                  :dropdownStyle="{ zIndex: 9999999950 }">
                  <SelectOption :key="sk" v-for="(item, sk) in selectList['advisorList']" :title="item['name']" :value="item['idAdv']">{{
                      item['name']
                  }}</SelectOption>
                </Select>
              </FormItem>
              </Col>
              <Col :xs="24" :sm="4">
                <FormItem :label="$t('salary.field.month')" name="month">
                  <DatePicker v-model:value="searchForm.month" valueFormat="YYYY-MM" picker="month" />
                </FormItem>
              </Col>
              <Col :xs="24" :sm="4">
                <FormItem :label="$t('persistency.name.show')" name="show">
                  <InputNumber :placeholder="$t('show')" :min="1" :step="1" v-model:value="searchForm.show"></InputNumber>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="16" class="text-right">
                <Button type="primary" @click="search_submit">{{$t('public.name.search')}}</Button>&nbsp;
                <Button @click="search_reset">{{$t('public.name.reset')}}</Button>&nbsp;
                <!-- <Button type="primary" :disabled="loading" @click="submit">{{ $t('persistency.name.save') }}</Button>&nbsp; -->
                <Button type="primary" @click="downloadPersistency">{{$t('persistency.name.download')}}</Button>&nbsp;
                <Button type="primary" @click="downloadPersistencyExcel">{{$t('persistency.name.downloadExcel')}}</Button>&nbsp;
              </Col>
            </Row>
          </Form>
        </Col>
        <Col :span="24">
          <Descriptions :title="$t('persistency.name.advisorInfo')">
            <DescriptionsItem :label="$t('advisor.table.title')">{{title}}</DescriptionsItem>
            <DescriptionsItem :label="$t('advisor.table.tr_no')">{{trNo}}</DescriptionsItem>
            <DescriptionsItem :label="$t('advisor.table.join_date')">{{joinDate}}</DescriptionsItem>
            <DescriptionsItem label="">
              <Table
              :columns="yearColumns"
              :data-source="yearDataSource"
              :pagination="false"
            >
            </Table>
            </DescriptionsItem>
            
          </Descriptions>
        </Col>
        <Col :span="24">
          <Table :columns="columns" :dataSource="list" :scroll="{ x: true }" :loading="loading" :pagination="false" >
            <template #afyp="{ text, record }">
            <div class="editable-cell" v-if="text != null">
              <div v-if="editableData[record.month+'afyp']" class="editable-cell-input-wrapper">
                <InputNumber :step="1" :precision="0" v-model:value="editableData[record.month+'afyp'].afyp" @pressEnter="save(record.month,'afyp')" 
                 />
                <CheckOutlined class="editable-cell-icon-check" @click="save(record.month,'afyp')" />
              </div>
              <div v-else class="editable-cell-text-wrapper">
                <span v-if="text === record.clacAfyp">{{ text.toFixed(0) }}</span>
                <span v-else style="color: red;">{{ text.toFixed(0) }}  ( {{ record.clacAfyp }} )</span>
                <EditOutlined class="editable-cell-icon" @click="edit(record.month,'afyp')" />
              </div>
            </div>
          </template>
          <template #fyc="{ text, record }">
            <div class="editable-cell" v-if="text != null">
              <div v-if="editableData[record.month+'fyc']" class="editable-cell-input-wrapper">
                <InputNumber :step="1" :precision="0" v-model:value="editableData[record.month+'fyc'].fyc" @pressEnter="save(record.month,'fyc')" 
                 />
                <CheckOutlined class="editable-cell-icon-check" @click="save(record.month,'fyc')" />
              </div>
              <div v-else class="editable-cell-text-wrapper">
                <span v-if="text === record.clacFyc">{{ text.toFixed(0) }}</span>
                <span v-else style="color: red;">{{ text.toFixed(0) }} ( {{ record.clacFyc }} )</span>
                <EditOutlined class="editable-cell-icon" @click="edit(record.month,'fyc')" />
              </div>
            </div>
          </template>
            <template #caseNum="{ text, record }">
            <div class="editable-cell" v-if="text != null">
              <div v-if="editableData[record.month+'caseNum']" class="editable-cell-input-wrapper">
                <InputNumber :step="1" :precision="0" v-model:value="editableData[record.month+'caseNum'].caseNum" @pressEnter="save(record.month,'caseNum')" 
                 />
                <CheckOutlined class="editable-cell-icon-check" @click="save(record.month,'caseNum')" />
              </div>
              <div v-else class="editable-cell-text-wrapper">
                <span v-if="text === record.clacCaseNum">{{ text.toFixed(0) }}</span>
                <span v-else style="color: red;">{{ text.toFixed(0) }}( {{ record.clacCaseNum }} )</span>
                <EditOutlined class="editable-cell-icon" @click="edit(record.month,'caseNum')" />
              </div>
            </div>
          </template>
          <template #lapsedAfyp="{ text, record }">
            <div class="editable-cell" v-if="text != null">
              <div v-if="editableData[record.month+'lapsedAfyp']" class="editable-cell-input-wrapper">
                <InputNumber :step="1" :precision="0" v-model:value="editableData[record.month+'lapsedAfyp'].lapsedAfyp" @pressEnter="save(record.month,'lapsedAfyp')" 
                :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\$\s?|(,*)/g, '')" />
                <CheckOutlined class="editable-cell-icon-check" @click="save(record.month,'lapsedAfyp')" />
              </div>
              <div v-else class="editable-cell-text-wrapper">
                {{ text.toFixed(0) }}
                <EditOutlined class="editable-cell-icon" @click="edit(record.month,'lapsedAfyp')" />
              </div>
            </div>
          </template>
          <template #reinstatedAfyp="{ text, record }">
            <div class="editable-cell" v-if="text != null">
              <div v-if="editableData[record.month+'reinstatedAfyp']" class="editable-cell-input-wrapper">
                <InputNumber :step="1" :precision="0" v-model:value="editableData[record.month+'reinstatedAfyp'].reinstatedAfyp" @pressEnter="save(record.month,'reinstatedAfyp')" 
                :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\$\s?|(,*)/g, '')" />
                <CheckOutlined class="editable-cell-icon-check" @click="save(record.month,'reinstatedAfyp')" />
              </div>
              <div v-else class="editable-cell-text-wrapper">
                {{ text.toFixed(0) }}
                <EditOutlined class="editable-cell-icon" @click="edit(record.month,'reinstatedAfyp')" />
              </div>
            </div>
          </template>
          </Table>
        </Col>
      </Row>
    </Col>
  
  </Row>
  <DownloadPersistencyPrintDate ref="refDownloadPersistencyPrintDate" @selectedPrintDate="downloadCommissionPaymetDown"></DownloadPersistencyPrintDate>
</template>

<script>
import { createVNode } from "vue";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, Form, Input, Select, message,DatePicker,Descriptions,InputNumber } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined,CheckOutlined,EditOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { AdvisorListAll } from "@/api/AdvisorListAll"
import { PaymentSummaryPayment } from "@/api/PaymentSummaryPayment"
import { PaymentPersistency } from "@/api/PaymentPersistency"
import { PaymentHistoryPersistency } from "@/api/PaymentHistoryPersistency"
import { PersistencyBatchAdd } from "@/api/PersistencyBatchAdd"
import { PaymentDownPersistency } from "@/api/PaymentDownPersistency"
import { PaymentPersistencyExport } from "@/api/PaymentPersistencyExport"
import { cloneDeep } from 'lodash-es';
import DownloadPersistencyPrintDate from "@/components/Home/DownloadPersistencyPrintDate";

export default {
  name: "PersistencyManage",
  components: {
    Row,
    Col,
    Table,
    Form,
    FormItem: Form.Item,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Input,
    Select,
    SelectOption: Select.Option,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    CheckOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    Menu,
    MenuItem: Menu.Item,
    DatePicker,
    Descriptions,
    DescriptionsItem: Descriptions.Item,
    InputNumber,
    DownloadPersistencyPrintDate,
  },
  data() {
    return {
      searchForm: {
        idAdv: "",
        month: "",
        show: 21,
      },
      title: "",
      trNo: "",
      joinDate: "",
      loading: false,
      list: [],
      editableData: {
      },
      // negative2historyPersistency: null,
      // negative1historyPersistency: null,
      columns: [
       { title: this.$t('persistency.table.month'), dataIndex: "month", key: "month" },
          { title: 'Current Month', children:[
            { title: this.$t("persistency.table.afyp"), dataIndex: "afyp", key: "afyp", slots:{
                customRender: "afyp"
              }
            },
            { title: this.$t("persistency.table.fyc"), dataIndex: "fyc", key: "fyc", slots:{
                customRender: "fyc"
              }  },
            { title: this.$t("persistency.table.case_num"), dataIndex: "caseNum", key: "caseNum", slots:{
                customRender: "caseNum"
              } },
            { title: this.$t("persistency.table.lapsed_afyp"), dataIndex: "lapsedAfyp", key: "lapsedAfyp", slots:{
                customRender: "lapsedAfyp"
              }
            },
            { title: this.$t("persistency.table.reinstated_afyp"), dataIndex: "reinstatedAfyp", key: "reinstatedAfyp", slots:{
                customRender: "reinstatedAfyp"
              } },
          ]},
          { title: 'Total', children:[
            { title: this.$t("persistency.table.exposure_afyp"), dataIndex: "exposureAfyp", key: "exposureAfyp", customRender: ({text}) =>{
              if(text != null){
                return text.toFixed(0)
              }
            }  },
            // { title: this.$t("total_exposure_afyp"), dataIndex: "totalExposureAfyp", key: "totalExposureAfyp" },
            { title: this.$t("persistency.table.total_lapsed_afyp"), dataIndex: "totalLapsedAfyp", key: "totalLapsedAfyp", customRender: ({text}) =>{
              if(text != null){
                return text.toFixed(0)
              }
            }  },
            { title: this.$t("persistency.table.persistency"), dataIndex: "persistency", key: "persistency", customRender: ({text}) =>{
              if(text != null){
                if(text == 100){
                  return text.toFixed(2) + '%'
                }else{
                  return text.toFixed(2) + '%'
                }
              }
            }
           },
          ]}
          
          
      ],
      yearDataSource: [],
      yearColumns: [
           { title: this.$t("persistency.name.year"), dataIndex: "year", key: "year" },
          { title: this.$t("persistency.table.afyp"), dataIndex: "afyp", key: "afyp", customRender: ({text}) =>{
            return text.toFixed(0);
          }},
          { title: this.$t("persistency.table.fyc"), dataIndex: "fyc", key: "fyc", customRender: ({text}) =>{
            return text.toFixed(0);
          } },
      ],
      selectList:{
        advisorList: [],
      }
    };
  },
  methods: {
    get_list() {
      this.loading = true;
      const api = new PaymentPersistency();
      api
        .get({
          idAdv:this.searchForm.idAdv,
          month:this.searchForm.month,
          show: this.searchForm.show
        })
        .then((response) => {
          this.loading = false;
          if (0 === parseInt(response.data.code)) {
            // this.list = response.data.data;
            this.calcu(response.data.data);
            this.getSummaryPayment();
          } else {
            this.list = [];
          }
        });
    },
    sumExposureAfyp(i,dataList){
      let totalExposureAfyp = 0;
      for(let j = 0;j < 19 ;j ++){
        if(i < 0){
          return totalExposureAfyp;
        }
        totalExposureAfyp += dataList[i].afyp;
        i --;
      }
      return totalExposureAfyp;
    },
    sumAfyp(i){
      let result = 0;
      for(let j = 0;j <= i;j ++){
        result += this.list[j].afyp
      }
      console.log(result)
      return result
    },
    sumLapsedAfyp(i){
      let result = 0;
      for(let j = 0;j <= i;j ++){
        result += this.list[j].lapsedAfyp - this.list[j].reinstatedAfyp
      }
      console.log(result)
      return result
    },
    sumTotalLapsedAfyp(i,dataList){
      let totalLapsedAfyp = 0;
      for(let j = 0;j < 19 ;j ++){
        if(i < 0){
          return totalLapsedAfyp;
        }
        totalLapsedAfyp += (dataList[i].lapsedAfyp - dataList[i].reinstatedAfyp)
        i --;
      }
      return totalLapsedAfyp;
    },
    calcu(dataList){
      
      //算法改为前端计算show的数量，依据员工的入职日期
      let advisor = this.selectList.advisorList.find(item => item.idAdv === this.searchForm.idAdv);
      let months = moment(moment(this.searchForm.month,'YYYY-MM').startOf('month')).diff(moment(advisor.joinDate).startOf('month'), 'months') + 1;
      let startMonth = moment(advisor.joinDate);
      if(months > this.searchForm.show){
        startMonth = moment(this.searchForm.month,'YYYY-MM').subtract(this.searchForm.show - 1,'month');
      }
      console.log(startMonth)
      let tempList = [];
      for(let i = 0;i < dataList.length;i++){
        if(dataList[i].month === startMonth.format('YYYY-MM')){
          //计算Exposure AFYP
          let tempData = dataList[i];
          tempData.exposureAfyp = this.sumExposureAfyp(i - 2,dataList);
          tempData.totalLapsedAfyp = this.sumTotalLapsedAfyp(i, dataList);
          console.log(tempData.month)
          console.log(tempData.exposureAfyp)
          console.log(tempData.totalLapsedAfyp)
          if(tempData.exposureAfyp === 0){
            
            if(tempList.length == 0){
              tempData.persistency = 100;
            }else{
              tempData.persistency = tempList[tempList.length - 1].persistency
            }
            console.log('IF:' + tempData.persistency)
          }else{
            tempData.persistency = ((tempData.exposureAfyp - tempData.totalLapsedAfyp) / tempData.exposureAfyp).toFixed(4) * 100
            if(tempData.persistency> 100){
              tempData.persistency = 100;
            }

          }
          tempList.push(tempData);
          startMonth = startMonth.add(1, 'month');
        }
      }






      this.list = tempList;

      // for(let i = 0; i < this.list.length; i++) {
      //   //如果Afyp有数据，才计算，不然就留空
      //   if(this.list[i].afyp != null){
      //     if(i == 0){
      //       this.list[i].exposureAfyp = this.negative2historyPersistency.exposureAfyp
      //       // this.list[i].totalLapsedAfyp = this.negative2historyPersistency.lapsedAfyp
      //       this.list[i].totalLapsedAfyp = this.sumLapsedAfyp(i) + this.negative1historyPersistency.lapsedAfyp
      //       if(this.list[i].exposureAfyp == 0){
      //         this.list[i].persistency = 100
      //       }else{
      //         this.list[i].persistency = ((this.list[i].exposureAfyp - this.list[i].totalLapsedAfyp) / this.list[i].exposureAfyp).toFixed(4) * 100
      //       }
      //     }else if(i == 1) {
      //       this.list[i].exposureAfyp = this.negative1historyPersistency.exposureAfyp
      //       // this.list[i].totalLapsedAfyp = this.negative1historyPersistency.lapsedAfyp
      //       this.list[i].totalLapsedAfyp = this.sumLapsedAfyp(i) + this.negative1historyPersistency.lapsedAfyp
      //       if(this.list[i].exposureAfyp == 0){
      //         this.list[i].persistency = 100
      //       }else{
      //         this.list[i].persistency = ((this.list[i].exposureAfyp - this.list[i].totalLapsedAfyp) / this.list[i].exposureAfyp).toFixed(4) * 100
      //       }
      //     }else{
      //       this.list[i].exposureAfyp = this.sumAfyp(i - 2) + this.negative1historyPersistency.exposureAfyp
      //       // this.list[i].totalLapsedAfyp = this.sumLapsedAfyp(i - 2) + this.negative1historyPersistency.lapsedAfyp
      //       this.list[i].totalLapsedAfyp = this.sumLapsedAfyp(i) + this.negative1historyPersistency.lapsedAfyp
      //       if(this.list[i].exposureAfyp == 0){
      //         this.list[i].persistency = 100
      //       }else{
      //         this.list[i].persistency = ((this.list[i].exposureAfyp - this.list[i].totalLapsedAfyp) / this.list[i].exposureAfyp).toFixed(4) * 100
      //       }
      //     }
      //   }
      // }
      // for(let i = 0; i < this.list.length; i++) {
      //   //如果Afyp有数据，才计算，不然就留空
      //   if(this.list[i].afyp != null){
          
      //       this.list[i].exposureAfyp = this.sumAfyp(i) + this.negative1historyPersistency.exposureAfyp
      //       this.list[i].totalLapsedAfyp = this.sumLapsedAfyp(i) + this.negative1historyPersistency.lapsedAfyp
      //       if(this.list[i].exposureAfyp == 0){
      //         this.list[i].persistency = 100
      //       }else{
      //         this.list[i].persistency = ((this.list[i].exposureAfyp - this.list[i].totalLapsedAfyp) / this.list[i].exposureAfyp).toFixed(4) * 100
      //       }
          
      //   }
      // }
    },
    search_submit() {
      if(!this.searchForm.idAdv || !this.searchForm.month || !this.searchForm.show){
        message.error("請輸入所有條件")
        return;
      }
      this.title = this.selectList.advisorList.find(item=>item.idAdv===this.searchForm.idAdv).title
      this.trNo = this.selectList.advisorList.find(item=>item.idAdv===this.searchForm.idAdv).trNo
      this.joinDate = this.selectList.advisorList.find(item=>item.idAdv===this.searchForm.idAdv).joinDate
      // this.getSummaryPayment();
      // this.getHistoryPersistency();
      this.get_list();
      
    },
    getAdvisorList() {
      const api = new AdvisorListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.selectList.advisorList = response.data.data;
        } else {
          message.error(response.data.msg);
        }
      });
    },
    getSummaryPayment() {
      // const api = new PaymentSummaryPayment();
      // api.get({
      //   idAdv:this.searchForm.idAdv
      // }).then((response) => {
      //   if (0 == parseInt(response.data.code)) {
      //     this.yearDataSource = response.data.data;
      //   } else {
      //     message.error(response.data.msg);
      //   }
      // });
      let result = new Map();
      //改為計算當前表的總數
      for(let i = 0; i < this.list.length; i++) {
        //如果Afyp有数据，才计算，不然就留空
        if(this.list[i].afyp != null){
          let year = this.list[i].month.substring(0, 4);
          let yearObj = result.get(year);
          if(yearObj == null){
            yearObj = {
              afyp: 0,
              fyc:0
            }
          }
          yearObj.afyp = yearObj.afyp + this.list[i].afyp;
          yearObj.fyc = yearObj.fyc + this.list[i].fyc;
          result.set(year,yearObj)
        }
      }
      this.yearDataSource = []
      for (let [key, value] of result) {
        this.yearDataSource.push({
          year:key,
          afyp: value.afyp,
          fyc: value.fyc
        })
      }

    },
    // getHistoryPersistency(){
    //   const api = new PaymentHistoryPersistency();
    //   api.get({
    //     idAdv:this.searchForm.idAdv,
    //     month: moment(this.searchForm.month,'YYYY-MM').subtract(1 + this.searchForm.show, 'months').format('YYYY-MM')
    //   }).then((response) => {
    //     if (0 == parseInt(response.data.code)) {
    //       this.negative1historyPersistency = response.data.data;
    //       // this.get_list();
    //     } else {
    //       message.error(response.data.msg);
    //     }
    //   });
    //   api.get({
    //     idAdv:this.searchForm.idAdv,
    //     month: moment(this.searchForm.month,'YYYY-MM').subtract(2 + this.searchForm.show, 'months').format('YYYY-MM')
    //   }).then((response) => {
    //     if (0 == parseInt(response.data.code)) {
    //       this.negative2historyPersistency = response.data.data;
    //       this.get_list();
    //     } else {
    //       message.error(response.data.msg);
    //     }
    //   });
    // },
    search_reset() {
      this.searchForm = {
        show:21
      }
      this.list = [];
      this.yearDataSource = []
      this.historyPersistency=null
    },
    edit(key,fieldName){
      this.editableData[key+''+fieldName] = cloneDeep(this.list.filter(item => key === item.month)[0]);
      // this.calcu();
    },
    save(key,fieldName){
      
      Object.assign(this.list.filter(item => key === item.month)[0], this.editableData[key+''+fieldName]);
      delete this.editableData[key+''+fieldName];
      // this.calcu();
      // this.submit();
      // 改為單項保存
      console.log(this.list.filter(item => key === item.month))
      let api = new PersistencyBatchAdd();
      this.loading=true;
      api.post({
        persistencyListStr: JSON.stringify([...this.list.filter(item => key === item.month)])
      }).then((response, any) => {
        if (0 == parseInt(response.data.code)) {
          message.success(response.data.msg);
          this.search_submit();
        } else {
          message.error(response.data.msg);
        }
        this.loading=false;
      });

    },
    submit(){
      let api = new PersistencyBatchAdd();
      this.loading=true;
      api.post({
        persistencyListStr: JSON.stringify(this.list)
      }).then((response, any) => {
        if (0 == parseInt(response.data.code)) {
          message.success(response.data.msg);
          this.search_submit();
        } else {
          message.error(response.data.msg);
        }
        this.loading=false;
      });
    },
    downloadPersistency(){
      if(!this.searchForm.idAdv || !this.searchForm.month || !this.searchForm.show){
        message.error("請輸入所有條件")
        return;
      }
      // this.submit();
      this.$refs.refDownloadPersistencyPrintDate.show();
    },

    downloadPersistencyExcel() {
      if(!this.searchForm.idAdv || !this.searchForm.month || !this.searchForm.show){
        message.error("請輸入所有條件")
        return;
      }
      // this.submit();
      const api = new PaymentPersistencyExport();
      
      api
        .download({
          persistencyListStr: JSON.stringify(this.list),
          idAdv:this.searchForm.idAdv,
          month:this.searchForm.month,
          show: this.searchForm.show
        })
        .then((response) => {
          // console.log(decodeURI(res.headers['filename']));
          let blob = new Blob([response.data], { type: "application/xlsx" });
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a"); // 创建a标签
          link.href = url;
          link.download = decodeURI(response.headers['filename']); // 重命名文件
          link.click();
          URL.revokeObjectURL(url);
        });

    },
    downloadCommissionPaymetDown(printDate){
      const api = new PaymentDownPersistency();
          api.download({
            persistencyListStr: JSON.stringify(this.list),
            idAdv:this.searchForm.idAdv,
            month:this.searchForm.month,
            show: this.searchForm.show,
            printDate:printDate
          }).then((response) => {
            if (!response) {
              return
            }
            if (response.data.type == 'application/json') {
              message.error(this.$t("public.name.no_data"))
              return
            }
            let url = window.URL.createObjectURL(new Blob([response.data]))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download',  response.headers.filename)
            document.body.appendChild(link)
            link.click()
          });
    },
    filterOption(input, option) {
    
      return option.title.toLowerCase().indexOf(input.toLowerCase()) != -1;
    }, 
  },
  mounted() {
    this.getAdvisorList();
  },
}
</script>

<style lang="less">
@import url("~@/assets/less/home/salary.less");
</style>