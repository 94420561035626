import { Base,Axios } from "@/api/Base"

export class Uploads extends Base {
  //此上传接口配合上传控件使用，如项目需要使用上传控件，则需要修改如下上传接口
  path = "upload/img"
  show(url) {
    return Axios.get(url, { responseType: 'arraybuffer' })
  }
  download(url) {
    return Axios.get(url, { responseType: "blob" })
  }
}