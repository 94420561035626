<template>
  <Modal
    id="PolicyEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="policyForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                    <FormItem :label="$t('policy.field.id_adv')" name="idAdv">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="form.idAdv"
                              show-search
                              :placeholder="$t('policy.validate.required.id_adv')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                              :filter-option="filterOption"
                      >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['advisorList']" :title="item['name']" :value="item['idAdv']">{{ item['name'] }}</SelectOption>
                      </Select>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('policy.field.id_ser_pro')" name="idSerPro">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="form.idSerPro"
                              :placeholder="$t('policy.validate.required.id_ser_pro')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                              :filter-option="filterOption"
                              show-search
                      >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['serviceProviderList']" :title="item['companyName']" :value="item['idSerPro']">{{ item['companyName'] }}</SelectOption>
                      </Select>
                  </FormItem>
                  </Col>

                  <Col span="10">
                    <FormItem :label="$t('policy.field.policy_no')" name="policyNo">
                      <Input :placeholder="$t('policy.validate.required.policy_no')"
                             v-model:value="form.policyNo">
                      </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('policy.field.submit_date')" name="submitDate">
                      <DatePicker valueFormat="YYYY-MM-DD" v-model:value="form.submitDate" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('policy.field.approval_date')" name="approvalDate">
                      <DatePicker valueFormat="YYYY-MM-DD" v-model:value="form.approvalDate" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('policy.field.policy_date')" name="policyDate">
                      <DatePicker valueFormat="YYYY-MM-DD" v-model:value="form.policyDate" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                  </Col>
                    <Col span="10">
                    <FormItem :label="$t('policy.field.insured_name')" name="insuredName">
                      <Input :placeholder="$t('policy.validate.required.insured_name')"
                             v-model:value="form.insuredName">
                      </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('policy.field.owner_name')" name="ownerName">
                      <Input :placeholder="$t('policy.validate.required.owner_name')"
                             v-model:value="form.ownerName">
                      </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('policy.field.age')" name="age">
                                            <InputNumber :placeholder="$t('policy.validate.required.age')"
                                                   v-model:value="form.age">
                                            </InputNumber>
                  </FormItem>
                  </Col>


                  <Col span="10">
                    <FormItem :label="$t('policy.field.id_pay_mod')" name="idPayMod">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="form.idPayMod"
                              :placeholder="$t('policy.validate.required.id_pay_mod')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                              @change="caculateAFYP"
                      >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['payModeList']" :value="item['idPayMod']">{{ item['payMode'] }}</SelectOption>
                      </Select>
                  </FormItem>
                  </Col>

                  <Col span="10">
                    <FormItem :label="$t('currency.field.currency_name')" name="idCur">
                        <!--关联表的下拉选择-->
                        <Select
                                v-model:value="form.idCur"
                                :placeholder="$t('policy.validate.required.id_cur')"
                                :dropdownStyle="{ zIndex: 9999999950 }"
                                @change="changeCurrency"
                        >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['currencyList']" :value="item['idCur']">{{ item['currencyName'] }}</SelectOption>
                        </Select>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('policy.field.real_currency')" name="realCurrency">
                                            <InputNumber
                                                    v-model:value="form.realCurrency"
                                                    :placeholder="$t('policy.validate.required.real_currency')"
                                                    :step="0.01"
                                                    

                                                    @change="caculateAFYP"
                                            />
                  </FormItem>
                  </Col>
                  
                  <Col span="10">
                    <FormItem :label="$t('policy.field.payment_amount')" name="paymentAmount">
                      <InputNumber
                              disabled
                              v-model:value="form.paymentAmount"
                              :placeholder="$t('policy.validate.required.payment_amount')"
                              :step="0.01"
                              @change="caculateAFYP"
                              :precision="2"
                      />
                      <span v-if="form.idCur && selectList.currencyList.length > 0">&nbsp;&nbsp;{{selectList.currencyList.find(item => item.idCur === form.idCur).currencyName}}</span>
                    </FormItem>
                    
                  </Col>
                  
                  <Col span="10">
                    <FormItem :label="$t('policy.field.start_period')" name="startPeriod">
                      <InputNumber
                              v-model:value="form.startPeriod"
                              :placeholder="$t('policy.validate.required.start_period')"
                              min="1"
                              step="1"
                      />
                  </FormItem>
                  </Col>
                  
                  <Col span="10">
                    <FormItem :label="$t('policy.field.status')" name="status">
                      <Select
                              v-model:value="form.status"
                              :placeholder="$t('policy.validate.required.status')"
                              :options="selectList.statusList"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                      </Select>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('policy.field.afyp_rate')" name="afypRate">
                                            <InputNumber
                                              
                                                    v-model:value="form.afypRate"
                                                    :placeholder="$t('policy.validate.required.afyp_rate')"
                                                    :step="0.01"
                                                    :min="0"
                                                    :max="100"
                                                    :formatter="value => `${value}%`"
                                                    :parser="value => value.replace('%', '')"
                                                    @change="caculateAFYP"
                                            />
                  </FormItem>
                  </Col>
                  
                  <Col span="10">
                    <FormItem :label="$t('policy.field.afyp')" name="afyp">
                                            <InputNumber
                                            disabled
                                                    v-model:value="form.afyp"
                                                    :placeholder="$t('policy.validate.required.afyp')"
                                                    :step="0.01"
                                                    :precision="2"
                                            />
                  </FormItem>
                  </Col>
		  <Col span="10">
                    <FormItem :label="$t('policy.field.remark')" name="remark">
                      <Textarea
                              v-model:value="form.remark"
                              :placeholder="$t('policy.validate.required.remark')"
                              :auto-size="{ minRows: 5, maxRows: 10 }"
                              showCount
                      />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('policy.field.extra_commission_rate')" name="extraCommissionRate">
                                            <InputNumber
                                              
                                                    v-model:value="form.extraCommissionRate"
                                                    :placeholder="$t('policy.validate.required.extra_commission_rate')"
                                                    :step="0.01"
                                                    :min="0"
                                                    :max="100"
                                                    :formatter="value => `${value}%`"
                                                    :parser="value => value.replace('%', '')"
                                                    @change="caculateAFYP"
                                            />
                  </FormItem>
                  </Col>
                  
                  <Col span="10">
                    <FormItem :label="$t('policy.field.extra_commission')" name="extraCommission">
                                            <InputNumber
                                            disabled
                                                    v-model:value="form.extraCommission"
                                                    :placeholder="$t('policy.validate.required.extra_commission')"
                                                    :step="0.01"
                                                    :precision="2"
                                            />
                  </FormItem>
                  </Col>
		  
                  <!-- <Col span="15">
                    <Space
                      v-for="(policyPlan, index) in form.policyPlanList"
                      :key="policyPlan.idPla"
                      style="display: flex; margin-bottom: 8px"
                      align="baseline"
                    >
                      <FormItem
                        :name="['policyPlanList', index, 'idPla']"
                        :rules="{
                          required: true,
                          message: $t('policy.validate.required.id_pla')
                        }"
                      >
                        <Select
                              v-model:value="policyPlan.idPla"
                              :placeholder="$t('policy.validate.required.id_pla')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                               @change="changePlan($event,index)"
                      >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['planList']" :value="item['idPla']">{{ item['planName'] }}</SelectOption>
                      </Select>
                      </FormItem>
                      <FormItem
                        :name="['policyPlanList', index, 'amount']"
                        :rules="{
                          required: true,
                          message:  $t('policyPlan.validate.required.amount'),
                        }"
                      >
                        <InputNumber v-model:value="policyPlan.amount" :min="0.01" placeholder="Amount" />
                      </FormItem>
                      <Col>
                        <Space
                          v-for="(realCommissionRate, index2) in form.policyPlanList[index].realCommissionRateList"
                          :key="realCommissionRate.startMonth"
                          style="display: flex; margin-bottom: 8px"
                          align="baseline"
                        >
                          {{realCommissionRate.startMonth}} - {{realCommissionRate.endMonth}}
                          <FormItem
                            :name="['policyPlanList.realCommissionRateList', index2, 'commissionRate']"
                            :rules="{
                              required: false,
                              message:  $t('policyPlan.validate.required.commission_rate'),
                            }"
                          >
                          
                            <InputNumber v-model:value="realCommissionRate.commissionRate" :min="0.01" :max="100" placeholder="Commission Rate" />
                          </FormItem>
                        </Space>
                      </Col>
                      <MinusCircleOutlined @click="removePolicyPlan(policyPlan)" />
                    </Space>
                  </Col> -->
                  <!-- <Col span="5">
                  <FormItem>
                    <Button type="dashed" block @click="addPolicyPlan">
                      <PlusOutlined />
                      Add Policy Plan
                    </Button>
                  </FormItem>
                  </Col> -->
                  <Col span="24">
                  <Card size="small" :title="$t('policy.name.subTitle')">
                    <template #extra>
                      <Button size="small" type="primary" block @click="addPolicyPlan">
                        <PlusOutlined />
                        {{ this.$t("public.name.add") }}
                      </Button>
                    </template>
                    <Table size="small" :columns="columns" :data-source="form.policyPlanList" class="components-table-demo-nested" defaultExpandAllRows :rowKey="(record,index)=>{return index}" :expandedRowKeys="expandedRowKeys" :pagination="false">
                      <template #operation="{ record, index }">
                        <div class="editable-row-operations">
                          <span>
                            <template v-if="actionType === 'edit'">
                              <a @click="editAdvisorOverride(record)">{{ this.$t("public.name.edit") }}</a>
                              &nbsp;
                            </template>
                            <Popconfirm :title="$t('public.name.are_you_sure_delete')" @confirm="removePolicyPlan(record)">
                              <a>{{ this.$t("public.name.del") }}</a>
                            </Popconfirm>
                          </span>
                        </div>
                      </template>
                      <template #idPla="{ record, index }">
                        <FormItem
                          :name="['policyPlanList', index, 'idPla']"
                          :rules="{
                            required: true,
                            message: $t('policy.validate.required.id_pla')
                          }"
                          style="margin-bottom: 0px;"
                        >
                          <Select
                                  v-if="form.idSerPro"
                                  v-model:value="record.idPla"
                                  :placeholder="$t('policy.validate.required.id_pla')"
                                  :dropdownStyle="{ zIndex: 9999999950 }"
                                  @change="changePlan($event,index)"
                                  show-search
                                  :filter-option="filterOption"
                          >
                          <template :key="sk" v-for="(item,sk) in selectList['planList']">
                            <SelectOption v-if="item['idSerPro'] === form.idSerPro" :key="item['idPla']" :title="item['planNameHk'] + '-' + item['planCode']" :value="item['idPla']">{{ item['planNameHk'] + '-' + item['planCode'] }}</SelectOption>
                          </template>
                              
                          </Select>
                        </FormItem>
                      </template>
                      <template #amount="{ record, index }">
                        <FormItem
                          :name="['policyPlanList', index, 'amount']"
                          :rules="{
                            required: true,
                            message: $t('policy.validate.required.id_pla')
                          }"
                          
                          style="margin-bottom: 0px;"
                        >
                          <InputNumber v-model:value="record.amount" :step="0.01" placeholder="Amount" @change="changeAmount" :precision="2" />
                          <span v-if="form.idCur && selectList.currencyList.length > 0">&nbsp;&nbsp;{{selectList.currencyList.find(item => item.idCur === form.idCur).currencyName}}</span>
                        </FormItem>
                      </template>
                      <template #expandedRowRender="{record:pRecord, index:pIndex, indent, expanded}">
                        <Table size="small" :columns="innerColumns" :data-source="pRecord.realCommissionRateList" :pagination="false">
                          <template #commissionRate="{ record, index }">
                            <FormItem
                              :name="['policyPlanList', pIndex, 'realCommissionRateList', index , 'commissionRate']"
                              :rules="{
                                required: true,
                                message: $t('policy.validate.required.commission_rate')
                              }"
                              style="margin-bottom: 0px;"
                            >
                              <InputNumber v-model:value="record.commissionRate" :min="0" :step="0.01"
                                                    :max="100" placeholder="Amount" :formatter="value => `${value}%`"
      :parser="value => value.replace('%', '')" @change="caculateAFYP"/>
                            </FormItem>
                          </template>
                          <template #firstYear="{ record, index }">
                            <FormItem
                              :name="['policyPlanList', pIndex, 'realCommissionRateList', index , 'firstYear']"
                              :rules="{
                                required: true,
                                message: $t('realCommissionRate.validate.required.first_year')
                              }"
                              style="margin-bottom: 0px;"
                            >
                              <Select
                                v-model:value="record.firstYear"
                                :placeholder="$t('realCommissionRate.validate.required.first_year')"
                                :options="selectList.firstYearList"
                                
                              >
                              </Select>
                            </FormItem>
                          </template>
                          <!-- <template #operation>
                            <span class="table-operation">
                              <a>Pause</a>
                              <a>Stop</a>
                              <Dropdown>
                                <template #overlay>
                                  <Menu>
                                    <MenuItem>Action 1</MenuItem>
                                    <MenuItem>Action 2</MenuItem>
                                  </Menu>
                                </template>
                                <a>
                                  More
                                  
                                </a>
                              </Dropdown>
                            </span>
                          </template> -->
                        </Table>
                      </template>
                    </Table>
                    </Card>
                  </Col>
                  <!-- <Col span="24" style="margin-top:30px;">
            <Card size="small" :title="$t('extraOverrideTemplate.name.title')">
              <template #extra>
                      <Button size="small" type="primary" block @click="addExtraOverrideTemplate">
                        <PlusOutlined />
                        {{ this.$t("public.name.add") }}
                      </Button>
                    </template>
              <Table size="small" :columns="extraOverrideTemplateColumns" :data-source="form.extraOverrideTemplateList" bordered
                :pagination="false">
                <template #idAdv="{ record, index }">
                  <FormItem :name="['extraOverrideTemplateList', index, 'idAdv']" :rules="{
                    required: true,
                    message: $t('extraOverrideTemplate.validate.required.id_adv')
                  }" style="margin-bottom: 0px;">
                    <Select v-model:value="record.idAdv"
                      :placeholder="$t('extraOverrideTemplate.validate.required.id_adv')">
                      <SelectOption :key="sk" v-for="(item,sk) in selectList['advisorList']" :value="item['idAdv']">{{
                      item['name'] }}</SelectOption>
                    </Select>
                  </FormItem>
                </template>
                <template #commissionRate="{ record, index }">
                  <FormItem :name="['extraOverrideTemplateList', index, 'commissionRate']" :rules="{
                    required: true,
                    message: $t('extraOverrideTemplate.validate.required.commission_rate')
                  }" style="margin-bottom: 0px;">
                    <InputNumber v-model:value="record.commissionRate" :min="0.01" placeholder="Commission Rate"
                      :max="100"
                      :formatter="value => `${value}%`"
                      :parser="value => value.replace('%', '')"
                      :step="0.01"
                      :precision="2"
                      />
                  </FormItem>
                </template>
                <template #operation="{ record, index }">
                        <div class="editable-row-operations">
                          <span>
                            <Popconfirm :title="$t('public.name.are_you_sure_delete')" @confirm="removeExtraOverrideTemplate(record)">
                              <a>{{ this.$t("public.name.del") }}</a>
                            </Popconfirm>
                          </span>
                        </div>
                      </template>
              </Table>
            </Card>
            </Col> -->

              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Space,
  Table,
  Popconfirm,
  Card,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons-vue";
import { PolicyAdd } from "@/api/PolicyAdd";
import { PolicyEdit } from "@/api/PolicyEdit";
import { PayModeListAll } from "@/api/PayModeListAll"
import { AdvisorListAll } from "@/api/AdvisorListAll"
import { ServiceProviderListAll } from "@/api/ServiceProviderListAll"
import { PlanListAll } from "@/api/PlanListAll"
import { CurrencyListAll } from "@/api/CurrencyListAll"
import { PolicyDeleteExtraOverrideTemplate } from "@/api/PolicyDeleteExtraOverrideTemplate"


export default {
  name: "PolicyEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    MinusCircleOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    Space,
    Table,
    Popconfirm,
    Card,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idPol : "",
        idPayMod : "",
        idAdv : "",
        idSerPro : "",
        submitDate : "",
        insuredName : "",
        ownerName : "",
        paymentAmount : "",
        createDate : "",
        startPeriod : "",
        policyNo : "",
        status : "",
        approvalDate : "",
        policyDate : "",
	age : "",
        afyp : "",
	idCur : "",
	realCurrency : "",
	afypRate : "",
        remark : "",
        extraCommissionRate : 0,
        extraCommission : "",
      },
      fileList: {
      },
      selectList: {
        payModeList: [],
        advisorList: [],
        serviceProviderList: [],
        statusList: [{value: 0,label: 'Off'},{value:1,label: 'On'}],
        currencyList: [],
        planList:[],
        isBasicList: [{value: 0,label: 'Rider'},{value:1,label: 'Basic'}],
        firstYearList: [{value: 0,label: 'First Year'},{value:1,label: 'Renewal'}],
      },
      validateRules: {
        idPol: [
          {
            required: true,
            message: this.$t("policy.validate.required.id_pol"),
            trigger: "change",
            type: "number",
          },
        ],
        idPayMod: [
          {
            required: true,
            message: this.$t("policy.validate.required.id_pay_mod"),
            trigger: "change",
            type: "number",
          },
        ],
        idAdv: [
          {
            required: true,
            message: this.$t("policy.validate.required.id_adv"),
            trigger: "change",
            type: "number",
          },
        ],
        idSerPro: [
          {
            required: true,
            message: this.$t("policy.validate.required.id_ser_pro"),
            trigger: "change",
            type: "number",
          },
        ],
        submitDate: [
          {
            required: true,
            message: this.$t("policy.validate.required.submit_date"),
            trigger: "change",
          },
        ],
        insuredName: [
          {
            required: true,
            message: this.$t("policy.validate.required.insured_name"),
            trigger: "change",
          },
        ],
        ownerName: [
          {
            required: true,
            message: this.$t("policy.validate.required.owner_name"),
            trigger: "change",
          },
        ],
        paymentAmount: [
          {
            required: true,
            message: this.$t("policy.validate.required.payment_amount"),
            trigger: "change",
            type: "number",
          },
        ],
        createDate: [
          {
            required: true,
            message: this.$t("policy.validate.required.create_date"),
            trigger: "change",
          },
        ],
        startPeriod: [
          {
            required: true,
            message: this.$t("policy.validate.required.start_period"),
            trigger: "change",
            type: "number",
          },
        ],
        policyNo: [
          {
            required: true,
            message: this.$t("policy.validate.required.policy_no"),
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: this.$t("policy.validate.required.status"),
            trigger: "change",
            type: "number",
          },
        ],
        approvalDate: [
          {
            required: true,
            message: this.$t("policy.validate.required.approval_date"),
            trigger: "change",
          },
        ],
        policyDate: [
          {
            required: true,
            message: this.$t("policy.validate.required.policy_date"),
            trigger: "change",
          },
        ],
	age: [
          {
            required: true,
            message: this.$t("policy.validate.required.age"),
            trigger: "change",
            type: "number",
          },
        ],
        afyp: [
          {
            required: true,
            message: this.$t("policy.validate.required.afyp"),
            trigger: "change",
            type: "number",
          },
        ],
	idCur: [
          {
            required: true,
            message: this.$t("policy.validate.required.id_cur"),
            trigger: "change",
            type: "number",
          },
        ],
	realCurrency: [
          {
            required: true,
            message: this.$t("policy.validate.required.real_currency"),
            trigger: "change",
            type: "number",
          },
        ],
	afypRate: [
          {
            required: true,
            message: this.$t("policy.validate.required.afyp_rate"),
            trigger: "change",
            type: "number",
          },
        ],
	remark: [
          {
            required: false,
            message: this.$t("policy.validate.required.remark"),
            trigger: "change",
          },
        ],
	extraCommissionRate: [
          {
            required: true,
            message: this.$t("policy.validate.required.extra_commission_rate"),
            trigger: "change",
            type: "number",
          },
        ],
        extraCommission: [
          {
            required: true,
            message: this.$t("policy.validate.required.extra_commission"),
            trigger: "change",
            type: "number",
          },
        ],
      },
      columns: [
          { title: this.$t("policyPlan.table.id_pla"), dataIndex: "idPla", key: "idPla", slots: { customRender: "idPla" } },
          { title: this.$t("policyPlan.table.amount"), dataIndex: "amount", key: "amount", slots: { customRender: "amount" } },
          { title: this.$t("policyPlan.table.is_basic"), dataIndex: "isBasic", key: "isBasic", customRender: ({text}) =>{
            if(typeof(text)!="undefined" && text != null)
              return this.selectList.isBasicList.find(item=>item.value===text).label
            }
          },
          // { title: this.$t("plan.table.adjust_afyp_rate"), dataIndex: "adjustAfypRate", key: "adjustAfypRate" , customRender: ({text}) =>{return text + '%'}},
        { key: "operation", fixed: "right", slots: { title: "actionTitle", customRender: "operation" } },
      ],
      innerColumns: [
          { title: this.$t("realCommissionRate.table.start_month"), dataIndex: "startMonth", key: "startMonth" },
          { title: this.$t("realCommissionRate.table.end_month"), dataIndex: "endMonth", key: "endMonth" },
          { title: this.$t("realCommissionRate.table.commission_rate"), dataIndex: "commissionRate", key: "commissionRate", slots: { customRender: "commissionRate" } },
          { title: this.$t("realCommissionRate.table.first_year"), dataIndex: "firstYear", key: "firstYear", slots: { customRender: "firstYear" } },
        { key: "operation", fixed: "right", slots: { title: "actionTitle", customRender: "operation" } },
      ],

      extraOverrideTemplateColumns: [
          { title: this.$t("extraOverrideTemplate.table.id_adv"), dataIndex: "idAdv", key: "idAdv", slots: { customRender: "idAdv" } },
          { title: this.$t("extraOverrideTemplate.table.commission_rate"), dataIndex: "commissionRate", key: "commissionRate", slots: { customRender: "commissionRate" } },
        { key: "action", fixed: "right", slots: { title: "actionTitle", customRender: "operation" } },
      ],
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
    expandedRowKeys: function(){
      let expandedRowKeys = [];
      for(let i = 0;i < this.form.policyPlanList.length;i ++){
        expandedRowKeys.push(i)
      }
      return expandedRowKeys;
    }
  },
  // watch: {
  //     "form.policyPlanList": {
  //         deep: true,
  //         handler: function (newValue, oldValue) {
  //           //如果有变化，则把第一个置为Basic并且把第一个的第一个佣金比率化作AFYP Rate
  //           if(this.form.policyPlanList[0] && this.form.policyPlanList[0].idPla){
  //             this.form.policyPlanList[0].isBasic = 1;
  //             debugger
  //             this.form.afypRate =this.form.policyPlanList[0].adjustAfypRate
  //             this.caculateAFYP();
  //           }
  //         }
  //     }
  // },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.getPayModeList();
      this.getAdvisorList();
      this.getServiceProviderList();
      this.getPlanList();
      this.getCurrencyList();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.getPayModeList();
      this.getAdvisorList();
      this.getServiceProviderList();
      this.getPlanList();
      this.getCurrencyList();
      this.visible = true;
    },
    submit() {
      this.$refs["policyForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new PolicyAdd();
              break;
            case "edit":
              console.log("edit");
              api = new PolicyEdit();
              break;
            default:
              return false;
          }
          this.form.policyPlanListStr = JSON.stringify(this.form.policyPlanList)
          this.form.extraOverrideTemplateListStr = JSON.stringify(this.form.extraOverrideTemplateList)
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    getPayModeList() {
      const api = new PayModeListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.payModeList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    getAdvisorList() {
      const api = new AdvisorListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.advisorList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    getServiceProviderList() {
      const api = new ServiceProviderListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.serviceProviderList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    addPolicyPlan() {
      if(this.form.policyPlanList.length == 0){
        this.form.policyPlanList.push({
          idPol: null,
          amount: 0,
          isBasic: 1,
          adjustAfypRate: 0,
          realCommissionRateList: [],
        });
      }else{
        this.form.policyPlanList.push({
          idPol: null,
          amount: 0,
          isBasic: 0,
          adjustAfypRate: 0,
          realCommissionRateList: [],
        });
      }
    },
    removePolicyPlan(policyPlan){
      let index = this.form.policyPlanList.indexOf(policyPlan);
      if (index !== -1) {
        this.form.policyPlanList.splice(index, 1);
      }
      //如果变化的是第一个plan，则要更新afypRate
      if(index == 0){
        this.policyPlanListChanged();
      }
    },
    policyPlanListChanged(){
      //如果有变化，则把第一个置为Basic并且把第一个的第一个佣金比率化作AFYP Rate
      if(this.form.policyPlanList[0] && this.form.policyPlanList[0].idPla){
        this.form.policyPlanList[0].isBasic = 1;
        this.form.afypRate =this.form.policyPlanList[0].adjustAfypRate
        this.caculateAFYP();
      }
    },
    getPlanList() {
      const api = new PlanListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.planList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    changePlan(value,index){
      console.log(value)
      console.log(index)
      let plan = this.selectList.planList.find( item => 
        item.idPla === value
      )
      
      this.form.policyPlanList[index].adjustAfypRate = plan.adjustAfypRate;
      this.form.policyPlanList[index].realCommissionRateList = [];
      for(var i = 0;i < plan.commissionRateList.length; i++){
        var commissionRate = plan.commissionRateList[i]
        this.form.policyPlanList[index].realCommissionRateList.push({
          startMonth: commissionRate.startMonth,
          endMonth: commissionRate.endMonth,
          commissionRate: commissionRate.commissionRate,
          firstYear:commissionRate.firstYear,
        })
      }
      console.log(this.form.policyPlanList[index].realCommissionRateList)

      //如果变化的是第一个plan，则要更新afypRate
      if(index == 0){
        this.policyPlanListChanged();
      }

    },
    changeAmount(){
      let paymentAmount = 0;
      this.form.policyPlanList.forEach(item => {
        paymentAmount += item.amount
      })
      this.form.paymentAmount = paymentAmount;
      this.caculateAFYP();
    },
    getCurrencyList() {
      const api = new CurrencyListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.currencyList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    changeCurrency(val){
      let currency = this.selectList.currencyList.find(item => {
        return item.idCur === val
      })
      this.form.realCurrency = currency.exchangeRate
      this.caculateAFYP();
    },
    caculateAFYP(){
      
      if(this.form.idPayMod && this.form.paymentAmount && this.form.afypRate != null && this.form.realCurrency){
        let payMode = this.selectList.payModeList.find(item => {
          return item.idPayMod === this.form.idPayMod
        })
        if(payMode)
          this.form.afyp = this.form.paymentAmount * 1 / (payMode.quantity / 12) * this.form.afypRate * this.form.realCurrency / 100
      }
      if(this.form.extraCommissionRate != null && this.form.policyPlanList[0]){
        this.form.extraCommission = this.form.realCurrency * this.form.policyPlanList[0].amount * this.form.extraCommissionRate / 100
      }
    },
    filterOption(input, option) {
    
      return option.title.toLowerCase().indexOf(input.toLowerCase()) != -1;
    }, 
   
    addExtraOverrideTemplate() {
      this.form.extraOverrideTemplateList.push({
        idAdv: null,
        commissionRate: 0.01,
      });
    },
    removeExtraOverrideTemplate(extraOverrideTemplate) {
      if(this.actionType == 'add'){
        let index = this.form.extraOverrideTemplateList.indexOf(extraOverrideTemplate);
        if (index !== -1) {
          this.form.extraOverrideTemplateList.splice(index, 1);
        }
      }else{
        const api = new PolicyDeleteExtraOverrideTemplate();
        api.post({
          idExtOveTem: extraOverrideTemplate.idExtOveTem
        }).then((response) => {
          if (0 == parseInt(response.data.code)) {
            let index = this.form.extraOverrideTemplateList.indexOf(extraOverrideTemplate);
            if (index !== -1) {
              this.form.extraOverrideTemplateList.splice(index, 1);
            }
          } else {
              message.error(response.data.msg);
          }
        });
      }
      
    },

    // reset data
    _resetData() {
      this.form = {
        idPol : "",
        idPayMod : "",
        idAdv : "",
        idSerPro : "",
        submitDate : "",
        insuredName : "",
        ownerName : "",
        paymentAmount : "",
        createDate : "",
        startPeriod : "",
        policyNo : "",
        status : "",
        approvalDate : "",
        policyDate : "",
	age : "",
        afyp : "",
	idCur : "",
	realCurrency : "",
	afypRate : "",
	remark : "",
	extraCommissionRate : 0,
        extraCommission : "",
        policyPlanList:[],
        extraOverrideTemplateList: [],
      };
    },
  },
};
</script>

<style lang="less">
</style>