const zhTW = {
    public: {
        name: {
            status: "狀態",
            login: "登錄",
            logout: "登出",
            hello: "你好",
            username: "用戶名",
            email: "E-mail",
            password: "密碼",
            confirm_password: "確認密碼",
            reset_password: "重置密碼",
            captcha: "驗證碼",
            more: "更多",
            add: "添加",
            edit: "編輯",
            del: "刪除",
            detail: "詳情",
            yes: "是",
            no: "否",
            empty: "空",
            are_you_sure_delete: "你確定需要刪除該信息嗎",
            are_you_sure_confirm: "你確定需要確認該信息嗎",
            action_cannot_resumed: "該操作將無法恢復",
            search: "搜索",
            reset: "重置",
            spread: "展開",
            retract: "收回",
            show: "展示",
            no_page: "無頁面",
            service: "服務商",
            phone_country_code: "電話國家代碼",
            name: "名稱",
            phone: "電話",
            sex: "性別",
            country: "國家",
            address: "地址",
            birthday: "出生年月日",
            remark: "備注",
            personal: "個人信息",
            enclosure: "附件",
            logo: "Logo",
            confirm: "確認",
            all: "全部",
            download: "下載"
        },
        language: {
            zhTW: "中文（繁體）",
            enUS: "英文",
            zhCN: "中文（簡體）"
        },
        status: {
            0: "未知",
            1: "正常",
            2: "禁用",
            3: "已删除",
            4: "禁用",
            5: "正常",
        },
        sex: {
            1: "男",
            2: "女",
            3: "未知"
        },
        placeholder: {
            please_select: "請選擇",
        },
        table: {
            name: "名稱",
            status: "狀態",
            action: "操作",
            content: "內容",
            created_at: "創建時間",
            image: "圖像",
            email: "E-mail",
            order_id: "排序",
            sex: "性別",
            last_ip: "最後登錄IP",
            phone: "電話",
        },
        field: {
            name: "名稱",
            username: "用戶名",
            content: "內容",
            phone_country_code: "電話國家代碼",
            phone: "電話",
            email: "E-mail",
            order_id: "排序",
            portrait: "頭像",
            status: "狀態",
            image: "圖像",
            sex: "性別",
        },
        validate: {
            required: {
                id: "缺少ID",
                username: "請輸入用戶名",
                password: "請輸入密碼",
                repassword: "請重復密碼",
                name: "請輸入名稱",
                phone: "請輸入電話",
                email: "請輸入E-mail",
                portrait: "請上傳頭像",
                phone_country_code: "請選擇電話國家代碼",
            },
            tips: {
                inconsistent_password: "密碼不一致",
                length_should: "長度應爲{min}至{max}"
            }
        }
    },
    language: {
        zhTW: "中文（繁體）",
        enUS: "英語",
        zhCN: "中文（簡體）"
    },
    nav: {
        name: {
            Dashboard: "儀錶盤",
            AdvisorManage: "保險經紀管理",
            AuthorityManage: "權限管理",
            CurrencyManage: "幣種管理",
            EmployeeManage: "員工管理",
            GiManage: "GI管理",
            InsuranceCompanyManage: "保險公司管理",
            MpfManage: "強積金管理",
            OtherManage: "其他管理",
            PaymentManage: "實付管理",
            PayModeManage: "支付模式管理",
            PlanManage: "供款計劃管理",
            PlanCategoryManage: "計劃類別管理",
            PolicyManage: "保單管理",
            RoleManage: "角色管理",
            SalaryManage: "工資管理",
            SalaryOtherManage: "工資其他管理",
            ServiceProviderManage: "服務提供商管理",
            SubjectManage: "類別管理",
            SubjectListSummaryManage: "條目匯總管理",
            UploadRecordManage: "上傳記錄管理",
            LogManage: "日誌管理",
            PersistencyManage: "持續化管理",
            ReportManage: "報告",
        },
        table: {
            order_id: "排序",
            url: "Url",
        }, 
        field: {
            pid: "PID",
            order_id: "排序",
            name: "名稱",
            url: "Url",
            status: "狀態",
            icon: "圖標",
        },
    },
    login: {
        validate: {
            required: {
                username: "請輸入用戶名",
                email: "請輸入邮箱",
                password: "輸輸入密碼",
                captcha: "請輸入驗證碼",
            }
        }
    },
    advisor:{
        name: {
            title: "保險經紀",
            detailTitle: "保險經紀詳情",
            editTitle: "編輯保險經紀"
        },
        table: {
            id_adv: "ID",
            name: "姓名",
            title: "職位",
            licence_no: "證照號",
            join_date: "入職日期",
            create_date: "創建日期",
            status: "狀態",
            mpf_no: "MPF號",
            contribution: "Contribution(HKD)",
            tr_no: "編號",
            tr_full_name: "全名",
        },
        field: {
            id_adv: "ID",
            name: "姓名",
            title: "職位",
            licence_no: "證照號",
            join_date: "入職日期",
            create_date: "創建日期",
            status: "狀態",
            mpf_no: "MPF號",
            contribution: "Contribution(HKD)",
            head_id_adv: "上級經紀",
            tr_no: "編號",
            tr_full_name: "全名",
        },
        validate: {
            required: {
                id_adv: "請填寫ID",
                name: "請填寫姓名",
                title: "請填寫職位",
                licence_no: "請填寫證照號",
                join_date: "請填寫入職日期",
                create_date: "請填寫創建日期",
                status: "請填寫狀態",
                mpf_no: "請填寫MPF號",
                contribution: "請填寫Contribution",
                head_id_adv: "請選擇上級經紀",
                tr_no: "請填寫編號",
                tr_full_name: "請填寫全名",
            }
        },
    },
    advisorCommission:{
        name: {
            title: "經紀佣金",
            detailTitle: "經紀佣金詳情",
            editTitle: "編輯經紀佣金"
        },
        table: {
            id_adv_com: "ID",
            id_pay: "ID",
            id_adv: "ID",
            commission_rate: "佣金比率(%)",
            commission: "佣金(HKD)",
            total_commission: "總佣金(HKD)",
        },
        field: {
            id_adv_com: "ID",
            id_pay: "ID",
            id_adv: "ID",
            commission_rate: "佣金比率(%)",
            commission: "佣金(HKD)",
            total_commission: "總佣金(HKD)",
        },
        validate: {
            required: {
                id_adv_com: "請填寫ID",
                id_pay: "請填寫ID",
                id_adv: "請填寫ID",
                commission_rate: "請填寫佣金比率(%)",
                commission: "請填寫佣金(HKD)",
                total_commission: "請填寫總佣金(HKD)",
            }
        },
    },
    advisorOverride:{
        name: {
            title: "追加佣金",
            detailTitle: "追加佣金詳情",
            editTitle: "編輯追加佣金"
        },
        table: {
            id_adv_ove: "ID",
            id_adv: "所屬經紀",
            head_id_adv: "上級經紀",
            override_rate: "比率(%)",
        },
        field: {
            id_adv_ove: "ID",
            id_adv: "所屬經紀",
            head_id_adv: "上級經紀",
            override_rate: "比率(%)",
        },
        validate: {
            required: {
                id_adv_ove: "請填寫ID",
                id_adv: "請填寫所屬經紀",
                head_id_adv: "請填寫上級經紀",
                override_rate: "請填寫比率",
            }
        },
    },
    authority:{
        name: {
            title: "權限",
            detailTitle: "權限詳情",
            editTitle: "編輯權限"
        },
        table: {
            id_aut: "ID",
            a_id_aut: "父權限",
            name: "權限",
            resource_name: "權限標識",
            type: "權限類型",
            sort: "排序",
        },
        field: {
            id_aut: "ID",
            a_id_aut: "父權限",
            name: "權限",
            resource_name: "權限標識",
            type: "權限類型",
            sort: "排序",
        },
        validate: {
            required: {
                id_aut: "請填寫ID",
                a_id_aut: "請填寫父權限",
                name: "請填寫權限",
                resource_name: "請填寫權限標識",
                type: "請填寫權限類型",
                sort: "請填寫排序",
            }
        },
    },
    commission:{
        name: {
            title: "實際佣金",
            detailTitle: "實際佣金詳情",
            editTitle: "編輯實際佣金"
        },
        table: {
            id_com: "ID",
            id_pay: "ID",
            commission: "佣金",
            commission_rate: "佣金比率(%)",
            amount: "保險費",
            is_basic: "是否Basic",
            id_pla: "計劃名稱",
            id_pol_pla: "id_pol_pla",
            amount_hkd:"保險費(HKD)",
            commission_hkd:"傭金(HKD)"
        },
        field: {
            id_com: "ID",
            id_pay: "ID",
            commission: "佣金",
            commission_rate: "佣金比率(%)",
            amount: "保險費",
            is_basic: "是否Basic",
            id_pla: "計劃名稱",
            id_pol_pla: "id_pol_pla",
            amount_hkd:"保險費(HKD)",
            commission_hkd:"傭金(HKD)"
        },
        validate: {
            required: {
                id_com: "請填寫ID",
                id_pay: "請填寫ID",
                commission: "請填寫佣金",
                commission_rate: "請填寫佣金比率(%)",
                amount: "請填寫保險費",
                is_basic: "請填寫是否Basic",
                id_pla: "請填寫計劃名稱",
                id_pol_pla: "請填寫id_pol_pla",
                amount_hkd:"請填寫保險費(HKD)"
            }
        },
    },
    commissionRate:{
        name: {
            title: "佣金率",
            detailTitle: "佣金率詳情",
            editTitle: "編輯佣金率"
        },
        table: {
            id_com_rat: "ID",
            id_pla: "計劃名稱",
            start_month: "開始月份",
            end_month: "結束月份",
            commission_rate: "佣金比率(%)",
            first_year: "保費支付期限",
        },
        field: {
            id_com_rat: "ID",
            id_pla: "計劃名稱",
            start_month: "開始月份",
            end_month: "結束月份",
            commission_rate: "佣金比率(%)",
            first_year: "保費支付期限",
        },
        validate: {
            required: {
                id_com_rat: "請填寫ID",
                id_pla: "請填寫計劃名稱",
                start_month: "請填寫開始月份",
                end_month: "請填寫結束月份",
                commission_rate: "請填寫佣金比率",
                first_year: "請填寫是否首年",
            }
        },
    },
    currency:{
        name: {
            title: "幣種",
            detailTitle: "幣種詳情",
            editTitle: "編輯幣種"
        },
        table: {
            id_cur: "ID",
            currency_name: "幣種",
            exchange_rate: "匯率",
        },
        field: {
            id_cur: "ID",
            currency_name: "幣種",
            exchange_rate: "匯率",
        },
        validate: {
            required: {
                id_cur: "請填寫ID",
                currency_name: "請填寫幣種",
                exchange_rate: "請填寫匯率",
            }
        },
    },
    employee:{
        name: {
            title: "員工",
            detailTitle: "員工詳情",
            editTitle: "編輯員工"
        },
        table: {
            id_emp: "ID",
            id_rol: "角色",
            account: "賬號",
            password: "密碼",
            salt: "鹽",
            last_login_time: "上次登錄時間",
            register_time: "創建時間",
            login_failure_counter: "登錄錯誤計數",
            email: "郵箱",
            remark: "備註",
            status: "狀態",
        },
        field: {
            id_emp: "ID",
            id_rol: "角色",
            account: "賬號",
            password: "密碼",
            salt: "鹽",
            last_login_time: "上次登錄時間",
            register_time: "創建時間",
            login_failure_counter: "登錄錯誤計數",
            email: "郵箱",
            remark: "備註",
            status: "狀態",
        },
        validate: {
            required: {
                id_emp: "請填寫ID",
                id_rol: "請填寫角色",
                account: "請填寫賬號",
                password: "請填寫密碼",
                salt: "請填寫鹽",
                last_login_time: "請填寫上次登錄時間",
                register_time: "請填寫創建時間",
                login_failure_counter: "請填寫登錄錯誤計數",
                email: "請填寫郵箱",
                remark: "請填寫備註",
                status: "請填寫狀態",
            }
        },
    },
    extraOverride:{
        name: {
            title: "附加提成",
            detailTitle: "附加提成詳情",
            editTitle: "編輯附加提成"
        },
        table: {
            id_ext_ove: "ID",
            id_pay: "ID",
            id_adv: "ID",
            commission_rate: "佣金比率(%)",
            override: "Extra Overide(HKD)",
            total_override: "Total Overide(HKD)",
        },
        field: {
            id_ext_ove: "ID",
            id_pay: "ID",
            id_adv: "ID",
            commission_rate: "佣金比率(%)",
            override: "Extra Overide(HKD)",
            total_override: "Total Overide(HKD)",
        },
        validate: {
            required: {
                id_ext_ove: "請填寫ID",
                id_pay: "請填寫ID",
                id_adv: "請填寫ID",
                commission_rate: "請填寫佣金比率(%)",
                override: "請填寫Extra Overide",
                total_override: "請填寫Total Overide(HKD)",
            }
        },
    },
    extraOverrideTemplate:{
        name: {
            title: "附件提成模板",
            detailTitle: "附件提成模板詳情",
            editTitle: "編輯附件提成模板"
        },
        table: {
            id_ext_ove_tem: "ID",
            id_pol: "ID",
            id_adv: "ID",
            commission_rate: "佣金比率",
        },
        field: {
            id_ext_ove_tem: "ID",
            id_pol: "ID",
            id_adv: "ID",
            commission_rate: "佣金比率",
        },
        validate: {
            required: {
                id_ext_ove_tem: "請填寫ID",
                id_pol: "請填寫ID",
                id_adv: "請填寫ID",
                commission_rate: "請填寫佣金比率",
            }
        },
    },
    gi:{
        name: {
            title: "GI",
            detailTitle: "GI詳情",
            editTitle: "編輯GI",
            upload:"上傳GI文件"
        },
        table: {
            id_gi: "ID",
            id_upl_rec: "ID",
            date_time: "日期",
            tr: "經紀",
            insurer: "保險人",
            policy_no: "保單號",
            risk: "Risk",
            insured: "受保人",
            period_from: "從",
            period_to: "到",
            gross_premium: "總保費",
            commission: "佣金",
            net_amount: "淨值",
            payment: "收款",
            balance: "餘額",
            tr_rate: "經紀佣金率",
            eae_received: "EAE Received",
            eae_rate: "EAE Rate",
            com: "Com",
            levy: "LEVY",
            bank: "Bank",
            cq_no: "Cq No",
            amount: "Amount",
            amount_date: "Date",
            com_rev_date: "com_rev_date",
        },
        field: {
            id_gi: "ID",
            id_upl_rec: "ID",
            date_time: "日期",
            tr: "經紀",
            insurer: "保險人",
            policy_no: "保單號",
            risk: "Risk",
            insured: "受保人",
            period_from: "從",
            period_to: "到",
            gross_premium: "總保費",
            commission: "佣金",
            net_amount: "淨值",
            payment: "收款",
            balance: "餘額",
            tr_rate: "經紀佣金率",
            eae_received: "EAE Received",
            eae_rate: "EAE Rate",
            com: "Com",
            levy: "LEVY",
            bank: "Bank",
            cq_no: "Cq No",
            amount: "Amount",
            amount_date: "Date",
            com_rev_date: "com_rev_date",
        },
        validate: {
            required: {
                id_gi: "請填寫ID",
                id_upl_rec: "請填寫ID",
                date_time: "請填寫日期",
                tr: "請填寫經紀",
                insurer: "請填寫保險人",
                policy_no: "請填寫保單號",
                risk: "請填寫Risk",
                insured: "請填寫受保人",
                period_from: "請填寫從",
                period_to: "請填寫到",
                gross_premium: "請填寫總保費",
                commission: "請填寫佣金",
                net_amount: "請填寫淨值",
                payment: "請填寫收款",
                balance: "請填寫餘額",
                tr_rate: "請填寫經紀佣金率",
                eae_received: "請填寫EAE Received",
                eae_rate: "請填寫EAE Rate",
                com: "請填寫Com",
                levy: "請填寫LEVY",
                bank: "請填寫Bank",
                cq_no: "請填寫Cq No",
                amount: "請填寫Amount",
                amount_date: "請填寫Date",
                com_rev_date: "請填寫com_rev_date",
            }
        },
    },
    insuranceCompany:{
        name: {
            title: "保險公司",
            detailTitle: "保險公司詳情",
            editTitle: "編輯保險公司"
        },
        table: {
            id_ins_com: "ID",
            company_name: "保險公司名稱",
        },
        field: {
            id_ins_com: "ID",
            company_name: "保險公司名稱",
        },
        validate: {
            required: {
                id_ins_com: "請填寫ID",
                company_name: "請填寫保險公司名稱",
            }
        },
    },
    log:{
      name: {
          title: "日誌",
          detailTitle: "日誌詳情",
          editTitle: "編輯日誌"
      },
      table: {
          id_log: "ID",
          content: "日誌",
          create_at: "創建於",
      },
      field: {
          id_log: "ID",
          content: "日誌",
          create_at: "創建於",
      },
      validate: {
          required: {
              id_log: "請填寫ID",
              content: "請填寫日誌",
              create_at: "請填寫創建於",
          }
      },
  },
    mpf:{
        name: {
            title: "強積金",
            detailTitle: "強積金詳情",
            editTitle: "編輯強積金",
            upload:"上傳Mpf文件"
        },
        table: {
            id_mpf: "ID",
            mpf_no: "強積金號",
            mpf_date: "強積金日期",
            provider: "提供者",
            scheme_no: "模式號",
            company_name: "公司名稱",
            net_commission: "淨佣金",
            commission: "佣金",
            contribution_period: "期",
        },
        field: {
            id_mpf: "ID",
            mpf_no: "強積金號",
            mpf_date: "強積金日期",
            provider: "提供者",
            scheme_no: "模式號",
            company_name: "公司名稱",
            net_commission: "淨佣金",
            commission: "佣金",
            contribution_period: "期",
        },
        validate: {
            required: {
                id_mpf: "請填寫ID",
                mpf_no: "請填寫強積金號",
                mpf_date: "請填寫強積金日期",
                provider: "請填寫提供者",
                scheme_no: "請填寫模式號",
                company_name: "請填寫公司名稱",
                net_commission: "請填寫淨佣金",
                commission: "請填寫佣金",
                contribution_period: "請填寫期",
            }
        },
    },
    other:{
        name: {
            title: "其他",
            detailTitle: "其他詳情",
            editTitle: "編輯其他"
        },
        table: {
            id_oth: "ID",
            id_pay: "ID",
            id_sub: "類別",
            id_adv: "經紀名稱",
            other_amount: "金額(HKD)",
        },
        field: {
            id_oth: "ID",
            id_pay: "ID",
            id_sub: "類別",
            id_adv: "經紀名稱",
            other_amount: "金額(HKD)",
        },
        validate: {
            required: {
                id_oth: "請填寫ID",
                id_pay: "請填寫ID",
                id_sub: "請填寫類別",
                id_adv: "請填寫經紀名稱",
                other_amount: "請填寫金額(HKD)",
            }
        },
    },
    payment:{
        name: {
            title: "實付",
            detailTitle: "實付詳情",
            editTitle: "編輯實付",
            dateRange: "日期範圍",
        },
        table: {
            id_pay: "ID",
            id_pol: "所屬保單",
            from_month: "開始月份",
            to_month: "結束月份",
            period: "期數",
            bank_charge: "Bank Charge",
            afyp: "AFYP(HKD)",
            total_commission: "總提成",
            payment_date: "支付日期",
            fyc: "FYC(HKD)",
            ifyp: "Premimu Amount",
            submitted_afyp: "提交的AFYP",
            adjusted_afyp: "Adjusted AFYP",
            is_renewal: "是否超過一年",
            create_date: "創建日期",
            others: "其他",
            real_exchange_rate: "實際匯率",
            afyp_rate: "AFYP Rate(%)",
            extra_rate: "Extra Rate(%)",
            extra_commission: "額外佣金 (HKD)",
            extra_overide: "Extra Overide",
            extra_bonus: "額外獎金",
            remark: "備註",
            renewal_commission: "Renewal Commission(HKD)",
            service_provider:"服務供應商",
            insured_name:"公司名稱",
            pay_mode:"支付模式",
            plan_name:"計畫名稱",
            total_fyc: "Total Commission",
        },
        field: {
            id_pay: "ID",
            id_pol: "所屬保單",
            from_month: "開始月份",
            to_month: "結束月份",
            period: "期數",
            bank_charge: "Bank Charge",
            afyp: "AFYP(HKD)",
            total_commission: "總提成",
            payment_date: "支付日期",
            fyc: "FYC(HKD)",
            ifyp: "Premimu Amount",
            submitted_afyp: "提交的AFYP",
            adjusted_afyp: "Adjusted AFYP",
            is_renewal: "是否超過一年",
            create_date: "創建日期",
            others: "其他",
            current_period: "上期",
            real_exchange_rate: "實際匯率",
            afyp_rate: "AFYP Rate(%)",
            extra_rate: "Extra Rate(%)",
            extra_commission: "額外佣金 (HKD)",
            extra_overide: "Extra Overide",
            extra_bonus: "額外獎金",
            remark: "備註",
            renewal_commission: "Renewal Commission(HKD)",
            total_fyc: "Total Commission",
        },
        validate: {
            required: {
                id_pay: "請填寫ID",
                id_pol: "請填寫所屬保單",
                from_month: "請填寫開始月份",
                to_month: "請填寫結束月份",
                period: "請填寫期數",
                bank_charge: "請填寫Bank Charge",
                afyp: "請填寫AFYP(HKD)",
                total_commission: "請填寫總提成",
                payment_date: "請填寫支付日期",
                fyc: "請填寫FYC(HKD)",
                ifyp: "請填寫Premimu Amount",
                submitted_afyp: "請填寫提交的AFYP",
                adjusted_afyp: "請填寫Adjusted AFYP",
                is_renewal: "請填寫是否超過一年",
                create_date: "請填寫創建日期",
                others: "請填寫其他",
                real_exchange_rate: "請填寫實際匯率",
                afyp_rate: "請填寫AFYP Rate(%)",
                extra_rate: "請填寫Extra Rate(%)",
                extra_commission: "請填寫額外佣金 (HKD)",
                extra_overide: "請填寫Extra Overide",
                extra_bonus: "請填寫額外獎金",
                remark: "請填寫備註",
                renewal_commission: "請填寫Renewal Commission(HKD)",
                total_fyc: "請填寫Total Commission",
            }
        },
    },
    payMode:{
        name: {
            title: "支付模式",
            detailTitle: "支付模式詳情",
            editTitle: "編輯支付模式"
        },
        table: {
            id_pay_mod: "ID",
            pay_mode: "支付模式",
            quantity: "月數",
            create_date: "創建日期",
            status: "狀態",
        },
        field: {
            id_pay_mod: "ID",
            pay_mode: "支付模式",
            quantity: "月數",
            create_date: "創建日期",
            status: "狀態",
        },
        validate: {
            required: {
                id_pay_mod: "請填寫ID",
                pay_mode: "請填寫支付模式",
                quantity: "請填寫月數",
                create_date: "請填寫創建日期",
                status: "請填寫狀態",
            }
        },
    },
    plan:{
        name: {
            title: "供款計劃",
            detailTitle: "供款計劃詳情",
            editTitle: "編輯供款計劃",
            company: "請選擇公司"
        },
        table: {
            id_pla: "ID",
            id_ser_pro: "公司名稱",
            id_pla_cat: "計劃類別",
            id_ins_com: "保險公司名稱",
            plan_name_hk: "計劃名稱(繁體)",
            plan_name: "計劃名稱(EN)",
            plan_code: "計劃代碼",
            create_date: "創建日期",
            status: "狀態",
            adjust_afyp_rate: "AFYP Rate(%)",
            expiry_date: "過期日",
            plan_code_print: "計劃名稱(打印)",
        },
        field: {
            id_pla: "ID",
            id_ser_pro: "公司名稱",
            id_pla_cat: "計劃類別",
            id_ins_com: "保險公司名稱",
            plan_name_hk: "計劃名稱(繁體)",
            plan_name: "計劃名稱(EN)",
            plan_code: "計劃代碼",
            create_date: "創建日期",
            status: "狀態",
            adjust_afyp_rate: "AFYP Rate(%)",
            expiry_date: "過期日",
            plan_code_print: "計劃名稱(打印)",
        },
        validate: {
            required: {
                id_pla: "請填寫ID",
                id_ser_pro: "請填寫公司名稱",
                id_pla_cat: "請填寫計劃類別",
                id_ins_com: "請填寫保險公司名稱",
                plan_name_hk: "請填寫計劃名稱(繁體)",
                plan_name: "請填寫計劃名稱(EN)",
                plan_code: "請填寫計劃代碼",
                create_date: "請填寫創建日期",
                status: "請填寫狀態",
                adjust_afyp_rate: "請填寫AFYP Rate",
                expiry_date: "請填寫過期日",
                plan_code_print: "請填寫計劃名稱(打印)",
            }
        },
    },
    planCategory:{
        name: {
            title: "計劃類別",
            detailTitle: "計劃類別詳情",
            editTitle: "編輯計劃類別"
        },
        table: {
            id_pla_cat: "ID",
            category_name: "類別名稱",
        },
        field: {
            id_pla_cat: "ID",
            category_name: "類別名稱",
        },
        validate: {
            required: {
                id_pla_cat: "請填寫ID",
                category_name: "請填寫類別名稱",
            }
        },
    },
    policy:{
        name: {
            title: "保單",
            subTitle: "保單計劃",
            detailTitle: "保單詳情",
            editTitle: "編輯保單",
            search: "TR Name/Service Provider/Policy No/Insured Name/Owner Name",
        },
        table: {
            id_pol: "ID",
            id_pay_mod: "支付模式",
            id_adv: "經紀名稱",
            id_ser_pro: "服務提供者",
            submit_date: "提交日期",
            insured_name: "受益人",
            owner_name: "保單所有者",
            payment_amount: "保費金額",
            create_date: "創建日期",
            currency: "幣種",
            start_period: "開始期數",
            policy_no: "保單號",
            status: "狀態",
            approval_date: "審核日期",
            policy_date: "保單生效日期",
            age: "年齡",
            afyp: "AFYP(HKD)",
            id_cur: "幣種",
            real_currency: "實際匯率",
            afyp_rate: "AFYP Rate(%)",
            remark: "備註",
            extra_commission_rate: "Extra Commission Rate",
            extra_commission: "Extra Commission(HKD)",
        },
        field: {
            id_pol: "ID",
            id_pay_mod: "支付模式",
            id_adv: "經紀名稱",
            id_ser_pro: "服務提供者",
            submit_date: "提交日期",
            insured_name: "受益人",
            owner_name: "保單所有者",
            payment_amount: "保費金額",
            create_date: "創建日期",
            currency: "幣種",
            start_period: "開始期數",
            policy_no: "保單號",
            status: "狀態",
            approval_date: "審核日期",
            policy_date: "保單生效日期",
            age: "年齡",
            afyp: "AFYP(HKD)",
            id_pla: "供款計劃",
            id_cur: "幣種",
            real_currency: "實際匯率",
            afyp_rate: "AFYP Rate(%)",
            remark: "備註",
            extra_commission_rate: "Extra Commission Rate",
            extra_commission: "Extra Commission(HKD)",
        },
        validate: {
            required: {
                id_pol: "請填寫ID",
                id_pay_mod: "請填寫支付模式",
                id_adv: "請填寫經紀名稱",
                id_ser_pro: "請填寫服務提供者",
                submit_date: "請填寫提交日期",
                insured_name: "請填寫受益人",
                owner_name: "請填寫保單所有者",
                payment_amount: "請填寫保費金額",
                create_date: "請填寫創建日期",
                currency: "請填寫幣種",
                start_period: "請填寫開始期數",
                policy_no: "請填寫保單號",
                status: "請填寫狀態",
                approval_date: "請填寫審核日期",
                policy_date: "請填寫保單生效日期",
                age: "請填寫年齡",
                afyp: "請填寫AFYP(HKD)",
                id_pla: "請選擇供款計劃",
                id_cur: "請填寫幣種",
                real_currency: "請填寫實際匯率",
                afyp_rate: "請填寫AFYP Rate(%)",
                remark: "請填寫備註",
                extra_commission_rate: "請填寫Extra Commission Rate",
                extra_commission: "請填寫Extra Commission(HKD)",
            }
        },
    },
    policyPlan:{
        name: {
            title: "保單計劃",
            detailTitle: "保單計劃詳情",
            editTitle: "編輯保單計劃"
        },
        table: {
            id_pol_pla: "ID",
            id_pla: "計劃名稱",
            id_pol: "所屬保單",
            amount: "保費",
            is_basic: "是否Basic",
        },
        field: {
            id_pol_pla: "ID",
            id_pla: "計劃名稱",
            id_pol: "所屬保單",
            amount: "保費",
            is_basic: "是否Basic",
        },
        validate: {
            required: {
                id_pol_pla: "請填寫ID",
                id_pla: "請填寫計劃名稱",
                id_pol: "請填寫所屬保單",
                amount: "請填寫保費",
                is_basic: "請填寫是否Basic",
            }
        },
    },
    realCommissionRate:{
        name: {
            title: "實際費率",
            detailTitle: "實際費率詳情",
            editTitle: "編輯實際費率"
        },
        table: {
            id_rea_com_rat: "ID",
            id_pol_pla: "計劃名稱",
            start_month: "開始月份",
            end_month: "結束月份",
            commission_rate: "佣金比率(%)",
            first_year: "保費支付期限",
        },
        field: {
            id_rea_com_rat: "ID",
            id_pol_pla: "計劃名稱",
            start_month: "開始月份",
            end_month: "結束月份",
            commission_rate: "佣金比率(%)",
            first_year: "保費支付期限",
        },
        validate: {
            required: {
                id_rea_com_rat: "請填寫ID",
                id_pol_pla: "請填寫計劃名稱",
                start_month: "請填寫開始月份",
                end_month: "請填寫結束月份",
                commission_rate: "請填寫佣金比率(%)",
                first_year: "請填寫保費支付期限",
            }
        },
    },
    role:{
        name: {
            title: "角色",
            detailTitle: "角色詳情",
            editTitle: "編輯角色"
        },
        table: {
            id_rol: "ID",
            name: "角色",
            remark: "備註",
        },
        field: {
            id_rol: "ID",
            name: "角色",
            remark: "備註",
                authorityList: "權限列表"
        },
        validate: {
            required: {
                id_rol: "請填寫ID",
                name: "請填寫角色",
                remark: "請填寫備註",
            }
        },
    },
    roleAuthority:{
        name: {
            title: "RoleAuthority",
            detailTitle: "RoleAuthority Detail",
            editTitle: "Edit RoleAuthority"
        },
        table: {
            id_rol_aut: "id_rol_aut",
            id_rol: "id_rol",
            id_aut: "id_aut",
        },
        field: {
            id_rol_aut: "id_rol_aut",
            id_rol: "id_rol",
            id_aut: "id_aut",
        },
        validate: {
            required: {
                id_rol_aut: "請填寫id_rol_aut",
                id_rol: "請填寫id_rol",
                id_aut: "請填寫id_aut",
            }
        },
    },
    salary:{
        name: {
            title: "工資",
            detailTitle: "工資詳情",
            editTitle: "編輯工資",
            month: "月份",
        },
        table: {
            id_sal: "ID",
            id_adv: "經紀名稱",
            payment_date: "支付日期",
            total_afyp: "總AFYP",
            total_ifyp: "總IFYP",
            total_renewal: "總Renewal",
            payment_amount: "總金額",
            total_fyc: "總FYC",
            create_date: "創建日期",
            others: "其他",
            contribution: "Contribution",
            bank_charge: "Bank Charge",
            overide: "Overide",
            extra_commission: "額外佣金",
            extra_bonus: "額外獎金",
            total_subject: "類目總額",
            confirm_date: "確認日期",
            month: "月份",
            override_remark: "Override Remark",
            total_fyc_remark: "Total FYC Remark",
            total_renewal_remark: "Total Renewal Remark",
            contribution_remark: "Contribution Remark",
            bank_charge_remark: "Bank Charge Remark",
        },
        field: {
            id_sal: "ID",
            id_adv: "經紀名稱",
            payment_date: "支付日期",
            total_afyp: "總AFYP",
            total_ifyp: "總IFYP",
            total_renewal: "總Renewal",
            payment_amount: "總金額",
            total_fyc: "總FYC",
            create_date: "創建日期",
            others: "其他",
            contribution: "Contribution",
            bank_charge: "Bank Charge",
            overide: "Overide",
            extra_commission: "額外佣金",
            extra_bonus: "額外獎金",
            total_subject: "類目總額",
            confirm_date: "確認日期",
            month: "月份",
            override_remark: "Override Remark",
            total_fyc_remark: "Total FYC Remark",
            total_renewal_remark: "Total Renewal Remark",
            contribution_remark: "Contribution Remark",
            bank_charge_remark: "Bank Charge Remark",
        },
        validate: {
            required: {
                id_sal: "請填寫ID",
                id_adv: "請填寫經紀名稱",
                payment_date: "請填寫支付日期",
                total_afyp: "請填寫總AFYP",
                total_ifyp: "請填寫總IFYP",
                total_renewal: "請填寫總Renewal",
                payment_amount: "請填寫總金額",
                total_fyc: "請填寫總FYC",
                create_date: "請填寫創建日期",
                others: "請填寫其他",
                contribution: "請填寫Contribution",
                bank_charge: "請填寫Bank Charge",
                overide: "請填寫Overide",
                extra_commission: "請填寫額外佣金",
                extra_bonus: "請填寫額外獎金",
                total_subject: "請填寫類目總額",
                confirm_date: "請填寫確認日期",
                month: "請填寫月份",
                override_remark: "請填寫Override Remark",
                total_fyc_remark: "請填寫Total FYC Remark",
                total_renewal_remark: "請填寫Total Renewal Remark",
                contribution_remark: "請填寫Contribution Remark",
                bank_charge_remark: "請填寫Bank Charge Remark",
            }
        },
    },
    salaryOther:{
        name: {
            title: "工資其他",
            detailTitle: "工資其他詳情",
            editTitle: "編輯工資其他"
        },
        table: {
            id_sal_oth: "ID",
            id_sal: "工資",
            id_sub: "類別",
            amount: "金額",
            remark: "備註",
        },
        field: {
            id_sal_oth: "ID",
            id_sal: "工資",
            id_sub: "類別",
            amount: "金額",
            remark: "備註",
        },
        validate: {
            required: {
                id_sal_oth: "請填寫ID",
                id_sal: "請填寫工資",
                id_sub: "請填寫類別",
                amount: "請填寫金額",
                remark: "請填寫備註",
            }
        },
    },
    serviceProvider:{
        name: {
            title: "服務提供商",
            detailTitle: "服務提供商詳情",
            editTitle: "編輯服務提供商"
        },
        table: {
            id_ser_pro: "ID",
            company_name: "公司名稱",
            company_code: "公司代碼",
            create_date: "創建日期",
            status: "狀態",
            short_name: "公司名稱（簡稱）",
        },
        field: {
            id_ser_pro: "ID",
            company_name: "公司名稱",
            company_code: "公司代碼",
            create_date: "創建日期",
            status: "狀態",
            short_name: "公司名稱（簡稱）",
        },
        validate: {
            required: {
                id_ser_pro: "請填寫ID",
                company_name: "請填寫公司名稱",
                company_code: "請填寫公司代碼",
                create_date: "請填寫創建日期",
                status: "請填寫狀態",
                short_name: "請填寫公司名稱（簡稱）",
            }
        },
    },
    subject:{
        name: {
            title: "類別",
            detailTitle: "類別詳情",
            editTitle: "編輯類別"
        },
        table: {
            id_sub: "ID",
            subject_name: "類別名稱",
            subject_name_hk: "類別名稱HK",
        },
        field: {
            id_sub: "ID",
            subject_name: "類別名稱",
            subject_name_hk: "類別名稱HK",
        },
        validate: {
            required: {
                id_sub: "請填寫ID",
                subject_name: "請填寫類別名稱",
                subject_name_hk: "請填寫類別名稱HK",
            }
        },
    },
    subjectListSummary:{
        name: {
            title: "條目匯總",
            detailTitle: "條目匯總詳情",
            editTitle: "編輯條目匯總"
        },
        table: {
            id_sub_lis_sum: "ID",
            id_sub: "類別",
            id_sal: "工資",
            total_amount: "總額",
            remark: "備註",
        },
        field: {
            id_sub_lis_sum: "ID",
            id_sub: "類別",
            id_sal: "工資",
            total_amount: "總額",
            remark: "備註",
        },
        validate: {
            required: {
                id_sub_lis_sum: "請填寫ID",
                id_sub: "請填寫類別",
                id_sal: "請填寫工資",
                total_amount: "請填寫總額",
                remark: "請填寫備註",
            }
        },
    },
    uploadRecord:{
        name: {
            title: "上傳記錄",
            detailTitle: "上傳記錄詳情",
            editTitle: "編輯上傳記錄"
        },
        table: {
            id_upl_rec: "ID",
            type: "類型",
            created_time: "上傳日期",
        },
        field: {
            id_upl_rec: "ID",
            type: "類型",
            created_time: "上傳日期",
        },
        validate: {
            required: {
                id_upl_rec: "請填寫ID",
                type: "請填寫類型",
                created_time: "請填寫上傳日期",
            }
        },
    },
    generalSalary:{
        name: {
            title: "生成工資",
            month: "月份",
            month_placeholder: "選擇月份",
            generalSalary: "生成",
            payment_list: "實付列錶",
            override_list:"傭金列錶",
            subject_list:"附加類別",
            extra_override_list: "額外傭金列錶",
        },
        table: {
            id_rep: "ID",
            id_emp: "員工",
            month: "月份",
            base_salary: "基礎工資",
            allowance: "津貼",
            car_subsidy: "車補",
            house_subsidy: "房補",
            other_subsidy: "其他補貼",
            commission: "佣金",
            overtime_times: "加班次數",
            overtime_pay: "加班工資",
            overtime: "加班時間（分鐘）",
            bonus: "獎金",
            compensatory_leave_time: "調休時間",
            total_use_annual_leave: "本年已用年假",
            total_annual_leave: "本年總年假",
            use_annual_leave: "本月總使用年假",
            remark: "備註",
            leave_time: "本月請假時間",
            absence_days: "缺勤天數",
            create_time: "創建時間",
            mpf: "強積金",
            other_deductions: "其他扣除",
            total_salary: "實發金額",
            attendance_days: "出勤天數",
            rest_days: "休息天數",
            work_hours: "工作時長",
            late_time: "遲到時間",
            late_times: "遲到次數",
            serious_late_times: "嚴重遲到次數",
            serious_late_time: "嚴重遲到時長",
            absenteeism_times: "曠工次數",
            early_leave_time: "早退時間",
            early_leave_times: "早退次數",
            no_check_in_times: "上班缺卡次數",
            no_check_out_times: "下班缺卡次數",
        },
        field: {
            id_rep: "ID",
            id_emp: "員工",
            month: "月份",
            base_salary: "基礎工資",
            allowance: "津貼",
            car_subsidy: "車補",
            house_subsidy: "房補",
            other_subsidy: "其他補貼",
            commission: "佣金",
            overtime_times: "加班次數",
            overtime_pay: "加班工資",
            overtime: "加班時間（分鐘）",
            bonus: "獎金",
            compensatory_leave_time: "調休時間",
            total_use_annual_leave: "本年已用年假",
            total_annual_leave: "本年總年假",
            use_annual_leave: "本月總使用年假",
            remark: "備註",
            leave_time: "本月請假時間",
            absence_days: "缺勤天數",
            create_time: "創建時間",
            mpf: "強積金",
            other_deductions: "其他扣除",
            total_salary: "實發金額",
            attendance_days: "出勤天數",
            rest_days: "休息天數",
            work_hours: "工作時長",
            late_time: "遲到時間",
            late_times: "遲到次數",
            serious_late_times: "嚴重遲到次數",
            serious_late_time: "嚴重遲到時長",
            absenteeism_times: "曠工次數",
            early_leave_time: "早退時間",
            early_leave_times: "早退次數",
            no_check_in_times: "上班缺卡次數",
            no_check_out_times: "下班缺卡次數",
        },
        validate: {
            required: {
                id_rep: "請填寫ID",
                id_emp: "請填寫員工",
                month: "請填寫月份",
                base_salary: "請填寫基礎工資",
                allowance: "請填寫津貼",
                car_subsidy: "請填寫車補",
                house_subsidy: "請填寫房補",
                other_subsidy: "請填寫其他補貼",
                commission: "請填寫佣金",
                overtime_times: "請填寫加班次數",
                overtime_pay: "請填寫加班工資",
                overtime: "請填寫加班時間",
                bonus: "請填寫獎金",
                compensatory_leave_time: "請填寫調休時間",
                total_use_annual_leave: "請填寫本年已用年假",
                total_annual_leave: "請填寫本年總年假",
                use_annual_leave: "請填寫本月總使用年假",
                remark: "請填寫備註",
                leave_time: "請填寫本月請假時間",
                absence_days: "請填寫缺勤天數",
                create_time: "請填寫創建時間",
                mpf: "請填寫強積金",
                other_deductions: "請填寫其他扣除",
                total_salary: "請填寫實發金額",
                attendance_days: "請填寫出勤天數",
                rest_days: "請填寫休息天數",
                work_hours: "請填寫工作時長",
                late_time: "請填寫遲到時間",
                late_times: "請填寫遲到次數",
                serious_late_times: "請填寫嚴重遲到次數",
                serious_late_time: "請填寫嚴重遲到時長",
                absenteeism_times: "請填寫曠工次數",
                early_leave_time: "請填寫早退時間",
                early_leave_times: "請填寫早退次數",
                no_check_in_times: "請填寫上班缺卡次數",
                no_check_out_times: "請填寫下班缺卡次數",
            }
        },
    },
    downloadMpfStatement:{
        name: {
            title: "下载Mpf报表",
            month: "月份",
            print_date:"打印日期"
        },
    },
    downloadMpfStatementYear:{
        name: {
            title: "下载Mpf年表",
            year: "年份",
        },
    },
    downloadEaeGiList:{
        name: {
            title: "Download Eae Gi List",
        },
    },
    downloadGerenalInsurance:{
        name: {
            title: "下載保單報錶",
            month: "月份",
        },
    },
    downloadCommissionPayment:{
        name: {
            title: "Download Commission Payment",
            month: "Month",
            downloadPaymentCommissionExcel:"Download Commission Payment Excel",
            downloadAdvisorExcel:"Download Advisor Excel",
        },
    },
    persistency:{
      name: {
          title: "持續性",
          detailTitle: "持續性詳情",
          editTitle: "編輯持續性",
          save: "保存",
          download: "下載",
          advisorInfo: "Advisor 信息",
          year: "年",
          downloadExcel: "下載Excel",
      },
      table: {
          id_per: "ID",
          id_adv: "所屬經紀",
          month: "月份",
          afyp: "AFYP",
          fyc: "FYC",
          case_num: "Case",
          lapsed_afyp: "Lapsed AFYP",
          reinstated_afyp: "Reinstated AFYP",
          exposure_afyp: "Exposure AFYP",
          total_lapsed_afyp: "Lapsed AFYP",
          persistency: "19M Personal Persistency",
      },
      field: {
          id_per: "ID",
          id_adv: "所屬經紀",
          month: "月份",
          afyp: "AFYP",
          fyc: "FYC",
          case_num: "Case",
          lapsed_afyp: "Lapsed AFYP",
          reinstated_afyp: "Reinstated AFYP",
          exposure_afyp: "Exposure AFYP",
          total_lapsed_afyp: "Lapsed AFYP",
          persistency: "19M Personal Persistency",
      },
      validate: {
          required: {
              id_per: "請填寫ID",
              id_adv: "請填寫所屬經紀",
              month: "請填寫月份",
              afyp: "請填寫AFYP",
              fyc: "請填寫FYC",
              case_num: "請填寫Case",
              lapsed_afyp: "請填寫Lapsed AFYP",
              reinstated_afyp: "請填寫Reinstated AFYP",
              exposure_afyp: "請填寫Exposure AFYP",
              total_lapsed_afyp: "請填寫Lapsed AFYP",
              persistency: "請填寫19M Personal Persistency",
          }
      },
  },
}

export default zhTW