<template>
  <Modal
    id="SalaryEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="$t('downloadMpfStatement.name.title')"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="salaryForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                  <Col span="10">
                    <FormItem :label="$t('salary.field.id_adv')" name="idAdv">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="form.idAdv"
                              :placeholder="$t('salary.validate.required.id_adv')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                          <SelectOption :value="null">{{ $t('public.name.all') }}</SelectOption>
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['advisorList']" :value="item['idAdv']">{{ item['name'] }}</SelectOption>
                      </Select>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('downloadMpfStatement.name.month')" name="month">
                      <MonthPicker valueFormat="YYYY-MM" v-model:value="form.month" :popupStyle="{ zIndex: 9999999950 }"></MonthPicker>
                  </FormItem>
                  </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  MonthPicker,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { AdvisorListAll } from "@/api/AdvisorListAll"
import { MpfDownMpfReport } from "@/api/MpfDownMpfReport"

export default {
  name: "SalaryEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    MonthPicker,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idAdv : null,
        month : "",
      },
      fileList: {
      },
      selectList: {
        advisorList: [],
      },
      validateRules: {
        paymentDate: [
          {
            required: true,
            message: this.$t("salary.validate.required.payment_date"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  methods: {
    show() {
      this.actionType = "add";
      this._resetData();
      this.getAdvisorList();
      this.visible = true;
    },
    submit() {
      if (!this.form.month)
        return;
      const api = new MpfDownMpfReport();
      api.download(this.form).then((response) => {
        if (!response) {
          return
        }
        if(response.data.type == 'application/json'){
          message.error(this.$t("public.name.no_data"))
          return
        }
        let url = window.URL.createObjectURL(new Blob([response.data]))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', 'advisorStatementForMpf.pdf')
        document.body.appendChild(link)
        link.click()
      });
    },
    getAdvisorList() {
      const api = new AdvisorListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.advisorList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    // reset data
    _resetData() {
      this.form = {
        idAdv : null,
        month : "",
      };
    },
  },
};
</script>

<style lang="less">
</style>