<template>
  <Modal
    id="UploadRecordEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="visible = !visible"
    :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="uploadRecordForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                  <Col span="10">
                    <FormItem :label="$t('uploadRecord.field.type')" name="type">
                      <Select
                              v-model:value="form.type"
                              :placeholder="$t('uploadRecord.validate.required.type')"
                              :options="selectList.typeList"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                      </Select>
                  </FormItem>
                  </Col>
                  <Col span="10" v-if="form.type == 1">
                  <FormItem :label="$t('uploadRecord.field.type')" name="type">
                   <UploadMpfFile @uploaded_url="done"></UploadMpfFile>
                   </FormItem>
                  </Col>
                  <Col span="10" v-if="form.type == 2">
                  <FormItem :label="$t('uploadRecord.field.type')" name="type">
                   <UploadGiFile @uploaded_url="done"></UploadGiFile>
                   </FormItem>
                  </Col>
                <!--判断是否有子表，如果有则直接加入子表管理逻辑-->
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Space,
  Card,
  Table,
  Popconfirm,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { UploadRecordAdd } from "@/api/UploadRecordAdd";
import { UploadRecordEdit } from "@/api/UploadRecordEdit";
import UploadMpfFile from "@/components/Common/UploadMpfFile";
import UploadGiFile from "@/components/Common/UploadGiFile";

export default {
  name: "UploadRecordEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    MinusCircleOutlined,
    ExclamationCircleOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    Space,
    Card,
    Table,
    Popconfirm,
    UploadMpfFile,
    UploadGiFile,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idUplRec : "",
        type : "",
        createdTime : "",
      },
      fileList: {
      },
      selectList: {
        typeList: [{value: 1,label: 'Mpf'},{value:2,label: 'GI'}],
      },
      validateRules: {
        idUplRec: [
          {
            required: true,
            message: this.$t("uploadRecord.validate.required.id_upl_rec"),
            trigger: "change",
            type: "number",
          },
        ],
        type: [
          {
            required: true,
            message: this.$t("uploadRecord.validate.required.type"),
            trigger: "change",
            type: "number",
          },
        ],
        createdTime: [
          {
            required: true,
            message: this.$t("uploadRecord.validate.required.created_time"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  watch: {
      "visible": {
          deep: true,
          handler: function (newValue, oldValue) {
              if (!newValue) {
                  this.$emit("updateList");
              }
          }
      }
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
    submit() {
      this.$refs["uploadRecordForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new UploadRecordAdd();
              break;
            case "edit":
              console.log("edit");
              api = new UploadRecordEdit();
              break;
            default:
              return false;
          }
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList",response.data.data);
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    done(){
      this.$emit("updateList");
      this.visible = false;
    },
    // reset data
    _resetData() {
      this.form = {
        idUplRec : "",
        type : "",
        createdTime : "",
      };
    },
  },
};
</script>

<style lang="less">
</style>