<template>
  <Modal id="SalaryEdit" width="80%" :visible="visible" @cancel="visible = !visible" @ok="submit" :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
      <Row>
        <Col span="24">
        <Form ref="salaryForm" :model="form" layout="vertical" :rules="validateRules">
          <Row justify="space-around">
            <Col span="10">
            <FormItem :label="$t('salary.field.id_adv')">
              <!--关联表的下拉选择-->
              <Select v-model:value="form.idAdv" :placeholder="$t('salary.validate.required.id_adv')" :disabled="actionType === 'edit'"
                :dropdownStyle="{ zIndex: 9999999950 }" @change="changeAdvisor" :filter-option="filterOption"
                      show-search>
                <SelectOption :key="sk" v-for="(item, sk) in selectList['advisorList']" :title="item['name']" :value="item['idAdv']">{{
                    item['name']
                }}</SelectOption>
              </Select>
            </FormItem>
            </Col>
            <Col span="10">
            <FormItem :label="$t('generalSalary.name.month')" name="month">
              <DatePicker valueFormat="YYYY-MM" picker="month" v-model:value="form.month" @change="updateData" :disabled="actionType === 'edit'"
                :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
            </FormItem>
            </Col>
            <Col v-if="advisor" span="10">
            <FormItem :label="$t('advisor.field.title')">
              <Input disabled :placeholder="$t('advisor.validate.required.title')" v-model:value="advisor.title">
              </Input>
            </FormItem>
            </Col>
            <Col v-if="advisor" span="10">
            <FormItem :label="$t('advisor.field.tr_no')">
              <Input disabled :placeholder="$t('advisor.validate.required.tr_no')" v-model:value="advisor.trNo">
              </Input>
            </FormItem>
            </Col>
            <Col v-if="advisor" span="10">
            <FormItem :label="$t('advisor.field.join_date')" name="joinDate">
              <DatePicker disabled valueFormat="YYYY-MM-DD" v-model:value="advisor.joinDate"
                :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
            </FormItem>
            </Col>
            <Col span="24" style="margin-top:30px;">
            <Card size="small" :title="$t('generalSalary.name.payment_list')">
              <Table size="small" :columns="paymentColumns" :data-source="paymentList" bordered :pagination="false">
                
              </Table>
            </Card>
            </Col>
            <Col span="24" style="margin-top:30px;">
            <Card size="small" :title="$t('generalSalary.name.override_list')">
              <Table size="small" :columns="overrideColumns" :data-source="overrideList" bordered :pagination="false">
                
              </Table>
            </Card>
            </Col>
            <Col span="24" style="margin-top:30px;">
            <Card size="small" :title="$t('generalSalary.name.extra_override_list')">
              <Table size="small" :columns="extraOverrideColumns" :data-source="extraOverrideList" bordered :pagination="false">
                
              </Table>
            </Card>
            </Col>
            <Col span="24" style="margin-top:30px;">
            <Card size="small" :title="$t('generalSalary.name.subject_list')">
              <Table size="small" :columns="otherColumns" :data-source="otherList" bordered :pagination="false">
                
              </Table>
            </Card>
            </Col>

            <Col span="24" style="margin-top:30px;">
            <Card size="small" :title="$t('subjectListSummary.name.title')">
             
              <Table size="small" :columns="subjectListSummaryColumns" :data-source="form.subjectListSummaryList" bordered
                :pagination="false">
                
                
                <template #idSub="{ record, index }">
                  <FormItem :name="['subjectListSummaryList', index, 'idSub']" :rules="{
                    required: true,
                    message: $t('subjectListSummary.validate.required.id_sub')
                  }" style="margin-bottom: 0px;">
                    <Select v-model:value="record.idSub"
                      disabled
                      :placeholder="$t('subjectListSummary.validate.required.id_sub')">
                      <SelectOption :key="sk" v-for="(item,sk) in selectList['subjectList']" :value="item['idSub']">{{
                      item['subjectNameHk'] }}</SelectOption>
                    </Select>
                  </FormItem>
                </template>
                <template #totalAmount="{ record, index }">
                  <FormItem :name="['subjectListSummaryList', index, 'amount']" :rules="{
                    required: false,
                    message: $t('subjectListSummary.validate.required.amount')
                  }" style="margin-bottom: 0px;">
                    <InputNumber disabled v-model:value="record.totalAmount" :step="0.01" :min="0.01" :precision="2"/>
                  </FormItem>
                </template>
                <template #remark="{ record, index }">
                  <FormItem :name="['subjectListSummaryList', index, 'remark']" :rules="{
                    required: false,
                    message: $t('subjectListSummary.validate.required.remark')
                  }" style="margin-bottom: 0px;">
                    <Input v-model:value="record.remark"/>
                  </FormItem>
                </template>
              </Table>
            </Card>
            </Col>

            <Col span="24" style="margin-top:30px;">
            <Card size="small" :title="$t('other.name.title')">
              <template #extra>
                <Button size="small" type="primary" block @click="addSalaryOther">
                <PlusOutlined />
                  {{ this.$t("public.name.add") }}
                </Button>
              </template>
              <Table size="small" :columns="salaryOtherColumns" :data-source="form.salaryOtherList" bordered
                :pagination="false">
                <template #action="{ record, index }">
                  <div class="editable-row-operations">
                    <span>
                      <!-- <template v-if="actionType === 'edit'">
                        <a @click="editOther(record)">{{ this.$t("public.name.edit") }}</a>
                              &nbsp;
                      </template> -->
                      <Popconfirm :title="$t('public.name.are_you_sure_delete')" @confirm="removeSalaryOther(record)">
                        <a>{{ this.$t("public.name.del") }}</a>
                      </Popconfirm>
                    </span>
                  </div>
                </template>
                <template #idSub="{ record, index }">
                  <FormItem :name="['salaryOtherList', index, 'idSub']" :rules="{
                    required: true,
                    message: $t('other.validate.required.id_sub')
                  }" style="margin-bottom: 0px;">
                    <Select v-model:value="record.idSub"
                      :placeholder="$t('other.validate.required.id_sub')" :filter-option="filterOption"
                      show-search>
                      <SelectOption :key="sk" v-for="(item,sk) in selectList['subjectList']" :title="item['subjectNameHk']" :value="item['idSub']" >{{
                      item['subjectNameHk'] }}</SelectOption>
                    </Select>
                  </FormItem>
                </template>
                <template #amount="{ record, index }">
                  <FormItem :name="['salaryOtherList', index, 'amount']" :rules="{
                    required: true,
                    message: $t('other.validate.required.other_amount')
                  }" style="margin-bottom: 0px;">
                    <InputNumber v-model:value="record.amount" :step="0.01" :precision="2"/>
                  </FormItem>
                </template>
                <template #remark="{ record, index }">
                  <FormItem :name="['salaryOtherList', index, 'remark']" :rules="{
                    required: false,
                    message: $t('subjectListSummary.validate.required.remark')
                  }" style="margin-bottom: 0px;">
                    <Input v-model:value="record.remark"/>
                  </FormItem>
                </template>
              </Table>
            </Card>
            </Col>

            <Col span="10">
                    <FormItem :label="$t('salary.field.total_afyp')" name="totalAfyp">
                      <InputNumber
                              v-model:value="form.totalAfyp"
                              :placeholder="$t('salary.validate.required.total_afyp')"
                              :step="0.01"
                      />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.overide')" name="showOverride">
                                            <InputNumber
                                                    v-model:value="form.showOverride"
                                                    :placeholder="$t('salary.validate.required.overide')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <!-- <Col span="10">
                    <FormItem :label="$t('salary.field.extra_commission')" name="extraCommission">
                                            <InputNumber
                                                    v-model:value="form.extraCommission"
                                                    :placeholder="$t('salary.validate.required.extra_commission')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col> -->
                  <Col span="10">
                    <FormItem :label="$t('salary.field.total_fyc')" name="totalFyc">
                      <InputNumber
                              v-model:value="form.totalFyc"
                              :placeholder="$t('salary.validate.required.total_fyc')"
                              :step="0.01"
                      />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.total_renewal')" name="totalRenewal">
                      <InputNumber
                              v-model:value="form.totalRenewal"
                              :placeholder="$t('salary.validate.required.total_renewal')"
                              :step="0.01"
                      />
                  </FormItem>
                  </Col>
                  <!-- <Col span="10">
                    <FormItem :label="$t('salary.field.total_subject')" name="totalSubject">
                      <InputNumber
                              v-model:value="form.totalSubject"
                              :placeholder="$t('salary.validate.required.total_subject')"
                              :step="0.01"
                      />
                  </FormItem>
                  </Col> -->

                  <!-- <Col span="10">
                    <FormItem :label="$t('salary.field.extra_bonus')" name="extraBonus">
                                            <InputNumber
                                                    v-model:value="form.extraBonus"
                                                    :placeholder="$t('salary.validate.required.extra_bonus')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col> -->
                  <Col span="10">
                    <FormItem :label="$t('salary.field.bank_charge')" name="bankCharge">
                                            <InputNumber
                                                    v-model:value="form.bankCharge"
                                                    :placeholder="$t('salary.validate.required.bank_charge')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.contribution')" name="contribution">
                                            <InputNumber
                                                    v-model:value="form.contribution"
                                                    :placeholder="$t('salary.validate.required.contribution')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <!-- <Col span="10">
                    <FormItem :label="$t('salary.field.others')" name="others">
                                            <InputNumber
                                                    v-model:value="form.others"
                                                    :placeholder="$t('salary.validate.required.others')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col> -->
                  <Col span="10">
                    <FormItem :label="$t('salary.field.confirm_date')" name="confirmDate">
                      <DatePicker show-time valueFormat="YYYY-MM-DD HH:mm:ss" v-model:value="form.confirmDate" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.month')" name="month">
                                            <Input :placeholder="$t('salary.validate.required.month')"
                                                   v-model:value="form.month">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.override_remark')" name="overrideRemark">
                      <Textarea
                              v-model:value="form.overrideRemark"
                              :placeholder="$t('salary.validate.required.override_remark')"
                              :auto-size="{ minRows: 5, maxRows: 10 }"
                              showCount
                      />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.total_fyc_remark')" name="totalFycRemark">
                      <Textarea
                              v-model:value="form.totalFycRemark"
                              :placeholder="$t('salary.validate.required.total_fyc_remark')"
                              :auto-size="{ minRows: 5, maxRows: 10 }"
                              showCount
                      />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.total_renewal_remark')" name="totalRenewalRemark">
                      <Textarea
                              v-model:value="form.totalRenewalRemark"
                              :placeholder="$t('salary.validate.required.total_renewal_remark')"
                              :auto-size="{ minRows: 5, maxRows: 10 }"
                              showCount
                      />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.contribution_remark')" name="contributionRemark">
                      <Textarea
                              v-model:value="form.contributionRemark"
                              :placeholder="$t('salary.validate.required.contribution_remark')"
                              :auto-size="{ minRows: 5, maxRows: 10 }"
                              showCount
                      />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('salary.field.bank_charge_remark')" name="bankChargeRemark">
                      <Textarea
                              v-model:value="form.bankChargeRemark"
                              :placeholder="$t('salary.validate.required.bank_charge_remark')"
                              :auto-size="{ minRows: 5, maxRows: 10 }"
                              showCount
                      />
                  </FormItem>
                  </Col>
                  
                  
                  
                  
                  
          </Row>
        </Form>
        </Col>
      </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Table,
  Card,
  Popconfirm,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { SalaryAdd } from "@/api/SalaryAdd";
import { SalaryEdit } from "@/api/SalaryEdit";
import { AdvisorListAll } from "@/api/AdvisorListAll"
import { PaymentListByIdAdv } from "@/api/PaymentListByIdAdv"
import { AdvisorCommissionListByIdAdv } from "@/api/AdvisorCommissionListByIdAdv"
import { OtherListByIdAdv } from "@/api/OtherListByIdAdv"
import { PaymentListExtraOverrideByIdAdv } from "@/api/PaymentListExtraOverrideByIdAdv"
import { SubjectListSummaryListByIdAdv } from "@/api/SubjectListSummaryListByIdAdv"
import { SalaryGetPreSalaryOtherList } from "@/api/SalaryGetPreSalaryOtherList"
import { SubjectListAll } from "@/api/SubjectListAll"

import moment from "moment";
import { SalaryCheckSalary } from "@/api/SalaryCheckSalary";

export default {
  name: "SalaryEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea: Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    Table,
    Card,
    Popconfirm,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idSal: "",
        idAdv: "",
        paymentDate: "",
        totalAfyp: "",
        totalIfyp: "",
        totalRenewal: "",
        paymentAmount: "",
        totalFyc: "",
        createDate: "",
        others: "",
        contribution: "",
        bankCharge: "",
        overide: "",
        showOverride: "", // 仅用于显示 数据库照旧两个字段，overide和extra override
        extraCommission: "",
        extraBonus: "",
        totalSubject:"",
	overrideRemark : "",
        totalFycRemark : "",
        totalRenewalRemark : "",
        contributionRemark : "",
        bankChargeRemark : "",
        subjectListSummaryList: [],
        salaryOtherList:[],
      },
      advisor: null,
      paymentList: [],
      overrideList: [],
      extraOverrideList: [],
      otherList: [],
      fileList: {
      },
      selectList: {
        advisorList: [],
        subjectList: [],
      },
      paymentColumns: [
          // { title: this.$t("payment.table.id_pay"), fixed: "left", dataIndex: "idPay", key: "idPay" },
          { title: this.$t("payment.table.service_provider"), dataIndex: "companyName", key: "companyName" },
          { title: this.$t("payment.table.id_pol"), dataIndex: "policyPolicyNo", key: "policyPolicyNo" },
          { title: this.$t("payment.table.insured_name"), dataIndex: "shortName", key: "shortName" },
          { title: this.$t("payment.table.from_month"), dataIndex: "fromMonth", key: "fromMonth" },
          { title: this.$t("payment.table.to_month"), dataIndex: "toMonth", key: "toMonth" },
          { title: this.$t("payment.table.pay_mode"), dataIndex: "payMode", key: "payMode" },
          // { title: this.$t("payment.table.period"), dataIndex: "period", key: "period" },
          { title: this.$t("payment.table.bank_charge"), dataIndex: "bankCharge", key: "bankCharge" },
          { title: this.$t("payment.table.afyp"), dataIndex: "afyp", key: "afyp" },
          // { title: this.$t("payment.table.plan_name"), dataIndex: "planName", key: "planName" },

          { title: this.$t("payment.table.payment_date"), dataIndex: "paymentDate", key: "paymentDate", customRender: ({text}) =>{return moment(text).format('YYYY-MM-DD')}},
          { title: this.$t("payment.table.fyc"), dataIndex: "fyc", key: "fyc" },
          { title: this.$t("payment.table.renewal_commission"), dataIndex: "renewalCommission", key: "renewalCommission" },
          { title: this.$t("payment.table.extra_commission"), dataIndex: "extraCommission", key: "extraCommission", customRender: ({text,record}) =>{return this.money(record.extraCommission)} },
          { title: this.$t("payment.table.extra_bonus"), dataIndex: "extraBonus", key: "extraBonus" },
          { title: this.$t("payment.table.remark"), dataIndex: "remark", key: "remark" },

      ],
      overrideColumns: [
          // { title: this.$t("advisor.table.id_adv"), fixed: "left", dataIndex: "idAdv", key: "idAdv" },
          { title: this.$t("policy.table.policy_no"), dataIndex: "policyNo", key: "policyNo" },
          { title: this.$t("advisor.table.name"), dataIndex: "trName", key: "trName" },
          { title: this.$t("advisorCommission.table.commission"), dataIndex: "commission", key: "commission" },
        
      ],
      extraOverrideColumns: [
          { title: this.$t("advisor.table.name"), dataIndex: "advisorName", key: "advisorName" },
          { title: this.$t("policy.table.policy_no"), dataIndex: "policyNo", key: "policyNo" },
          { title: this.$t("extraOverride.table.commission_rate"), dataIndex: "commissionRate", key: "commissionRate", slots: { customRender: "commissionRate" }  },
          { title: this.$t("extraOverride.table.override"), dataIndex: "override", key: "override", slots: { customRender: "override" } },
          // { title: this.$t("extraOverride.table.total_override"), dataIndex: "totalOverride", key: "totalOverride", slots: { customRender: "totalOverride" } },
          { key: "action", fixed: "right", slots: { title: "actionTitle", customRender: "action" } },
      ],
      otherColumns: [
          { title: this.$t("policy.table.policy_no"), dataIndex: "policyName", key: "policyName" },
          { title: this.$t("advisor.table.name"), dataIndex: "advisorName", key: "advisorName" },
          { title: this.$t("other.table.id_sub"), dataIndex: "subjectSubjectNameHk", key: "subjectSubjectNameHk" },
          { title: this.$t("other.table.other_amount"), dataIndex: "otherAmount", key: "otherAmount" },
      ],
      subjectListSummaryColumns: [

          { title: this.$t("subjectListSummary.table.id_sub"), dataIndex: "idSub", key: "idSub",slots: { customRender: "idSub" }  },
          { title: this.$t("subjectListSummary.table.total_amount"), dataIndex: "totalAmount", key: "totalAmount",slots: { customRender: "totalAmount" } },
          { title: this.$t("subjectListSummary.table.remark"), dataIndex: "remark", key: "remark",slots: { customRender: "remark" }  },
      ],
      salaryOtherColumns: [
          { title: this.$t("other.table.id_sub"), dataIndex: "idSub", key: "idSub", slots: { customRender: "idSub" } },
          { title: this.$t("other.table.other_amount"), dataIndex: "amount", key: "amount", slots: { customRender: "amount" } },
          { title: this.$t("subjectListSummary.table.remark"), dataIndex: "remark", key: "remark",slots: { customRender: "remark" }  },
        { key: "action", fixed: "right", slots: { title: "actionTitle", customRender: "action" } },
      ],
      validateRules: {
        idSal: [
          {
            required: true,
            message: this.$t("salary.validate.required.id_sal"),
            trigger: "change",
            type: "number",
          },
        ],
        idAdv: [
          {
            required: true,
            message: this.$t("salary.validate.required.id_adv"),
            trigger: "change",
            type: "number",
          },
        ],
        paymentDate: [
          {
            required: true,
            message: this.$t("salary.validate.required.payment_date"),
            trigger: "change",
          },
        ],
        totalAfyp: [
          {
            required: true,
            message: this.$t("salary.validate.required.total_afyp"),
            trigger: "change",
            type: "number",
          },
        ],
        totalIfyp: [
          {
            required: true,
            message: this.$t("salary.validate.required.total_ifyp"),
            trigger: "change",
            type: "number",
          },
        ],
        totalRenewal: [
          {
            required: true,
            message: this.$t("salary.validate.required.total_renewal"),
            trigger: "change",
            type: "number",
          },
        ],
        paymentAmount: [
          {
            required: true,
            message: this.$t("salary.validate.required.payment_amount"),
            trigger: "change",
            type: "number",
          },
        ],
        totalFyc: [
          {
            required: true,
            message: this.$t("salary.validate.required.total_fyc"),
            trigger: "change",
            type: "number",
          },
        ],
        createDate: [
          {
            required: true,
            message: this.$t("salary.validate.required.create_date"),
            trigger: "change",
          },
        ],
        others: [
          {
            required: true,
            message: this.$t("salary.validate.required.others"),
            trigger: "change",
            type: "number",
          },
        ],
        contribution: [
          {
            required: true,
            message: this.$t("salary.validate.required.contribution"),
            trigger: "change",
            type: "number",
          },
        ],
        bankCharge: [
          {
            required: true,
            message: this.$t("salary.validate.required.bank_charge"),
            trigger: "change",
            type: "number",
          },
        ],
        overide: [
          {
            required: true,
            message: this.$t("salary.validate.required.overide"),
            trigger: "change",
            type: "number",
          },
        ],
        extraCommission: [
          {
            required: true,
            message: this.$t("salary.validate.required.extra_commission"),
            trigger: "change",
            type: "number",
          },
        ],
        extraBonus: [
          {
            required: true,
            message: this.$t("salary.validate.required.extra_bonus"),
            trigger: "change",
            type: "number",
          },
        ],
        totalSubject: [
          {
            required: true,
            message: this.$t("salary.validate.required.total_subject"),
            trigger: "change",
            type: "number",
          },
        ],
	month: [
          {
            required: true,
            message: this.$t("salary.validate.required.month"),
            trigger: "change",
          },
        ],
        overrideRemark: [
          {
            required: false,
            message: this.$t("salary.validate.required.override_remark"),
            trigger: "change",
          },
        ],
        totalFycRemark: [
          {
            required: false,
            message: this.$t("salary.validate.required.total_fyc_remark"),
            trigger: "change",
          },
        ],
        totalRenewalRemark: [
          {
            required: false,
            message: this.$t("salary.validate.required.total_renewal_remark"),
            trigger: "change",
          },
        ],
        contributionRemark: [
          {
            required: false,
            message: this.$t("salary.validate.required.contribution_remark"),
            trigger: "change",
          },
        ],
        bankChargeRemark: [
          {
            required: false,
            message: this.$t("salary.validate.required.bank_charge_remark"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  watch: {
    // watch第一次绑定值的时候不会执行监听，修改数据才会触发函数
    // 'form.overide' (newVal,oldVal) {
    //   if(this.form.showOverride === null || typeof(this.form.showOverride) == "undefined" || this.form.showOverride === ''){
    //     this.form.showOverride = this.form.overide?this.form.overide: 0 + this.form.extraOverride?this.form.extraOverride : 0;
    //   }
    // },
    // 'form.extraOverride' (newVal,oldVal) {
    //   if(this.form.showOverride === null || typeof(this.form.showOverride) == "undefined" || this.form.showOverride === ''){
    //     this.form.showOverride = this.form.overide?this.form.overide: 0 + this.form.extraOverride?this.form.extraOverride : 0;
    //   }
    // }
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.getAdvisorList();
      this.getSubjectList();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.getAdvisorList();
      this.getSubjectList();
      this.visible = true;
      this.updateData();
    },
    submit() {
      this.$refs["salaryForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new SalaryAdd();
              break;
            case "edit":
              console.log("edit");
              api = new SalaryEdit();
              break;
            default:
              return false;
          }
       
          this.form.subjectListSummaryListStr = JSON.stringify(this.form.subjectListSummaryList)
          this.form.salaryOtherListStr = JSON.stringify(this.form.salaryOtherList)
          console.log(this.form)
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    getAdvisorList() {
      const api = new AdvisorListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.selectList.advisorList = response.data.data;
        } else {
          message.error(response.data.msg);
        }
      });
    },
    getPaymentList() {
      const api = new PaymentListByIdAdv();
      api.get({
        idAdv:this.form.idAdv,
        month:this.form.month
      }).then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.paymentList = response.data.data;
          if(this.actionType=="add"){
            let total_afyp = 0;
            let extraCommission = 0;
            let total_fyc = 0;
            let extra_bonus = 0;
            let bank_charge = 0;
            let totalRenewal = 0;
            //填入afyp
            for(let i = 0;i < response.data.data.length;i ++){
              let payment = response.data.data[i];
              total_afyp += payment.afyp;
              extraCommission += payment.extraCommission;
              total_fyc += payment.fyc;
              extra_bonus += payment.extraBonus
              bank_charge += payment.bankCharge
              totalRenewal += payment.renewalCommission
            }
            //contribution的值直接等于advisor的contribution的值
            this.form.contribution = this.advisor.contribution
            this.form.totalAfyp = total_afyp;
            this.form.extraCommission = extraCommission;
            this.form.totalFyc = total_fyc;
            this.form.extraBonus = extra_bonus;
            this.form.bankCharge = bank_charge;
            this.form.totalRenewal = totalRenewal;
            this.form.others = 0;
          }
          

        } else {
          message.error(response.data.msg);
        }
      });
    },
    getOverrideList() {
      const api = new AdvisorCommissionListByIdAdv();
      api.get({
        idAdv:this.form.idAdv,
        month:this.form.month
      }).then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.overrideList = response.data.data;
          // if(this.actionType=="add"){
            let total_override = 0;
            //填入afyp
            for(let i = 0;i < response.data.data.length;i ++){
              let override = response.data.data[i];
              total_override += override.commission;
            }
            this.form.overide = total_override;
            // 如果在数据库改过，则直接显示，不计算
            // if(!this.form.showOverride){
            //   this.form.showOverride = (total_override + parseFloat(this.form.extraOverride)).toFixed(2);
            // }

          // }
          this.getExtraOverrideList();

          

          
        } else {
          message.error(response.data.msg);
        }
      });
    },

    getExtraOverrideList() {
      const api = new PaymentListExtraOverrideByIdAdv();
      api.get({
        idAdv:this.form.idAdv,
        month:this.form.month
      }).then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.extraOverrideList = response.data.data;
          let extra_override = 0;
            //填入afyp
            for(let i = 0;i < response.data.data.length;i ++){
              extra_override += response.data.data[i].override;
            }
            this.form.extraOverride = extra_override;
            
            // if(!this.form.showOverride){
            //   this.form.showOverride = parseFloat(this.form.overide) + extra_override;
            // }
            if(this.form.showOverride === null || typeof(this.form.showOverride) == "undefined" || this.form.showOverride === ''){
             
              this.form.showOverride = (this.form.overide + this.form.extraOverride).toFixed(2);
            }
        } else {
          message.error(response.data.msg);
        }
      });
    },
    getOtherList() {
      const api = new OtherListByIdAdv();
      api.get({
        idAdv:this.form.idAdv,
        month:this.form.month
      }).then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.otherList = response.data.data;
          if(this.actionType=="add"){
            let subject_amount = 0;
            //填入afyp
            for(let i = 0;i < response.data.data.length;i ++){
              let other = response.data.data[i];
              subject_amount += other.otherAmount;
            }
            this.form.totalSubject = subject_amount;
          }

          


        } else {
          message.error(response.data.msg);
        }
      });
    },
    getSubjectListSummaryList() {
      const api = new SubjectListSummaryListByIdAdv();
      api.get({
        idAdv:this.form.idAdv,
        month:this.form.month
      }).then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.form.subjectListSummaryList = response.data.data;
        
          


        } else {
          message.error(response.data.msg);
        }
      });
    },
    getSubjectList() {
      const api = new SubjectListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.selectList.subjectList = response.data.data;
        } else {
          message.error(response.data.msg);
        }
      });
    },
    changeAdvisor() {
      let advisor = this.selectList.advisorList.find(item => {
        return item.idAdv === this.form.idAdv
      })
      this.advisor = advisor


      this.updateData();
    },
    async updateData(){
      if(this.form.month && this.form.idAdv){
        await this.checkSalary();
        await this.getPaymentList();
        await this.getOverrideList();
        await this.getOtherList();
        // 把下面的额外Override加到getOverrideList回调内，再计算showOverride
        // await this.getExtraOverrideList();
        if(this.actionType === "add"){
          await this.getSubjectListSummaryList();
          await this.getPreSalaryOtherList();
        }
        
      }
    },

    addSalaryOther() {
      this.form.salaryOtherList.push({
        idSub: null,
        amount: null,
        remark: null,
      });
    },
    removeSalaryOther(salaryOther){
      let index = this.form.salaryOtherList.indexOf(salaryOther);
      if (index !== -1) {
        this.form.salaryOtherList.splice(index, 1);
      }
    },
    getPreSalaryOtherList() {
      const api = new SalaryGetPreSalaryOtherList();
      api.get({
        idAdv:this.form.idAdv,
        month:this.form.month
      }).then((response) => {
        
        if (0 == parseInt(response.data.code)) {
          this.form.salaryOtherList = response.data.data;
        } else {
          message.error(response.data.msg);
        }
      });
    },
    filterOption(input, option) {
    // console.log("option.title.toLowerCase()：" + option.title.toLowerCase());
    // console.log("input.toLowerCase()：" + input.toLowerCase());
    // console.log("option.title.toLowerCase().indexOf(input.toLowerCase())：" + option.title.toLowerCase().indexOf(input.toLowerCase()));
    return option.title.toLowerCase().indexOf(input.toLowerCase()) != -1;
  }, 
  async checkSalary() {
      const api = new SalaryCheckSalary();
      let response = await api.get({
        idAdv:this.form.idAdv,
        month:this.form.month
      })
      
      if (0 == parseInt(response.data.code)) {
        if(response.data.data){
          message.error("已存在當月工資記錄");
        }
      }
  
    },
    
    // reset data
    _resetData() {
      this.form = {
        idSal: "",
        idAdv: "",
        paymentDate: "",
        totalAfyp: "",
        totalIfyp: "",
        totalRenewal: "",
        paymentAmount: "",
        totalFyc: "",
        createDate: "",
        others: "",
        contribution: "",
        bankCharge: "",
        overide: "",
        extraCommission: "",
        extraBonus: "",
        totalSubject: "",
	overrideRemark : "",
        totalFycRemark : "",
        totalRenewalRemark : "",
        contributionRemark : "",
        bankChargeRemark : "",
        extraOverride:"",
        subjectListSummaryList:[],
        salaryOtherList:[]
      };
      this.paymentList = [];
      this.overrideList = [];
      this.otherList = [];
      this.extraOverrideList = [];
    },
  },
};
</script>

<style lang="less">

</style>