<template>
  <Modal
    id="MpfEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="mpfForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                  <Col span="10">
                    <FormItem :label="$t('mpf.field.id_upl_rec')" name="idUplRec">
                                            <Input :placeholder="$t('mpf.validate.required.id_upl_rec')"
                                                   v-model:value="form.idUplRec">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('mpf.field.mpf_no')" name="mpfNo">
                                            <Input :placeholder="$t('mpf.validate.required.mpf_no')"
                                                   v-model:value="form.mpfNo">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('mpf.field.mpf_date')" name="mpfDate">
                                            <Input :placeholder="$t('mpf.validate.required.mpf_date')"
                                                   v-model:value="form.mpfDate">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('mpf.field.provider')" name="provider">
                                            <Input :placeholder="$t('mpf.validate.required.provider')"
                                                   v-model:value="form.provider">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('mpf.field.scheme_no')" name="schemeNo">
                                            <Input :placeholder="$t('mpf.validate.required.scheme_no')"
                                                   v-model:value="form.schemeNo">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('mpf.field.company_name')" name="companyName">
                                            <Input :placeholder="$t('mpf.validate.required.company_name')"
                                                   v-model:value="form.companyName">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('mpf.field.net_commission')" name="netCommission">
                                            <InputNumber
                                                    v-model:value="form.netCommission"
                                                    :placeholder="$t('mpf.validate.required.net_commission')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('mpf.field.commission')" name="commission">
                                            <InputNumber
                                                    v-model:value="form.commission"
                                                    :placeholder="$t('mpf.validate.required.commission')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('mpf.field.contribution_period')" name="contributionPeriod">
                                            <Input :placeholder="$t('mpf.validate.required.contribution_period')"
                                                   v-model:value="form.contributionPeriod">
                                            </Input>
                  </FormItem>
                  </Col>
                <!--判断是否有子表，如果有则直接加入子表管理逻辑-->
                <!-- <Col span="10">
                  <UploadMpfFile></UploadMpfFile>
                </Col> -->
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Space,
  Card,
  Table,
  Popconfirm,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { MpfAdd } from "@/api/MpfAdd";
import { MpfEdit } from "@/api/MpfEdit";
import UploadMpfFile from "@/components/Common/UploadMpfFile";

export default {
  name: "MpfEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    MinusCircleOutlined,
    ExclamationCircleOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    Space,
    Card,
    Table,
    Popconfirm,
    UploadMpfFile,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idMpf : "",
        idUplRec : "",
        mpfNo : "",
        mpfDate : "",
        provider : "",
        schemeNo : "",
        companyName : "",
        netCommission : "",
        commission : "",
        contributionPeriod : "",
      },
      fileList: {
      },
      selectList: {
      },
      validateRules: {
        idMpf: [
          {
            required: true,
            message: this.$t("mpf.validate.required.id_mpf"),
            trigger: "change",
            type: "number",
          },
        ],
        idUplRec: [
          {
            required: true,
            message: this.$t("mpf.validate.required.id_upl_rec"),
            trigger: "change",
            type: "number",
          },
        ],
        mpfNo: [
          {
            required: true,
            message: this.$t("mpf.validate.required.mpf_no"),
            trigger: "change",
          },
        ],
        mpfDate: [
          {
            required: true,
            message: this.$t("mpf.validate.required.mpf_date"),
            trigger: "change",
          },
        ],
        provider: [
          {
            required: true,
            message: this.$t("mpf.validate.required.provider"),
            trigger: "change",
          },
        ],
        schemeNo: [
          {
            required: true,
            message: this.$t("mpf.validate.required.scheme_no"),
            trigger: "change",
          },
        ],
        companyName: [
          {
            required: true,
            message: this.$t("mpf.validate.required.company_name"),
            trigger: "change",
          },
        ],
        netCommission: [
          {
            required: true,
            message: this.$t("mpf.validate.required.net_commission"),
            trigger: "change",
            type: "number",
          },
        ],
        commission: [
          {
            required: true,
            message: this.$t("mpf.validate.required.commission"),
            trigger: "change",
            type: "number",
          },
        ],
        contributionPeriod: [
          {
            required: true,
            message: this.$t("mpf.validate.required.contribution_period"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  watch: {
      "visible": {
          deep: true,
          handler: function (newValue, oldValue) {
              if (!newValue) {
                  this.$emit("updateList");
              }
          }
      }
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
    submit() {
      this.$refs["mpfForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new MpfAdd();
              break;
            case "edit":
              console.log("edit");
              api = new MpfEdit();
              break;
            default:
              return false;
          }
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList",response.data.data);
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    // reset data
    _resetData() {
      this.form = {
        idMpf : "",
        idUplRec : "",
        mpfNo : "",
        mpfDate : "",
        provider : "",
        schemeNo : "",
        companyName : "",
        netCommission : "",
        commission : "",
        contributionPeriod : "",
      };
    },
  },
};
</script>

<style lang="less">
</style>