import { defineComponent, ref, onMounted } from "vue"
import { Upload, Button, message } from "ant-design-vue";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons-vue";
import { MpfUpload as uploadApi } from "@/api/MpfUpload";

import i18n from "@/i18n"

export default defineComponent({
  name: "UploadMpfFile",
  emits: ['uploaded_url'],
  setup(props,ctx) {
    const uploading = ref(false)
    const uploadList = ref()

    // upload handle
    const _upload_handle = (obj) => {
      uploading.value = true
      uploadList.value = []
      let form = new FormData();
      let api = new uploadApi();
      form.append("fileName", obj.file.name);
      form.append("file", obj.file);
      api.upload(form).then((response) => {
        if (parseInt(response.data.code) == 0) {
          ctx.emit('uploaded_url', response.data.data);
          message.success(response.data.msg);
        } else {
          message.warning(response.data.msg);
        }
        uploading.value = false
      });
    }
    onMounted(() => {
    })
    return () => (
      <Upload customRequest={_upload_handle} fileList={uploadList.value}  >
        {
          uploading.value == true ?
            (
              <LoadingOutlined style="{fontSize:'25px'}"></LoadingOutlined>
            ) :
            (
              <Button type="primary">
                <UploadOutlined style="{fontSize:'25px'}"></UploadOutlined>
                {i18n.global.t('mpf.name.upload')}
              </Button>
            )
        }
      </Upload>
    )
  }
})
