const zhCN = {
    public: {
        name: {
            status: "状态",
            login: "登录",
            logout: "登出",
            hello: "你好",
            username: "用户名",
            email: "E-mail",
            password: "密码",
            confirm_password: "确认密码",
            reset_password: "重置密码",
            captcha: "验证码",
            more: "更多",
            add: "添加",
            edit: "编辑",
            del: "删除",
            detail: "详情",
            yes: "是",
            no: "否",
            empty: "空",
            are_you_sure_delete: "你确定需要删除该信息吗",
            are_you_sure_confirm: "你确定需要确认该信息吗",
            action_cannot_resumed: "该操作将无法恢复",
            search: "搜索",
            reset: "重置",
            spread: "展开",
            retract: "收回",
            show: "展示",
            no_page: "无页面",
            service: "服务商",
            phone_country_code: "电话国家代码",
            name: "名称",
            phone: "电话",
            sex: "性别",
            country: "国家",
            address: "地址",
            birthday: "出生年月日",
            remark: "备注",
            personal: "个人信息",
            enclosure: "附件",
            logo: "Logo",
            confirm: "确认",
            all: "全部",
            download: "下载"
        },
        language: {
            zhTW: "中文(繁体)",
            enUS: "英文",
            zhCN: "中文(简体)"
        },
        status: {
            0: "未知",
            1: "正常",
            2: "禁用",
            3: "已删除",
            4: "禁用",
            5: "正常",
        },
        sex: {
            1: "男",
            2: "女",
            3: "未知"
        },
        placeholder: {
            please_select: "请选择",
        },
        table: {
            name: "名称",
            status: "状态",
            action: "操作",
            content: "内容",
            created_at: "创建时间",
            image: "图像",
            email: "E-mail",
            order_id: "排序",
            sex: "性别",
            last_ip: "最后登录IP",
            phone: "电话",
        },
        field: {
            name: "名称",
            username: "用户名",
            content: "内容",
            phone_country_code: "电话国家代码",
            phone: "电话",
            email: "E-mail",
            order_id: "排序",
            portrait: "头像",
            status: "状态",
            image: "图像",
            sex: "性别",
        },
        validate: {
            required: {
                id: "缺少ID",
                username: "请输入用户名",
                password: "请输入密码",
                repassword: "请重复密码",
                name: "请输入名称",
                phone: "请输入电话",
                email: "请输入E-mail",
                portrait: "请上传头像",
                phone_country_code: "请选择电话国家代码",
            },
            tips: {
                inconsistent_password: "密码不一致",
                length_should: "长度应为{min}至{max}"
            }
        }
    },
    language: {
        zhTW: "中文（繁体）",
        enUS: "英语",
        zhCN: "中文（简体）"
    },
    nav: {
        name: {
            Dashboard: "仪錶盘",
            AdvisorManage: "保险经纪管理",
            AuthorityManage: "权限管理",
            CurrencyManage: "币种管理",
            EmployeeManage: "员工管理",
            GiManage: "GI管理",
            InsuranceCompanyManage: "保险公司管理",
            MpfManage: "强积金管理",
            OtherManage: "其他管理",
            PaymentManage: "实付管理",
            PayModeManage: "支付模式管理",
            PlanManage: "供款计划管理",
            PlanCategoryManage: "计划类别管理",
            PolicyManage: "保单管理",
            RoleManage: "角色管理",
            SalaryManage: "工资管理",
            SalaryOtherManage: "工资其他管理",
            ServiceProviderManage: "服务提供商管理",
            SubjectManage: "类别管理",
            SubjectListSummaryManage: "条目汇总管理",
            UploadRecordManage: "上传记录管理",
            LogManage: "日志管理",
            PersistencyManage: "持续化管理",
            ReportManage: "报告",
        },
        table: {
            order_id: "排序",
            url: "Url",
        },
        field: {
            pid: "PID",
            order_id: "排序",
            name: "名称",
            url: "Url",
            status: "状态",
            icon: "图标",
        },
    },
    login: {
        validate: {
            required: {
                username: "请输入用户名",
                email: "请输入邮箱",
                password: "输输入密码",
                captcha: "请输入验证码",
            }
        }
    },
    advisor:{
        name: {
          title: "保险经纪",
          detailTitle: "保险经纪详情",
          editTitle: "编辑保险经纪"
        },
        table: {
            id_adv: "ID",
            name: "姓名",
            title: "职位",
            licence_no: "证照号",
            join_date: "入职日期",
            create_date: "创建日期",
            status: "状态",
            mpf_no: "MPF号",
            contribution: "Contribution(HKD)",
            tr_no: "编号",
            tr_full_name: "全名",
        },
        field: {
            id_adv: "ID",
            name: "姓名",
            title: "职位",
            licence_no: "证照号",
            join_date: "入职日期",
            create_date: "创建日期",
            status: "状态",
            mpf_no: "MPF号",
            contribution: "Contribution(HKD)",
            head_id_adv: "上级经纪",
            tr_no: "编号",
            tr_full_name: "全名",
        },
        validate: {
            required: {
                id_adv: "请填写ID",
                name: "请填写姓名",
                title: "请填写职位",
                licence_no: "请填写证照号",
                join_date: "请填写入职日期",
                create_date: "请填写创建日期",
                status: "请填写状态",
                mpf_no: "请填写MPF号",
                contribution: "请填写Contribution",
                head_id_adv: "请选择上级经纪",
                tr_no: "请填写编号",
                tr_full_name: "请填写全名",
            }
        },
    },
    advisorCommission:{
        name: {
          title: "经纪佣金",
          detailTitle: "经纪佣金详情",
          editTitle: "编辑经纪佣金"
        },
        table: {
            id_adv_com: "ID",
            id_pay: "ID",
            id_adv: "ID",
            commission_rate: "佣金比率(%)",
            commission: "佣金(HKD)",
            total_commission: "总佣金(HKD)",
        },
        field: {
            id_adv_com: "ID",
            id_pay: "ID",
            id_adv: "ID",
            commission_rate: "佣金比率(%)",
            commission: "佣金(HKD)",
            total_commission: "总佣金(HKD)",
        },
        validate: {
            required: {
                id_adv_com: "请填写ID",
                id_pay: "请填写ID",
                id_adv: "请填写ID",
                commission_rate: "请填写佣金比率(%)",
                commission: "请填写佣金(HKD)",
                total_commission: "请填写总佣金(HKD)",
            }
        },
    },
        advisorOverride:{
        name: {
          title: "追加佣金",
          detailTitle: "追加佣金详情",
          editTitle: "编辑追加佣金"
        },
        table: {
            id_adv_ove: "ID",
            id_adv: "所属经纪",
            head_id_adv: "上级经纪",
            override_rate: "比率(%)",
        },
        field: {
            id_adv_ove: "ID",
            id_adv: "所属经纪",
            head_id_adv: "上级经纪",
            override_rate: "比率(%)",
        },
        validate: {
            required: {
                id_adv_ove: "请填写ID",
                id_adv: "请填写所属经纪",
                head_id_adv: "请填写上级经纪",
                override_rate: "请填写比率",
            }
        },
    },
    authority:{
        name: {
          title: "权限",
          detailTitle: "权限详情",
          editTitle: "编辑权限"
        },
        table: {
            id_aut: "ID",
            a_id_aut: "父权限",
            name: "权限",
            resource_name: "权限标识",
            type: "权限类型",
            sort: "排序",
        },
        field: {
            id_aut: "ID",
            a_id_aut: "父权限",
            name: "权限",
            resource_name: "权限标识",
            type: "权限类型",
            sort: "排序",
        },
        validate: {
            required: {
                id_aut: "请填写ID",
                a_id_aut: "请填写父权限",
                name: "请填写权限",
                resource_name: "请填写权限标识",
                type: "请填写权限类型",
                sort: "请填写排序",
            }
        },
    },
    commission:{
        name: {
          title: "实际佣金",
          detailTitle: "实际佣金详情",
          editTitle: "编辑实际佣金"
        },
        table: {
            id_com: "ID",
            id_pay: "ID",
            commission: "佣金",
            commission_rate: "佣金比率(%)",
            amount: "保险费",
            is_basic: "是否Basic",
            id_pla: "计划名称",
            id_pol_pla: "id_pol_pla",
            amount_hkd:"保险费(HKD)",
            commission_hkd:"佣金(HKD)"
        },
        field: {
            id_com: "ID",
            id_pay: "ID",
            commission: "佣金",
            commission_rate: "佣金比率(%)",
            amount: "保险费",
            is_basic: "是否Basic",
            id_pla: "计划名称",
            id_pol_pla: "id_pol_pla",
            amount_hkd:"保险费(HKD)",
            commission_hkd:"佣金(HKD)"
        },
        validate: {
            required: {
                id_com: "请填写ID",
                id_pay: "请填写ID",
                commission: "请填写佣金",
                commission_rate: "请填写佣金比率(%)",
                amount: "请填写保险费",
                is_basic: "请填写是否Basic",
                id_pla: "请填写计划名称",
                id_pol_pla: "请填写id_pol_pla",
                amount_hkd:"请填写保险费(HKD)"
            }
        },
    },
    commissionRate:{
        name: {
          title: "佣金率",
          detailTitle: "佣金率详情",
          editTitle: "编辑佣金率"
        },
        table: {
            id_com_rat: "ID",
            id_pla: "计划名称",
            start_month: "开始月份",
            end_month: "结束月份",
            commission_rate: "佣金比率(%)",
            first_year: "保费支付期限",
        },
        field: {
            id_com_rat: "ID",
            id_pla: "计划名称",
            start_month: "开始月份",
            end_month: "结束月份",
            commission_rate: "佣金比率(%)",
            first_year: "保费支付期限",
        },
        validate: {
            required: {
                id_com_rat: "请填写ID",
                id_pla: "请填写计划名称",
                start_month: "请填写开始月份",
                end_month: "请填写结束月份",
                commission_rate: "请填写佣金比率",
                first_year: "请填写是否首年",
            }
        },
    },
    currency:{
        name: {
          title: "币种",
          detailTitle: "币种详情",
          editTitle: "编辑币种"
        },
        table: {
            id_cur: "ID",
            currency_name: "币种",
            exchange_rate: "汇率",
        },
        field: {
            id_cur: "ID",
            currency_name: "币种",
            exchange_rate: "汇率",
        },
        validate: {
            required: {
                id_cur: "请填写ID",
                currency_name: "请填写币种",
                exchange_rate: "请填写汇率",
            }
        },
    },
    employee:{
        name: {
          title: "员工",
          detailTitle: "员工详情",
          editTitle: "编辑员工"
        },
        table: {
            id_emp: "ID",
            id_rol: "角色",
            account: "账号",
            password: "密码",
            salt: "盐",
            last_login_time: "上次登录时间",
            register_time: "创建时间",
            login_failure_counter: "登录错误计数",
            email: "邮箱",
            remark: "备注",
            status: "状态",
        },
        field: {
            id_emp: "ID",
            id_rol: "角色",
            account: "账号",
            password: "密码",
            salt: "盐",
            last_login_time: "上次登录时间",
            register_time: "创建时间",
            login_failure_counter: "登录错误计数",
            email: "邮箱",
            remark: "备注",
            status: "状态",
        },
        validate: {
            required: {
                id_emp: "请填写ID",
                id_rol: "请填写角色",
                account: "请填写账号",
                password: "请填写密码",
                salt: "请填写盐",
                last_login_time: "请填写上次登录时间",
                register_time: "请填写创建时间",
                login_failure_counter: "请填写登录错误计数",
                email: "请填写邮箱",
                remark: "请填写备注",
                status: "请填写状态",
            }
        },
    },
    extraOverride:{
        name: {
          title: "附件提成",
          detailTitle: "附件提成详情",
          editTitle: "编辑附件提成"
        },
        table: {
            id_ext_ove: "ID",
            id_pay: "ID",
            id_adv: "ID",
            commission_rate: "佣金比率(%)",
            override: "Extra Overide(HKD)",
            total_override: "Total Overide(HKD)",
        },
        field: {
            id_ext_ove: "ID",
            id_pay: "ID",
            id_adv: "ID",
            commission_rate: "佣金比率(%)",
            override: "Extra Overide(HKD)",
            total_override: "Total Overide(HKD)",
        },
        validate: {
            required: {
                id_ext_ove: "请填写ID",
                id_pay: "请填写ID",
                id_adv: "请填写ID",
                commission_rate: "请填写佣金比率(%)",
                override: "请填写Extra Overide",
                total_override: "请填写Total Overide",
            }
        },
    },
    extraOverrideTemplate:{
        name: {
          title: "附加提成模板",
          detailTitle: "附加提成模板详情",
          editTitle: "编辑附加提成模板"
        },
        table: {
            id_ext_ove_tem: "ID",
            id_pol: "ID",
            id_adv: "ID",
            commission_rate: "佣金比率",
        },
        field: {
            id_ext_ove_tem: "ID",
            id_pol: "ID",
            id_adv: "ID",
            commission_rate: "佣金比率",
        },
        validate: {
            required: {
                id_ext_ove_tem: "请填写ID",
                id_pol: "请填写ID",
                id_adv: "请填写ID",
                commission_rate: "请填写佣金比率",
            }
        },
    },
    gi:{
        name: {
          title: "GI",
          detailTitle: "GI详情",
          editTitle: "编辑GI",
          upload:"上传GI文件"
        },
        table: {
            id_gi: "ID",
            id_upl_rec: "ID",
            date_time: "日期",
            tr: "经纪",
            insurer: "保险人",
            policy_no: "保单号",
            risk: "Risk",
            insured: "受保人",
            period_from: "从",
            period_to: "到",
            gross_premium: "总保费",
            commission: "佣金",
            net_amount: "净值",
            payment: "收款",
            balance: "余额",
            tr_rate: "经纪佣金率",
            eae_received: "EAE Received",
            eae_rate: "EAE Rate",
            com: "Com",
            levy: "LEVY",
            bank: "Bank",
            cq_no: "Cq No",
            amount: "Amount",
            amount_date: "Date",
            com_rev_date: "com_rev_date",
        },
        field: {
            id_gi: "ID",
            id_upl_rec: "ID",
            date_time: "日期",
            tr: "经纪",
            insurer: "保险人",
            policy_no: "保单号",
            risk: "Risk",
            insured: "受保人",
            period_from: "从",
            period_to: "到",
            gross_premium: "总保费",
            commission: "佣金",
            net_amount: "净值",
            payment: "收款",
            balance: "余额",
            tr_rate: "经纪佣金率",
            eae_received: "EAE Received",
            eae_rate: "EAE Rate",
            com: "Com",
            levy: "LEVY",
            bank: "Bank",
            cq_no: "Cq No",
            amount: "Amount",
            amount_date: "Date",
            com_rev_date: "com_rev_date",
        },
        validate: {
            required: {
                id_gi: "请填写ID",
                id_upl_rec: "请填写ID",
                date_time: "请填写日期",
                tr: "请填写经纪",
                insurer: "请填写保险人",
                policy_no: "请填写保单号",
                risk: "请填写Risk",
                insured: "请填写受保人",
                period_from: "请填写从",
                period_to: "请填写到",
                gross_premium: "请填写总保费",
                commission: "请填写佣金",
                net_amount: "请填写净值",
                payment: "请填写收款",
                balance: "请填写余额",
                tr_rate: "请填写经纪佣金率",
                eae_received: "请填写EAE Received",
                eae_rate: "请填写EAE Rate",
                com: "请填写Com",
                levy: "请填写LEVY",
                bank: "请填写Bank",
                cq_no: "请填写Cq No",
                amount: "请填写Amount",
                amount_date: "请填写Date",
                com_rev_date: "请填写com_rev_date",
            }
        },
    },
    insuranceCompany:{
        name: {
          title: "保险公司",
          detailTitle: "保险公司详情",
          editTitle: "编辑保险公司"
        },
        table: {
            id_ins_com: "ID",
            company_name: "保险公司名称",
        },
        field: {
            id_ins_com: "ID",
            company_name: "保险公司名称",
        },
        validate: {
            required: {
                id_ins_com: "请填写ID",
                company_name: "请填写保险公司名称",
            }
        },
    },
    log:{
      name: {
        title: "日志",
        detailTitle: "日志详情",
        editTitle: "编辑日志"
      },
      table: {
          id_log: "ID",
          content: "日志",
          create_at: "创建于",
      },
      field: {
          id_log: "ID",
          content: "日志",
          create_at: "创建于",
      },
      validate: {
          required: {
              id_log: "请填写ID",
              content: "请填写日志",
              create_at: "请填写创建于",
          }
      },
  },
    mpf:{
        name: {
          title: "强积金",
          detailTitle: "强积金详情",
          editTitle: "编辑强积金",
          upload: "上传Mpf文件"
        },
        table: {
            id_mpf: "ID",
            id_upl_rec: "ID",
            mpf_no: "强积金号",
            mpf_date: "强积金日期",
            provider: "提供者",
            scheme_no: "模式号",
            company_name: "公司名称",
            net_commission: "净佣金",
            commission: "佣金",
            contribution_period: "期",
        },
        field: {
            id_mpf: "ID",
            id_upl_rec: "ID",
            mpf_no: "强积金号",
            mpf_date: "强积金日期",
            provider: "提供者",
            scheme_no: "模式号",
            company_name: "公司名称",
            net_commission: "净佣金",
            commission: "佣金",
            contribution_period: "期",
        },
        validate: {
            required: {
                id_mpf: "请填写ID",
                id_upl_rec: "请填写ID",
                mpf_no: "请填写强积金号",
                mpf_date: "请填写强积金日期",
                provider: "请填写提供者",
                scheme_no: "请填写模式号",
                company_name: "请填写公司名称",
                net_commission: "请填写净佣金",
                commission: "请填写佣金",
                contribution_period: "请填写期",
            }
        },
    },
    other:{
        name: {
          title: "其他",
          detailTitle: "其他详情",
          editTitle: "编辑其他"
        },
        table: {
            id_oth: "ID",
            id_pay: "ID",
            id_sub: "类别",
            id_adv: "经纪名称",
            other_amount: "金额(HKD)",
        },
        field: {
            id_oth: "ID",
            id_pay: "ID",
            id_sub: "类别",
            id_adv: "经纪名称",
            other_amount: "金额(HKD)",
        },
        validate: {
            required: {
                id_oth: "请填写ID",
                id_pay: "请填写ID",
                id_sub: "请填写类别",
                id_adv: "请填写经纪名称",
                other_amount: "请填写金额(HKD)",
            }
        },
    },
    payment:{
        name: {
          title: "实付",
          detailTitle: "实付详情",
          editTitle: "编辑实付",
          dateRange: "日期范围",
        },
        table: {
            id_pay: "ID",
            id_pol: "所属保单",
            from_month: "开始月份",
            to_month: "结束月份",
            period: "期数",
            bank_charge: "Bank Charge",
            afyp: "AFYP(HKD)",
            total_commission: "总提成",
            payment_date: "支付日期",
            fyc: "FYC(HKD)",
            ifyp: "Premimu Amount",
            submitted_afyp: "提交的AFYP",
            adjusted_afyp: "Adjusted AFYP",
            is_renewal: "是否超过一年",
            create_date: "创建日期",
            others: "Others",
            real_exchange_rate: "实际汇率",
            afyp_rate: "AFYP Rate(%)",
            extra_rate: "Extra Rate(%)",
            extra_commission: "额外佣金 (HKD)",
            extra_overide: "Extra Overide",
            extra_bonus: "额外奖金",
            remark: "备注",
            renewal_commission: "Renewal Commission(HKD)",
            service_provider:"服务供应商",
            insured_name:"公司名称",
            pay_mode:"支付模式",
            plan_name:"计划名称",
            total_fyc: "Total Commission",
        },
        field: {
            id_pay: "ID",
            id_pol: "所属保单",
            from_month: "开始月份",
            to_month: "结束月份",
            period: "期数",
            bank_charge: "Bank Charge",
            afyp: "AFYP(HKD)",
            total_commission: "总提成",
            payment_date: "支付日期",
            fyc: "FYC(HKD)",
            ifyp: "Premimu Amount",
            submitted_afyp: "提交的AFYP",
            adjusted_afyp: "Adjusted AFYP",
            is_renewal: "是否超过一年",
            create_date: "创建日期",
            others: "Others",
            current_period: "上期",
            real_exchange_rate: "实际汇率",
            afyp_rate: "AFYP Rate(%)",
            extra_rate: "Extra Rate(%)",
            extra_commission: "额外佣金 (HKD)",
            extra_overide: "Extra Overide",
            extra_bonus: "额外奖金",
            remark: "备注",
            renewal_commission: "Renewal Commission(HKD)",
            total_fyc: "Total Commission",
        },
        validate: {
            required: {
                id_pay: "请填写ID",
                id_pol: "请填写所属保单",
                from_month: "请填写开始月份",
                to_month: "请填写结束月份",
                period: "请填写期数",
                bank_charge: "请填写Bank Charge",
                afyp: "请填写AFYP(HKD)",
                total_commission: "请填写总提成",
                payment_date: "请填写支付日期",
                fyc: "请填写FYC(HKD)",
                ifyp: "请填写Premimu Amount",
                submitted_afyp: "请填写提交的AFYP",
                adjusted_afyp: "请填写Adjusted AFYP",
                is_renewal: "请填写是否超过一年",
                create_date: "请填写创建日期",
                others: "请填写Others",
                real_exchange_rate: "请填写实际汇率",
                afyp_rate: "请填写AFYP Rate(%)",
                extra_rate: "请填写Extra Rate(%)",
                extra_commission: "请填写额外佣金 (HKD)",
                extra_overide: "请填写Extra Overide",
                extra_bonus: "请填写额外奖金",
                remark: "请填写备注",
                renewal_commission: "请填写Renewal Commission(HKD)",
                total_fyc: "请填写Total Commission",
            }
        },
    },
    payMode:{
        name: {
          title: "支付模式",
          detailTitle: "支付模式详情",
          editTitle: "编辑支付模式"
        },
        table: {
            id_pay_mod: "ID",
            pay_mode: "支付模式",
            quantity: "月数",
            create_date: "创建日期",
            status: "状态",
        },
        field: {
            id_pay_mod: "ID",
            pay_mode: "支付模式",
            quantity: "月数",
            create_date: "创建日期",
            status: "状态",
        },
        validate: {
            required: {
                id_pay_mod: "请填写ID",
                pay_mode: "请填写支付模式",
                quantity: "请填写月数",
                create_date: "请填写创建日期",
                status: "请填写状态",
            }
        },
    },
    plan:{
        name: {
          title: "供款计划",
          detailTitle: "供款计划详情",
          editTitle: "编辑供款计划",
          company: "请选择公司"
        },
        table: {
            id_pla: "ID",
            id_ser_pro: "公司名称",
            id_pla_cat: "计划类别",
            id_ins_com: "保险公司名称",
            plan_name_hk: "计划名称(繁体)",
            plan_name: "计划名称(EN)",
            plan_code: "计划代码",
            create_date: "创建日期",
            status: "状态",
            adjust_afyp_rate: "AFYP Rate(%)",
            expiry_date: "过期日",
            plan_code_print: "计划名称(打印)",
        },
        field: {
            id_pla: "ID",
            id_ser_pro: "公司名称",
            id_pla_cat: "计划类别",
            id_ins_com: "保险公司名称",
            plan_name_hk: "计划名称(繁体)",
            plan_name: "计划名称(EN)",
            plan_code: "计划代码",
            create_date: "创建日期",
            status: "状态",
            adjust_afyp_rate: "AFYP Rate(%)",
            expiry_date: "过期日",
            plan_code_print: "计划名称(打印)",
        },
        validate: {
            required: {
                id_pla: "请填写ID",
                id_ser_pro: "请填写公司名称",
                id_pla_cat: "请填写计划类别",
                id_ins_com: "请填写保险公司名称",
                plan_name_hk: "请填写计划名称(繁体)",
                plan_name: "请填写计划名称(EN)",
                plan_code: "请填写计划代码",
                create_date: "请填写创建日期",
                status: "请填写状态",
                adjust_afyp_rate: "请填写AFYP Rate",
                expiry_date: "请填写过期日",
                plan_code_print: "请填写计划名称(打印)",
            }
        },
    },
    planCategory:{
        name: {
          title: "计划类别",
          detailTitle: "计划类别详情",
          editTitle: "编辑计划类别"
        },
        table: {
            id_pla_cat: "ID",
            category_name: "类别名称",
        },
        field: {
            id_pla_cat: "ID",
            category_name: "类别名称",
        },
        validate: {
            required: {
                id_pla_cat: "请填写ID",
                category_name: "请填写类别名称",
            }
        },
    },
    policy:{
        name: {
          title: "保单",
          subTitle: "保单计划",
          detailTitle: "保单详情",
          editTitle: "编辑保单",
          search: "TR Name/Service Provider/Policy No/Insured Name/Owner Name",
        },
        table: {
            id_pol: "ID",
            id_pay_mod: "支付模式",
            id_adv: "经纪名称",
            id_ser_pro: "服务提供者",
            submit_date: "提交日期",
            insured_name: "受益人",
            owner_name: "保单所有者",
            payment_amount: "保费金额",
            create_date: "创建日期",
            currency: "币种",
            start_period: "开始期数",
            policy_no: "保单号",
            status: "状态",
            approval_date: "审核日期",
            policy_date: "保单生效日期",
            age: "年龄",
            afyp: "AFYP(HKD)",
            id_cur: "币种",
            real_currency: "实际汇率",
            afyp_rate: "AFYP Rate(%)",
            remark: "备注",
            extra_commission_rate: "Extra Commission Rate",
            extra_commission: "Extra Commission(HKD)",
        },
        field: {
            id_pol: "ID",
            id_pay_mod: "支付模式",
            id_adv: "经纪名称",
            id_ser_pro: "服务提供者",
            submit_date: "提交日期",
            insured_name: "受益人",
            owner_name: "保单所有者",
            payment_amount: "保费金额",
            create_date: "创建日期",
            currency: "币种",
            start_period: "开始期数",
            policy_no: "保单号",
            status: "状态",
            approval_date: "审核日期",
            policy_date: "保单生效日期",
            age: "年龄",
            afyp: "AFYP(HKD)",
            id_pla: "供款计划",
            id_cur: "币种",
            real_currency: "实际汇率",
            afyp_rate: "AFYP Rate(%)",
            remark: "备注",
            extra_commission_rate: "Extra Commission Rate",
            extra_commission: "Extra Commission(HKD)",
        },
        validate: {
            required: {
                id_pol: "请填写ID",
                id_pay_mod: "请填写支付模式",
                id_adv: "请填写经纪名称",
                id_ser_pro: "请填写服务提供者",
                submit_date: "请填写提交日期",
                insured_name: "请填写受益人",
                owner_name: "请填写保单所有者",
                payment_amount: "请填写保费金额",
                create_date: "请填写创建日期",
                currency: "请填写币种",
                start_period: "请填写开始期数",
                policy_no: "请填写保单号",
                status: "请填写状态",
                approval_date: "请填写审核日期",
                policy_date: "请填写保单生效日期",
                age: "请填写年龄",
                afyp: "请填写AFYP(HKD)",
                id_pla:"请选择供款计划",
                id_cur: "请填写币种",
                real_currency: "请填写实际汇率",
                afyp_rate: "请填写AFYP Rate(%)",
                remark: "请填写备注",
                extra_commission_rate: "请填写Extra Commission Rate",
                extra_commission: "请填写Extra Commission(HKD)",
            }
        },
    },
    policyPlan:{
        name: {
          title: "保单计划",
          detailTitle: "保单计划详情",
          editTitle: "编辑保单计划"
        },
        table: {
            id_pol_pla: "ID",
            id_pla: "计划名称",
            id_pol: "所属保单",
            amount: "保费",
            is_basic: "是否Basic",
        },
        field: {
            id_pol_pla: "ID",
            id_pla: "计划名称",
            id_pol: "所属保单",
            amount: "保费",
            is_basic: "是否Basic",
        },
        validate: {
            required: {
                id_pol_pla: "请填写ID",
                id_pla: "请填写计划名称",
                id_pol: "请填写所属保单",
                amount: "请填写保费",
                is_basic: "请填写是否Basic",
            }
        },
    },
    realCommissionRate:{
        name: {
          title: "实际费率",
          detailTitle: "实际费率详情",
          editTitle: "编辑实际费率"
        },
        table: {
            id_rea_com_rat: "ID",
            id_pol_pla: "计划名称",
            start_month: "开始月份",
            end_month: "结束月份",
            commission_rate: "佣金比率(%)",
            first_year: "保费支付期限",
        },
        field: {
            id_rea_com_rat: "ID",
            id_pol_pla: "计划名称",
            start_month: "开始月份",
            end_month: "结束月份",
            commission_rate: "佣金比率(%)",
            first_year: "保费支付期限",
        },
        validate: {
            required: {
                id_rea_com_rat: "请填写ID",
                id_pol_pla: "请填写计划名称",
                start_month: "请填写开始月份",
                end_month: "请填写结束月份",
                commission_rate: "请填写佣金比率(%)",
                first_year: "请填写保费支付期限",
            }
        },
    },
    role:{
        name: {
          title: "角色",
          detailTitle: "角色详情",
          editTitle: "编辑角色"
        },
        table: {
            id_rol: "ID",
            name: "角色",
            remark: "备注",
        },
        field: {
            id_rol: "ID",
            name: "角色",
            remark: "备注",
            authorityList: "权限列表"
        },
        validate: {
            required: {
                id_rol: "请填写ID",
                name: "请填写角色",
                remark: "请填写备注",
            }
        },
    },
    roleAuthority:{
        name: {
          title: "RoleAuthority",
          detailTitle: "RoleAuthority Detail",
          editTitle: "Edit RoleAuthority"
        },
        table: {
            id_rol_aut: "id_rol_aut",
            id_rol: "id_rol",
            id_aut: "id_aut",
        },
        field: {
            id_rol_aut: "id_rol_aut",
            id_rol: "id_rol",
            id_aut: "id_aut",
        },
        validate: {
            required: {
                id_rol_aut: "请填写id_rol_aut",
                id_rol: "请填写id_rol",
                id_aut: "请填写id_aut",
            }
        },
    },
    salary:{
        name: {
          title: "工资",
          detailTitle: "工资详情",
          editTitle: "编辑工资",
          month: "月份",
        },
        table: {
            id_sal: "ID",
            id_adv: "经纪名称",
            payment_date: "支付日期",
            total_afyp: "总AFYP",
            total_ifyp: "总IFYP",
            total_renewal: "总Renewal",
            payment_amount: "总金额",
            total_fyc: "总FYC",
            create_date: "创建日期",
            others: "其他",
            contribution: "Contribution",
            bank_charge: "Bank Charge",
            overide: "Overide",
            extra_commission: "额外佣金",
            extra_bonus: "额外奖金",
            total_subject: "类目总额",
            confirm_date: "确认日期",
            month: "月份",
            override_remark: "Override Remark",
            total_fyc_remark: "Total FYC Remark",
            total_renewal_remark: "Total Renewal Remark",
            contribution_remark: "Contribution Remark",
            bank_charge_remark: "Bank Charge Remark",
        },
        field: {
            id_sal: "ID",
            id_adv: "经纪名称",
            payment_date: "支付日期",
            total_afyp: "总AFYP",
            total_ifyp: "总IFYP",
            total_renewal: "总Renewal",
            payment_amount: "总金额",
            total_fyc: "总FYC",
            create_date: "创建日期",
            others: "其他",
            contribution: "Contribution",
            bank_charge: "Bank Charge",
            overide: "Overide",
            extra_commission: "额外佣金",
            extra_bonus: "额外奖金",
            total_subject: "类目总额",
            confirm_date: "确认日期",
            month: "月份",
            override_remark: "Override Remark",
            total_fyc_remark: "Total FYC Remark",
            total_renewal_remark: "Total Renewal Remark",
            contribution_remark: "Contribution Remark",
            bank_charge_remark: "Bank Charge Remark",
        },
        validate: {
            required: {
                id_sal: "请填写ID",
                id_adv: "请填写经纪名称",
                payment_date: "请填写支付日期",
                total_afyp: "请填写总AFYP",
                total_ifyp: "请填写总IFYP",
                total_renewal: "请填写总Renewal",
                payment_amount: "请填写总金额",
                total_fyc: "请填写总FYC",
                create_date: "请填写创建日期",
                others: "请填写其他",
                contribution: "请填写Contribution",
                bank_charge: "请填写Bank Charge",
                overide: "请填写Overide",
                extra_commission: "请填写额外佣金",
                extra_bonus: "请填写额外奖金",
                total_subject: "请填写类目总额",
                confirm_date: "请填写确认日期",
                month: "请填写月份",
                override_remark: "请填写Override Remark",
                total_fyc_remark: "请填写Total FYC Remark",
                total_renewal_remark: "请填写Total Renewal Remark",
                contribution_remark: "请填写Contribution Remark",
                bank_charge_remark: "请填写Bank Charge Remark",
            }
        },
    },
    salaryOther:{
        name: {
          title: "工资其他",
          detailTitle: "工资其他详情",
          editTitle: "编辑工资其他"
        },
        table: {
            id_sal_oth: "ID",
            id_sal: "工资",
            id_sub: "类别",
            amount: "金额",
            remark: "备注",
        },
        field: {
            id_sal_oth: "ID",
            id_sal: "工资",
            id_sub: "类别",
            amount: "金额",
            remark: "备注",
        },
        validate: {
            required: {
                id_sal_oth: "请填写ID",
                id_sal: "请填写工资",
                id_sub: "请填写类别",
                amount: "请填写金额",
                remark: "请填写备注",
            }
        },
    },
    serviceProvider:{
        name: {
          title: "服务提供商",
          detailTitle: "服务提供商详情",
          editTitle: "编辑服务提供商"
        },
        table: {
            id_ser_pro: "ID",
            company_name: "公司名称",
            company_code: "公司代码",
            create_date: "创建日期",
            status: "状态",
            short_name: "公司名称（简称）",
        },
        field: {
            id_ser_pro: "ID",
            company_name: "公司名称",
            company_code: "公司代码",
            create_date: "创建日期",
            status: "状态",
            short_name: "公司名称（简称）",
        },
        validate: {
            required: {
                id_ser_pro: "请填写ID",
                company_name: "请填写公司名称",
                company_code: "请填写公司代码",
                create_date: "请填写创建日期",
                status: "请填写状态",
                short_name: "请填写公司名称（简称）",
            }
        },
    },
    subject:{
        name: {
          title: "类别",
          detailTitle: "类别详情",
          editTitle: "编辑类别"
        },
        table: {
            id_sub: "ID",
            subject_name: "类别名称",
            subject_name_hk: "类别名称HK",
        },
        field: {
            id_sub: "ID",
            subject_name: "类别名称",
            subject_name_hk: "类别名称HK",
        },
        validate: {
            required: {
                id_sub: "请填写ID",
                subject_name: "请填写类别名称",
                subject_name_hk: "请填写类别名称HK",
            }
        },
    },
    subjectListSummary:{
        name: {
          title: "条目汇总",
          detailTitle: "条目汇总详情",
          editTitle: "编辑条目汇总"
        },
        table: {
            id_sub_lis_sum: "ID",
            id_sub: "类别",
            id_sal: "工资",
            total_amount: "总额",
            remark: "备注",
        },
        field: {
            id_sub_lis_sum: "ID",
            id_sub: "类别",
            id_sal: "工资",
            total_amount: "总额",
            remark: "备注",
        },
        validate: {
            required: {
                id_sub_lis_sum: "请填写ID",
                id_sub: "请填写类别",
                id_sal: "请填写工资",
                total_amount: "请填写总额",
                remark: "请填写备注",
            }
        },
    },
    uploadRecord:{
        name: {
          title: "上传记录",
          detailTitle: "上传记录详情",
          editTitle: "编辑上传记录"
        },
        table: {
            id_upl_rec: "ID",
            type: "类型",
            created_time: "上传日期",
        },
        field: {
            id_upl_rec: "ID",
            type: "类型",
            created_time: "上传日期",
        },
        validate: {
            required: {
                id_upl_rec: "请填写ID",
                type: "请填写类型",
                created_time: "请填写上传日期",
            }
        },
    },
    generalSalary:{
        name: {
            title: "生成工资",
            month: "月份",
            month_placeholder: "选择月份",
            generalSalary: "生成",
            payment_list: "实付列表",
            override_list:"佣金列表",
            subject_list:"附加类别",
            extra_override_list: "额外佣金列表",
        },
        table: {
            id_rep: "ID",
            id_emp: "员工",
            month: "月份",
            base_salary: "基础工资",
            allowance: "津贴",
            car_subsidy: "车补",
            house_subsidy: "房补",
            other_subsidy: "其他补贴",
            commission: "佣金",
            overtime_times: "加班次数",
            overtime_pay: "加班工资",
            overtime: "加班时间（分钟）",
            bonus: "奖金",
            compensatory_leave_time: "调休时间",
            total_use_annual_leave: "本年已用年假",
            total_annual_leave: "本年总年假",
            use_annual_leave: "本月总使用年假",
            remark: "备注",
            leave_time: "本月请假时间",
            absence_days: "缺勤天数",
            create_time: "创建时间",
            mpf: "强积金",
            other_deductions: "其他扣除",
            total_salary: "实发金额",
            attendance_days: "出勤天数",
            rest_days: "休息天数",
            work_hours: "工作时长",
            late_time: "迟到时间",
            late_times: "迟到次数",
            serious_late_times: "严重迟到次数",
            serious_late_time: "严重迟到时长",
            absenteeism_times: "旷工次数",
            early_leave_time: "早退时间",
            early_leave_times: "早退次数",
            no_check_in_times: "上班缺卡次数",
            no_check_out_times: "下班缺卡次数",
        },
        field: {
            id_rep: "ID",
            id_emp: "员工",
            month: "月份",
            base_salary: "基础工资",
            allowance: "津贴",
            car_subsidy: "车补",
            house_subsidy: "房补",
            other_subsidy: "其他补贴",
            commission: "佣金",
            overtime_times: "加班次数",
            overtime_pay: "加班工资",
            overtime: "加班时间(分钟)",
            bonus: "奖金",
            compensatory_leave_time: "调休时间",
            total_use_annual_leave: "本年已用年假",
            total_annual_leave: "本年总年假",
            use_annual_leave: "本月总使用年假",
            remark: "备注",
            leave_time: "本月请假时间",
            absence_days: "缺勤天数",
            create_time: "创建时间",
            mpf: "强积金",
            other_deductions: "其他扣除",
            total_salary: "实发金额",
            attendance_days: "出勤天数",
            rest_days: "休息天数",
            work_hours: "工作时长",
            late_time: "迟到时间",
            late_times: "迟到次数",
            serious_late_times: "严重迟到次数",
            serious_late_time: "严重迟到时长",
            absenteeism_times: "旷工次数",
            early_leave_time: "早退时间",
            early_leave_times: "早退次数",
            no_check_in_times: "上班缺卡次数",
            no_check_out_times: "下班缺卡次数",
        },
        validate: {
            required: {
                id_rep: "请填写ID",
                id_emp: "请填写员工",
                month: "请填写月份",
                base_salary: "请填写基础工资",
                allowance: "请填写津贴",
                car_subsidy: "请填写车补",
                house_subsidy: "请填写房补",
                other_subsidy: "请填写其他补贴",
                commission: "请填写佣金",
                overtime_times: "请填写加班次数",
                overtime_pay: "请填写加班工资",
                overtime: "请填写加班时间",
                bonus: "请填写奖金",
                compensatory_leave_time: "请填写调休时间",
                total_use_annual_leave: "请填写本年已用年假",
                total_annual_leave: "请填写本年总年假",
                use_annual_leave: "请填写本月总使用年假",
                remark: "请填写备注",
                leave_time: "请填写本月请假时间",
                absence_days: "请填写缺勤天数",
                create_time: "请填写创建时间",
                mpf: "请填写强积金",
                other_deductions: "请填写其他扣除",
                total_salary: "请填写实发金额",
                attendance_days: "请填写出勤天数",
                rest_days: "请填写休息天数",
                work_hours: "请填写工作时长",
                late_time: "请填写迟到时间",
                late_times: "请填写迟到次数",
                serious_late_times: "请填写严重迟到次数",
                serious_late_time: "请填写严重迟到时长",
                absenteeism_times: "请填写旷工次数",
                early_leave_time: "请填写早退时间",
                early_leave_times: "请填写早退次数",
                no_check_in_times: "请填写上班缺卡次数",
                no_check_out_times: "请填写下班缺卡次数",
            }
        },
    },
    downloadMpfStatement:{
        name: {
            title: "下载Mpf报表",
            month: "月份",
            print_date:"打印日期"
        },
    },
    downloadMpfStatementYear:{
        name: {
            title: "下载Mpf年报",
            year: "年份",
        },
    },
    downloadEaeGiList:{
        name: {
            title: "Download Eae Gi List",
        },
    },
    downloadGerenalInsurance:{
        name: {
            title: "下载保单报表",
            month: "月份",
        },
    },
    downloadCommissionPayment:{
        name: {
            title: "Download Commission Payment",
            month: "Month",
            downloadPaymentCommissionExcel:"Download Commission Payment Excel",
            downloadAdvisorExcel:"Download Advisor Excel",
        },
    },
    persistency:{
      name: {
        title: "持续性",
        detailTitle: "持续性详情",
        editTitle: "编辑持续性",
        show: "显示",
          save: "保存",
          download: "下载",
          advisorInfo: "Advisor 信息",
          year: "年",
          downloadExcel: "下载Excel",
      },
      table: {
          id_per: "ID",
          id_adv: "所属经纪",
          month: "月份",
          afyp: "AFYP",
          fyc: "FYC",
          case_num: "Case",
          lapsed_afyp: "Lapsed AFYP",
          reinstated_afyp: "Reinstated AFYP",
          exposure_afyp: "Exposure AFYP",
          total_lapsed_afyp: "Lapsed AFYP",
          persistency: "19M Personal Persistency",
      },
      field: {
          id_per: "ID",
          id_adv: "所属经纪",
          month: "月份",
          afyp: "AFYP",
          fyc: "FYC",
          case_num: "Case",
          lapsed_afyp: "Lapsed AFYP",
          reinstated_afyp: "Reinstated AFYP",
          exposure_afyp: "Exposure AFYP",
          total_lapsed_afyp: "Lapsed AFYP",
          persistency: "19M Personal Persistency",
      },
      validate: {
          required: {
              id_per: "请填写ID",
              id_adv: "请填写所属经纪",
              month: "请填写月份",
              afyp: "请填写AFYP",
              fyc: "请填写FYC",
              case_num: "请填写Case",
              lapsed_afyp: "请填写Lapsed AFYP",
              reinstated_afyp: "请填写Reinstated AFYP",
              exposure_afyp: "请填写Exposure AFYP",
              total_lapsed_afyp: "请填写Lapsed AFYP",
              persistency: "请填写19M Personal Persistency",
          }
      },
  },
}

export default zhCN