<template>
  <Modal
    id="AdvisorEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="advisorForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                  <Col span="10">
                    <FormItem :label="$t('advisor.field.name')" name="name">
                      <Input :placeholder="$t('advisor.validate.required.name')"
                             v-model:value="form.name">
                      </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('advisor.field.title')" name="title">
                      <Input :placeholder="$t('advisor.validate.required.title')"
                             v-model:value="form.title">
                      </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('advisor.field.tr_no')" name="trNo">
                                            <Input :placeholder="$t('advisor.validate.required.tr_no')"
                                                   v-model:value="form.trNo">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('advisor.field.tr_full_name')" name="trFullName">
                                            <Input :placeholder="$t('advisor.validate.required.tr_full_name')"
                                                   v-model:value="form.trFullName">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('advisor.field.licence_no')" name="licenceNo">
                      <Input :placeholder="$t('advisor.validate.required.licence_no')"
                             v-model:value="form.licenceNo">
                      </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('advisor.field.join_date')" name="joinDate">
                      <DatePicker valueFormat="YYYY-MM-DD" v-model:value="form.joinDate" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                  </Col>
                  <!--<Col span="10">
                    <Space
                      v-for="(advisorOverride, index) in form.advisorOverrideList"
                      :key="advisorOverride.headIdAdv"
                      style="display: flex; margin-bottom: 8px"
                      align="baseline"
                    >
                      <FormItem
                        :name="['advisorOverrideList', index, 'headIdAdv']"
                        :rules="{
                          required: true,
                          message: $t('advisor.validate.required.head_id_adv')
                        }"
                      >
                        <Select
                              v-model:value="advisorOverride.headIdAdv"
                              :placeholder="$t('advisor.validate.required.head_id_adv')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['advisorList']" :value="item['idAdv']">{{ item['name'] }}</SelectOption>
                      </Select>
                      </FormItem>
                      <FormItem
                        :name="['advisorOverrideList', index, 'overrideRate']"
                        :rules="{
                          required: true,
                          message:  $t('advisorOverride.validate.required.override_rate'),
                        }"
                      >
                        <InputNumber v-model:value="advisorOverride.overrideRate" :min="0.01" :max="100" placeholder="Rate" />
                      </FormItem>
                      <MinusCircleOutlined @click="removeAdvisorOverride(advisorOverride)" />
                    </Space>
                  </Col>
                  <Col span="10">
                  <FormItem>
                    <Button type="dashed" block @click="addAdvisorOverride">
                      <PlusOutlined />
                      Add Advisor Override
                    </Button>
                  </FormItem>
                  </Col>-->


                  <Col span="10">
                    <FormItem :label="$t('advisor.field.status')" name="status">
                      <Select
                              v-model:value="form.status"
                              :placeholder="$t('advisor.validate.required.status')"
                              :options="selectList.statusList"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                      </Select>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('advisor.field.mpf_no')" name="mpfNo">
                                            <Input :placeholder="$t('advisor.validate.required.mpf_no')"
                                                   v-model:value="form.mpfNo">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('advisor.field.contribution')" name="contribution">
                                            <InputNumber
                                                    v-model:value="form.contribution"
                                                    :placeholder="$t('advisor.validate.required.contribution')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  
                <!--判断是否有子表，如果有则直接加入子表管理逻辑-->
                      <!--遍历Table，找出子表-->
                <Col span="24">
                  <Card size="small" :title="$t('advisorOverride.name.title')">
                    <template #extra>
                      <Button size="small" type="primary" block @click="addAdvisorOverride">
                        <PlusOutlined />
                        {{ this.$t("public.name.add") }}
                      </Button>
                    </template>
                    <Table size="small" :columns="advisorOverrideColumns" :data-source="form.advisorOverrideList" bordered :pagination="false">
                      <template #operation="{ record, index }">
                        <div class="editable-row-operations">
                          <span>
                            <template v-if="actionType === 'edit'">
                              <a @click="editAdvisorOverride(record)">{{ this.$t("public.name.edit") }}</a>
                              &nbsp;
                            </template>
                            <Popconfirm :title="$t('public.name.are_you_sure_delete')" @confirm="removeAdvisorOverride(index)">
                              <a>{{ this.$t("public.name.del") }}</a>
                            </Popconfirm>
                          </span>
                        </div>
                      </template>
                    </Table>
                  </Card>
                </Col>

              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
  <AdvisorOverrideEdit ref="refAdvisorOverrideEdit" @updateList="updateListAdvisorOverride"></AdvisorOverrideEdit>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Space,
  Card,
  Table,
  Popconfirm,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { AdvisorAdd } from "@/api/AdvisorAdd";
import { AdvisorEdit } from "@/api/AdvisorEdit";
import { AdvisorListAll } from "@/api/AdvisorListAll"
import AdvisorOverrideEdit from "@/components/Home/AdvisorOverrideEdit";
import { AdvisorOverrideDelete } from "@/api/AdvisorOverrideDelete"

export default {
  name: "AdvisorEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    MinusCircleOutlined,
    ExclamationCircleOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    Space,
    Card,
    Table,
    Popconfirm,
    AdvisorOverrideEdit,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idAdv : "",
        name : "",
        title : "",
        licenceNo : "",
        joinDate : "",
        createDate : "",
        status : "",
        mpfNo : "",
        contribution : "",
        trNo : "",
        trFullName : "",
        advisorOverrideList: []
      },
      fileList: {
      },
      selectList: {
        statusList: [{value: 0,label: 'Off'},{value:1,label: 'On'}],
        advisorList: [],
      },
      validateRules: {
        idAdv: [
          {
            required: true,
            message: this.$t("advisor.validate.required.id_adv"),
            trigger: "change",
            type: "number",
          },
        ],
        name: [
          {
            required: true,
            message: this.$t("advisor.validate.required.name"),
            trigger: "change",
          },
        ],
        title: [
          {
            required: true,
            message: this.$t("advisor.validate.required.title"),
            trigger: "change",
          },
        ],
        licenceNo: [
          {
            required: true,
            message: this.$t("advisor.validate.required.licence_no"),
            trigger: "change",
          },
        ],
        joinDate: [
          {
            required: true,
            message: this.$t("advisor.validate.required.join_date"),
            trigger: "change",
          },
        ],
        createDate: [
          {
            required: true,
            message: this.$t("advisor.validate.required.create_date"),
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: this.$t("advisor.validate.required.status"),
            trigger: "change",
            type: "number",
          },
        ],
        mpfNo: [
          {
            required: true,
            message: this.$t("advisor.validate.required.mpf_no"),
            trigger: "change",
          },
        ],
        contribution: [
          {
            required: true,
            message: this.$t("advisor.validate.required.contribution"),
            trigger: "change",
            type: "number",
          },
        ],
	trNo: [
          {
            required: true,
            message: this.$t("advisor.validate.required.tr_no"),
            trigger: "change",
          },
        ],
        trFullName: [
          {
            required: true,
            message: this.$t("advisor.validate.required.tr_full_name"),
            trigger: "change",
          },
        ],
      },
      advisorOverrideColumns: [
        { title: this.$t("advisor.validate.required.head_id_adv"), dataIndex: "headIdAdv", key: "headIdAdv", customRender: ({text}) =>{
          console.log('text:' + text)
          if(typeof(text)!="undefined" && text != null)
            return this.selectList.advisorList.find(item=>item.idAdv===text).name
          }
        },
        { title: this.$t("advisorOverride.table.override_rate"), dataIndex: "overrideRate", key: "overrideRate", customRender: ({text}) =>{
            return text + '%'
         },
        },
        {
          title: this.$t("public.table.action"),
          dataIndex: 'operation',
          slots: {
            customRender: 'operation',
          },
        },
      ],
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  watch: {
      "visible": {
          deep: true,
          handler: function (newValue, oldValue) {
              if (!newValue) {
                  this.$emit("updateList");
              }
          }
      }
  },
  mounted(){
    this.getAdvisorList();
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.getAdvisorList();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.getAdvisorList();
      this.visible = true;
    },
    submit() {
      this.$refs["advisorForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new AdvisorAdd();
              this.form.advisorOverrideListStr = JSON.stringify(this.form.advisorOverrideList)
              break;
            case "edit":
              console.log("edit");
              api = new AdvisorEdit();
              break;
            default:
              return false;
          }
          
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList",response.data.data);
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    addAdvisorOverride() {
      this.$refs.refAdvisorOverrideEdit.addShow(this.form.idAdv);
    },
    editAdvisorOverride(obj) {
      if (this.actionType === 'edit') {
        obj.idAdv = this.form.idAdv
      }
      this.$refs.refAdvisorOverrideEdit.editShow(obj);
    },
    removeAdvisorOverride(index) {
      if (this.actionType === 'add') {
        this.form.advisorOverrideList.splice(index, 1);
      } else {
        let advisorOverride = this.form.advisorOverrideList[index]
        const api = new AdvisorOverrideDelete();
        api.post({ idAdvOve: advisorOverride.idAdvOve }).then((response) => {
          if (0 === parseInt(response.data.code)) {
            this.form.advisorOverrideList.splice(index, 1);
            message.success(response.data.msg);
          } else {
            message.error(response.data.msg);
          }
        });
      }
    },
    updateListAdvisorOverride(obj) {
      if (this.actionType === 'add') {
        this.form.advisorOverrideList.push(obj);
      } else {
        let index = this.form.advisorOverrideList.findIndex(item => {
          return item.idAdvOve === obj.idAdvOve
        })
        //û���ҵ�index�������༭ʱ����
        if (index === -1) {
          this.form.advisorOverrideList.push(obj);
        } else {
          this.form.advisorOverrideList[index] = obj
        }
      }
    },
    getAdvisorList() {
      const api = new AdvisorListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.advisorList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    // reset data
    _resetData() {
      this.form = {
        idAdv : "",
        name : "",
        title : "",
        licenceNo : "",
        joinDate : "",
        createDate : "",
        status : "",
        mpfNo : "",
        contribution : "",
        trNo : "",
        trFullName : "",
        advisorOverrideList: []
      };
    },
  },
};
</script>

<style lang="less">
</style>