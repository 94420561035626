<template>
  <Row id="Currency">
    <Col :span="24" class="title">
      <TypographyTitle>{{$t('nav.name.CurrencyManage')}}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24" >
          <!-- <Form :model="searchForm">
            <Row :gutter="30">
              <Col :xs="24" :sm="8">
                <FormItem :label="$t('member.name.search_user_name')" name="search">
                  <Input :placeholder="$t('member.name.search_user_name')" v-model:value="searchForm.search"></Input>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="8">
                <FormItem :label="$t('public.field.status')">
                  <Select v-model:value="searchForm.status" :placeholder="$t('public.placeholder.please_select')">
                    <SelectOption :value="0">{{ $t('public.placeholder.please_select') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('public.status.1') }}</SelectOption>
                    <SelectOption :value="2">{{ $t('public.status.2') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="8" class="text-right">
                <Button type="primary" @click="search_submit">{{$t('public.name.search')}}</Button>&nbsp;
                <Button @click="search_reset">{{$t('public.name.reset')}}</Button>
              </Col>
            </Row>
          </Form> -->
        </Col>
        <Col :span="24">
        <Table :columns="columns" :dataSource="list" :scroll="{ x: true }" rowKey="idCur" :loading="loading" :pagination="tablePagination" @change="chang_page">
            <template #actionTitle>
              <span>
                {{$t("public.table.action")}}
                <Button v-if="checkPermission('CurrencyManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined />
                </Button>
              </span>
            </template>
            <template #action="{text}">
              <div>
                <div class="pc-display">
                  <Button type="default" size="small" @click="detail(text)">{{$t("public.name.detail")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('CurrencyManage.Edit')" type="primary" size="small" @click="edit(text)">{{$t("public.name.edit")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('CurrencyManage.Delete')" type="primary" danger size="small" @click="del(text.idCur)">{{$t("public.name.del")}}</Button>
                </div>
                <Dropdown :trigger="['click']" class="wap-display">
                  <template #overlay>
                    <Menu>
                      <MenuItem @click="del(text.id)">{{$t('public.name.del')}}</MenuItem>
                    </Menu>
                  </template>
                  <Button size="small">
                    {{$t("public.name.more")}}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </template>
          </Table>
        </Col>
      </Row>
    </Col>
    <CurrencyDetail ref="refCurrencyDetail"></CurrencyDetail>
    <CurrencyEdit ref="refCurrencyEdit" @updateList="get_list"></CurrencyEdit>
  </Row>
</template>

<script>
import { createVNode } from "vue";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, Form, Input, Select, message } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { CurrencyList } from "@/api/CurrencyList";
import { CurrencyDelete } from "@/api/CurrencyDelete"
import CurrencyDetail from "@/components/Home/CurrencyDetail";
import CurrencyEdit from "@/components/Home/CurrencyEdit";

export default {
  name: "CurrencyManage",
  components: {
    Row,
    Col,
    Table,
    Form,
    FormItem: Form.Item,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Input,
    Select,
    SelectOption: Select.Option,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    Menu,
    MenuItem: Menu.Item,
    CurrencyDetail,
    CurrencyEdit,
  },
  data() {
    return {
      searchForm: {
      },
      tablePagination: {
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      list: [],
      columns: [
          { title: this.$t("currency.table.id_cur"), fixed: "left", dataIndex: "idCur", key: "idCur" },
          { title: this.$t("currency.table.currency_name"), dataIndex: "currencyName", key: "currencyName" },
          { title: this.$t("currency.table.exchange_rate"), dataIndex: "exchangeRate", key: "exchangeRate" },
        { key: "action", fixed: "right", slots: { title: "actionTitle", customRender: "action" } },
      ],
      selectList:{
      }
    };
  },
  methods: {
    chang_page(pagination) {
      this.tablePagination = pagination;
      this.get_list();
    },
    get_list() {
      this.loading = true;
      const api = new CurrencyList();
      const getData = {
        page: this.tablePagination.current,
        limit: this.tablePagination.pageSize,
      }
      // if (this.searchForm.search != "") getData.search = this.searchForm.search
      // if (this.searchForm.status != 0) getData.status = this.searchForm.status
      // if (this.searchForm.order != "") getData.order = this.searchForm.order
      api
        .get(getData)
        .then((response) => {
          this.loading = false;
          if (0 === parseInt(response.data.code)) {
            const dataTmp = response.data.data;
            this.tablePagination.current = parseInt(dataTmp.current);
            this.tablePagination.pageSize = parseInt(dataTmp.pageSize);
            this.tablePagination.total = parseInt(dataTmp.total);
            this.list = dataTmp.data;
          } else {
            this.list = [];
          }
        });
    },
    add() {
      this.$refs.refCurrencyEdit.addShow();
    },
    detail(obj) {
      this.$refs.refCurrencyDetail.detailShow(obj);
    },
    edit(obj) {
      this.$refs.refCurrencyEdit.editShow(obj);
    },
    del(idCur) {
      Modal.confirm({
        title: this.$t("public.name.are_you_sure_delete") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          const api = new CurrencyDelete();
          api.post({ idCur: idCur }).then((response) => {
            if (0 === parseInt(response.data.code)) {
              this.get_list();
              message.success(response.data.msg);
            } else {
              message.error(response.data.msg);
            }
          });
        },
      });
    },
    search_submit() {
      this.get_list()
    },
    search_reset() {
      this.searchForm = {
        // search: "",
        // status: 0,
        // order: "",
      }
      this.get_list()
    }
  },
  mounted() {
    this.get_list();
  },
}
</script>

<style lang="less">
@import url("~@/assets/less/home/currency.less");
</style>