<template>
  <Modal id="EmployeeDetail" width="60%" :visible="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('employee.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('employee.field.id_emp')">{{data.idEmp}}</DescriptionsItem>
      <DescriptionsItem :label="$t('employee.field.id_rol')">{{data.roleName}}</DescriptionsItem>
      <DescriptionsItem :label="$t('employee.field.account')">{{data.account}}</DescriptionsItem>
      <DescriptionsItem :label="$t('employee.field.salt')">{{data.salt}}</DescriptionsItem>
      <DescriptionsItem :label="$t('employee.field.last_login_time')">{{data.lastLoginTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('employee.field.register_time')">{{data.registerTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('employee.field.login_failure_counter')">{{data.loginFailureCounter}}</DescriptionsItem>
      <DescriptionsItem :label="$t('employee.field.email')">{{data.email}}</DescriptionsItem>
      <DescriptionsItem :label="$t('employee.field.remark')">{{data.remark}}</DescriptionsItem>
      <DescriptionsItem :label="$t('employee.field.status')">{{selectList.statusList.find(item=>item.value===data.status).label}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>

<script>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "EmployeeDetail",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      selectList:{
        statusList: [{value: 0,label: 'Off'},{value:1,label: 'On'}],
      },
      data: {
      },
      visible: false,
    };
  },
  computed: {
  },
  methods: {
    async detailShow(obj) {
      this.data = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
  },
}
</script>

<style lang="less">
</style>