<template>
  <Modal
    id="GiEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="giForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                  <Col span="10">
                    <FormItem :label="$t('gi.field.id_upl_rec')" name="idUplRec">
                                            <Input :placeholder="$t('gi.validate.required.id_upl_rec')"
                                                   v-model:value="form.idUplRec">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.date_time')" name="dateTime">
                      <DatePicker valueFormat="YYYY-MM-DD" v-model:value="form.dateTime" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.tr')" name="tr">
                                            <Input :placeholder="$t('gi.validate.required.tr')"
                                                   v-model:value="form.tr">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.insurer')" name="insurer">
                                            <Input :placeholder="$t('gi.validate.required.insurer')"
                                                   v-model:value="form.insurer">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.policy_no')" name="policyNo">
                                            <Input :placeholder="$t('gi.validate.required.policy_no')"
                                                   v-model:value="form.policyNo">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.risk')" name="risk">
                                            <Input :placeholder="$t('gi.validate.required.risk')"
                                                   v-model:value="form.risk">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.insured')" name="insured">
                                            <Input :placeholder="$t('gi.validate.required.insured')"
                                                   v-model:value="form.insured">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.period_from')" name="periodFrom">
                      <DatePicker valueFormat="YYYY-MM-DD" v-model:value="form.periodFrom" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.period_to')" name="periodTo">
                      <DatePicker valueFormat="YYYY-MM-DD" v-model:value="form.periodTo" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.gross_premium')" name="grossPremium">
                                            <InputNumber
                                                    v-model:value="form.grossPremium"
                                                    :placeholder="$t('gi.validate.required.gross_premium')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.commission')" name="commission">
                                            <InputNumber
                                                    v-model:value="form.commission"
                                                    :placeholder="$t('gi.validate.required.commission')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.net_amount')" name="netAmount">
                                            <InputNumber
                                                    v-model:value="form.netAmount"
                                                    :placeholder="$t('gi.validate.required.net_amount')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.payment')" name="payment">
                                            <InputNumber
                                                    v-model:value="form.payment"
                                                    :placeholder="$t('gi.validate.required.payment')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.balance')" name="balance">
                                            <InputNumber
                                                    v-model:value="form.balance"
                                                    :placeholder="$t('gi.validate.required.balance')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.tr_rate')" name="trRate">
                                            <InputNumber
                                                    v-model:value="form.trRate"
                                                    :placeholder="$t('gi.validate.required.tr_rate')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.eae_received')" name="eaeReceived">
                                            <InputNumber
                                                    v-model:value="form.eaeReceived"
                                                    :placeholder="$t('gi.validate.required.eae_received')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.eae_rate')" name="eaeRate">
                                            <InputNumber
                                                    v-model:value="form.eaeRate"
                                                    :placeholder="$t('gi.validate.required.eae_rate')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.com')" name="com">
                                            <InputNumber
                                                    v-model:value="form.com"
                                                    :placeholder="$t('gi.validate.required.com')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.levy')" name="levy">
                                            <InputNumber
                                                    v-model:value="form.levy"
                                                    :placeholder="$t('gi.validate.required.levy')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.bank')" name="bank">
                                            <Input :placeholder="$t('gi.validate.required.bank')"
                                                   v-model:value="form.bank">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.cq_no')" name="cqNo">
                                            <Input :placeholder="$t('gi.validate.required.cq_no')"
                                                   v-model:value="form.cqNo">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.amount')" name="amount">
                                            <InputNumber
                                                    v-model:value="form.amount"
                                                    :placeholder="$t('gi.validate.required.amount')"
                                                    :step="0.01"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.amount_date')" name="amountDate">
                      <DatePicker valueFormat="YYYY-MM-DD" v-model:value="form.amountDate" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('gi.field.com_rev_date')" name="comRevDate">
                      <DatePicker valueFormat="YYYY-MM-DD" v-model:value="form.comRevDate" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                  </Col>
                <!--判断是否有子表，如果有则直接加入子表管理逻辑-->
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Space,
  Card,
  Table,
  Popconfirm,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { GiAdd } from "@/api/GiAdd";
import { GiEdit } from "@/api/GiEdit";

export default {
  name: "GiEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    MinusCircleOutlined,
    ExclamationCircleOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    Space,
    Card,
    Table,
    Popconfirm,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idGi : "",
        idUplRec : "",
        dateTime : "",
        tr : "",
        insurer : "",
        policyNo : "",
        risk : "",
        insured : "",
        periodFrom : "",
        periodTo : "",
        grossPremium : "",
        commission : "",
        netAmount : "",
        payment : "",
        balance : "",
        trRate : "",
        eaeReceived : "",
        eaeRate : "",
        com : "",
        levy : "",
        bank : "",
        cqNo : "",
        amount : "",
        amountDate : "",
        comRevDate : "",
      },
      fileList: {
      },
      selectList: {
      },
      validateRules: {
        idGi: [
          {
            required: true,
            message: this.$t("gi.validate.required.id_gi"),
            trigger: "change",
            type: "number",
          },
        ],
        idUplRec: [
          {
            required: true,
            message: this.$t("gi.validate.required.id_upl_rec"),
            trigger: "change",
            type: "number",
          },
        ],
        dateTime: [
          {
            required: true,
            message: this.$t("gi.validate.required.date_time"),
            trigger: "change",
          },
        ],
        tr: [
          {
            required: true,
            message: this.$t("gi.validate.required.tr"),
            trigger: "change",
          },
        ],
        insurer: [
          {
            required: true,
            message: this.$t("gi.validate.required.insurer"),
            trigger: "change",
          },
        ],
        policyNo: [
          {
            required: true,
            message: this.$t("gi.validate.required.policy_no"),
            trigger: "change",
          },
        ],
        risk: [
          {
            required: true,
            message: this.$t("gi.validate.required.risk"),
            trigger: "change",
          },
        ],
        insured: [
          {
            required: true,
            message: this.$t("gi.validate.required.insured"),
            trigger: "change",
          },
        ],
        periodFrom: [
          {
            required: true,
            message: this.$t("gi.validate.required.period_from"),
            trigger: "change",
          },
        ],
        periodTo: [
          {
            required: true,
            message: this.$t("gi.validate.required.period_to"),
            trigger: "change",
          },
        ],
        grossPremium: [
          {
            required: true,
            message: this.$t("gi.validate.required.gross_premium"),
            trigger: "change",
            type: "number",
          },
        ],
        commission: [
          {
            required: true,
            message: this.$t("gi.validate.required.commission"),
            trigger: "change",
            type: "number",
          },
        ],
        netAmount: [
          {
            required: true,
            message: this.$t("gi.validate.required.net_amount"),
            trigger: "change",
            type: "number",
          },
        ],
        payment: [
          {
            required: true,
            message: this.$t("gi.validate.required.payment"),
            trigger: "change",
            type: "number",
          },
        ],
        balance: [
          {
            required: true,
            message: this.$t("gi.validate.required.balance"),
            trigger: "change",
            type: "number",
          },
        ],
        trRate: [
          {
            required: true,
            message: this.$t("gi.validate.required.tr_rate"),
            trigger: "change",
            type: "number",
          },
        ],
        eaeReceived: [
          {
            required: true,
            message: this.$t("gi.validate.required.eae_received"),
            trigger: "change",
            type: "number",
          },
        ],
        eaeRate: [
          {
            required: true,
            message: this.$t("gi.validate.required.eae_rate"),
            trigger: "change",
            type: "number",
          },
        ],
        com: [
          {
            required: true,
            message: this.$t("gi.validate.required.com"),
            trigger: "change",
            type: "number",
          },
        ],
        levy: [
          {
            required: true,
            message: this.$t("gi.validate.required.levy"),
            trigger: "change",
            type: "number",
          },
        ],
        bank: [
          {
            required: true,
            message: this.$t("gi.validate.required.bank"),
            trigger: "change",
          },
        ],
        cqNo: [
          {
            required: true,
            message: this.$t("gi.validate.required.cq_no"),
            trigger: "change",
          },
        ],
        amount: [
          {
            required: true,
            message: this.$t("gi.validate.required.amount"),
            trigger: "change",
            type: "number",
          },
        ],
        amountDate: [
          {
            required: true,
            message: this.$t("gi.validate.required.amount_date"),
            trigger: "change",
          },
        ],
        comRevDate: [
          {
            required: true,
            message: this.$t("gi.validate.required.com_rev_date"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  watch: {
      "visible": {
          deep: true,
          handler: function (newValue, oldValue) {
              if (!newValue) {
                  this.$emit("updateList");
              }
          }
      }
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
    submit() {
      this.$refs["giForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new GiAdd();
              break;
            case "edit":
              console.log("edit");
              api = new GiEdit();
              break;
            default:
              return false;
          }
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList",response.data.data);
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    // reset data
    _resetData() {
      this.form = {
        idGi : "",
        idUplRec : "",
        dateTime : "",
        tr : "",
        insurer : "",
        policyNo : "",
        risk : "",
        insured : "",
        periodFrom : "",
        periodTo : "",
        grossPremium : "",
        commission : "",
        netAmount : "",
        payment : "",
        balance : "",
        trRate : "",
        eaeReceived : "",
        eaeRate : "",
        com : "",
        levy : "",
        bank : "",
        cqNo : "",
        amount : "",
        amountDate : "",
        comRevDate : "",
      };
    },
  },
};
</script>

<style lang="less">
</style>