<template>
  <Modal id="GiDetail" width="60%" :visible="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('gi.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('gi.field.id_gi')">{{data.idGi}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.id_upl_rec')">{{data.idUplRec}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.date_time')">{{data.dateTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.tr')">{{data.tr}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.insurer')">{{data.insurer}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.policy_no')">{{data.policyNo}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.risk')">{{data.risk}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.insured')">{{data.insured}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.period_from')">{{data.periodFrom}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.period_to')">{{data.periodTo}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.gross_premium')">{{data.grossPremium}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.commission')">{{data.commission}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.net_amount')">{{data.netAmount}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.payment')">{{data.payment}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.balance')">{{data.balance}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.tr_rate')">{{data.trRate}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.eae_received')">{{data.eaeReceived}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.eae_rate')">{{data.eaeRate}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.com')">{{data.com}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.levy')">{{data.levy}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.bank')">{{data.bank}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.cq_no')">{{data.cqNo}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.amount')">{{data.amount}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.amount_date')">{{data.amountDate}}</DescriptionsItem>
      <DescriptionsItem :label="$t('gi.field.com_rev_date')">{{data.comRevDate}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>

<script>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "GiDetail",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      selectList:{
      },
      data: {
      },
      visible: false,
    };
  },
  computed: {
  },
  methods: {
    async detailShow(obj) {
      this.data = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
  },
}
</script>

<style lang="less">
</style>