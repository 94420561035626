<template>
  <Modal id="PolicyDetail" width="60%" :visible="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('policy.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('policy.field.id_pol')">{{data.idPol}}</DescriptionsItem>
      <DescriptionsItem :label="$t('policy.field.id_pay_mod')">{{data.payModePayMode}}</DescriptionsItem>
      <DescriptionsItem :label="$t('policy.field.id_adv')">{{data.advisorName}}</DescriptionsItem>
      <DescriptionsItem :label="$t('policy.field.id_ser_pro')">{{data.serviceProviderCompanyName}}</DescriptionsItem>
      <DescriptionsItem :label="$t('policy.field.submit_date')">{{data.submitDate}}</DescriptionsItem>
      <DescriptionsItem :label="$t('policy.field.insured_name')">{{data.insuredName}}</DescriptionsItem>
      <DescriptionsItem :label="$t('policy.field.owner_name')">{{data.ownerName}}</DescriptionsItem>
      <DescriptionsItem :label="$t('policy.field.payment_amount')">{{data.paymentAmount}}</DescriptionsItem>
      <DescriptionsItem :label="$t('policy.field.create_date')">{{data.createDate}}</DescriptionsItem>
      <DescriptionsItem :label="$t('policy.field.start_period')">{{data.startPeriod}}</DescriptionsItem>
      <DescriptionsItem :label="$t('policy.field.policy_no')">{{data.policyNo}}</DescriptionsItem>
      <DescriptionsItem :label="$t('policy.field.status')">{{selectList.statusList.find(item=>item.value===data.status).label}}</DescriptionsItem>
      <DescriptionsItem :label="$t('policy.field.approval_date')">{{data.approvalDate}}</DescriptionsItem>
      <DescriptionsItem :label="$t('policy.field.policy_date')">{{data.policyDate}}</DescriptionsItem>
      <DescriptionsItem :label="$t('policy.field.age')">{{data.age}}</DescriptionsItem>
      <DescriptionsItem :label="$t('policy.field.afyp')">{{data.afyp}}</DescriptionsItem>
      <DescriptionsItem :label="$t('currency.field.currency_name')">{{data.currencyCurrencyName}}</DescriptionsItem>
      <DescriptionsItem :label="$t('policy.field.real_currency')">{{data.realCurrency}}</DescriptionsItem>
      <DescriptionsItem :label="$t('policy.field.afyp_rate')">{{data.afypRate}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>

<script>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "PolicyDetail",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      selectList:{
        statusList: [{value: 0,label: 'Off'},{value:1,label: 'On'}],
      },
      data: {
      },
      visible: false,
    };
  },
  computed: {
  },
  methods: {
    async detailShow(obj) {
      this.data = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
  },
}
</script>

<style lang="less">
</style>