<template>
  <Modal id="PaymentEdit" width="80%" :visible="visible" @cancel="visible = !visible" @ok="submit" :title="title">
    <Row align="middle" justify="center">
      <Col span="18" class="content">
      <Row>
        <Col span="24">
        <Form ref="paymentForm" :model="form" layout="vertical" :rules="validateRules">
          <Row justify="space-around">
            <Col span="10">
            <FormItem :label="$t('policy.field.policy_no')" name="idPol">
              <!--关联表的下拉选择-->
              <Select :disabled="actionType === 'edit'" v-model:value="form.idPol"
                :placeholder="$t('policy.validate.required.policy_no')" :dropdownStyle="{ zIndex: 9999999950 }" show-search :filter-option="filterOption"
                @change="changePolicy">
                <SelectOption :key="sk" v-for="(item,sk) in selectList['policyList']" :title="item['policyNo']" :value="item['idPol']">{{
                item['policyNo'] }}</SelectOption>
              </Select>
            </FormItem>
            </Col>
            <Col span="10" v-if="currentPolicy">
            <FormItem :label="$t('policy.field.id_adv')" name="advisorName">
              <Input disabled :placeholder="$t('policy.validate.required.id_adv')"
                v-model:value="currentPolicy.advisorName">
              </Input>
            </FormItem>
            </Col>
            <Col span="10" v-if="currentPolicy">
            <FormItem :label="$t('policy.field.insured_name')" name="insuredName">
              <Input disabled :placeholder="$t('policy.validate.required.insured_name')"
                v-model:value="currentPolicy.insuredName">
              </Input>
            </FormItem>
            </Col>
            <Col span="10" v-if="currentPolicy">
            <FormItem :label="$t('policy.field.currency')" name="currency">
              <Select disabled v-model:value="currentPolicy.idCur"
                :placeholder="$t('policy.validate.required.currency')" :dropdownStyle="{ zIndex: 9999999950 }">
                <SelectOption :key="sk" v-for="(item,sk) in selectList['currencyList']" :value="item['idCur']">{{
                item['currencyName'] }}</SelectOption>
              </Select>
            </FormItem>
            </Col>
	    <Col span="10">
                    <FormItem :label="$t('payment.field.real_exchange_rate')" name="realExchangeRate">
                                            <InputNumber
                                                    v-model:value="form.realExchangeRate"
                                                    :placeholder="$t('payment.validate.required.real_exchange_rate')"
                                                    :step="0.01"
                                                    @change="changeCommission"
                                            />
                  </FormItem>
                  </Col>
            <Col span="10" v-if="currentPolicy">
            <FormItem :label="$t('policy.field.policy_date')" name="policyDate">
              <DatePicker disabled valueFormat="YYYY-MM-DD" v-model:value="currentPolicy.policyDate"
                :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
            </FormItem>
            </Col>
            <Col span="10">
            <FormItem :label="$t('payment.field.from_month')" name="fromMonth">
              <Input disabled :placeholder="$t('payment.validate.required.from_month')" v-model:value="form.fromMonth">
              </Input>
            </FormItem>
            </Col>
            <Col span="10">
            <FormItem :label="$t('payment.field.to_month')" name="toMonth">
              <Input disabled :placeholder="$t('payment.validate.required.to_month')" v-model:value="form.toMonth">
              </Input>
            </FormItem>
            </Col>
            <Col span="10" v-if="currentPolicy">
              <FormItem :label="$t('policy.field.id_pay_mod')" name="idPayMod">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="form.idPayMod"
                              :placeholder="$t('policy.validate.required.id_pay_mod')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                              @change="changePayMode"
                      >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['payModeList']" :value="item['idPayMod']">{{ item['payMode'] }}</SelectOption>
                      </Select>
                  </FormItem>
            </Col>
            <!-- <Col span="10" v-if="currentPolicy">
                    <FormItem :label="$t('payment.field.current_period')">
                      <InputNumber
                              disabled
                              v-model:value="lastPaymentPeriod"
                      />
                  </FormItem>
                  </Col> -->
            <Col span="10">
            <FormItem :label="$t('payment.field.period')" name="period">
              <InputNumber v-model:value="form.period" :placeholder="$t('payment.validate.required.period')" :min="1"
                @change="changePeriod" :step="periodStep"/>
            </FormItem>
            </Col>
            <Col span="10">
            <FormItem :label="$t('payment.field.bank_charge')" name="bankCharge">
              <InputNumber v-model:value="form.bankCharge" :placeholder="$t('payment.validate.required.bank_charge')"
                :step="0.01" :min="0" :precision="2"/>
            </FormItem>
            </Col>
	    <Col span="10">
                    <FormItem :label="$t('payment.field.afyp_rate')" name="afypRate">
                                            <InputNumber
                                                    v-model:value="form.afypRate"
                                                    :placeholder="$t('payment.validate.required.afyp_rate')"
                                                    :step="0.01"
                                                    :min="0"
                                                    @change="changeCommission"
      :max="100"
      :formatter="value => `${value}%`"
      :parser="value => value.replace('%', '')"
                                            />
                  </FormItem>
                  </Col>
            <Col span="10">
            <FormItem :label="$t('payment.field.afyp')" name="afyp">
              <InputNumber v-model:value="form.afyp" :placeholder="$t('payment.validate.required.afyp')" :precision="2" :step="0.01" />
            </FormItem>
            </Col>
            <!-- <Col span="10">
            <FormItem :label="$t('payment.field.total_commission')" name="totalCommission">
              <InputNumber disabled v-model:value="form.totalCommission"
                :placeholder="$t('payment.validate.required.total_commission')" :step="0.01" />
            </FormItem>
            </Col> -->
            <Col span="10">
            <FormItem :label="$t('payment.field.payment_date')" name="paymentDate">
              <DatePicker valueFormat="YYYY-MM-DD" v-model:value="form.paymentDate"
                :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
            </FormItem>
            </Col>
            <Col span="10">
            <FormItem :label="$t('payment.field.fyc')" name="fyc">
              <InputNumber v-model:value="form.fyc" :placeholder="$t('payment.validate.required.fyc')" :step="0.01" :precision="2"/>
            </FormItem>
            </Col>
	    <Col span="10">
                    <FormItem :label="$t('payment.field.renewal_commission')" name="renewalCommission">
                                            <InputNumber
                                                    v-model:value="form.renewalCommission"
                                                    :placeholder="$t('payment.validate.required.renewal_commission')"
                                                    :step="0.01"
                                                    :precision="2"
                                            />
                  </FormItem>
      		</Col>
            <Col span="10">
            <FormItem :label="$t('payment.field.ifyp')" name="ifyp">
              <InputNumber v-model:value="form.ifyp" :placeholder="$t('payment.validate.required.ifyp')" :step="0.01" />
              <span v-if="currentPolicy && selectList.currencyList.length > 0">&nbsp;&nbsp;{{selectList.currencyList.find(item => item.idCur === currentPolicy.idCur).currencyName}}</span>
            </FormItem>
            </Col>
            <!-- <Col span="10">
            <FormItem :label="$t('payment.field.others')" name="others">
              <InputNumber v-model:value="form.others" :placeholder="$t('payment.validate.required.others')"
                :step="0.01" />
            </FormItem>
            </Col> -->
	    <Col span="10">
                    <FormItem :label="$t('payment.field.extra_rate')" name="extraRate">
                                            <InputNumber
                                                    v-model:value="form.extraRate"
                                                    :placeholder="$t('payment.validate.required.extra_rate')"
                                                    :min="0"
                                                    :step="0.01"
      :max="100"
      :formatter="value => `${value}%`"
      :parser="value => value.replace('%', '')"
                                                    @change="changeCommission"
                                            />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('payment.field.extra_commission')" name="extraCommission">
                                            <InputNumber
                                                    v-model:value="form.extraCommission"
                                                    :placeholder="$t('payment.validate.required.extra_commission')"
                                                    :step="0.01"
                                                    :precision="2"
                                            />
                                            
                                            <!-- <span v-if="currentPolicy && selectList.currencyList.length > 0">&nbsp;&nbsp;{{selectList.currencyList.find(item => item.idCur === currentPolicy.idCur).currencyName}}</span> -->
                  </FormItem>
                  </Col>
                  <!--<Col span="10"> -->
                    <!-- <FormItem :label="$t('payment.field.extra_overide')" name="extraOveride">
                                            <Input :placeholder="$t('payment.validate.required.extra_overide')"
                                                   v-model:value="form.extraOveride">
                                            </Input>
                  </FormItem> -->
                  
                    <!-- <FormItem :label="$t('payment.field.extra_overide')" name="extraOveride">
                 
                      <Select
                              v-model:value="form.extraOveride"
                              :placeholder="$t('payment.validate.required.extra_overide')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                          <SelectOption :value="null"></SelectOption>
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['advisorList']" :value="item['idAdv']">{{ item['name'] }}</SelectOption>
                      </Select>
                  </FormItem>
                 
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('payment.field.extra_bonus')" name="extraBonus">
                                            <InputNumber
                                                    v-model:value="form.extraBonus"
                                                    :placeholder="$t('payment.validate.required.extra_bonus')"
                                                    :step="0.01"
                                                    :precision="2"
                                            />
                  </FormItem>
                  </Col> -->
                  <Col span="10">
            <FormItem :label="$t('payment.field.total_fyc')" name="totalFyc">
              <InputNumber v-model:value="form.totalFyc" :placeholder="$t('payment.validate.required.total_fyc')" :step="0.01" :precision="2"/>
            </FormItem>
            </Col>
		  <Col span="10">
                    <FormItem :label="$t('payment.field.remark')" name="remark">
                      <Textarea
                              v-model:value="form.remark"
                              :placeholder="$t('payment.validate.required.remark')"
                              :auto-size="{ minRows: 5, maxRows: 10 }"
                              showCount
                      />
                  </FormItem>
                  </Col>
            <!-- <Col span="10">
                    <FormItem :label="$t('payment.field.submitted_afyp')" name="submittedAfyp">
                      <InputNumber
                              v-model:value="form.submittedAfyp"
                              :placeholder="$t('payment.validate.required.submitted_afyp')"
                              :step="0.01"
                      />
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('payment.field.adjusted_afyp')" name="adjustedAfyp">
                      <InputNumber
                              v-model:value="form.adjustedAfyp"
                              :placeholder="$t('payment.validate.required.adjusted_afyp')"
                              :step="0.01"
                      />
                  </FormItem>
                  </Col> -->
            <!-- <Col span="10">
                    <FormItem :label="$t('payment.field.is_renewal')" name="isRenewal">
                      <Input :placeholder="$t('payment.validate.required.is_renewal')"
                             v-model:value="form.isRenewal">
                      </Input>
                  </FormItem>
	
                  </Col> -->
            <!-- <Col span="10">
                    <Space
                      v-for="(commission, index) in form.commissionList"
                      :key="commission.idPolPla"
                      style="display: flex; margin-bottom: 8px"
                      align="baseline"
                    >
                      <FormItem
                        :name="['commissionList', index, 'idPla']"
                        :rules="{
                          required: true,
                          message: $t('policyPlan.validate.required.id_pla')
                        }"
                      >
                        <Select
                              disabled
                              v-model:value="commission.idPla"
                              :placeholder="$t('policyPlan.validate.required.id_pla')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                               @change="changePlan($event,index)"
                      >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['planList']" :value="item['idPla']">{{ item['planName'] }}</SelectOption>
                      </Select>
                      </FormItem>
                      <FormItem
                        :name="['commissionList', index, 'amount']"
                        :rules="{
                          required: true,
                          message:  $t('policyPlan.validate.required.amount'),
                        }"
                      >
                        <InputNumber v-model:value="commission.amount" :min="0" placeholder="Amount" @change="changeCommission" />
                      </FormItem>
                      <FormItem
                        :name="['commissionList', index, 'commissionRate']"
                        :rules="{
                          required: true,
                          message:  $t('policyPlan.validate.required.commissionRate'),
                        }"
                      >
                        <InputNumber v-model:value="commission.commissionRate" :min="0" placeholder="Commission Rate" @change="changeCommission" />
                      </FormItem>
                      <FormItem
                        :name="['commissionList', index, 'commission']"
                        :rules="{
                          required: true,
                          message:  $t('policyPlan.validate.required.commission'),
                        }"
                      >
                        <InputNumber disabled v-model:value="commission.commission" :min="0" placeholder="Commission" />
                      </FormItem>
                    </Space>
                  </Col> -->

            <Col span="24">
            <Card size="small" :title="$t('commission.name.title')">
              <Table size="small" :columns="commissionColumns" :data-source="form.commissionList" bordered
                :pagination="false">
                <template #idPla="{ record, index }">
                  <FormItem :name="['commissionList', index, 'idPla']" :rules="{
                    required: true,
                    message: $t('commission.validate.required.id_pla')
                  }" style="margin-bottom: 0px;">
                    <Select disabled v-model:value="record.idPla"
                      :placeholder="$t('commission.validate.required.id_pla')" @change="changePlan($event,index)">
                      <SelectOption :key="sk" v-for="(item,sk) in selectList['planList']" :value="item['idPla']">{{
                      item['planNameHk'] }}</SelectOption>
                    </Select>
                  </FormItem>
                </template>
                <template #amount="{ record, index }">
                  <FormItem :name="['commissionList', index, 'amount']" :rules="{
                    required: true,
                    message: $t('commission.validate.required.amount')
                  }" style="margin-bottom: 0px;">
                    <InputNumber v-model:value="record.amount" placeholder="Amount" :step="0.01" :precision="2"
                      @change="changeCommission" />
                      <span v-if="currentPolicy && selectList.currencyList.length > 0">&nbsp;&nbsp;{{selectList.currencyList.find(item => item.idCur === currentPolicy.idCur).currencyName}}</span>
                  </FormItem>
                </template>
                <template #commissionRate="{ record, index }">
                  <FormItem :name="['commissionList', index, 'commissionRate']" :rules="{
                    required: true,
                    message: $t('commission.validate.required.commission_rate')
                  }" style="margin-bottom: 0px;">
                    <InputNumber v-model:value="record.commissionRate" :min="0"
      :max="100"
      :formatter="value => `${value}%`"
      :parser="value => value.replace('%', '')" placeholder="Amount" :step="0.01"
                      @change="changeCommission" />
                  </FormItem>
                </template>
                <template #commission="{ record, index }">
                  <FormItem :name="['commissionList', index, 'commission']" :rules="{
                    required: true,
                    message: $t('commission.validate.required.commission')
                  }" style="margin-bottom: 0px;">
                    <InputNumber disabled v-model:value="record.commission" :min="0" placeholder="Commission" :step="0.01" :precision="2"/>
                    <span v-if="currentPolicy && selectList.currencyList.length > 0">&nbsp;&nbsp;{{selectList.currencyList.find(item => item.idCur === currentPolicy.idCur).currencyName}}</span>
                  </FormItem>
                </template>
                <template #amountHkd="{ record, index }">
                  <FormItem :name="['commissionList', index, 'amount']" :rules="{
                    required: true,
                    message: $t('commission.validate.required.amount')
                  }" style="margin-bottom: 0px;">
                      <span>{{money(record.amount * form.realExchangeRate)}} (HKD)</span>
                  </FormItem>
                </template>
                <template #commissionHkd="{ record, index }">
                  <FormItem :name="['commissionList', index, 'commission']" :rules="{
                    required: true,
                    message: $t('commission.validate.required.commission')
                  }" style="margin-bottom: 0px;">
                      <span>{{money(record.commission * form.realExchangeRate)}} (HKD)</span>
                  </FormItem>
                </template>
              </Table>
            </Card>
            </Col>

            <Col span="24" style="margin-top:30px;">
            <Card size="small" :title="$t('advisorCommission.name.title')">
              <Table size="small" :columns="advisorCommissionColumns" :data-source="form.advisorCommissionList" bordered
                :pagination="false">
                <template #idAdv="{ record, index }">
                  <FormItem :name="['advisorCommissionList', index, 'idAdv']" :rules="{
                    required: true,
                    message: $t('advisorCommission.validate.required.id_adv')
                  }" style="margin-bottom: 0px;">
                    <Select v-model:value="record.idAdv"
                      :placeholder="$t('advisorCommission.validate.required.id_adv')"
                      :filter-option="filterOption"
                      show-search>
                      <SelectOption :key="sk" v-for="(item,sk) in selectList['advisorList']" :title="item['name']" :value="item['idAdv']">{{
                      item['name'] }}</SelectOption>
                    </Select>
                  </FormItem>
                </template>
                <template #commissionRate="{ record, index }">
                  <FormItem :name="['advisorCommissionList', index, 'commissionRate']" :rules="{
                    required: true,
                    message: $t('advisorCommission.validate.required.commission_rate')
                  }" style="margin-bottom: 0px;">
                    <InputNumber v-model:value="record.commissionRate" :min="0" placeholder="Commission Rate"
      :max="100"
      :formatter="value => `${value}%`"
      :parser="value => value.replace('%', '')"
      :step="0.01"
      :precision="2"
                      @change="changeCommission" />
                  </FormItem>
                </template>
                <template #commission="{ record, index }">
                  <FormItem :name="['advisorCommissionList', index, 'commission']" :rules="{
                    required: true,
                    message: $t('advisorCommission.validate.required.commission')
                  }" style="margin-bottom: 0px;">
                    <InputNumber disabled v-model:value="record.commission" :min="0" placeholder="Commission" :step="0.01" :precision="2"
                      @change="changeCommission" />
                  </FormItem>
                </template>
                <template #totalCommission="{ record, index }">
                  <FormItem :name="['advisorCommissionList', index, 'totalCommission']" :rules="{
                    required: true,
                    message: $t('advisorCommission.validate.required.total_commission')
                  }" style="margin-bottom: 0px;">
                    <InputNumber disabled v-model:value="record.totalCommission" :min="0" placeholder="Total Commission" :step="0.01" :precision="2"
                      @change="changeCommission" />
                  </FormItem>
                </template>
              </Table>
            </Card>
            </Col>

            <Col span="24" style="margin-top:30px;">
            <Card size="small" :title="$t('extraOverride.name.title')">
              <template #extra>
                <Button size="small" type="primary" block @click="addExtraOverride">
                <PlusOutlined />
                  {{ this.$t("public.name.add") }}
                </Button>
              </template>
              <Table size="small" :columns="extraOverrideColumns" :data-source="form.extraOverrideList" bordered
                :pagination="false">
                <template #action="{ record, index }">
                  <div class="editable-row-operations">
                    <span>
                      <!-- <template v-if="actionType === 'edit'">
                        <a @click="editOther(record)">{{ this.$t("public.name.edit") }}</a>
                              &nbsp;
                      </template> -->
                      <Popconfirm :title="$t('public.name.are_you_sure_delete')" @confirm="removeExtraOverride(record)">
                        <a>{{ this.$t("public.name.del") }}</a>
                      </Popconfirm>
                    </span>
                  </div>
                </template>
                <template #idAdv="{ record, index }">
                  <FormItem :name="['extraOverrideList', index, 'idAdv']" :rules="{
                    required: true,
                    message: $t('extraOverride.validate.required.id_adv')
                  }" style="margin-bottom: 0px;" >
                    <Select v-model:value="record.idAdv"
                      :placeholder="$t('extraOverride.validate.required.id_adv')" :filter-option="filterOption"
                      show-search>
                      <SelectOption :key="sk" v-for="(item,sk) in selectList['advisorList']" :value="item['idAdv']" :title="item['name']">{{
                      item['name'] }}</SelectOption>
                    </Select>
                  </FormItem>
                </template>
                <template #commissionRate="{ record, index }">
                  <FormItem :name="['extraOverrideList', index, 'commissionRate']" :rules="{
                    required: true,
                    message: $t('extraOverride.validate.required.commission_rate')
                  }" style="margin-bottom: 0px;">
                    <InputNumber v-model:value="record.commissionRate" :min="0.01" placeholder="Commission Rate"
                      :max="100"
                      :formatter="value => `${value}%`"
      :parser="value => value.replace('%', '')"
                      :step="0.01"
                      :precision="2"
                      @change="changeCommission" />
                  </FormItem>
                </template>
                <template #override="{ record, index }">
                  <FormItem :name="['extraOverrideList', index, 'override']" :rules="{
                    required: true,
                    message: $t('extraOverride.validate.required.override')
                  }" style="margin-bottom: 0px;">
                    <InputNumber v-model:value="record.override" :min="0" placeholder="Override"
                      disabled
                      :step="0.01"
                      :precision="2"
                       />
                  </FormItem>
                </template>
                <template #totalOverride="{ record, index }">
                  <FormItem :name="['extraOverrideList', index, 'totalOverride']" :rules="{
                    required: true,
                    message: $t('extraOverride.validate.required.total_override')
                  }" style="margin-bottom: 0px;">
                    <InputNumber v-model:value="record.totalOverride" :min="0" placeholder="Total Override"
                      disabled
                      :step="0.01"
                      :precision="2"
                       />
                  </FormItem>
                </template>
              </Table>
            </Card>
            </Col>

            <Col span="24" style="margin-top:30px;">
            <Card size="small" :title="$t('other.name.title')">
              <template #extra>
                <Button size="small" type="primary" block @click="addOther">
                <PlusOutlined />
                  {{ this.$t("public.name.add") }}
                </Button>
              </template>
              <Table size="small" :columns="otherColumns" :data-source="form.otherList" bordered
                :pagination="false">
                <template #action="{ record, index }">
                  <div class="editable-row-operations">
                    <span>
                      <!-- <template v-if="actionType === 'edit'">
                        <a @click="editOther(record)">{{ this.$t("public.name.edit") }}</a>
                              &nbsp;
                      </template> -->
                      <Popconfirm :title="$t('public.name.are_you_sure_delete')" @confirm="removeOther(record)">
                        <a>{{ this.$t("public.name.del") }}</a>
                      </Popconfirm>
                    </span>
                  </div>
                </template>
                <template #idAdv="{ record, index }">
                  <FormItem :name="['otherList', index, 'idAdv']" :rules="{
                    required: true,
                    message: $t('advisorCommission.validate.required.id_adv')
                  }" style="margin-bottom: 0px;">
                    <Select v-model:value="record.idAdv"
                      :placeholder="$t('advisorCommission.validate.required.id_adv')"
                      :filter-option="filterOption"
                      show-search>
                      <SelectOption :key="sk" v-for="(item,sk) in selectList['advisorList']" :title="item['name']" :value="item['idAdv']">{{
                      item['name'] }}</SelectOption>
                    </Select>
                  </FormItem>
                </template>
                <template #idSub="{ record, index }">
                  <FormItem :name="['otherList', index, 'idSub']" :rules="{
                    required: true,
                    message: $t('other.validate.required.id_sub')
                  }" style="margin-bottom: 0px;">
                    <Select v-model:value="record.idSub"
                      :placeholder="$t('other.validate.required.id_sub')">
                      <SelectOption :key="sk" v-for="(item,sk) in selectList['subjectList']" :value="item['idSub']">{{
                      item['subjectNameHk'] }}</SelectOption>
                    </Select>
                  </FormItem>
                </template>
                <template #otherAmount="{ record, index }">
                  <FormItem :name="['otherList', index, 'otherAmount']" :rules="{
                    required: true,
                    message: $t('other.validate.required.other_amount')
                  }" style="margin-bottom: 0px;">
                    <InputNumber v-model:value="record.otherAmount" :step="0.01" :precision="2"/>
                  </FormItem>
                </template>
              </Table>
            </Card>
            </Col>
          </Row>
        </Form>
        </Col>
      </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Space,
  Card,
  Table,
  Popconfirm,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons-vue";
import { PaymentAdd } from "@/api/PaymentAdd";
import { PaymentEdit } from "@/api/PaymentEdit";
import { PolicyListAll } from "@/api/PolicyListAll"
import { PlanListAll } from "@/api/PlanListAll"
import { SubjectListAll } from "@/api/SubjectListAll"
import { PaymentGetLastPayment } from "@/api/PaymentGetLastPayment"
import { AdvisorOverrideGetByIdAdv } from "@/api/AdvisorOverrideGetByIdAdv"
import { AdvisorListAll } from "@/api/AdvisorListAll"
import { CurrencyListAll } from "@/api/CurrencyListAll"
import { PayModeListAll } from "@/api/PayModeListAll"
import moment from "moment";


export default {
  name: "PaymentEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    MinusCircleOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea: Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    Space,
    Card,
    Table,
    Popconfirm,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idPay: "",
        idPol: "",
        fromMonth: "",
        toMonth: "",
        period: "",
        bankCharge: 0,
        afyp: "",
        totalCommission: "",
        paymentDate: "",
        fyc: "",
        ifyp: "",
        submittedAfyp: "",
        adjustedAfyp: "",
        isRenewal: "",
        createDate: "",
	      others : "",
        realExchangeRate : "",
        afypRate : "",
	      extraRate : 0,
        extraCommission : 0,
        extraOveride : "",
        extraBonus : 0,
	remark : "",
	renewalCommission : "",
        commissionList: [],
        advisorCommissionList: [],
        extraOverrideList: [],
        otherList: [],
      },
      lastPayment: null,
      currentPolicy: null,
      periodStep: 1,
      // lastPaymentPeriod: 0,
      fileList: {
      },
      selectList: {
        policyList: [],
        planList: [],
        currencyList: [],
        payModeList: [],
        subjectList: [],
      },
      validateRules: {
        idPay: [
          {
            required: true,
            message: this.$t("payment.validate.required.id_pay"),
            trigger: "change",
            type: "number",
          },
        ],
        idPol: [
          {
            required: true,
            message: this.$t("payment.validate.required.id_pol"),
            trigger: "change",
            type: "number",
          },
        ],
        fromMonth: [
          {
            required: true,
            message: this.$t("payment.validate.required.from_month"),
            trigger: "change",
          },
        ],
        toMonth: [
          {
            required: true,
            message: this.$t("payment.validate.required.to_month"),
            trigger: "change",
          },
        ],
        period: [
          {
            required: true,
            message: this.$t("payment.validate.required.period"),
            trigger: "change",
            type: "number",
          },
        ],
        bankCharge: [
          {
            required: true,
            message: this.$t("payment.validate.required.bank_charge"),
            trigger: "change",
            type: "number",
          },
        ],
        afyp: [
          {
            required: true,
            message: this.$t("payment.validate.required.afyp"),
            trigger: "change",
            type: "number",
          },
        ],
        totalCommission: [
          {
            required: true,
            message: this.$t("payment.validate.required.total_commission"),
            trigger: "change",
            type: "number",
          },
        ],
        paymentDate: [
          {
            required: true,
            message: this.$t("payment.validate.required.payment_date"),
            trigger: "change",
          },
        ],
        fyc: [
          {
            required: true,
            message: this.$t("payment.validate.required.fyc"),
            trigger: "change",
            type: "number",
          },
        ],
        ifyp: [
          {
            required: true,
            message: this.$t("payment.validate.required.ifyp"),
            trigger: "change",
            type: "number",
          },
        ],
        submittedAfyp: [
          {
            required: true,
            message: this.$t("payment.validate.required.submitted_afyp"),
            trigger: "change",
            type: "number",
          },
        ],
        adjustedAfyp: [
          {
            required: true,
            message: this.$t("payment.validate.required.adjusted_afyp"),
            trigger: "change",
            type: "number",
          },
        ],
        isRenewal: [
          {
            required: true,
            message: this.$t("payment.validate.required.is_renewal"),
            trigger: "change",
            type: "number",
          },
        ],
        createDate: [
          {
            required: true,
            message: this.$t("payment.validate.required.create_date"),
            trigger: "change",
          },
        ],
	others: [
          {
            required: true,
            message: this.$t("payment.validate.required.others"),
            trigger: "change",
            type: "number",
          },
        ],
        realExchangeRate: [
          {
            required: true,
            message: this.$t("payment.validate.required.real_exchange_rate"),
            trigger: "change",
            type: "number",
          },
        ],
        afypRate: [
          {
            required: true,
            message: this.$t("payment.validate.required.afyp_rate"),
            trigger: "change",
            type: "number",
          },
        ],
        extraRate: [
          {
            required: true,
            message: this.$t("payment.validate.required.extra_rate"),
            trigger: "change",
            type: "number",
          },
        ],
        extraCommission: [
          {
            required: true,
            message: this.$t("payment.validate.required.extra_commission"),
            trigger: "change",
            type: "number",
          },
        ],
        extraOveride: [
          {
            required: false,
            message: this.$t("payment.validate.required.extra_overide"),
            trigger: "change",
            type: "number",
          },
        ],
        extraBonus: [
          {
            required: false,
            message: this.$t("payment.validate.required.extra_bonus"),
            trigger: "change",
            type: "number",
          },
        ],
	remark: [
          {
            required: false,
            message: this.$t("payment.validate.required.remark"),
            trigger: "change",
          },
        ],
      },
      commissionColumns: [
        { title: this.$t("commission.table.id_pla"), dataIndex: "idPla", key: "idPla", slots: { customRender: "idPla" } },
        { title: this.$t("commission.table.amount"), dataIndex: "amount", key: "amount", slots: { customRender: "amount" } },
        { title: this.$t("commission.table.amount_hkd"), dataIndex: "amount", key: "amount", slots: { customRender: "amountHkd" } },
        { title: this.$t("commission.table.commission_rate"), dataIndex: "commissionRate", key: "commissionRate", slots: { customRender: "commissionRate" } },
        // { title: this.$t("policy.field.currency"), dataIndex: "currency", key: "currency",customRender: () =>{
        //     if(this.currentPolicy){
        //       return this.selectList.currencyList.find(item=>item.idCur===this.currentPolicy.idCur).currencyName
        //     }
        //     return ""
        //   } 
        // },
        { title: this.$t("commission.table.commission"), dataIndex: "commission", key: "commission", slots: { customRender: "commission" } },
        { title: this.$t("commission.table.commission_hkd"), dataIndex: "commission", key: "commission", slots: { customRender: "commissionHkd" } },
        { title: this.$t("payment.table.period"), dataIndex: "period", key: "period", slots: { customRender: "period" } },

      ],
      advisorCommissionColumns: [
        { title: this.$t("advisorCommission.table.id_adv"), dataIndex: "idAdv", key: "idAdv", slots: { customRender: "idAdv" } },
        { title: this.$t("advisorCommission.table.commission_rate"), dataIndex: "commissionRate", key: "commissionRate", slots: { customRender: "commissionRate" } },
        { title: this.$t("advisorCommission.table.commission"), dataIndex: "commission", key: "commission", slots: { customRender: "commission" } },
        // { title: this.$t("advisorCommission.table.total_commission"), dataIndex: "totalCommission", key: "totalCommission", slots: { customRender: "totalCommission" } },
      ],
      extraOverrideColumns: [
          { title: this.$t("extraOverride.table.id_adv"), dataIndex: "idAdv", key: "idAdv", slots: { customRender: "idAdv" } },
          { title: this.$t("extraOverride.table.commission_rate"), dataIndex: "commissionRate", key: "commissionRate", slots: { customRender: "commissionRate" }  },
          { title: this.$t("extraOverride.table.override"), dataIndex: "override", key: "override", slots: { customRender: "override" } },
          // { title: this.$t("extraOverride.table.total_override"), dataIndex: "totalOverride", key: "totalOverride", slots: { customRender: "totalOverride" } },
          { key: "action", fixed: "right", slots: { title: "actionTitle", customRender: "action" } },
      ],
      otherColumns: [
          { title: this.$t("other.table.id_adv"), dataIndex: "idAdv", key: "idAdv", slots: { customRender: "idAdv" } },
          { title: this.$t("other.table.id_sub"), dataIndex: "idSub", key: "idSub", slots: { customRender: "idSub" } },
          { title: this.$t("other.table.other_amount"), dataIndex: "otherAmount", key: "otherAmount", slots: { customRender: "otherAmount" } },
        { key: "action", fixed: "right", slots: { title: "actionTitle", customRender: "action" } },
      ],
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  watch: {
    "visible": {
      deep: true,
      handler: function (newValue, oldValue) {
        if (!newValue) {
          this.$emit("updateList");
        }
      }
    }
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.getPolicyList();
      this.getPlanList();
      this.getAdvisorList();
      this.getCurrencyList();
      this.getPayModeList();
      this.getSubjectList();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      await this.getPolicyList();
      await this.getPlanList();
      await this.getAdvisorList();
      await this.getCurrencyList();
      await this.getPayModeList();
      await this.getSubjectList();
      this.changePolicy(this.form.idPol);
      this.visible = true;
    },
    submit() {
      this.$refs["paymentForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new PaymentAdd();
              break;
            case "edit":
              console.log("edit");
              api = new PaymentEdit();
              break;
            default:
              return false;
          }
          this.form.commissionListStr = JSON.stringify(this.form.commissionList)
          this.form.advisorCommissionListStr = JSON.stringify(this.form.advisorCommissionList)
          this.form.extraOverrideListStr = JSON.stringify(this.form.extraOverrideList)
          this.form.otherListStr = JSON.stringify(this.form.otherList)
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    async getPolicyList() {
      const api = new PolicyListAll();
      let response = await api.get();
      if (0 == parseInt(response.data.code)) {
          this.selectList.policyList = response.data.data;
        } else {
          message.error(response.data.msg);
        }
    },
    getPlanList() {
      const api = new PlanListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.selectList.planList = response.data.data;
        } else {
          message.error(response.data.msg);
        }
      });
    },
    changePolicy(value, option) {
      //构建paymentPolicyPlanList
      const api = new PaymentGetLastPayment();
      api.get({
        idPol: value
      }).then((response) => {
        if (0 == parseInt(response.data.code)) {
          
          this.lastPayment = response.data.data;
        
          let policy = this.selectList.policyList.find(item => item.idPol == value)
          this.currentPolicy = policy;
          
        
          if(this.actionType == 'edit'){
            
            this.changePayMode(this.form.idPayMod)
          }else{
            this.form.commissionList = []
            this.form.advisorCommissionList = []
            this.form.extraOverrideList= []
            this.form.otherList= []
            this.changePayMode(this.currentPolicy.idPayMod);
            this.form.period = 1 * this.periodStep;
          }
          // if(lastPayment){
          //   thisPeriod = lastPayment.period + 1;
          // this.lastPaymentPeriod = lastPayment.period
          // }
          
          // if(thisPeriod > 12){
          //   this.form.isRenewal = 1
          // }else{
          //   this.form.isRenewal = 0
          // }
          this.form.remark = policy.remark
          // this.form.afypRate = policy.afypRate

          this.form.afypRate = this.currentPolicy.afypRate
   

          let payMode = this.selectList.payModeList.find(item => item.idPayMod == this.currentPolicy.idPayMod)
          // if(this.actionType == 'edit'){
          //   this.form.fromMonth = moment(this.lastPayment.fromMonth, "MM/YYYY").format('MM/YYYY')
          //   this.form.toMonth = moment(this.lastPayment.fromMonth, "MM/YYYY").add(thisPeriod * payMode.quantity, 'M').format('MM/YYYY')
          // }else{
          //   if (this.lastPayment) {
          //     this.form.fromMonth = moment(this.lastPayment.toMonth, "MM/YYYY").format('MM/YYYY')
          //     this.form.toMonth = moment(this.lastPayment.toMonth, "MM/YYYY").add(thisPeriod * payMode.quantity, 'M').format('MM/YYYY')
          //   } else {
          //     this.form.fromMonth = moment(this.currentPolicy.policyDate).add(this.currentPolicy.startPeriod - 1, 'M').format('MM/YYYY')
          //     this.form.toMonth = moment(this.currentPolicy.policyDate).add(this.currentPolicy.startPeriod - 1 + thisPeriod * payMode.quantity, 'M').format('MM/YYYY')
          //   }
          // }
          
          if(this.actionType == 'edit'){
            this.form.fromMonth = moment(this.lastPayment.fromMonth, "MM/YYYY").format('MM/YYYY')
            this.form.toMonth = moment(this.lastPayment.fromMonth, "MM/YYYY").add(this.form.period, 'M').format('MM/YYYY')
          }else{
            // 为了避免加字段，直接用extraRate
            this.form.extraRate = policy.extraCommissionRate
            if (this.lastPayment) {
              this.form.fromMonth = moment(this.lastPayment.toMonth, "MM/YYYY").format('MM/YYYY')
              this.form.toMonth = moment(this.lastPayment.toMonth, "MM/YYYY").add(this.form.period, 'M').format('MM/YYYY')
            } else {
              this.form.fromMonth = moment(this.currentPolicy.policyDate).add(this.currentPolicy.startPeriod - 1, 'M').format('MM/YYYY')
              this.form.toMonth = moment(this.currentPolicy.policyDate).add(this.currentPolicy.startPeriod - 1 + this.form.period, 'M').format('MM/YYYY')
            }
          }


          // //计算fyc计算当前所交期数有多少期在第一年内
          // let inYearPeriodNum = 0;
          // //记录年外的期数
          // let outYearPeriodNum = 0;
          // //保单结束日期大于当前交期数的结束，则所有交的期数即为年内期数
          // if(moment(this.currentPolicy.policyDate).add(1,'Y').diff(moment(this.form.toMonth,"MM/YYYY")) >= 0){
          //   inYearPeriodNum = this.form.period
          // }
          // //保单结束日期小于当前交期的结束日期，又大于当前交期的开始日期，则年内期数为保单结束日期减开始日期的月份
          // else if(moment(this.currentPolicy.policyDate).add(1,'Y').diff(moment(this.form.fromMonth,"MM/YYYY")) >= 0){
          //   inYearPeriodNum = moment(this.currentPolicy.policyDate).add(1,'Y').diff(moment(this.form.fromMonth,"MM/YYYY"),'months') / payMode.quantity
          //   outYearPeriodNum = moment(this.form.toMonth,"MM/YYYY").add(1,'M').diff(moment(this.currentPolicy.policyDate).add(1,'Y'),'months') / payMode.quantity
          // }else{
          //   inYearPeriodNum = 0;
          //   outYearPeriodNum = moment(this.form.toMonth,"MM/YYYY").add(1,'M').diff(moment(this.form.fromMonth,"MM/YYYY"),'months') / payMode.quantity
          // }
          // if(inYearPeriodNum > 0){
          //   this.form.isRenewal = 1
          // }else{
          //   this.form.isRenewal = 0
          // }

          //以下，由于Period全部改为月份，所以不再除paymode的quantity  23-04-07  大改动，  periodNum全部均以月份为单位
          //计算fyc计算当前所交期数有多少期在第一年内
          let inYearPeriodNum = 0;
          //记录年外的期数
          let outYearPeriodNum = 0;
          //保单结束日期大于当前交期数的结束，则所有交的期数即为年内期数
          if(moment(this.currentPolicy.policyDate).add(1,'Y').diff(moment(this.form.toMonth,"MM/YYYY")) >= 0){
            inYearPeriodNum = this.form.period
          }
          //保单结束日期小于当前交期的结束日期，又大于当前交期的开始日期，则年内期数为保单结束日期减开始日期的月份
          else if(moment(this.currentPolicy.policyDate).add(1,'Y').diff(moment(this.form.fromMonth,"MM/YYYY")) >= 0){
            inYearPeriodNum = moment(this.currentPolicy.policyDate).add(1,'Y').diff(moment(this.form.fromMonth,"MM/YYYY"),'months') 
            outYearPeriodNum = moment(this.form.toMonth,"MM/YYYY").add(1,'M').diff(moment(this.currentPolicy.policyDate).add(1,'Y'),'months')
          }else{
            inYearPeriodNum = 0;
            outYearPeriodNum = moment(this.form.toMonth,"MM/YYYY").add(1,'M').diff(moment(this.form.fromMonth,"MM/YYYY"),'months')
          }
          if(inYearPeriodNum > 0){
            this.form.isRenewal = 1
          }else{
            this.form.isRenewal = 0
          }
          
          console.log(inYearPeriodNum)
          console.log(outYearPeriodNum)

          //计算fyc
          // this.form.fyc = inYearPeriodNum * this.form.totalCommission * this.form.realExchangeRate
          this.form.renewalCommission = outYearPeriodNum * this.form.totalCommission * this.form.realExchangeRate

          
          

          let currency = this.selectList.currencyList.find(item => item.idCur == this.currentPolicy.idCur)
          this.form.realExchangeRate = currency.exchangeRate


          // for (var i = 0; i < policy.policyPlanList.length; i++) {
          //   var policyPlan = policy.policyPlanList[i]
          //   let commission = {}
          //   commission['amount'] = policyPlan.amount;
          //   commission['isBasic'] = policyPlan.isBasic;
          //   commission['idPolPla'] = policyPlan.idPolPla;
          //   commission['idPla'] = policyPlan.idPla;
          //   //按照期数搜索commissionRate
          //   let commissionRate = 0;

          //   for (var j = 0; j < policyPlan.realCommissionRateList.length; j++) {
          //     let realCommissionRate = policyPlan.realCommissionRateList[j]
          //     if (realCommissionRate.startMonth <= thisPeriod && realCommissionRate.endMonth >= thisPeriod) {
          //       commissionRate = realCommissionRate.commissionRate
          //     }
          //   }
          //   commission['commissionRate'] = commissionRate;
          //   commission['commission'] = commissionRate * policyPlan.amount / 100;

          //   this.form.commissionList.push(commission)
          // }


          //计算出前面共计交了多少个月，用来后续判定commission rate
          // this.form.commissionList = []
          // debugger
          // let monthNum = moment(this.form.fromMonth,"MM/YYYY").diff(moment(this.currentPolicy.policyDate).startOf('month'),'months') + 1;
          // for (var i = 0; i < policy.policyPlanList.length; i++) {
          //   var policyPlan = policy.policyPlanList[i]
          //   for (var j = 0; j < policyPlan.realCommissionRateList.length; j++) {
          //     let realCommissionRate = policyPlan.realCommissionRateList[j]
          //     //按照交的期数，计算出有多少个commission，例如涉及夸区部分，要多条commission
          //     console.log(realCommissionRate.startMonth + ' ' + realCommissionRate.endMonth + ' ' + monthNum + '  ' + (this.form.period + monthNum - 1));
          //     let mixed = this.confMatrix(realCommissionRate.startMonth,realCommissionRate.endMonth,monthNum,this.form.period + monthNum - 1);
          //     console.log("mixed::" + mixed);
          //     if(mixed && (mixed[1] - mixed[0]) > 0){
          //       let commission = {}
          //       commission['amount'] = policyPlan.amount;
          //       commission['isBasic'] = policyPlan.isBasic;
          //       commission['idPolPla'] = policyPlan.idPolPla;
          //       commission['idPla'] = policyPlan.idPla;
          //       commission['commissionRate'] = realCommissionRate.commissionRate;
          //       commission['commission'] = realCommissionRate.commissionRate * policyPlan.amount / payMode.quantity * (mixed[1] - mixed[0] + 1) / 100;
          //       commission['period'] = mixed[1] - mixed[0] + 1
          //       this.form.commissionList.push(commission)
          //     }else{
          //       continue;
          //     }
          //   }
          // }


          if(this.actionType == 'add'){
            
            //如果没有lastPayment，则直接读取，如果有，则读取上一个paymentde advisorOverride
            if(this.lastPayment){



              for (var i = 0; i < this.lastPayment.extraOverrideList.length; i++) {
                var extraOverrideOld = this.lastPayment.extraOverrideList[i]
                let extraOverride = {}
                extraOverride['idAdv'] = extraOverrideOld.idAdv;
                extraOverride['commissionRate'] = extraOverrideOld.commissionRate;
                this.form.extraOverrideList.push(extraOverride)
              }

              for (i = 0; i < this.lastPayment.otherList.length; i++) {
                var otherOld = this.lastPayment.otherList[i]
                let other = {}
                other['idAdv'] = otherOld.idAdv;
                other['idSub'] = otherOld.idSub;
                other['otherAmount'] = otherOld.otherAmount;
                this.form.otherList.push(other)
              }

              this.form.advisorCommissionList = this.lastPayment.advisorCommissionList;
              //清空原来的idAdvCom
              if(this.form.advisorCommissionList){
                for(let i = 0;i < this.form.advisorCommissionList.length;i ++){
                  this.form.advisorCommissionList[i].idAdvCom = null;
                }
              }

              //其他数据也要读取上一个payment
              this.form.bankCharge = this.lastPayment.bankCharge;
              this.form.extraRate = this.lastPayment.extraRate
              console.log("first::::::::::::" + this.form.extraRate)
              this.form.remark = this.lastPayment.remark
              
            }else{
              for (i = 0; i < policy.extraOverrideTemplateList.length; i++) {
                var extraOverrideTemplate = policy.extraOverrideTemplateList[i]
                let extraOverride = {}
                extraOverride['idAdv'] = extraOverrideTemplate.idAdv;
                extraOverride['commissionRate'] = extraOverrideTemplate.commissionRate;
                this.form.extraOverrideList.push(extraOverride)
              }
              this.getAdvisorOverrideList(policy.idAdv)
            }
            
          }
          
          this.changePeriod(this.form.period)
          this.changeCommission();
          
        } else {
          message.error(response.data.msg);
        }
      });
    },
    changePayMode(idPayMod) {
      this.form.idPayMod= idPayMod
      let quantity = this.selectList['payModeList'].find((item) => {
        return item['idPayMod'] === idPayMod
      }).quantity;
      console.log('quantity:' + quantity)
      this.periodStep = quantity
      this.form.period = quantity
      this.changePeriod(this.form.period)
    },
    changePeriod(value) {
    
      //修改的期数，只能是支付模式的Step的倍数
      if(value % this.periodStep > 0){
        this.form.period = 1 * this.periodStep
        
      }
      
      let payMode = this.selectList.payModeList.find(item => item.idPayMod == this.currentPolicy.idPayMod)
      // if(this.actionType == 'edit'){
      //   this.form.fromMonth = moment(this.lastPayment.fromMonth, "MM/YYYY").format('MM/YYYY')
      //   this.form.toMonth = moment(this.lastPayment.fromMonth, "MM/YYYY").add(value * payMode.quantity, 'M').format('MM/YYYY')
      // }else{
      //   if (this.lastPayment) {
      //     this.form.fromMonth = moment(this.lastPayment.toMonth, "MM/YYYY").format('MM/YYYY')
      //     this.form.toMonth = moment(this.lastPayment.toMonth, "MM/YYYY").add(value * payMode.quantity, 'M').format('MM/YYYY')
      //   } else {
      //     this.form.fromMonth = moment(this.currentPolicy.policyDate).add(this.currentPolicy.startPeriod - 1, 'M').format('MM/YYYY')
      //     this.form.toMonth = moment(this.currentPolicy.policyDate).add(this.currentPolicy.startPeriod - 1 + value * payMode.quantity, 'M').format('MM/YYYY')
      //   }
      // }
     
      if(this.actionType == 'edit'){
        this.form.fromMonth = moment(this.lastPayment.fromMonth, "MM/YYYY").format('MM/YYYY')
        this.form.toMonth = moment(this.lastPayment.fromMonth, "MM/YYYY").add(value, 'M').format('MM/YYYY')
      }else{
        if (this.lastPayment) {
          this.form.fromMonth = moment(this.lastPayment.toMonth, "MM/YYYY").format('MM/YYYY')
          this.form.toMonth = moment(this.lastPayment.toMonth, "MM/YYYY").add(value, 'M').format('MM/YYYY')
        } else {
          this.form.fromMonth = moment(this.currentPolicy.policyDate).add(this.currentPolicy.startPeriod - 1, 'M').format('MM/YYYY')
          this.form.toMonth = moment(this.currentPolicy.policyDate).add(this.currentPolicy.startPeriod - 1 + value, 'M').format('MM/YYYY')
        }
        this.form.commissionList = []
        //计算出前面共计交了多少个月，用来后续判定commission rate
        let monthNum = moment(this.form.fromMonth,"MM/YYYY").diff(moment(this.currentPolicy.policyDate).startOf('month'),'months') + 1;
        console.log('monthNum:::',monthNum)
        for (var i = 0; i < this.currentPolicy.policyPlanList.length; i++) {
          var policyPlan = this.currentPolicy.policyPlanList[i]
          for (var j = 0; j < policyPlan.realCommissionRateList.length; j++) {
            let realCommissionRate = policyPlan.realCommissionRateList[j]
            //按照交的期数，计算出有多少个commission，例如涉及夸区部分，要多条commission
            console.log(realCommissionRate.startMonth + ' ' + realCommissionRate.endMonth + ' ' + monthNum + '  ' + (this.form.period + monthNum - 1));
            let mixed = this.confMatrix(realCommissionRate.startMonth,realCommissionRate.endMonth,monthNum,this.form.period +monthNum - 1);
            if(mixed){
              let commission = {}
              //基础金额直接显示原金额，不管是按年还是按月，但是后续commission则按月算
              // 此处再加一条规则，如果存在上次的支付记录，则按照上次支付金额自动填入
              if(this.lastPayment){
                let oldData = this.lastPayment.commissionList.find(item => item.idPolPla === policyPlan.idPolPla)
                if(oldData){
                  commission['amount'] = oldData.amount;
                }else{
                  commission['amount'] = policyPlan.amount;
                }
              }else{
                commission['amount'] = policyPlan.amount;
              }

              commission['isBasic'] = policyPlan.isBasic;
              commission['idPolPla'] = policyPlan.idPolPla;
              commission['idPla'] = policyPlan.idPla;
              //此处要依据上期payment获取对应的rate
              commission['commissionRate'] = this.getLastPaymentCommissionRate(realCommissionRate)
              // commission['commissionRate'] = realCommissionRate.commissionRate;

              commission['commission'] = commission.commissionRate * policyPlan.amount / payMode.quantity * (mixed[1] - mixed[0] + 1) / 100;
              commission['period'] = mixed[1] - mixed[0] + 1
              commission['idReaComRat'] = realCommissionRate.idReaComRat
              this.form.commissionList.push(commission)
            }else{
              continue;
            }
          }
        }
        

      }
      
      

      
      this.changeCommission();
    },
    getLastPaymentCommissionRate(realCommissionRate){
      if(this.lastPayment){
        for(let i = 0; i < this.lastPayment.commissionList.length;i++){
          if(this.lastPayment.commissionList[i].idReaComRat == realCommissionRate.idReaComRat){
            return this.lastPayment.commissionList[i].commissionRate
          }
        }
      }
      return realCommissionRate.commissionRate
    },

    getAdvisorOverrideList(idAdv) {
      let api = new AdvisorOverrideGetByIdAdv();
      api.get({
        idAdv: idAdv
      }).then((response) => {
        if (0 == parseInt(response.data.code)) {
          for (var i = 0; i < response.data.data.length; i++) {
            var advisorOverride = response.data.data[i]
            let advisorCommission = {}
            advisorCommission['idAdv'] = advisorOverride.headIdAdv;
            advisorCommission['commissionRate'] = advisorOverride.overrideRate;
            advisorCommission['commission'] = 0;
            advisorCommission['totalCommission'] = 0;
            this.form.advisorCommissionList.push(advisorCommission)
          }
          this.changeCommission();
        } else {
          message.error(response.data.msg);
        }
      })
    },
    changeCommission() {
      let payMode = this.selectList.payModeList.find(item => item.idPayMod == this.currentPolicy.idPayMod)
      let totalCommission = 0;
      this.form.ifyp = 0;
      for (var i = 0; i < this.form.commissionList.length; i++) {
        this.form.commissionList[i].commission = this.form.commissionList[i].amount / payMode.quantity * this.form.commissionList[i].period * this.form.commissionList[i].commissionRate / 100
        totalCommission += this.form.commissionList[i].commission;
        this.form.ifyp += this.form.commissionList[i].amount;
      }
      console.log('totalCommission: ' + totalCommission)
      this.form.totalCommission = totalCommission
    
      //计算Purmium Amount
      if(this.form.realExchangeRate && this.currentPolicy.idPayMod){

        // this.form.ifyp = this.currentPolicy.paymentAmount * this.form.realExchangeRate * 1 / (payMode.quantity / 12); 
        //这里，ifyp是废弃的，但是用作了Premium amount，即下面保费总和 所以下面这行注释了，改为计算总和 24-2-28
        // this.form.ifyp = this.currentPolicy.paymentAmount

        //填入afyp，只有第一期会有
        if(this.actionType == 'add'){
            if(this.lastPayment){
              this.form.afyp = 0
              this.form.afypRate = 0
            }else{
              if(this.currentPolicy.startPeriod === 1){
                
                
                
                this.form.afyp = this.form.ifyp * this.form.realExchangeRate * 1 / (payMode.quantity / 12) * this.form.afypRate / 100;
              }else{
                this.form.afyp = 0;
                this.form.afypRate = 0
              }
            }
          }
      }
      //计算fyc计算当前所交期数有多少期在第一年内
      let inYearPeriodNum = 0;
          //记录年外的期数
          let outYearPeriodNum = 0;
          
          //保单结束日期大于当前交期数的结束，则所有交的期数即为年内期数
          if(moment(this.currentPolicy.policyDate).add(1,'Y').diff(moment(this.form.toMonth,"MM/YYYY")) >= 0){
            inYearPeriodNum = this.form.period
          }
          //保单结束日期小于当前交期的结束日期，又大于当前交期的开始日期，则年内期数为保单结束日期减开始日期的月份
          else if(moment(this.currentPolicy.policyDate).add(1,'Y').diff(moment(this.form.fromMonth,"MM/YYYY")) >= 0){
            inYearPeriodNum = moment(this.currentPolicy.policyDate).add(1,'Y').diff(moment(this.form.fromMonth,"MM/YYYY"),'months')
            outYearPeriodNum = moment(this.form.toMonth,"MM/YYYY").add(1,'M').diff(moment(this.currentPolicy.policyDate).add(1,'Y'),'months') 
          }else{
            inYearPeriodNum = 0;
            outYearPeriodNum = moment(this.form.toMonth,"MM/YYYY").add(1,'M').diff(moment(this.form.fromMonth,"MM/YYYY"),'months')
          }
          
          console.log(inYearPeriodNum)
          console.log(outYearPeriodNum)

          //计算fyc
          // this.form.fyc = inYearPeriodNum * this.form.totalCommission * this.form.realExchangeRate
          // this.form.fyc = this.form.totalCommission * this.form.realExchangeRate;
          //fyc是inYearPeriodNum的期数内的数额，所以此处要遍历commissionList,找出inYearPeriodNum期数内的总额
          let k = 0;
          let fyc = 0;
          let renewalCommission = 0;
          //加入一个辅助变量，来标记是否变了idPla，因为fyc和renew是按每个plan计
          let idPla = null;
          for(let i = 0;i < this.form.commissionList.length;i ++){
      
            for(let j = 0;j < this.form.commissionList[i].period;j ++){
              //根据是否切换了plan来统计
              if(idPla == null || idPla != this.form.commissionList[i].idPla){
                idPla = this.form.commissionList[i].idPla;
                k = 0;
              }
              if(k < inYearPeriodNum){
                //fyc = fyc + this.form.commissionList[i].commission / this.form.commissionList[i].period;
                fyc = fyc + this.form.commissionList[i].amount / payMode.quantity * this.form.commissionList[i].commissionRate / 100
                console.log('fyc:' + fyc)
              }else{
                //renewalCommission = renewalCommission + this.form.commissionList[i].commission / this.form.commissionList[i].period;
                renewalCommission = renewalCommission + this.form.commissionList[i].amount / payMode.quantity * this.form.commissionList[i].commissionRate / 100
              }
              k ++;
            }

          }

      // this.form.extraCommission = this.form.ifyp * this.currentPolicy.extraCommissionRate / 100 * this.form.realExchangeRate * inYearPeriodNum / this.form.period  
      if(this.form.extraRate && this.currentPolicy.policyPlanList[0]){
        this.form.extraCommission = this.form.realExchangeRate * this.currentPolicy.policyPlanList[0].amount / payMode.quantity * this.form.extraRate / 100 * inYearPeriodNum;
      }
      this.form.fyc = fyc * this.form.realExchangeRate + this.form.extraCommission;
      this.form.renewalCommission = renewalCommission * this.form.realExchangeRate;
          // this.form.renewalCommission = outYearPeriodNum * this.form.totalCommission * this.form.realExchangeRate


      // if(this.form.commissionList[0]){
      //   // this.form.extraCommission = this.form.ifyp * this.form.extraRate / 100 * this.form.realExchangeRate
      //   //不再要這個extraCommission了
      //   // this.form.extraCommission = this.form.commissionList[0].amount * this.form.extraRate / 100
      //   for(let i = 0;i < this.form.extraOverrideList.length;i ++){
      //     this.form.extraOverrideList[i].override = this.form.extraOverrideList[i].commissionRate * this.form.commissionList[0].amount / payMode.quantity * this.form.commissionList[0].period * this.form.realExchangeRate / 100 * inYearPeriodNum
      //   }
      // }
      for(let i = 0;i < this.form.extraOverrideList.length;i ++){
        this.form.extraOverrideList[i].override = this.form.extraOverrideList[i].commissionRate * this.form.fyc / 100
        this.form.extraOverrideList[i].totalOverride = this.form.extraOverrideList[i].commissionRate * this.form.fyc / 100 * inYearPeriodNum
      }
      
      for (i = 0; i < this.form.advisorCommissionList.length; i++) {
        // this.form.advisorCommissionList[i].commission = this.form.advisorCommissionList[i].commissionRate * this.form.fyc / 100
        // this.form.advisorCommissionList[i].totalCommission = this.form.advisorCommissionList[i].commissionRate * this.form.fyc / 100 * inYearPeriodNum
        //this.form.advisorCommissionList[i].commission = this.form.advisorCommissionList[i].commissionRate * totalCommission * this.form.realExchangeRate / 100
        //this.form.advisorCommissionList[i].totalCommission = this.form.advisorCommissionList[i].commissionRate * totalCommission * this.form.realExchangeRate / 100 * inYearPeriodNum
        
        this.form.advisorCommissionList[i].commission = this.form.advisorCommissionList[i].commissionRate * fyc * this.form.realExchangeRate / 100 
        this.form.advisorCommissionList[i].totalCommission = this.form.advisorCommissionList[i].commissionRate * fyc * this.form.realExchangeRate / 100 
      }
      
      
      this.form.totalFyc = this.form.fyc + this.form.renewalCommission
    },
    getAdvisorList() {
      const api = new AdvisorListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.selectList.advisorList = response.data.data;
        } else {
          message.error(response.data.msg);
        }
      });
    },
    getCurrencyList() {
      const api = new CurrencyListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.selectList.currencyList = response.data.data;
        } else {
          message.error(response.data.msg);
        }
      });
    },
    getPayModeList() {
      const api = new PayModeListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.payModeList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    getSubjectList() {
      const api = new SubjectListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.selectList.subjectList = response.data.data;
        } else {
          message.error(response.data.msg);
        }
      });
    },
    addOther() {
      this.form.otherList.push({
        idSub: null,
        idAdv: null,
        otherAmount: null,
      });
    },
    removeOther(other){
      let index = this.form.otherList.indexOf(other);
      if (index !== -1) {
        this.form.otherList.splice(index, 1);
      }
    },

    addExtraOverride() {
      this.form.extraOverrideList.push({
        idAdv: null,
        commissionRate: null,
        override: null
      });
    },
    removeExtraOverride(extraOverride){
      let index = this.form.extraOverrideList.indexOf(extraOverride);
      if (index !== -1) {
        this.form.extraOverrideList.splice(index, 1);
      }
    },
    //计算两个区间的交集
    confMatrix(s1,e1,s2,e2){
      let start = [Math.min(s1,e1),Math.min(s2,e2)];//区间的两个最小值
      let end = [Math.max(s1,e1),Math.max(s2,e2)];//区间的两个最大值
      if(Math.max(...start) <= Math.min(...end)){//最大值里的最小值 是否 小于等于 最大值的最小值
        return [Math.max(...start),Math.min(...end)]
      }else{
        return 0;
      }
        
    },
    filterOption(input, option) {
    // console.log("option.title.toLowerCase()：" + option.title.toLowerCase());
    // console.log("input.toLowerCase()：" + input.toLowerCase());
    // console.log("option.title.toLowerCase().indexOf(input.toLowerCase())：" + option.title.toLowerCase().indexOf(input.toLowerCase()));
    return option.title.toLowerCase().indexOf(input.toLowerCase()) != -1;
  }, 
    // reset data
    _resetData() {
      this.currentPolicy = null;
      this.form = {
        idPay: "",
        idPol: "",
        fromMonth: "",
        toMonth: "",
        period: "",
        bankCharge: 0,
        afyp: "",
        totalCommission: "",
        paymentDate: "",
        fyc: "",
        ifyp: "",
        submittedAfyp: "",
        adjustedAfyp: "",
        isRenewal: "",
        createDate: "",
	      others : "",
        realExchangeRate : "",
        afypRate : "",
	      extraRate : 0,
        extraCommission : 0,
        extraOveride : "",
        extraBonus : 0,
      	remark : "",
	renewalCommission : "",
        commissionList: [],
        advisorCommissionList: [],
        extraOverrideList: [],
        otherList: [],
      };
    },
  },
};
</script>

<style lang="less">

</style>