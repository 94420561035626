import { createApp } from 'vue';
import 'ant-design-vue/dist/antd.css';
import App from './App';
import store from '@/store'
import router from '@/router';
import i18n from '@/i18n'
import "@/assets/less/public.less"
import mixin from '@/mixin';

const app = createApp(App);

app.use(store).use(i18n).use(router).mixin(mixin).mount('#app');