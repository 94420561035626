/**
 * @Auther    william
 * @Name      state define file
 * @Note      
 * @Time      2021/04/13 16:53
 */

let state = {
    systemTag: "crm",
    language: "enUS",
    logo: "",
    theme: "dark",
    copyRight: "Account Master ©2022 Created by Elite Ltd",
    token: "",
    noImage: require("@/assets/img/no-img-small.png"),
    username: "",
    portrait: "",
    lastIp: "",
    lastTime: 0,
    phone: "",
    login: "",
    email: "",
    type: 0,
    permissionList:[]
}

export default state