<template>
  <Modal id="PaymentDetail" width="60%" :visible="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('payment.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('payment.field.id_pay')">{{data.idPay}}</DescriptionsItem>
      <DescriptionsItem :label="$t('payment.field.id_pol')">{{data.policyPloicyNo}}</DescriptionsItem>
      <DescriptionsItem :label="$t('payment.field.from_month')">{{data.fromMonth}}</DescriptionsItem>
      <DescriptionsItem :label="$t('payment.field.to_month')">{{data.toMonth}}</DescriptionsItem>
      <DescriptionsItem :label="$t('payment.field.period')">{{data.period}}</DescriptionsItem>
      <DescriptionsItem :label="$t('payment.field.bank_charge')">{{data.bankCharge}}</DescriptionsItem>
      <DescriptionsItem :label="$t('payment.field.afyp')">{{data.afyp}}</DescriptionsItem>
      <DescriptionsItem :label="$t('payment.field.total_commission')">{{data.totalCommission}}</DescriptionsItem>
      <DescriptionsItem :label="$t('payment.field.payment_date')">{{data.paymentDate}}</DescriptionsItem>
      <DescriptionsItem :label="$t('payment.field.fyc')">{{data.fyc}}</DescriptionsItem>
      <DescriptionsItem :label="$t('payment.field.ifyp')">{{data.ifyp}}</DescriptionsItem>
      <!-- <DescriptionsItem :label="$t('payment.field.submitted_afyp')">{{data.submittedAfyp}}</DescriptionsItem>
      <DescriptionsItem :label="$t('payment.field.adjusted_afyp')">{{data.adjustedAfyp}}</DescriptionsItem> -->
      <DescriptionsItem :label="$t('payment.field.is_renewal')">{{data.isRenewal}}</DescriptionsItem>
      <DescriptionsItem :label="$t('payment.field.create_date')">{{data.createDate}}</DescriptionsItem>
      <!-- <DescriptionsItem :label="$t('payment.field.others')">{{data.others}}</DescriptionsItem> -->
      <DescriptionsItem :label="$t('payment.field.real_exchange_rate')">{{data.realExchangeRate}}</DescriptionsItem>
      <DescriptionsItem :label="$t('payment.field.afyp_rate')">{{data.afypRate}}</DescriptionsItem>
      <DescriptionsItem :label="$t('payment.field.extra_rate')">{{data.extraRate}}</DescriptionsItem>
      <DescriptionsItem :label="$t('payment.field.extra_commission')">{{data.extraCommission}}</DescriptionsItem>
      <DescriptionsItem :label="$t('payment.field.extra_overide')">{{data.extraOveride}}</DescriptionsItem>
      <DescriptionsItem :label="$t('payment.field.extra_bonus')">{{data.extraBonus}}</DescriptionsItem>
      <DescriptionsItem :label="$t('payment.field.remark')">{{data.remark}}</DescriptionsItem>
      <DescriptionsItem :label="$t('payment.field.renewal_commission')">{{data.renewalCommission}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>

<script>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "PaymentDetail",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      selectList:{
      },
      data: {
      },
      visible: false,
    };
  },
  computed: {
  },
  methods: {
    async detailShow(obj) {
      this.data = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
  },
}
</script>

<style lang="less">
</style>