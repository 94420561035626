<template>
  <Modal
    id="AdvisorOverrideEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
    :zIndex="9999999900"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="advisorOverrideForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                  <Col span="10">
                    <FormItem :label="$t('advisorOverride.field.head_id_adv')" name="headIdAdv">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="form.headIdAdv"
                              :placeholder="$t('advisorOverride.validate.required.head_id_adv')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                              :filter-option="filterOption"
                      show-search
                      >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['advisorList']" :title="item['name']" :value="item['idAdv']">{{ item['name'] }}</SelectOption>
                      </Select>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('advisorOverride.field.override_rate')" name="overrideRate">
                      <InputNumber
                              v-model:value="form.overrideRate"
                              :placeholder="$t('advisorOverride.validate.required.override_rate')"
                              :step="0.01"
                      />
                  </FormItem>
                  </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Space,
  Card,
  Table,
  Popconfirm,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { AdvisorOverrideAdd } from "@/api/AdvisorOverrideAdd";
import { AdvisorOverrideEdit } from "@/api/AdvisorOverrideEdit";
import { AdvisorListAll } from "@/api/AdvisorListAll"


export default {
  name: "AdvisorOverrideEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    MinusCircleOutlined,
    ExclamationCircleOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    Space,
    Card,
    Table,
    Popconfirm,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idAdvOve : "",
        idAdv : "",
        headIdAdv : "",
        overrideRate : "",
      },
      fileList: {
      },
      selectList: {
        advisorList: [],
      },
      validateRules: {
        idAdvOve: [
          {
            required: true,
            message: this.$t("advisorOverride.validate.required.id_adv_ove"),
            trigger: "change",
            type: "number",
          },
        ],
        idAdv: [
          {
            required: true,
            message: this.$t("advisorOverride.validate.required.id_adv"),
            trigger: "change",
            type: "number",
          },
        ],
        headIdAdv: [
          {
            required: true,
            message: this.$t("advisorOverride.validate.required.head_id_adv"),
            trigger: "change",
            type: "number",
          },
        ],
        overrideRate: [
          {
            required: true,
            message: this.$t("advisorOverride.validate.required.override_rate"),
            trigger: "change",
            type: "number",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  methods: {
    addShow(idAdv) {
      this.actionType = "add";
      this._resetData();
      this.form.idAdv = idAdv
      this.getAdvisorList();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.getAdvisorList();
      this.visible = true;
    },
    submit() {
      this.$refs["advisorOverrideForm"]
        .validate()
        .then(() => {
          //没有传父id，则代表新增时的状况，直接回调对象，不请求，由新增的api自行按数组添加数据
          if(!this.form.idAdv){
            this.$emit("updateList",this.form);
            this.visible = false;
            return;
          }
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new AdvisorOverrideAdd();
              break;
            case "edit":
              console.log("edit");
              api = new AdvisorOverrideEdit();
              break;
            default:
              return false;
          }
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList",response.data.data);
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    getAdvisorList() {
      const api = new AdvisorListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.advisorList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    filterOption(input, option) {
    // console.log("option.title.toLowerCase()：" + option.title.toLowerCase());
    // console.log("input.toLowerCase()：" + input.toLowerCase());
    // console.log("option.title.toLowerCase().indexOf(input.toLowerCase())：" + option.title.toLowerCase().indexOf(input.toLowerCase()));
    return option.title.toLowerCase().indexOf(input.toLowerCase()) != -1;
  }, 
    // reset data
    _resetData() {
      this.form = {
        idAdvOve : "",
        idAdv : "",
        headIdAdv : "",
        overrideRate : "",
      };
    },
  },
};
</script>

<style lang="less">
</style>