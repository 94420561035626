<template>
  <Modal
    width="100%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="$t('generalSalary.name.title')"
    :maskClosable="false"
    :keyboard="false"
  >
    <Row id="GeneralSalary">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24" >
          <Form :model="searchForm">
            <Row :gutter="30">
              <Col :xs="24" :sm="4">
                <FormItem :label="$t('generalSalary.name.month')">
                  <MonthPicker valueFormat="YYYY-MM" :placeholder="$t('generalSalary.name.month_placeholder')" v-model:value="searchForm.month" :popupStyle="{ zIndex: 9999999950 }"></MonthPicker>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="4">
                <Button type="primary" @click="general">{{$t('generalSalary.name.generalSalary')}}</Button>&nbsp;
              </Col>
            </Row>
          </Form>
        </Col>
        <Col :span="24">
        <Table :columns="columns" :dataSource="list" :scroll="{ x: true }" rowKey="idEmp" :loading="loading" :pagination="false">
          
          </Table>
        </Col>
      </Row>
    </Col>
  </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Table,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  MonthPicker
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons-vue";
import { SalaryGeneralSalary } from "@/api/SalaryGeneralSalary"
import { SalaryBatchAdd } from "@/api/SalaryBatchAdd"
import moment from "moment";


export default {
  name: "EmployeeEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    EditOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    Table,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    MonthPicker
  },
  data() {
    return {
      visible: false,
      searchForm: {
        month:""
      },
      loading: false,
      list: [],
      editableData: {

      },
      columns: [
          // { title: this.$t("salary.table.id_sal"), fixed: "left", dataIndex: "idSal", key: "idSal" },
          { title: this.$t("salary.table.id_adv"), dataIndex: "advisorName", key: "advisorName" },
          // { title: this.$t("salary.table.payment_date"), dataIndex: "paymentDate", key: "paymentDate", customRender: ({text}) =>{return moment(text).format('YYYY-MM-DD')}},
          { title: this.$t("salary.table.total_afyp"), dataIndex: "totalAfyp", key: "totalAfyp" },
          { title: this.$t("salary.table.total_ifyp"), dataIndex: "totalIfyp", key: "totalIfyp" },
          // { title: this.$t("salary.table.total_renewal"), dataIndex: "totalRenewal", key: "totalRenewal" },
          { title: this.$t("salary.table.payment_amount"), dataIndex: "paymentAmount", key: "paymentAmount" },
          { title: this.$t("salary.table.total_fyc"), dataIndex: "totalFyc", key: "totalFyc" },
          // { title: this.$t("salary.table.create_date"), dataIndex: "createDate", key: "createDate" },
      ],
    };
  },
  computed: {
  },
  methods: {
    addShow() {
      this._resetData();
      this.visible = true;
    },
    general() {
      this.loading = true;
      const api = new SalaryGeneralSalary();
      api
        .get({
          month: this.searchForm.month
        })
        .then((response) => {
          this.loading = false;
          if (0 === parseInt(response.data.code)) {
            const dataTmp = response.data;
            
            this.list = dataTmp.data;
        
            
          } else {
            this.list = [];
          }
        });
    },
    
    submit() {
      let api = new SalaryBatchAdd();
      api.post({
        salaryListStr: JSON.stringify(this.list)
      }).then((response, any) => {
        if (0 == parseInt(response.data.code)) {
          message.success(response.data.msg);
          this.$emit("updateList");
          this.visible = false;
        } else {
          message.error(response.data.msg);
        }
      });
       
    },
    // reset data
    _resetData() {
      this.searchForm = {
        month:""
      };
      this.list = [];
      this.form = {
      };
    },
  },
};
</script>

<style lang="less">
@import url("~@/assets/less/components/home/generalSalary.less");
</style>