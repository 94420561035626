<template>
  <Modal id="AdvisorDetail" width="60%" :visible="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('advisor.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('advisor.field.id_adv')">{{data.idAdv}}</DescriptionsItem>
      <DescriptionsItem :label="$t('advisor.field.name')">{{data.name}}</DescriptionsItem>
      <DescriptionsItem :label="$t('advisor.field.title')">{{data.title}}</DescriptionsItem>
      <DescriptionsItem :label="$t('advisor.field.licence_no')">{{data.licenceNo}}</DescriptionsItem>
      <DescriptionsItem :label="$t('advisor.field.join_date')">{{data.joinDate}}</DescriptionsItem>
      <DescriptionsItem :label="$t('advisor.field.create_date')">{{data.createDate}}</DescriptionsItem>
      <DescriptionsItem :label="$t('advisor.field.status')">{{selectList.statusList.find(item=>item.value===data.status).label}}</DescriptionsItem>
      <DescriptionsItem :label="$t('advisor.field.mpf_no')">{{data.mpfNo}}</DescriptionsItem>
      <DescriptionsItem :label="$t('advisor.field.contribution')">{{data.contribution}}</DescriptionsItem>
      <DescriptionsItem :label="$t('advisor.field.tr_no')">{{data.trNo}}</DescriptionsItem>
      <DescriptionsItem :label="$t('advisor.field.tr_full_name')">{{data.trFullName}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>

<script>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "AdvisorDetail",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      selectList:{
        statusList: [{value: 0,label: 'Off'},{value:1,label: 'On'}],
      },
      data: {
      },
      visible: false,
    };
  },
  computed: {
  },
  methods: {
    async detailShow(obj) {
      this.data = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
  },
}
</script>

<style lang="less">
</style>