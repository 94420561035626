<template>
  <Modal id="SalaryEdit" width="80%" :visible="visible" @cancel="visible = !visible" @ok="submit"
    :title="$t('downloadCommissionPayment.name.title')">
    <Row align="middle" justify="center">
      <Col span="18" class="content">
      <Row>
        <Col span="24">
        <Form ref="salaryForm" :model="form" layout="vertical" :rules="validateRules">
          <Row justify="space-around">
            <Col span="10">
            <FormItem :label="$t('downloadMpfStatement.name.print_date')" name="printDate">
              <DatePicker valueFormat="YYYY/MM/DD" v-model:value="form.printDate" :popupStyle="{ zIndex: 9999999950 }">
              </DatePicker>
            </FormItem>
            </Col>
          </Row>
        </Form>
        </Col>
      </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  MonthPicker,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";

export default {
  name: "SalaryEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea: Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    MonthPicker,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        printDate: ""
      },
      fileList: {
      },
      selectList: {
      },
      validateRules: {
        printDate: [
          {
            required: true,
            message: this.$t("downloadCommissionPayment.validate.required.print_date"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  methods: {
    show() {
      this.actionType = "add";
      this._resetData();
      this.visible = true;
    },
    submit() {
      this.$emit("selectedPrintDate",this.form.printDate);
      this.visible = false;
    },

    // reset data
    _resetData() {
      this.form = {
        printDate: ""
      };
    },
  },
};
</script>

<style lang="less">

</style>